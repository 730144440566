import React, { Component } from "react";
import { Route, Redirect } from "react-router-dom";
import Datetime from "react-datetime";
import jwt_decode from "jwt-decode";
import { Col, Row } from "reactstrap";

import ToastAlert from "./ToastAlert";
import Navbar from "../compon-styled/SupportCenterNavBar";
import CmbSelect from "../compon-controls/CmbSelect";
import { AppHelper_DateAndTimeToString } from "../businessjs/AppHelperFn";
import { AuthHelperAccessCheck } from "../businessjs/AuthHelper";
import { api } from "../businessjs/API";
import {
  Grid,
  TextField,
  Typography,
  MenuItem,
  Paper,
  Fab,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing.unit * 2,
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
});
class ServiceScheduleEdit extends Component {
  constructor(props) {
    super(props);
    this.redirect = 0;
    this.state = {
      ticketId: 0,
      scheduleDate: this.props.location.state.schedule.schedule_date,
      status: "",
      location_id: "",
      remarks: "",
      locationList: [],
      toastItems: [],
      redirect: 0,
    };
  }

  componentDidMount = () => {
    let _scheduleData = this.props.location.state.schedule;
    this.setState({
      ticketId: _scheduleData.ticket_id,
      scheduleDate: _scheduleData.schedule_date,
      status: _scheduleData.scheduleStatus,
      location_id: _scheduleData.location_id,
      remarks: _scheduleData.remarkContent,
      subscriber_id: _scheduleData.subscriber_id,
    });
    const _locationInput = {
      subscriber_id: _scheduleData.subscriber_id,
    };
    this.getAllLocation(_locationInput);
  };

  getAllLocation = async (_locationInput) => {
    const allLocation = await api.post(
      "sc-subscriber-location/get-subscriber-location",
      _locationInput
    );
    if (allLocation) {
      if (Array.isArray(allLocation)) {
        let dbArray = allLocation.map((opt) => ({
          label: opt.location_name,
          value: opt,
        }));
        dbArray.splice(0, 0, {
          label: "Select",
          value: { autoid: 0 },
        });
        this.setState({
          locationList: dbArray,
        });
        this.setState({
          location_id: this.getLocation(this.state.location_id),
        });
      } else {
        AuthHelperAccessCheck(allLocation);
      }
    }
  };

  getLocation(value) {
    if (this.state.locationList) {
      let filteredDataRows = this.state.locationList.filter((el) => {
        return el.value.autoid === value;
      })[0];
      return filteredDataRows;
    }
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onDateChange = (e) => {
    this.setState({ scheduleDate: e._d });
  };

  onSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.apiAuthAccessKey;
    const decoded = jwt_decode(token);
    const decodedUser = decoded.userAccessKey[0];

    const _scheduleEditInput = {
      subscriber_id: this.state.subscriber_id,
      location_id: this.state.location_id.value.autoid,
      scheduleDate: this.state.scheduleDate,
      status: this.state.status,
      userType: decodedUser.role,
      scheduleId: this.props.location.state.schedule.autoid,
      remarks: this.state.remarks,
    };

    const updateService = await api.post(
      "sc-ticket/edit-service-schedule",
      _scheduleEditInput
    );
    if (updateService) {
      AuthHelperAccessCheck(updateService);
      if (updateService.status === "success") {
        this.redirect = 1;
        this.onToastItemAdd(updateService.status, updateService.message);
      }
    }
  };

  handleToastItemClose = (itemObject) => {
    const toastItems = this.state.toastItems.filter(
      (c) => c.id !== itemObject.id
    );

    if (this.redirect === 1) {
      this.setState({
        redirect: 1,
        toastItems: toastItems,
      });
    } else {
      this.setState({ toastItems });
    }
  };

  onToastItemAdd(type, message) {
    let newid = this.state.toastItems.length + 1;
    if (
      this.state.toastItems.filter(function (e) {
        return e.id === newid;
      }).length > 0
    ) {
      newid = this.state.toastItems.length + Math.floor(Math.random() * 100);
    }

    let newToastItems = this.state.toastItems;
    let itemToast = {
      id: newid,
      type: type === "success" ? "success" : "danger",
      message: message,
    };

    newToastItems.push(itemToast);
    this.setState({ toastItems: newToastItems });

    setTimeout(() => {
      this.handleToastItemClose(itemToast);
    }, 2000);
  }

  cancelButton = () => {
    const ButtonCancel = () => (
      <Route
        render={({ history }) => (
          <Fab
            color="primary"
            size="small"
            variant="extended"
            onClick={() => {
              history.push({
                pathname: "/service-schedule-list",
              });
            }}
          >
            Cancel
          </Fab>
        )}
      />
    );
    return <ButtonCancel />;
  };

  render() {
    const { classes } = this.props;
    if (this.state.redirect === 1)
      return <Redirect to="/service-schedule-list" />;
    return (
      <div className={classes.root}>
        <Paper className={classes.paper} elevation={2}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography
                variant="h3"
                color="textSecondary"
                style={{
                  fontWeight: "bold",
                  margin: "1em",
                  textAlign: "start",
                }}
              >
                Service Schedule
              </Typography>
            </Grid>
            <Grid item xs={6} sm={3}>
              <Typography
                style={{
                  fontWeight: "bold",
                  // marginTop: "2em",
                  textAlign: "start",
                }}
              >
                Ticket ID{" "}
              </Typography>

              <TextField
                disabled
                value={`#${this.state.ticketId}`}
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={6} sm={3}>
              <Typography style={{ fontWeight: "bold", textAlign: "start" }}>
                Date
              </Typography>
              <Datetime
                defaultValue={AppHelper_DateAndTimeToString(
                  this.state.scheduleDate
                )}
                input={true}
                closeOnSelect={true}
                onChange={this.onDateChange}
                inputProps={{ style: { height: 57, width: 205 } }}
                fullWidth
              />
            </Grid>
            <Grid item xs={6} sm={3}>
              <Typography style={{ fontWeight: "bold", textAlign: "start" }}>
                Location
              </Typography>
              <CmbSelect
                name="location_id"
                value={this.state.location_id}
                onChange={this.onChange}
                dataSource={this.state.locationList}
              />
            </Grid>
            <Grid item xs={6} sm={3}>
              <Typography style={{ fontWeight: "bold", textAlign: "start" }}>
                Status
              </Typography>
              <TextField
                id="outlined-select-currency"
                select
                name="status"
                onChange={this.onChange}
                value={this.state.status}
                variant="outlined"
                fullWidth
              >
                <MenuItem key="0" value="0">
                  Open
                </MenuItem>
                <MenuItem key="1" value="1">
                  Closed
                </MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <Typography style={{ fontWeight: "bold", textAlign: "start" }}>
                Remarks
              </Typography>
              <TextField
                fullWidth
                multiline
                rows={3}
                rowsMax={10}
                variant="outlined"
                type="text"
                name="remarks"
                value={this.state.remarks}
                onChange={this.onChange}
              />
            </Grid>

            <Grid item xs={6} style={{ textAlign: "end" }}>
              <Fab
                type="submit"
                color="primary"
                size="small"
                variant="extended"
                style={{ marginRight: "1em", marginLeft: "1em" }}
                onClick={this.onSubmit}
              >
                Save
              </Fab>
            </Grid>
            <Grid item xs={6} style={{ textAlign: "start" }}>
              {this.cancelButton()}
            </Grid>
          </Grid>
        </Paper>
      </div>
    );
  }
}

export default withStyles(styles)(ServiceScheduleEdit);
