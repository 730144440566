import React, { Component } from "react";
import { Col, Row } from "reactstrap";
import CmbSelect from "../compon-controls/CmbSelect";
import { api } from "../businessjs/API";
import AddIcon from "@material-ui/icons/Add";
import {
  Grid,
  Paper,
  Typography,
  Fab,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Chip,
  CircularProgress,
} from "@material-ui/core";
import { SnackBar } from "./Login/SnackBar";
class InvoiceProduct extends Component {
  constructor(props) {
    super(props);

    let _product = this.props.currentProduct;
    let _selectedProduct = null;
    let _mode = "add";
    let _productName = "";
    let _productPrice = 0.0;
    let _productQuantity = 0.0;
    let _productDiscount = 0.0;
    let _total = 0.0;
    let selectedSerialNumbers = [];
    let _isSerialNumberExist = false;

    if (_product) {
      // console.log("product edit", _product);
      _productName = _product.product_name;
      _productPrice = _product.price;
      _productQuantity = _product.quantity;
      _productDiscount = _product.discount;
      _total = _product.total;
      selectedSerialNumbers = _product.serialNumberFinalArray;
      _selectedProduct = {
        label: _product.product_name,
        value: {
          autoid: _product.autoid,
          product_name: _product.product_name,
          price: _product.price,
          bundle_id: _product.bundle_id,
          bundle_name: _product.bundle_name,
        },
      };
      _mode = "edit";
      _isSerialNumberExist =
        _product.serialNumberFinalArray.length > 0 ? true : false;
    }

    this.state = {
      products: this.props.products,
      selected_products: this.props.selectedProducts,
      selectedProduct: _selectedProduct,
      oldProduct: _selectedProduct,
      discount: _productDiscount,
      product_name: _productName,
      price: _productPrice,
      quantity: _productQuantity,
      total: _total,
      mode: _mode,
      bundle_items: this.props.bundleProducts,
      errorMessage: "",
      color: "#d50000",
      serialNumberArray: [],
      isSerialNumberExist: _isSerialNumberExist,
      selectedSerialNumber: "",
      selectedSerialNumberArray: selectedSerialNumbers,
      dataLoading: false,
    };
  }
  componentDidMount = async () => {
    const product = this.props.currentProduct;
    // console.log("product in componentDidMount", product);
    // console.log("serialArray", this.state.selectedSerialNumberArray);
    if (product !== undefined) {
      this.setState({ dataLoading: true });
      const productId = product.autoid;
      let serialNumbersByProduct = await api.post(
        "sc-purchase/get-serial-numbers",
        { productId }
      );
      // console.log("serialNumbersByproduct", serialNumbersByProduct);
      if (serialNumbersByProduct.status !== "failed") {
        if (serialNumbersByProduct.length === 0) {
          this.setState({ isSerialNumberExist: false, dataLoading: false });
        } else {
          const serialTempArray = serialNumbersByProduct.map((item) => ({
            label: item.serial_no,
            value: item,
          }));

          const serialTempFilteredArray = serialTempArray.filter(
            (elem) =>
              !this.state.selectedSerialNumberArray.find(
                ({ serial_no }) => elem.label === serial_no
              )
          );
          // console.log("serialTempFilteredArray", serialTempFilteredArray);
          this.setState({
            serialNumberArray: serialTempFilteredArray,
            isSerialNumberExist: true,
            dataLoading: false,
          });
        }
      }
    } else {
    }
  };
  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onPriceChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      total:
        e.target.value * this.state.quantity -
        (e.target.value * this.state.quantity * this.state.discount) / 100,
    });
  };

  onProductChange = async (e) => {
    this.setState({ [e.target.name]: e.target.value, dataLoading: true });
    this.fillProductValues(e.target.value.value);
    // console.log(e.target.value.value);
    const productId = e.target.value.value.autoid;
    // console.log("prodcuttId",productId)
    let serialNumbersByProduct = await api.post(
      "sc-purchase/get-serial-numbers",
      { productId }
    );
    // console.log("invoiceSerialNumbers", serialNumbersByProduct);
    if (serialNumbersByProduct.status !== "failed") {
      if (serialNumbersByProduct.length === 0) {
        this.setState({
          isSerialNumberExist: false,
          dataLoading: false,
          serialNumberArray: [],
        });
      } else {
        const serialTempArray = serialNumbersByProduct.map((item) => ({
          label: item.serial_no,
          value: item,
        }));
        this.setState({
          serialNumberArray: serialTempArray,
          isSerialNumberExist: true,
          dataLoading: false,
        });
      }
    }
  };
  addSerialNumbers = (serialNumber) => {
    // console.log("serialNumber",serialNumber)
    const { selectedSerialNumberArray } = this.state;
    if (serialNumber !== "") {
      // console.log("inside add,serialNumber", serialNumber);
      let serialArray = serialNumber.value;
      // console.log("serialArrat", serialArray);
      if (selectedSerialNumberArray.length !== 0) {
        const mergedArray = [...selectedSerialNumberArray, serialArray];
        let uniqueArray = mergedArray.reduce((unique, o) => {
          if (
            !unique.some(
              (obj) => obj.serial_no === o.serial_no && obj.value === o.value
            )
          ) {
            unique.push(o);
          }
          return unique;
        }, []);
        // console.log("result",result);
        // const uniqueArray = [...new Set(mergedArray)];
        this.setState({
          selectedSerialNumberArray: uniqueArray,
          selectedSerialNumber: "",
        });
      } else {
        this.setState({
          selectedSerialNumberArray: [serialArray],
          selectedSerialNumber: "",
        });
      }
    }
  };
  onDeleteSerialNumber = (itemNumber) => {
    const { selectedSerialNumberArray, serialNumberArray } = this.state;
    const filteredArray =
      selectedSerialNumberArray &&
      selectedSerialNumberArray.filter(
        (number) => number.serial_no !== itemNumber
      );

    const deletedItem =
      selectedSerialNumberArray &&
      selectedSerialNumberArray.find(
        (number) => number.serial_no === itemNumber
      );
    const newItem = {
      label: deletedItem.serial_no,
      value: deletedItem,
    };
    const newSelectOptionArray = [...serialNumberArray, newItem];
    this.setState({
      selectedSerialNumberArray: filteredArray,
      serialNumberArray: newSelectOptionArray,
    });
  };
  onSerialNumberSelect = (e) => {
    const { serialNumberArray } = this.state;
    this.setState({ [e.target.name]: e.target.value });
    const filteredArray =
      serialNumberArray &&
      serialNumberArray.filter(
        (number) => number.label !== e.target.value.label
      );
    this.setState({ serialNumberArray: filteredArray });
    this.addSerialNumbers(e.target.value);
  };
  onDiscountChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      total:
        this.state.price * this.state.quantity -
        (this.state.price * this.state.quantity * e.target.value) / 100,
    });
  };

  onQuantityChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      total:
        this.state.price * e.target.value -
        (this.state.price * e.target.value * this.state.discount) / 100,
    });
  };

  fillProductValues = (product) => {
    if (product) {
      this.setState({
        price: product.price,
        quantity: 1,
        total:
          this.state.discount > 0
            ? product.price * this.state.quantity -
              (product.price * this.state.quantity * this.state.discount) / 100
            : product.price,
      });
    }
  };

  getProductBundleItems = (product) => {
    try {
      let bundle_products = this.state.bundle_items;
      const newFilteredItems = bundle_products.filter(
        (c) => c.bundle_id === product.bundle_id
      );

      return newFilteredItems;
    } catch (error) {
      console.log("something went wrong!", error);
      return null;
    }
  };

  productSave = () => {
    if (!this.validate()) return;

    const {
      selectedProduct,
      price,
      quantity,
      discount,
      total,
      bundle_items,
      selectedSerialNumberArray,
    } = this.state;

    let product = {
      autoid: selectedProduct.value.autoid,
      product_name: selectedProduct.value.product_name,
      bundle_name: selectedProduct.value.bundle_name,
      bundle_id: selectedProduct.value.bundle_id,
      bundle_items: [],
      price: price,
      quantity: quantity,
      discount: discount,
      total: total,
      mrp: price * quantity,
      status: 1,
      serialization: selectedSerialNumberArray.length === 0 ? 0 : 1,
      serialNumberFinalArray: selectedSerialNumberArray,
    };

    if (product.bundle_id) {
      let bundle_items = this.getProductBundleItems(product);
      product.bundle_items = bundle_items;
    }

    if (this.state.mode === "add") {
      this.state.selected_products.push(product);
      this.props.save(this.state.selected_products);
    } else {
      let newFilteredItems = [];

      if (
        this.state.oldProduct.value.autoid !==
        this.state.selectedProduct.value.autoid
      ) {
        const newFilteredItems1 = this.state.selected_products.filter(
          (c) => c.autoid !== this.state.oldProduct.value.autoid
        );

        newFilteredItems = this.state.selected_products.filter(
          (c) => c.autoid !== this.state.oldProduct.value.autoid
        );
      } else {
        newFilteredItems = this.state.selected_products.filter(
          (c) => c.autoid !== product.autoid
        );
      }

      newFilteredItems.push(product);
      this.setState({
        selected_products: newFilteredItems,
      });
      this.props.save(newFilteredItems);
    }
    this.props.cancel();
  };

  validate = () => {
    const { selectedProduct } = this.state;
    let value = true;
    // console.log(parseInt(this.state.quantity),"quantity")
    // console.log("selected Product", this.state.selectedProduct);
    if (this.state.selectedProduct === null) {
      this.setState({
        errorMessage: "Please select product",
      });
      value = false;
    } else if (this.state.quantity <= 0) {
      this.setState({
        errorMessage: "Quantity should be greater than zero",
      });
      value = false;
    } else if (
      parseInt(this.state.quantity) >
      this.state.selectedProduct.value.availableQuantity
    ) {
      if (selectedProduct.value.type === 1) {
        // console.log("inside error");
        this.setState({
          errorMessage: `Sorry...Available quantity is ${this.state.selectedProduct.value.availableQuantity}`,
        });
        value = false;
      }
    } else if (this.state.serialNumberArray.length > 0) {
      // console.log(
      //   "inside else if",
      //   this.state.quantity,
      //   this.state.selectedSerialNumberArray.length
      // );
      if (
        parseInt(this.state.quantity) !==
        this.state.selectedSerialNumberArray.length
      ) {
        // console.log("inside if");
        this.setState({
          errorMessage: "Quantity must be equal to the No.of serial Numbers",
        });
        value = false;
      } else {
        // console.log("inside else");
      }
    }

    return value;
  };
  onCloseSnackbar = () => {
    this.setState({ errorMessage: "" });
  };
  render() {
    // console.log(">>>>serialArray",this.state.selectedSerialNumberArray);
    const {
      selectedSerialNumberArray,
      isSerialNumberExist,
      dataLoading,
      errorMessage,
      color,
    } = this.state;
    return (
      <React.Fragment>
        <Dialog
          open={true}
          // onClose={addProductDialogClose}
          aria-labelledby="form-dialog-title"
          maxWidth="md"
          fullWidth
        >
          <SnackBar
            status={errorMessage}
            onCloseError={this.onCloseSnackbar}
            color={color}
          />
          {/* <Formik>
        <Form> */}
          <DialogTitle
            id="form-dialog-title"
            style={{ backgroundColor: "#007bff" }}
          >
            <Typography
              variant="h4"
              style={{ fontWeight: "bold", color: "#fff" }}
            >
              Select Product
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={isSerialNumberExist ? 6 : 12}>
                <Paper style={{ padding: "2em", textAlign: "center" }}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                      <Typography
                        variant="h6"
                        style={{ textAlign: "left", fontWeight: "bold" }}
                      >
                        Product
                      </Typography>
                      <CmbSelect
                        name="selectedProduct"
                        value={this.state.selectedProduct}
                        onChange={this.onProductChange}
                        dataSource={this.state.products}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography
                        variant="h6"
                        style={{ textAlign: "left", fontWeight: "bold" }}
                      >
                        Quantity
                      </Typography>
                      <TextField
                        fullWidth
                        required
                        variant="outlined"
                        name="quantity"
                        type="number"
                        value={this.state.quantity}
                        onChange={this.onQuantityChange}
                        placeholder="0.00"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography
                        variant="h6"
                        style={{ textAlign: "left", fontWeight: "bold" }}
                      >
                        Price
                      </Typography>
                      <TextField
                        fullWidth
                        variant="outlined"
                        name="price"
                        required
                        value={this.state.price}
                        onChange={this.onPriceChange}
                        placeholder="0.00"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography
                        variant="h6"
                        style={{ textAlign: "left", fontWeight: "bold" }}
                      >
                        Discount(%)
                      </Typography>
                      <TextField
                        fullWidth
                        variant="outlined"
                        name="discount"
                        type="number"
                        placeholder="0.00"
                        defaultValue={0}
                        value={this.state.discount}
                        onChange={this.onDiscountChange}
                        placeholder="0.00"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography
                        variant="h6"
                        style={{ textAlign: "left", fontWeight: "bold" }}
                      >
                        Total Price
                      </Typography>
                      <TextField
                        fullWidth
                        key="Total Price"
                        variant="outlined"
                        name="total"
                        value={this.state.total}
                        onChange={this.onChange}
                        placeholder="0.00"
                      />
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                style={{ display: isSerialNumberExist ? "" : "none" }}
              >
                <Paper style={{ padding: "2em", textAlign: "center" }}>
                  {" "}
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={10}>
                      <Typography
                        variant="h6"
                        style={{ textAlign: "left", fontWeight: "bold" }}
                      >
                        Serial Number
                      </Typography>
                      <CmbSelect
                        name="selectedSerialNumber"
                        value={this.state.selectedSerialNumber}
                        onChange={this.onSerialNumberSelect}
                        dataSource={this.state.serialNumberArray}
                      />
                    </Grid>

                    <Grid item xs={12} sm={12}>
                      <Paper style={{ padding: "2em", textAlign: "center" }}>
                        <Grid container spacing={3}>
                          {selectedSerialNumberArray.length !== 0 ? (
                            selectedSerialNumberArray.map((item) => (
                              <Grid item>
                                <Chip
                                  key={item}
                                  label={item.serial_no}
                                  style={{ minWidth: "8em" }}
                                  onDelete={() =>
                                    this.onDeleteSerialNumber(item.serial_no)
                                  }
                                  color="primary"
                                  variant="outlined"
                                />
                              </Grid>
                            ))
                          ) : (
                            <Typography>No Data....</Typography>
                          )}
                        </Grid>
                      </Paper>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Fab
              variant="extended"
              size="small"
              color="primary"
              onClick={() => {
                this.props.cancel();
              }}
            >
              Cancel
            </Fab>
            <Fab
              variant="extended"
              size="small"
              color="primary"
              disabled={dataLoading}
              type="submit"
              onClick={this.productSave}
            >
              Save
            </Fab>
            {dataLoading && (
              <CircularProgress
                size={24}
                style={{
                  position: "absolute",
                  left: "92%",
                }}
              />
            )}
          </DialogActions>
          {/* </Form>
      </Formik> */}
        </Dialog>
      </React.Fragment>
    );
  }
}

export default InvoiceProduct;
