import React from "react";
import VehicleSelect from "../compon-controls/VehicleSelect";
class SubscriptionItem extends React.Component {
  constructor(props) {
    super(props);

    let vehicleStatus = "";

    if (this.props.itemObject.status === 2) {
      vehicleStatus = {
        cssClass: "btn btn-secondary btn-sm",
        btnText: "Re-Assign"
      };
    } else {
      vehicleStatus = { cssClass: "btn btn-info btn-sm", btnText: "Assign" };
    }

    this.state = {
      deleteBtn: { cssClass: "btn btn-default btn-sm", btnText: "Delete" },
      editBtn: { cssClass: "btn btn-default btn-sm", btnText: "Edit" },
      vehileBtn: vehicleStatus,
      mode: "view",

      subscriber_id: 0,
      subscription_id: 0,
      title: "",
      type: "",
      device_id: "",
      subscription_code: "",
      re_newal_interval: "",

      toastItems: [],
      subscribers: this.props.subscribers,
      status: this.props.itemObject.status,
      plate_number: this.props.itemObject.plate_number
        ? this.props.itemObject.plate_number
        : "Un-Assigned"
    };

    this.onDelete = this.onDelete.bind(this);
    this.onEdit = this.onEdit.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onAssign = this.onAssign.bind(this);
  }

  onDelete(e) {
    switch (this.state.deleteBtn.btnText) {
      case "Delete":
        this.setState({
          deleteBtn: { cssClass: "btn btn-danger btn-sm", btnText: "Confirm ?" }
        });

        this.setState({
          editBtn: { cssClass: "btn btn-warning btn-sm", btnText: "Cancel" }
        });
        break;
      case "Update":
        let temObj = this.props.itemObject;

        temObj.title = this.state.title;
        temObj.type = this.state.type;
        temObj.device_id = this.state.device_id;
        temObj.subscription_code = this.state.subscription_code;
        temObj.re_newal_interval = this.state.re_newal_interval;

        this.props.onUpdate(temObj);
        this.setState({ mode: "view" });
        this.setState({
          deleteBtn: { cssClass: "btn btn-default btn-sm", btnText: "Delete" }
        });

        this.setState({
          editBtn: { cssClass: "btn btn-default btn-sm", btnText: "Edit" }
        });

        break;
      case "Confirm ?":
        let statusObj = this.props.itemObject;
        statusObj.status = 0;

        this.props.onDelete(statusObj);

        break;
      default:
        this.setState({
          deleteBtn: { cssClass: "btn btn-danger btn-sm", btnText: "Confirm ?" }
        });

        this.setState({
          editBtn: { cssClass: "btn btn-warning btn-sm", btnText: "Cancel" }
        });
        break;
    }
  }

  onEdit(e) {
    if (this.state.editBtn.btnText === "Cancel") {
      this.setState({ mode: "view" });
      this.setState({
        deleteBtn: { cssClass: "btn btn-default btn-sm", btnText: "Delete" }
      });

      this.setState({
        editBtn: { cssClass: "btn btn-default btn-sm", btnText: "Edit" }
      });

      this.setState({
        vehileBtn: { cssClass: "btn btn-info btn-sm", btnText: "Assign" }
      });
    } else if (this.state.editBtn.btnText === "Edit") {
      this.setState({ mode: "edit" });
      this.setState({
        deleteBtn: { cssClass: "btn btn-primary btn-sm", btnText: "Update" }
      });

      this.setState({
        editBtn: { cssClass: "btn btn-warning btn-sm", btnText: "Cancel" }
      });

      this.setState({ subscriber_id: this.props.itemObject.subscriber_id });
      this.setState({ title: this.props.itemObject.title });
      this.setState({ type: this.props.itemObject.type });
      this.setState({ device_id: this.props.itemObject.device_id });
      this.setState({
        subscription_code: this.props.itemObject.subscription_code
      });
      this.setState({
        re_newal_interval: this.props.itemObject.re_newal_interval
      });
      this.setState({ subscribers: this.props.subscribers });
    }
  }

  onAssign(e) {
    if (this.state.vehileBtn.btnText === "Save") {
      if (this.state.subscription_id) {
        let temObj = this.props.itemObject;
        let obj = {
          autoid: temObj.autoid,
          subscriber_id: temObj.subscriber_id,
          vehicle_id: this.state.subscription_id.value.autoid,
          device_id: temObj.device_id
        };

        this.setState({ mode: "view" });

        this.setState({
          deleteBtn: { cssClass: "btn btn-default btn-sm", btnText: "Delete" }
        });

        this.setState({
          editBtn: { cssClass: "btn btn-default btn-sm", btnText: "Edit" }
        });

        this.props.onAssign(obj);

        this.setState({ status: 2 });

        this.setState({
          vehileBtn: {
            cssClass: "btn btn-secondary btn-sm",
            btnText: "Re-Assign"
          }
        });

        this.setState({
          plate_number: this.state.subscription_id.label
        });
      } else {
        alert("Please select one vehicle");
      }
    } else if (
      this.state.vehileBtn.btnText === "Assign" ||
      this.state.vehileBtn.btnText === "Re-Assign"
    ) {
      this.setState({ subscriber_id: this.props.itemObject.subscriber_id });
      this.setState({ mode: "assign" });

      this.setState({
        vehileBtn: { cssClass: "btn btn-success btn-sm", btnText: "Save" }
      });

      this.setState({
        editBtn: { cssClass: "btn btn-warning btn-sm", btnText: "Cancel" }
      });
    }
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  subscriberChange = selected => {
    this.setState({ subscriber_id: selected.target.value });
  };

  formateBody(mode) {
    if (mode === "edit") {
      return (
        <React.Fragment>
          <div className="mb-2 mt-2">
            <div className="form-body">
              <div className="row">
                <div className="col-12">
                  <div className="form-group">
                    <label>Title</label>
                    <div className="position-relative has-icon-left">
                      <input
                        type="text"
                        className="form-control"
                        name="title"
                        value={this.state.title}
                        onChange={this.onChange}
                        required
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6 col-12">
                  <div className="form-group">
                    <label>Type</label>
                    <div className="position-relative has-icon-left">
                      <select
                        className="form-control"
                        name="type"
                        onChange={this.onChange}
                        value={this.state.type}
                      >
                        <option value=""> Select </option>
                        <option value="1">Tracking</option>
                        <option value="2">Other</option>
                      </select>
                    </div>
                  </div>
                </div>{" "}
                <div className="col-md-6 col-12">
                  <div className="form-group">
                    <label>Device Id</label>
                    <div className="position-relative has-icon-left">
                      <input
                        type="text"
                        className="form-control"
                        name="device_id"
                        value={this.state.device_id}
                        onChange={this.onChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 col-12">
                  <div className="form-group">
                    <label>Re-Newal Interval</label>
                    <div className="position-relative has-icon-left">
                      <select
                        className="form-control"
                        name="re_newal_interval"
                        onChange={this.onChange}
                        value={this.state.re_newal_interval}
                      >
                        <option value="0"> Select </option>
                        <option value="30">1 Month</option>
                        <option value="90">3 Month</option>
                        <option value="365">1 Year</option>
                        <option value="730">2 Year</option>
                      </select>
                    </div>
                  </div>
                </div>{" "}
              </div>
            </div>
          </div>
        </React.Fragment>
      );
    } else if (mode === "view") {
      return (
        <React.Fragment>
          <div className="row">
            <div className="col-md-6 col-12">
              <span>
                {this.props.itemObject.title} {this.props.itemObject.type}
              </span>
              <span className="phone d-block">
                Device {this.props.itemObject.device_id}
              </span>
            </div>
            <div className="col-md-6 col-12">
              <span className="phone d-block">
                Code {this.props.itemObject.subscription_code}
              </span>
              <span className="phone d-block">
                Vehicle {this.state.plate_number}
              </span>
            </div>
          </div>
        </React.Fragment>
      );
    } else if (mode === "assign") {
      return (
        <React.Fragment>
          <div className="mb-2 mt-2">
            <div className="form-body">
              <div className="row">
                <div className="col-12">
                  <div className="form-group">
                    <label>Subscription</label>
                    <div className="position-relative has-icon-left">
                      <VehicleSelect
                        subscriber_id={this.state.subscriber_id}
                        onChange={this.onChange}
                        value={this.state.subscription_id}
                        name="subscription_id"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      );
    }
  }

  render() {
    return (
      <li>
        {this.formateBody(this.state.mode)}
        <div className="btn-group float-right">
          <button
            type="button"
            onClick={this.onEdit}
            className={this.state.editBtn.cssClass}
          >
            {this.state.editBtn.btnText}
          </button>
          <button
            type="button"
            onClick={this.onDelete}
            className={this.state.deleteBtn.cssClass}
          >
            {this.state.deleteBtn.btnText}
          </button>
          <button
            type="button"
            onClick={this.onAssign}
            className={this.state.vehileBtn.cssClass}
          >
            {this.state.vehileBtn.btnText}
          </button>
        </div>
      </li>
    );
  }
}

export default SubscriptionItem;
