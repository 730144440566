import React, { Component } from "react";
import Select from "react-select";
import { api } from "../businessjs/API";

class SubscriberSelect extends Component {
  constructor(props) {
    super(props);

    this.state = { selectedOption: null, optionsArray: [] };
  }

  componentDidMount = async () => {
    const activeSubscriber = await api.post("subscriber/getactive");

    if (activeSubscriber) {
      if (Array.isArray(activeSubscriber)) {
        let dbArray = activeSubscriber.map(opt => ({
          label: opt.company_name,
          value: opt
        }));

        if (this.props.showAll)
          dbArray.splice(0, 0, { value: 0, label: "All" });

        let subValue = null;
        //assing the selected value to the combo
        if (dbArray && dbArray.length > 0) {
          const result = dbArray.filter(
            row => row.value.autoid === this.props.value
          );

          if (result && result.length > 0) subValue = result[0];
        }

        this.setState({ selectedOption: subValue, optionsArray: dbArray });

        if (this.state.selectedOption)
          this.handleChange(this.state.selectedOption);
      }
    }
  };

  handleChange = selectedOption => {
    this.setState({ selectedOption });

    let target = {};
    target.value = selectedOption;
    target.name = this.props.name;

    let event = {};
    event.target = target;
    this.props.onChange(event);
  };

  render() {
    return (
      <Select
        name={this.props.name}
        value={this.props.value}
        onChange={this.handleChange}
        options={this.state.optionsArray}
        styles={{ container: base => ({ ...base, zIndex: 100 }) }}
        maxMenuHeight={150}
      />
    );
  }
}

export default SubscriberSelect;
