import React, { Component } from "react";
import { Link as RouterLink, Route, Redirect } from "react-router-dom";
import {
  Grid,
  Paper,
  TextField,
  Typography,
  Fab,
  Divider,
} from "@material-ui/core";
import moment from "moment";
import { SnackBar } from "./Login/SnackBar";
import { makeStyles, withStyles } from "@material-ui/core/styles";

import { AppHelper_DateAndTimeToString } from "../businessjs/AppHelperFn";
import { AuthHelperAccessCheck } from "../businessjs/AuthHelper";
import { api } from "../businessjs/API";
import CmbSelect from "../compon-controls/CmbSelect";

const NavLink = React.forwardRef((props, ref) => (
  <RouterLink innerRef={ref} {...props} />
));

const paymentOptions = [
  { value: 1, label: "Cash" },
  { value: 2, label: "Check" },
  { value: 3, label: "Card" },
  { value: 4, label: "Online" },
  { value: 5, label: "Other" },
];

class InvoicePayment extends Component {
  constructor(props) {
    super(props);
    this.redirect = 0;
    this.state = {
      toastItems: [],
      infoMessage: "",
      color: "#d50000",
      paymentDate:
        this.props.location.state && this.props.location.state.paymentDate
          ? AppHelper_DateAndTimeToString(this.props.location.state.paymentDate)
          : new Date(),

      balanceAmount: "0.00",
      amount: "",
      paymentType: "",
      narration: "",
      amountMessage: "",
      redirect: 0,
      auto_id:
        this.props.location.state && this.props.location.state.paymentId
          ? this.props.location.state.paymentId
          : null,
      invoiceId:
        this.props.location.state && this.props.location.state.invoiceId
          ? this.props.location.state.invoiceId
          : null,
      accountId:
        this.props.location.state && this.props.location.state.accountId
          ? this.props.location.state.accountId
          : null,

      showCancel:
        this.props.location.state && this.props.location.state.paymentId
          ? "col"
          : "col hidden",
    };
  }

  componentDidMount = () => {
    if (this.state.auto_id) {
      this.getPaymentById(this.state.auto_id);
    }
    const paymentInput = {
      invoiceId: this.props.location.state.invoiceId,
    };
    this.getPaymentByInvoiceId(paymentInput);
  };

  getPaymentById = async (paymentId) => {
    try {
      const _input = {
        paymentId: paymentId,
      };

      const paymentById = await api.post("sc-invoice/get-payment-id", _input);
      if (paymentById && paymentById.length) {
        console.log("paymentById[0].payment_type", paymentById[0].payment_type);
        this.setState({
          accountId: paymentById[0].subscriber_id,
          invoiceId: paymentById[0].invoice_id,
          paymentDate: paymentById[0].payment_date,
          paymentType: paymentOptions.filter(
            (payment) => payment.value === paymentById[0].payment_type
          )[0],
          amount: paymentById[0].amount,
          narration: paymentById[0].narration,
        });
      }
    } catch (error) {
      console.log("something went wrong!", error);
    }
  };

  /**
   * method used to calculate sum of corresponding invoice payments.
   */
  getPaymentByInvoiceId = async (paymentInput) => {
    try {
      const paymentResponse = await api.post(
        "sc-invoice/get-payment-by-invoice",
        paymentInput
      );

      if (paymentResponse[0].paymentAmount != null) {
        this.setState({
          balanceAmount:
            parseFloat(paymentResponse[0].grandTotal) -
            parseFloat(paymentResponse[0].paymentAmount),
        });
      } else {
        this.setState({
          balanceAmount: parseFloat(paymentResponse[0].grandTotal),
        });
      }
    } catch (error) {
      console.log("something went wrong!", error);
    }
  };

  cancelButton = () => {
    const CancelButton = () => (
      <Route
        render={({ history }) => (
          <Fab
            variant="extended"
            size="small"
            color="primary"
            onClick={() => {
              this.props.location.state.from === "invoice"
                ? history.push({
                    pathname: "/invoice-list",
                  })
                : this.props.location.state.from === "payment"
                ? this.props.history.push({
                    pathname: `/payments-list`,
                  })
                : this.props.history.push({
                    pathname: `/account-details`,
                    state: {
                      companyId: this.props.location.state.accountId,
                    },
                  });
            }}
          >
            Cancel
          </Fab>
        )}
      />
    );
    return <CancelButton />;
  };

  handleToastItemClose = (itemObject) => {
    const toastItems = this.state.toastItems.filter(
      (c) => c.id !== itemObject.id
    );

    if (this.redirect == 1) {
      this.setState({
        redirect: 1,
        toastItems: toastItems,
      });
    } else {
      this.setState({ toastItems });
    }
    this.setState({ toastItems });
  };

  onToastItemAdd(type, message) {
    let newid = this.state.toastItems.length + 1;
    if (
      this.state.toastItems.filter(function (e) {
        return e.id === newid;
      }).length > 0
    ) {
      newid = this.state.toastItems.length + Math.floor(Math.random() * 100);
    }

    let newToastItems = this.state.toastItems;
    let itemToast = {
      id: newid,
      type: type === "success" ? "success" : "danger",
      message: message,
    };

    newToastItems.push(itemToast);
    this.setState({ toastItems: newToastItems });

    setTimeout(() => {
      this.handleToastItemClose(itemToast);
    }, 2000);
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  onAmountChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    if (!this.validate(e.target.value)) return;
  };

  validate = (amount) => {
    let value = true;
    if (amount > this.state.balanceAmount) {
      this.setState({
        amountMessage: "Entered amount greater than Balance Amount.",
      });
      value = false;
    } else if (amount < this.state.balanceAmount) {
      this.setState({
        amountMessage: "",
      });
      value = false;
    }

    return value;
  };

  onDateChange = (e) => {
    this.setState({ paymentDate: e._d });
  };

  onSubmit = async (e) => {
    e.preventDefault();
    // if (!this.validate(this.state.amount)) return;
    try {
      const _paymentInput = {
        subscriberId: this.props.location.state.accountId,
        invoiceId: this.props.location.state.invoiceId,
        paymentType: this.state.paymentType ? this.state.paymentType.value : 0,
        paymentDate: this.state.paymentDate,
        amount: this.state.amount,
        narration: this.state.narration,
        status: 1, // default
      };
      console.log("payment type", this.state.paymentType);
      console.log("sub", this.props.location.state.accountId);

      console.log("payment", _paymentInput);
      if (this.state.auto_id) {
        _paymentInput.auto_id = this.state.auto_id;
        const paymentResponse = await api.post(
          "sc-invoice/edit_invoice-payment",
          _paymentInput
        );
        if (paymentResponse.status === "success") {
          this.redirect = 1;
          this.onToastItemAdd(paymentResponse.status, paymentResponse.message);
        }
      } else {
        const paymentResponse = await api.post(
          "sc-invoice/invoice-payment",
          _paymentInput
        );

        if (paymentResponse) {
          if (paymentResponse.status === "success") {
            this.redirect = 1;
            this.onToastItemAdd(
              paymentResponse.status,
              paymentResponse.message
            );
          }
        }
      }
    } catch (error) {
      console.log("something went wrong!", error);
    }
  };

  cancelPayment = async () => {
    const _cancelInvoiceInputs = {
      invoice_id: this.state.invoiceId,
      paymentId: this.state.auto_id,
    };
    const paymentCancel = await api.post(
      "sc-invoice/cancel_invoice-payment",
      _cancelInvoiceInputs
    );
    if (paymentCancel.status === "success") {
      this.redirect = 1;
      this.onToastItemAdd(paymentCancel.status, paymentCancel.message);
    } else {
      AuthHelperAccessCheck(paymentCancel);
    }
  };

  render() {
    if (
      this.state.redirect === 1 &&
      this.props.location.state.from === "invoice"
    ) {
      this.props.history.push({
        pathname: `/invoice-list`,
      });
    } else if (
      this.state.redirect === 1 &&
      this.props.location.state.from === "account"
    ) {
      this.props.history.push({
        pathname: `/account-details`,
        state: {
          companyId: this.props.location.state.accountId,
        },
      });
    } else if (
      this.state.redirect === 1 &&
      this.props.location.state.from === "payment"
    ) {
      this.props.history.push({
        pathname: `/payments-list`,
      });
    }
    return (
      <React.Fragment>
        <SnackBar
          status={this.state.infoMessage}
          onCloseError={this.onCloseSnackbar}
          color={this.state.color}
        />
        <form className="form" onSubmit={this.onSubmit}>
          <Paper elevation={4}>
            <Grid container spacing={3} style={{ padding: "2em" }}>
              <Grid
                container
                direction="row"
                style={{ justifyContent: "space-between" }}
              >
                <Typography variant="h4" style={{ fontWeight: "bold" }}>
                  Payment
                </Typography>
                <Typography variant="h4" style={{ fontWeight: "bold" }}>
                  Balance:{" "}
                  <span className="text-danger">
                    {" " + Number(this.state.balanceAmount).toFixed(2)}
                  </span>
                </Typography>
              </Grid>

              <Grid
                container
                xs={4}
                sm={6}
                direction="row"
                style={{ marginTop: "3em", paddingLeft: "1em" }}
              >
                <Typography variant="h6" style={{ fontWeight: "bold" }}>
                  Invoice No. :
                </Typography>

                <Typography
                  variant="h6"
                  style={{
                    fontWeight: "bold",
                    color: "darkgray",
                  }}
                >
                  {"#" + this.state.invoiceId}
                </Typography>
              </Grid>

              <Grid
                container
                xs={8}
                sm={6}
                direction="row"
                style={{ marginTop: "3em" }}
              >
                <Typography variant="h6" style={{ fontWeight: "bold" }}>
                  Account Id. :
                </Typography>
                <Typography
                  variant="h6"
                  style={{
                    fontWeight: "bold",
                    color: "darkgray",
                  }}
                >
                  {"#" + this.state.accountId}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>

              <Grid item xs={12} sm={4}>
                <Typography
                  variant="h6"
                  style={{ textAlign: "left", fontWeight: "bold" }}
                >
                  Amount
                </Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  name="amount"
                  required
                  placeholder="Amount"
                  value={this.state.amount}
                  onChange={this.onAmountChange}
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <Typography
                  variant="h6"
                  style={{ textAlign: "left", fontWeight: "bold" }}
                >
                  Date
                </Typography>
                <TextField
                  type="date"
                  fullWidth
                  variant="outlined"
                  name="paymentDate"
                  required
                  value={moment(this.state.paymentDate).format("YYYY-MM-DD")}
                  onChange={(event) => this.onDateChange(event.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography
                  variant="h6"
                  style={{ textAlign: "left", fontWeight: "bold" }}
                >
                  Payment Type
                </Typography>
                <CmbSelect
                  name="paymentType"
                  onChange={this.onChange}
                  value={this.state.paymentType}
                  dataSource={paymentOptions}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="h6"
                  style={{ textAlign: "left", fontWeight: "bold" }}
                >
                  Narration
                </Typography>
                <TextField
                  fullWidth
                  multiline
                  rows={3}
                  rowsMax={10}
                  variant="outlined"
                  type="text"
                  value={this.state.narration}
                  onChange={this.onChange}
                  name="narration"
                />
              </Grid>

              <Grid
                container
                direction="row"
                style={{
                  paddingLeft: "1em",
                  marginTop: "1em",
                  marginBottom: "1em",
                }}
              >
                {this.cancelButton()}
                <Fab
                  variant="extended"
                  size="small"
                  color="primary"
                  aria-label="add"
                  type="submit"
                >
                  Add
                </Fab>
                <Grid item className={this.state.showCancel}>
                  <button
                    type="button"
                    className="btn btn-link btn-sm"
                    onClick={() => {
                      this.cancelPayment();
                    }}
                  >
                    Cancel Payment
                  </button>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </form>

        {/*         
        <div className="container mt-4">
          <Row
            style={{
              height: "100vh",
              margin: "0"
            }}
          >
            <Col xl={{ size: 10, offset: 1 }}>
              <div className="card">
                <ToastAlert
                  toastItems={this.state.toastItems}
                  onItemClose={this.handleToastItemClose}
                />
                <div className="card-header">
                  <Row>
                    <Col xl={9} lg={9} md={8} sm={7} xs={6}>
                      <h4>Payment</h4>
                    </Col>
                    <Col xl={3} lg={3} md={4} sm={5} xs={6}>
                      <label>Balance : </label>
                      <span className="text-danger">
                        {" " + Number(this.state.balanceAmount).toFixed(2)}
                      </span>
                    </Col>
                  </Row>
                </div>

                <div className="card-content collpase show">
                  <div className="card-body">
                    <form className="form" onSubmit={this.onSubmit}>
                      <div className="form-body">
                        <Row>
                          <Col xl={2} lg={2} md={3} sm={6} xs={5}>
                            <label>Invoice No.</label>
                          </Col>
                          <Col xl={4} lg={4} md={3} sm={6} xs={7}>
                            <span className="badge badge-success mt-1 float-left">
                              {"#" + this.state.invoiceId}
                            </span>
                          </Col>
                          <Col xl={2} lg={2} md={3} sm={6} xs={5}>
                            <label>Account Id.</label>
                          </Col>
                          <Col xl={4} lg={4} md={3} sm={6} xs={7}>
                            <span className="badge badge-success mt-1 float-left">
                              {"#" + this.state.accountId}
                            </span>
                          </Col>
                        </Row>

                        <Row className="mt-2">
                          <Col xl={2} lg={2} md={3} sm={6} xs={5}>
                            <label>Amount</label>
                          </Col>
                          <Col xl={4} lg={3} md={4} sm={6} xs={7}>
                            <input
                              type="text"
                              className="form-control"
                              name="amount"
                              required
                              value={this.state.amount}
                              onChange={this.onAmountChange}
                              style={{ width: "70%" }}
                            />
                          </Col>
                          <Col xl={2} lg={2} md={3} sm={6} xs={5}>
                            <label>Date</label>
                          </Col>
                          <Col xl={4} lg={4} md={3} sm={6} xs={7}>
                            <Datetime
                              defaultValue={this.state.paymentDate}
                              input={true}
                              closeOnSelect={true}
                              onChange={this.onDateChange}
                            />
                          </Col>
                        </Row>

                        <Row className="mt-2">
                          <Col xl={2} lg={2} md={3} sm={6} xs={5}>
                            <label>Payment Type</label>
                          </Col>
                          <Col xl={4} lg={4} md={3} sm={6} xs={7}>
                            <select
                              className="form-control"
                              name="paymentType"
                              onChange={this.onChange}
                              value={this.state.paymentType}
                              required
                              style={{ width: "70%" }}
                            >
                              <option value="">--Select Type--</option>
                              <option value="1">Cash</option>
                              <option value="2">Check</option>
                              <option value="3">Card</option>
                              <option value="4">Online</option>
                              <option value="5">Other</option>
                            </select>
                          </Col>
                        </Row>

                        <Row className="mt-2">
                          <Col xl={2} lg={2} md={3} xs={12}>
                            <label>Narration</label>
                          </Col>
                          <Col xl={9} lg={9} md={9} xs={12}>
                            <textarea
                              name="narration"
                              style={{ width: "100%" }}
                              rows="3"
                              value={this.state.narration}
                              onChange={this.onChange}
                            />
                          </Col>
                        </Row>
                        <Row className="mt-4">
                          <Col xl={3} lg={2} md={3} sm={4} xs={6}>
                            <button
                              type="submit"
                              className="btn btn-primary btn-sm"
                            >
                              Save
                            </button>
                            {this.cancelButton()}
                          </Col>
                          <Col
                            xl={2}
                            lg={2}
                            md={2}
                            sm={3}
                            xs={6}
                            className={this.state.showCancel}
                          >
                            <button
                              type="button"
                              className="btn btn-link btn-sm"
                              onClick={() => {
                                this.cancelPayment();
                              }}
                            >
                              Cancel Payment
                            </button>
                          </Col>
                          <Col>
                            <span className="text-danger">
                              {this.state.amountMessage}
                            </span>
                          </Col>
                        </Row>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div> */}
      </React.Fragment>
    );
  }
}

export default InvoicePayment;
