/**
 * Product : used for Product Adding and Editing.
 */
import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { Col, Row } from "reactstrap";

import NavBar from "../compon-styled/SupportCenterNavBar";
import { AuthHelperAccessCheck } from "../businessjs/AuthHelper";

import ToastAlert from "./ToastAlert";
import { api } from "../businessjs/API";

class Product extends Component {
  constructor(props) {
    super(props);
    this.redirect = 0;
    this.state = {
      type: 1,
      product_name: "",
      price: "",
      unitPrice: "",
      outsideCityPrice: "",
      description: undefined,
      priorWarningDays: 0,
      suspendDays: 0,
      noOfServiceVisits: "",
      maxServicePerVisit: "",
      status: 1,
      activeDays: 365,
      toastItems: [],
      caption: this.props.location.state.type === "add" ? "Add" : "Edit",
      bundle_id: 0,
      checked: 0,
      redirect: 0,
    };
  }

  componentDidMount = () => {
    if (this.props.location.state.type === "edit") {
      /**
       * If type = 'edit', getting corresponding product data and call setState().
       * And call getCompany() with parameter as corresponding company id & division id.
       */
      this.getProducts();
    }
  };

  getProducts = async () => {
    const _productInputs = {
      product_id: this.props.location.state.product_id,
    };

    const res = await api.post(
      "sc-product/get-product-data-by-id",
      _productInputs
    );
    if (res) {
      this.setState({
        product_name: res[0].product_name,
        price: res[0].price,
        outsideCityPrice: res[0].out_side_city_price,
        description: res[0].description,
        priorWarningDays: res[0].prior_warning_days,
        suspendDays: res[0].suspend_after_days,
        noOfServiceVisits: res[0].number_of_service_visits,
        maxServicePerVisit: res[0].max_services_per_visit,
        unitPrice: res[0].additional_per_unit_price,
        status: res[0].status,
        activeDays: res[0].active_days,
        type: res[0].type,
        bundle_id: res[0].bundle_id,
      });
    } else {
      AuthHelperAccessCheck(res);
    }
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.id });
  };

  onSubmit = async (e) => {
    e.preventDefault();

    const _productInput = {
      company_id: localStorage.apiCompanyId, // default company_id = 1
      division_id: localStorage.apiDivisionId, // default division_id = 1
      type: this.state.type,
      product_name: this.state.product_name,
      price: this.state.price,
      outsideCityPrice: this.state.outsideCityPrice,
      activeDays: this.state.activeDays,
      priorWarningDays: this.state.priorWarningDays,
      suspendDays: this.state.suspendDays,
      noOfServiceVisits: this.state.noOfServiceVisits,
      maxServicePerVisit: this.state.maxServicePerVisit,
      unitPrice: this.state.unitPrice,
      description:
        this.state.description === undefined ? null : this.state.description,
      status: this.state.status,
      bundleId: this.state.bundle_id === 0 ? null : this.state.bundle_id,
      productId:
        this.props.location.state.type === "edit"
          ? this.props.location.state.product_id
          : 0, // used for product editing.
    };
    if (this.props.location.state.type === "add") {
      const result = await api.post("sc-product/add-product", _productInput);
      if (result) {
        AuthHelperAccessCheck(result);
        if (result.status === "success") {
          this.redirect = 1;
          this.onToastItemAdd(result.status, result.message);
        }
      }
    } else if (this.props.location.state.type === "edit") {
      const result = await api.post("sc-product/edit-product", _productInput);
      if (result) {
        AuthHelperAccessCheck(result);
        if (result.status === "success") {
          this.redirect = 1;
          this.onToastItemAdd(result.status, result.message);
        }
      }
    }
  };

  handleToastItemClose = (itemObject) => {
    const toastItems = this.state.toastItems.filter(
      (c) => c.id !== itemObject.id
    );

    if (this.redirect == 1) {
      this.setState({
        redirect: 1,
        toastItems: toastItems,
      });
    } else {
      this.setState({ toastItems });
    }
  };

  onToastItemAdd(type, message) {
    let newid = this.state.toastItems.length + 1;
    if (
      this.state.toastItems.filter(function (e) {
        return e.id === newid;
      }).length > 0
    ) {
      newid = this.state.toastItems.length + Math.floor(Math.random() * 100);
    }

    let newToastItems = this.state.toastItems;
    let itemToast = {
      id: newid,
      type: type === "success" ? "success" : "danger",
      message: message,
    };

    newToastItems.push(itemToast);
    this.setState({ toastItems: newToastItems });

    setTimeout(() => {
      this.handleToastItemClose(itemToast);
    }, 2000);
  }

  render() {
    if (this.state.redirect === 1)
      return (
        <Redirect
          to={{
            pathname: "/product-list",
          }}
        />
      );
    let showService = this.state.type == 2 ? "row" : "row hidden";
    let showOutsidePrice = this.state.type == 2 ? "row" : "row hidden";

    return (
      <React.Fragment>
        {/* <NavBar /> */}
        <div className="container mt-4">
          <div className="card">
            <ToastAlert
              toastItems={this.state.toastItems}
              onItemClose={this.handleToastItemClose}
            />
            <div className="card-header">
              <Row>
                <Col>
                  <h4>{this.state.caption} Product</h4>
                </Col>
              </Row>
            </div>

            <div className="card-content collpase show">
              <div className="card-body">
                <form className="form" onSubmit={this.onSubmit}>
                  <div className="form-body">
                    <Row>
                      <Col xl={6} lg={6}>
                        <Row>
                          <Col xl={4} lg={4}>
                            <label>Type</label>
                          </Col>
                          <Col xl={6} lg={6}>
                            <select
                              className="form-control"
                              name="type"
                              onChange={this.onChange}
                              value={this.state.type}
                              required
                            >
                              <option value="1">Product</option>
                              <option value="2">Service Visits</option>
                              <option value="3">Subscription</option>
                            </select>
                          </Col>
                        </Row>

                        <Row className="mt-2">
                          <Col xl={4} lg={4}>
                            <label>Product Name</label>
                          </Col>
                          <Col xl={6} lg={6}>
                            <input
                              type="text"
                              className="form-control"
                              name="product_name"
                              required
                              value={this.state.product_name}
                              onChange={this.onChange}
                            />
                          </Col>
                        </Row>

                        <Row className="mt-2">
                          <Col xl={4} lg={4}>
                            <label>Price</label>
                          </Col>
                          <Col xl={6} lg={6}>
                            <input
                              type="text"
                              className="form-control"
                              name="price"
                              required
                              value={this.state.price}
                              onChange={this.onChange}
                              placeholder="0.00"
                            />
                          </Col>
                        </Row>

                        <Row
                          className={showOutsidePrice}
                          style={{ marginTop: "2%" }}
                        >
                          <Col xl={4} lg={4}>
                            <label>Outside City Price</label>
                          </Col>
                          <Col xl={6} lg={6}>
                            <input
                              type="text"
                              className="form-control"
                              name="outsideCityPrice"
                              // required
                              value={this.state.outsideCityPrice}
                              onChange={this.onChange}
                              placeholder="0.00"
                            />
                          </Col>
                        </Row>

                        <Row className="mt-2">
                          <Col xl={4} lg={4}>
                            <label>Description</label>
                          </Col>
                          <Col xl={6} lg={6}>
                            <textarea
                              name="description"
                              style={{ width: "100%" }}
                              rows="4"
                              value={this.state.description}
                              onChange={this.onChange}
                            />
                          </Col>
                        </Row>
                      </Col>
                      <Col xl={6} lg={6}>
                        <Row className={showService}>
                          <Col xl={5} lg={6}>
                            <label>Prior Warnings Days</label>
                          </Col>
                          <Col xl={5} lg={5}>
                            <input
                              type="text"
                              className="form-control"
                              name="priorWarningDays"
                              required
                              value={this.state.priorWarningDays}
                              onChange={this.onChange}
                              placeholder="0"
                            />
                          </Col>
                        </Row>

                        <Row
                          className={showService}
                          style={{ marginTop: "2%" }}
                        >
                          <Col xl={5} lg={6}>
                            <label>Suspend After Days</label>
                          </Col>
                          <Col xl={5} lg={5}>
                            <input
                              type="text"
                              className="form-control"
                              name="suspendDays"
                              required
                              value={this.state.suspendDays}
                              onChange={this.onChange}
                              placeholder="0"
                            />
                          </Col>
                        </Row>

                        <Row
                          className={showService}
                          style={{ marginTop: "2%" }}
                        >
                          <Col xl={5} lg={6}>
                            <label>Number of Service Visits</label>
                          </Col>
                          <Col xl={5} lg={5}>
                            <input
                              type="text"
                              className="form-control"
                              name="noOfServiceVisits"
                              value={this.state.noOfServiceVisits}
                              onChange={this.onChange}
                              placeholder="0"
                            />
                          </Col>
                        </Row>

                        <Row
                          className={showService}
                          style={{ marginTop: "2%" }}
                        >
                          <Col xl={5} lg={6}>
                            <label>Max Services Per Visit</label>
                          </Col>
                          <Col xl={5} lg={5}>
                            <input
                              type="text"
                              className="form-control"
                              name="maxServicePerVisit"
                              value={this.state.maxServicePerVisit}
                              onChange={this.onChange}
                              placeholder="0"
                            />
                          </Col>
                        </Row>

                        <Row
                          className={showService}
                          style={{ marginTop: "2%" }}
                        >
                          <Col xl={5} lg={6}>
                            <label>Additional Per Unit Price</label>
                          </Col>
                          <Col xl={5} lg={5}>
                            <input
                              type="text"
                              className="form-control"
                              name="unitPrice"
                              value={this.state.unitPrice}
                              onChange={this.onChange}
                              placeholder="0.00"
                            />
                          </Col>
                        </Row>

                        <Row className="mt-2">
                          <Col xl={5} lg={6}>
                            <label>Status</label>
                          </Col>
                          <Col xl={5} lg={5}>
                            <select
                              className="form-control"
                              name="status"
                              onChange={this.onChange}
                              value={this.state.status}
                              required
                            >
                              <option value="1">Active</option>
                              <option value="2">Inactive</option>
                              <option value="3">Suspended</option>
                            </select>
                          </Col>
                        </Row>

                        <Row className="mt-2">
                          <Col xl={5} lg={6}>
                            <label>
                              {this.state.type == 3
                                ? "Active Days"
                                : "Warranty Days"}
                            </label>
                          </Col>
                          <Col xl={5} lg={5}>
                            <input
                              type="text"
                              className="form-control"
                              name="activeDays"
                              required
                              value={this.state.activeDays}
                              onChange={this.onChange}
                              placeholder="0"
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row className="mt-2">
                      <Col style={{ textAlign: "center" }} xl={6} lg={6}>
                        <button
                          type="submit"
                          className="btn btn-primary btn-sm"
                        >
                          Save Product
                        </button>
                      </Col>
                    </Row>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Product;
