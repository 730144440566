import React, { Component } from "react";
import queryString from "query-string";
import { Col, Row } from "reactstrap";
import * as moment from "moment";
import { saveAs } from "file-saver";
import { Fab, Tooltip, CircularProgress } from "@material-ui/core";
import DescriptionIcon from "@material-ui/icons/Description";

import { AuthHelperAccessCheck } from "../businessjs/AuthHelper";
import { api } from "../businessjs/API";

class JobcardInvoiceBillTest extends Component {
  state = {
    jobCardInvoiceId: "",
    jobCards: [],
    address: "",
    invoiceDate: "",
    invoiceDueDate: "",
    company_name: "",
    mrp: "",
    discount: "",
    total: "",
  };

  componentDidMount = () => {
    const values = queryString.parse(this.props.location.search);
    this.setState({
      jobCardInvoiceId: values.id,
      subscriber_id: values.sub_id,
    });
    const _invoiceInputs = {
      jobCardInvoiceId: values.id,
    };
    this.getJobCardById(_invoiceInputs);
  };

  getJobCardById = async (_invoiceInputs) => {
    const res = await api.post(
      "job-card/get-jobcard-invoice_by_id",
      _invoiceInputs
    );

    if (res) {
      if (res.invoice) {
        this.setState({
          address: res.invoice[0].invoice_address,
          invoiceDate: res.invoice[0].invoice_date,
          invoiceDueDate: res.invoice[0].invoice_due_date,
          company_name: res.invoice[0].company_name,
          mrp: res.invoice[0].total_amount,
          discount: res.invoice[0].discount,
          total: res.invoice[0].total,
        });
      }

      if (res.jobcards && res.jobcards.length) {
        let invoice_items = res.jobcards.map((jobCard) => ({
          autoid: jobCard.autoid,
          type: jobCard.type,
          jobCardDate: jobCard.job_date,
          jobCard_name: jobCard.title,
          location_name: jobCard.location_name,
          price: jobCard.jobCardItemPrice,
          product_name: jobCard.product_name,
          ticket: jobCard.subject,
        }));

        this.setState({
          jobCards: invoice_items,
        });
      }
    } else {
      AuthHelperAccessCheck(res);
    }
  };

  createAndDownloadPdf = async () => {
    const _invoiceInputs = {
      invoice_id: this.state.jobCardInvoiceId,
      subscriber_id: this.state.subscriber_id,
      company_id: localStorage.apiCompanyId,
    };
    const jobCardInvoice = await api.post(
      "sc-invoice-pdf/create-jc-invoice-pdf",
      _invoiceInputs
    );
    if (jobCardInvoice) {
      const pdfInput = {
        fileName: jobCardInvoice.fileName,
      };
      const fetchInvoice = await api.post(
        "sc-invoice-pdf/fetch-jc-invoice-pdf",
        pdfInput,
        "blob"
      );
      if (fetchInvoice) {
        const fileName = moment(new Date()).format("YYYYMMDDhhmmss");
        const pdfBlob = new Blob([fetchInvoice], {
          type: "application/pdf",
        });
        saveAs(pdfBlob, `${fileName}.pdf`);
      }
    }
  };

  render() {
    let _showProduct = this.state.jobCards;
    return (
      <React.Fragment>
        <div className="container mt-4">
          <div className="col-10 offset-1">
            <div
              className="card mt-4"
              style={{
                border: "solid",
                paddingLeft: "5%",
                paddingRight: "5%",
                paddingTop: "5%",
                marginBottom: "5%",
              }}
            >
              <Row>
                <Col>
                  <Row className="mb-4" style={{ paddingRight: "5%" }}>
                    <Col
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      {/* <button
                        type="button"
                        className="btn btn-primary"
                        onClick={this.createAndDownloadPdf}
                      >
                        <i className="fa fa-file-pdf-o" aria-hidden="true" />
                      </button> */}
                       <Tooltip title="pdf">
                        <Fab
                          color="primary"
                          size="small"
                          style={{ marginLeft: "1em" }}
                          onClick={this.createAndDownloadPdf}
                        >
                          <DescriptionIcon />
                        </Fab>
                      </Tooltip>
                    </Col>
                  </Row>
                  <Row>
                    <Col xl={9} lg={8} md={7}>
                      <img
                        src="https://webdev.cloudtel.qa/wp-content/uploads/2020/03/CloudTel-Logo.png"
                        className="img-responsive"
                        alt="logo"
                      />
                    </Col>
                    <Col xl={3} lg={4} md={5}>
                      <h2>INVOICE</h2>
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      style={{
                        flexDirection: "column",
                        paddingRight: "7%",
                      }}
                    >
                      <Row style={{ justifyContent: "flex-end" }}>
                        INVOIVE : {" #" + this.state.jobCardInvoiceId}
                      </Row>
                      <Row style={{ justifyContent: "flex-end" }}>
                        DATE :{" "}
                        {" " + moment(this.state.invoiceDate).format("L")}
                      </Row>
                      <Row style={{ justifyContent: "flex-end" }}>
                        DUE DATE :{" "}
                        {" " + moment(this.state.invoiceDueDate).format("L")}
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <label>Bill To,</label>
                      <br />
                      {this.state.company_name}
                      <br />
                      {this.state.address}
                    </Col>
                  </Row>
                  <Row style={{ marginTop: "5%" }}>
                    <div className="table-responsive">
                      <Col>
                        <table
                          className="table table-bordered"
                          style={{ border: "1" }}
                        >
                          <thead>
                            <tr>
                              <th>JOBCARD</th>
                              <th>DATE</th>
                              <th>TYPE</th>
                              <th>LOCATION</th>
                              <th>TICKET</th>
                              <th>SERVICE</th>
                              <th>PRICE</th>
                            </tr>
                          </thead>
                          <tbody>
                            {_showProduct.map((jobcard, i) => (
                              <React.Fragment key={i}>
                                <tr>
                                  <td>{jobcard.jobCard_name}</td>
                                  <td>
                                    {moment(jobcard.jobCardDate).format("L")}
                                  </td>
                                  <td>{jobcard.type}</td>
                                  <td>{jobcard.location_name}</td>
                                  <td>{jobcard.ticket}</td>
                                  <td>{jobcard.product_name}</td>
                                  <td style={{ textAlign: "end" }}>
                                    {jobcard.price}
                                  </td>
                                </tr>
                              </React.Fragment>
                            ))}
                            <tr>
                              <td colSpan="6" style={{ textAlign: "end" }}>
                                SUBTOTAL(Qr)
                              </td>
                              <td style={{ textAlign: "end" }}>
                                {this.state.mrp}
                              </td>
                            </tr>
                            <tr>
                              <td colSpan="6" style={{ textAlign: "end" }}>
                                DISCOUNT(Qr)
                              </td>
                              <td style={{ textAlign: "end" }}>
                                {this.state.discount}
                              </td>
                            </tr>
                            <tr>
                              <td colSpan="6" style={{ textAlign: "end" }}>
                                <b>TOTAL(Qr)</b>
                              </td>
                              <td style={{ textAlign: "end" }}>
                                {this.state.total}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </Col>
                    </div>
                  </Row>
                  <Row style={{ marginTop: "5%" }}>
                    <Col style={{ flexDirection: "column" }}>
                      <Row>
                        <label> Warranty Terms :</label>
                      </Row>

                      <Row style={{ marginLeft: "1%", marginTop: "1px" }}>
                        For term and conditions follow "Annexure A" document.
                      </Row>
                    </Col>
                  </Row>

                  <Row style={{ marginTop: "5%" }}>
                    <Col style={{ flexDirection: "column" }}>
                      <Row>
                        <label style={{ fontStyle: "oblique" }}>
                          Cloud Telematics, Office No. C-35, First Floor,
                          Building No.164, Al Maha Commercial Complex Street No.
                          J-29, Energy Street, New Industrial Area, Zone-81,
                          Doha, Ar Rayyan 4473 1516
                        </label>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default JobcardInvoiceBillTest;
