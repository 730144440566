import React, { Component } from "react";
import moment from "moment";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {
  Typography,
  Card,
  Divider,
  TextField,
  Grid,
  TextareaAutosize,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import Select from "../../compon-controls/CmbSelect";

const useStyles = makeStyles((theme) => ({
  resize: {
    "&::placeholder": {
      fontSize: "0.813rem",
    },
  },
}));

export const QuotationDetails = (props) => {
  const { formValues } = props;
  const classes = useStyles();
  return (
    <Grid>
      <Grid item xs={12}>
        <Typography style={{ padding: 15 }}>Shipping Details</Typography>
        <Divider style={{ backgroundColor: "#cdc9c9" }} />
      </Grid>
      <Grid container spacing={3} style={{ padding: "2%" }}>
        <Grid item xs={12} sm={6}>
          <Typography style={{ fontSize: 14,fontWeight:"normal" }}>Shipping Method</Typography>
          <Select
            name="currentShipping"
            value={formValues.currentShipping}
            onChange={(e) =>
              props.changeFormValues((prev) => ({
                ...prev,
                currentShipping: e.target.value,
              }))
            }
            dataSource={formValues.shippingMethod}
          />
        </Grid>

        {/* <Grid item xs={12} sm={4}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              disableToolbar
              fullWidth
              variant="inline"
              format="MM/dd/yyyy"
              id="date-picker-inline"
              label="Delevery Date"
              value={formValues.deliveryDate}
              // onChange={props.setDeliveryDate}
              onChange={(e) =>
                props.changeFormValues((prev) => ({
                  ...prev,
                  deliveryDate: e,
                }))
              }
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              autoOk={true}
            />
          </MuiPickersUtilsProvider>
        </Grid> */}

        <Grid item xs={12} sm={6}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              disableToolbar
              fullWidth
              variant="inline"
              format="MM/dd/yyyy"
              id="date-picker-inline"
              label="Due Date"
              value={formValues.dueDate}
              // onChange={props.setDueDate}
              onChange={(e) =>
                props.changeFormValues((prev) => ({
                  ...prev,
                  dueDate: e,
                }))
              }
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              autoOk={true}
            />
          </MuiPickersUtilsProvider>
        </Grid>

        {/* <Grid item xs={12} sm={6}>
          <Typography style={{ fontSize: 14,fontWeight:"normal" }}>Shipping Terms</Typography>
          <TextField
            variant="outlined"
            name="shippingTerms"
            multiline={true}
            fullWidth
            InputProps={{
              classes: {
                input: classes.resize,
              },
            }}
            value={formValues.shippingTerms}
            onChange={(e) => {
              e.persist();
              props.changeFormValues((prev) => ({
                ...prev,
                shippingTerms: e.target.value,
              }));
            }}
          />
        </Grid> */}
        <Grid item xs={12} sm={12}>
          <Typography style={{ fontSize: 14,fontWeight:"normal" }}>Note</Typography>
          <TextField
            variant="outlined"
            name="paymentTerms"
            // rows={3}
            // rowsMax={10}
            multiline={true}
            fullWidth
            InputProps={{
              classes: {
                input: classes.resize,
              },
            }}
            value={formValues.paymentTerms}
            onChange={(e) => {
              e.persist();
              props.changeFormValues((prev) => ({
                ...prev,
                paymentTerms: e.target.value,
              }));
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
