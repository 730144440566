import React, { useState, useEffect } from "react";
import "date-fns";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import {
  Typography,
  TextField,
  Fab,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  CircularProgress,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { PurchaseOrderList } from "./PurchaseOrderList";
import ExportIcon from "@material-ui/icons/ArrowUpward";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { CSVLink } from "react-csv";
import { Link as RouterLink, Redirect } from "react-router-dom";
import { api } from "../../businessjs/API";
import { SnackBar } from "../Login/SnackBar";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  SearchInput: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  searchPaper: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    // width: 400,
  },
  margin: {
    margin: theme.spacing(1),
  },
  searchFab: {
    textAlign: "right",
    marginTop: "1em",
  },
  buttonProgress: {
    color: "#2196f3",
    position: "absolute",
    left: "55%",
    marginTop: "3.5em",
  },
  heading: {
    fontSize: theme.typography.pxToRem(20),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
}));

const headers = [
  { label: "Q.No", key: "autoid" },

  { label: "Account", key: "account_name" },
  { label: "Date", key: "po_date" },
  { label: "Ship To", key: "ship_to" },
  { label: "Discount", key: "discount" },
  { label: "Order Total", key: "total_amount" },
];

export const PurchaseOrder = () => {
  const classes = useStyles();
  const [rows, setRows] = useState([]);
  const [navigate, setNavigate] = useState(false);
  const [dataToExport, setDataToExport] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const [accountName, setAccountName] = useState(0);
  const [poNumber, setPoNumber] = useState("");
  const [poFromDate, setPoFromDate] = useState(
    moment(new Date()).subtract(7, "days").format()
  );
  const [poToDate, setPoToDate] = useState(new Date());
  const [accountOptions, setAccountOptions] = useState([]);
  const [infoMessage, setInfoMessage] = useState("");
  const [color, setColor] = useState("");
  const [loading, setLoading] = useState(false);
  // const [exportButtonDisabled, setExportButtonDisabled] = useState(true);
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const NavLink = React.forwardRef((props, ref) => (
    <RouterLink innerRef={ref} {...props} />
  ));

  useEffect(() => {
    async function loadPurchaseLists() {
      setLoading(true);
      try {
        const searchInput = {
          accountId: accountName,
          startDate: moment(poFromDate).format("YYYY-MM-DD"),
          endDate: moment(poToDate).format("YYYY-MM-DD"),
          poNumber: poNumber,
        };
        const purchaseOrderList = await api.post(
          "sc-purchase-order/get-purchase-order-list",
          searchInput
        );
        setLoading(false);
        if (purchaseOrderList && Array.isArray(purchaseOrderList)) {
          setRows(purchaseOrderList);
          setDataToExport(purchaseOrderList);
          setRowCount(purchaseOrderList.length);
          setDataToExport(purchaseOrderList);
        } else {
          setNavigate(true);
        }
      } catch (e) {
        console.log(e);
      }
    }
    loadPurchaseLists();
  }, [rows.length]);
  useEffect(() => {
    async function loadAccounts() {
      try {
        const accountList = await api.post("sc-account/get-all-for-cmb");
        setAccountOptions(accountList.accountSet);
      } catch (e) {
        console.log("error", e);
      }
    }
    loadAccounts();
  }, []);

  const handleFromDateChange = (date) => {
    setPoFromDate(date);
    const searchInput = {
      accountId: accountName,
      startDate: date,
      endDate: poToDate,
      poNumber: poNumber,
    };
    onSearchFunctionality(searchInput);
  };
  const handleToDateChange = (date) => {
    setPoToDate(date);
    const searchInput = {
      accountId: accountName,
      startDate: poFromDate,
      endDate: date,
      poNumber: poNumber,
    };
    onSearchFunctionality(searchInput);
  };
  const handleSelectChange = (value) => {
    setAccountName(value);
    const searchInput = {
      accountId: value,
      startDate: poFromDate,
      endDate: poToDate,
      poNumber: poNumber,
    };
    onSearchFunctionality(searchInput);
  };
  const handleChangePoNumber = (value) => {
    setPoNumber(value);
    const searchInput = {
      accountId: accountName,
      startDate: poFromDate,
      endDate: poToDate,
      poNumber: value,
    };
    onSearchFunctionality(searchInput);
  };
  const onSearchFunctionality = async (searchInput) => {
    try {
      const purchaseOrderList = await api.post(
        "sc-purchase-order/get-purchase-order-list",
        searchInput
      );

      if (purchaseOrderList && Array.isArray(purchaseOrderList)) {
        setRows(purchaseOrderList);
        setDataToExport(purchaseOrderList);
      }
    } catch (e) {
      console.log(e);
    }
  };
  const onDeletePurchaseOrder = async (purchaseOrderId) => {
    const purchaseOrderDeleteList =
      rows && rows.filter((row) => row.autoid !== purchaseOrderId);
    setRows(purchaseOrderDeleteList);
    const purchaseOrderListDeleted = await api.post(
      "sc-purchase-order/delete-purchaseOrder",
      { purchaseOrderId }
    );
    if (purchaseOrderListDeleted.status === "success") {
      setInfoMessage("Successfully cancelled this purchase");
      setColor("#2196f3");
    } else {
      setInfoMessage(purchaseOrderListDeleted.message);
      setColor("#d50000");
    }
  };
  const onCloseSnackbar = () => {
    setInfoMessage("");
  };
  if (navigate) {
    localStorage.clear();
    return <Redirect to="/login"></Redirect>;
  } else {
    return (
      <Grid container spacing={3}>
        <SnackBar
          status={infoMessage}
          onCloseError={onCloseSnackbar}
          color={color}
        />
        <Grid item xs={12}>
          <Typography variant="h3" style={{ fontWeight: "bold" }}>
            Purchase Orders List
          </Typography>
        </Grid>
        <Grid item xs={12} style={{ textAlign: "right" }}>
          {/* <RouterLink to="/accountnew"> */}
          <Fab
            variant="extended"
            size="medium"
            color="primary"
            aria-label="add"
            disabled={loading}
            className={classes.margin}
            component={NavLink}
            to={`/purchase-order/${"new"}`}
          >
            <AddIcon className={classes.extendedIcon} />
            Add New
          </Fab>
          {/* </RouterLink> */}
          <CSVLink
            data={dataToExport}
            headers={headers}
            filename={"purchaseorderdetails.csv"}
          >
            <Fab
              variant="extended"
              size="medium"
              color="primary"
              style={{ display: rowCount !== 0 ? "" : "none" }}
              // disabled={exportButtonDisabled}
              aria-label="export"
              className={classes.margin}
            >
              <ExportIcon className={classes.extendedIcon} />
              Export
            </Fab>
          </CSVLink>
        </Grid>

        <Grid item xs={12} sm={12} style={{ textAlign: "left" }}>
          <Paper className={classes.searchPaper}>
            <Grid
              container
              spacing={3}
              style={{ padding: "2em" }}
              justify="center"
            >
              <Grid item xs={12}>
                <Accordion
                  expanded={expanded === "panel1"}
                  onChange={handleChange("panel1")}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <Typography className={classes.heading}>Search</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {/* <Paper elevation={3}> */}
                    <Grid
                      container
                      spacing={3}
                      justify="center"
                      style={{ padding: "1em" }}
                    >
                      <Grid item xs={6} sm={3}>
                        <FormControl className={classes.formControl} fullWidth>
                          <InputLabel id="demo-simple-select-label">
                            Account Name
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            fullWidth
                            value={accountName}
                            onChange={(event) =>
                              handleSelectChange(event.target.value)
                            }
                          >
                            <MenuItem value={0}>All</MenuItem>
                            {accountOptions &&
                              accountOptions.map((option) => (
                                <MenuItem value={option.autoid}>
                                  {option.name}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      </Grid>

                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <Grid item xs={6} sm={3}>
                          <KeyboardDatePicker
                            disableToolbar
                            fullWidth
                            variant="inline"
                            format="dd-MM-yyyy"
                            id="date"
                            label="From Date"
                            value={poFromDate}
                            onChange={handleFromDateChange}
                            KeyboardButtonProps={{
                              "aria-label": "change date",
                            }}
                          />
                        </Grid>
                      </MuiPickersUtilsProvider>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <Grid item xs={6} sm={3}>
                          <KeyboardDatePicker
                            disableToolbar
                            fullWidth
                            variant="inline"
                            format="dd-MM-yyyy"
                            id="date"
                            label="To Date"
                            value={poToDate}
                            onChange={handleToDateChange}
                            KeyboardButtonProps={{
                              "aria-label": "change date",
                            }}
                          />
                        </Grid>
                      </MuiPickersUtilsProvider>
                      <Grid item xs={6} sm={3}>
                        <TextField
                          fullWidth
                          name="poNumber"
                          label="PO Number"
                          value={poNumber}
                          onChange={(event) =>
                            handleChangePoNumber(event.target.value)
                          }
                          inputProps={{ maxLength: 50 }}
                        />
                      </Grid>
                    </Grid>
                    {/* </Paper> */}
                  </AccordionDetails>
                </Accordion>
              </Grid>
              <Grid
                item
                xs={12}
                style={{ marginTop: "2em", minHeight: "15em" }}
              >
                {loading ? (
                  loading && (
                    <CircularProgress
                      size={50}
                      className={classes.buttonProgress}
                    />
                  )
                ) : (
                  <PurchaseOrderList
                    rows={rows}
                    onDeletePurchaseOrder={onDeletePurchaseOrder}
                  />
                )}
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        {/* <Grid item xs={4} sm={2} style={{marginLeft:"-2.5em"}}>
         
        </Grid> */}
      </Grid>
    );
  }
};
