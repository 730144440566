import React from "react";
import { Col, Row } from "reactstrap";
import ToastAlert from "./ToastAlert";
import { api } from "../businessjs/API";

class SupportMemberItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      deleteBtn: { cssClass: "btn btn-default btn-sm", btnText: "Delete" },
      editBtn: { cssClass: "btn btn-default btn-sm", btnText: "Edit" },
      resetBtn: {
        cssClass: "btn btn-default btn-sm",
        btnText: "Reset Password",
      },
      editMode: false,
      first_name: "",
      last_name: "",
      email: "",
      contact_number: "",
      auth_password: "",
      new_password: "",
      user_name: "",
      resetPasswordMode: false,
      hideResetBtn: false,
    };

    this.onDelete = this.onDelete.bind(this);
    this.onEdit = this.onEdit.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  onDelete(e) {
    switch (this.state.deleteBtn.btnText) {
      case "Delete":
        this.setState({
          deleteBtn: {
            cssClass: "btn btn-danger btn-sm",
            btnText: "Confirm ?",
          },
        });

        this.setState({
          editBtn: { cssClass: "btn btn-warning btn-sm", btnText: "Cancel" },
        });
        break;
      case "Update":
        let temObj = this.props.itemObject;
        temObj.first_name = this.state.first_name;
        temObj.last_name = this.state.last_name;
        let resetObj = {
          newPassword: this.state.new_password,
          email: this.state.user_name,
          autoid: this.props.itemObject.autoid,
        };
        if (this.state.resetPasswordMode) {
          this.props.handleOnResetPassword(resetObj);
        } else {
          this.props.onUpdate(temObj);
        }
        this.setState({ editMode: false, hideResetBtn: false });
        this.setState({
          deleteBtn: { cssClass: "btn btn-default btn-sm", btnText: "Delete" },
        });

        this.setState({
          editBtn: { cssClass: "btn btn-default btn-sm", btnText: "Edit" },
          resetBtn: {
            cssClass: "btn btn-default btn-sm",
            btnText: "Reset Password",
          },
        });

        break;
      case "Confirm ?":
        this.props.onDelete(this.props.itemObject);

        break;
      default:
        this.setState({
          deleteBtn: {
            cssClass: "btn btn-danger btn-sm",
            btnText: "Confirm ?",
          },
        });

        this.setState({
          editBtn: { cssClass: "btn btn-warning btn-sm", btnText: "Cancel" },
        });
        break;
    }
  }
  onReset = () => {
    this.setState({ resetPasswordMode: true });
    this.onEdit();
  };
  onEdit(e) {
    if (this.state.editBtn.btnText === "Cancel") {
      this.setState({ editMode: false, hideResetBtn: false });
      this.setState({
        deleteBtn: { cssClass: "btn btn-default btn-sm", btnText: "Delete" },
      });

      this.setState({
        editBtn: { cssClass: "btn btn-default btn-sm", btnText: "Edit" },
      });
    } else if (this.state.resetBtn.btnText === "Reset Password") {
      this.setState({ editMode: true, hideResetBtn: true });
      this.setState({
        deleteBtn: { cssClass: "btn btn-primary btn-sm", btnText: "Update" },
      });

      this.setState({
        editBtn: { cssClass: "btn btn-warning btn-sm", btnText: "Cancel" },
      });

      this.setState({
        first_name: this.props.itemObject.first_name,
        last_name: this.props.itemObject.last_name,
        user_name: this.props.itemObject.email,
      });
    } else if (this.state.editBtn.btnText === "Edit") {
      this.setState({ editMode: true, hideResetBtn: false });
      this.setState({
        deleteBtn: { cssClass: "btn btn-primary btn-sm", btnText: "Update" },
      });

      this.setState({
        editBtn: { cssClass: "btn btn-warning btn-sm", btnText: "Cancel" },
      });

      this.setState({
        first_name: this.props.itemObject.first_name,
        last_name: this.props.itemObject.last_name,
      });
    }
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  formateBody(mode) {
    if (mode) {
      return (
        <React.Fragment>
          <div
            className={
              this.state.resetPasswordMode ? "mb-2 mt-2" : "mb-2 mt-2 d-none"
            }
          >
            <label htmlFor="SupportMember_name">User Name</label>
            <input
              type="text"
              className="form-control"
              name="user_name"
              value={this.state.user_name}
              disabled
              onChange={this.onChange}
            />

            <label htmlFor="short_code" className="mt-4">
              New Password
            </label>
            <input
              type="text"
              className="form-control"
              name="new_password"
              value={this.state.new_password}
              onChange={this.onChange}
            />
          </div>

          <div
            className={
              this.state.resetPasswordMode ? "mb-2 mt-2 d-none" : "mb-2 mt-2"
            }
          >
            <label htmlFor="SupportMember_name">First Name</label>
            <input
              type="text"
              className="form-control"
              name="first_name"
              value={this.state.first_name}
              onChange={this.onChange}
              required
            />

            <label htmlFor="short_code" className="mt-4">
              Last Name
            </label>
            <input
              type="text"
              className="form-control"
              name="last_name"
              value={this.state.last_name}
              onChange={this.onChange}
            />
          </div>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <span>
            {this.props.itemObject.first_name} {this.props.itemObject.last_name}
          </span>
          <span className="phone">{this.props.itemObject.email}</span>
          <span className="phone">{this.props.itemObject.contact_number}</span>
        </React.Fragment>
      );
    }
  }

  render() {
    return (
      <li>
        {this.formateBody(this.state.editMode)}
        <div className="btn-group float-right">
          <button
            type="button"
            onClick={this.onEdit}
            className={this.state.editBtn.cssClass}
          >
            {this.state.editBtn.btnText}
          </button>
          <button
            type="button"
            onClick={this.onDelete}
            className={this.state.deleteBtn.cssClass}
          >
            {this.state.deleteBtn.btnText}
          </button>
          <button
            type="button"
            onClick={this.onReset}
            className={
              this.state.hideResetBtn
                ? "btn btn-default btn-sm d-none"
                : "btn btn-default btn-sm "
            }
          >
            {this.state.resetBtn.btnText}
          </button>
        </div>
      </li>
    );
  }
}

class SupportMember extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      allDataRows: [],
      filteredDataRows: [],
      first_name: "",
      last_name: "",
      email: "",
      contact_number: "",
      auth_password: "",
      toastItems: [],
    };

    this.searchHandler = this.searchHandler.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  searchHandler(event) {
    let searcjQery = event.target.value.toLowerCase(),
      filteredDataRows = this.state.allDataRows.filter((el) => {
        let searchValue = el.first_name.toLowerCase();
        return searchValue.indexOf(searcjQery) !== -1;
      });
    this.setState({
      filteredDataRows: filteredDataRows,
    });
  }

  componentDidMount = async () => {
    try {
      const allSupportMember = await api.post("supportmember/getall");
      if (allSupportMember) {
        this.setState({ allDataRows: allSupportMember.userdata });
        this.setState({ filteredDataRows: allSupportMember.userdata });
      }
    } catch (error) {
      return error;
    }
  };

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  onSubmit = async (e) => {
    e.preventDefault();

    const addMemberInputs = {
      first_name: this.state.first_name,
      last_name: this.state.last_name,
      email: this.state.email,
      contact_number: this.state.contact_number,
      auth_password: this.state.auth_password,
      updateby: 899, // TODO  remove it
    };
    const addSupportMember = await api.post(
      "supportmember/new",
      addMemberInputs
    );
    if (addSupportMember) {
      this.setState({ SupportMember_name: "", short_code: "" });
      this.onToastItemAdd(addSupportMember.status, addSupportMember.message);

      if (addSupportMember.status === "success") {
        const allSupportMember = await api.post("supportmember/getall");
        if (allSupportMember) {
          this.setState({
            allDataRows: allSupportMember.userdata,
            filteredDataRows: allSupportMember.userdata,

            first_name: "",
            last_name: "",
            email: "",
            contact_number: "",
            auth_password: "",
          });
        }
      }
    }
  };

  clearStateData = () => {
    this.setState({
      first_name: "",
      last_name: "",
      email: "",
      contact_number: "",
      auth_password: "",
    });
  };

  handleOnDelete = async (itemObject) => {
    const newFiltedItems = this.state.filteredDataRows.filter(
      (c) => c.autoid !== itemObject.autoid
    );

    const newAllItems = this.state.allDataRows.filter(
      (c) => c.autoid !== itemObject.autoid
    );

    this.setState({ allDataRows: newAllItems });
    this.setState({ filteredDataRows: newFiltedItems });
    const deleteSupportMember = await api.post(
      "supportmember/delete",
      itemObject
    );
    if (deleteSupportMember) {
      this.onToastItemAdd(
        deleteSupportMember.status,
        deleteSupportMember.message
      );
    }
  };

  handleOnUpdate = async (itemObject) => {
    const updateSupportMember = await api.post(
      "supportmember/update",
      itemObject
    );
    if (updateSupportMember) {
      this.onToastItemAdd(
        updateSupportMember.status,
        updateSupportMember.message
      );

      if (updateSupportMember.status === "success") {
        const allSupportMember = await api.post("supportmember/getall");
        if (allSupportMember) {
          this.setState({ allDataRows: allSupportMember.userdata });
          this.setState({ filteredDataRows: allSupportMember.userdata });
        }
      }
    }
  };
  handleOnResetPassword = async (itemObject) => {
    const updateSupportMemberPassword = await api.post(
      "supportmember/change-password",
      itemObject
    );
    if (updateSupportMemberPassword) {
      this.onToastItemAdd(
        updateSupportMemberPassword.status,
        updateSupportMemberPassword.message
      );

      if (updateSupportMemberPassword.status === "success") {
        const allSupportMember = await api.post("supportmember/getall");
        if (allSupportMember) {
          this.setState({ allDataRows: allSupportMember.userdata });
          this.setState({ filteredDataRows: allSupportMember.userdata });
        }
      }
    }
  };
  handleToastItemClose = (itemObject) => {
    const toastItems = this.state.toastItems.filter(
      (c) => c.id !== itemObject.id
    );
    this.setState({ toastItems });
  };

  onToastItemAdd(type, message) {
    let newid = this.state.toastItems.length + 1;
    if (
      this.state.toastItems.filter(function (e) {
        return e.id === newid;
      }).length > 0
    ) {
      newid = this.state.toastItems.length + Math.floor(Math.random() * 100);
    }

    let newToastItems = this.state.toastItems;
    let itemToast = {
      id: newid,
      type: type === "success" ? "success" : "danger",
      message: message,
    };

    newToastItems.push(itemToast);
    this.setState({ toastItems: newToastItems });

    setTimeout(() => {
      this.handleToastItemClose(itemToast);
    }, 5000);
  }

  render() {
    let showDataRows = this.state.filteredDataRows;

    return (
      <React.Fragment>
        {/* <Navbar /> */}
        <Row
          style={{
            margin: "0",
          }}
        >
          <Col
            style={{
              flexDirection: "column",
              display: "flex",
            }}
          >
            <div className="container mt-3">
              <Row>
                <ToastAlert
                  toastItems={this.state.toastItems}
                  onItemClose={this.handleToastItemClose}
                />
                <Col>
                  <div className="card mb-1">
                    <div className="card-header">
                      <h4 className="card-title">Add Support Member</h4>
                    </div>
                    <div className="card-content collpase show">
                      <div className="card-body">
                        <form className="form" onSubmit={this.onSubmit}>
                          <div className="form-body">
                            <div className="form-group">
                              <label htmlFor="SupportMember_name">
                                First Name
                              </label>
                              <div className="position-relative has-icon-left">
                                <input
                                  type="text"
                                  className="form-control"
                                  name="first_name"
                                  onChange={this.onChange}
                                  value={this.state.first_name}
                                  required
                                />
                              </div>
                            </div>

                            <div className="form-group">
                              <label htmlFor="short_code">Last Name</label>
                              <div className="position-relative has-icon-left">
                                <input
                                  type="text"
                                  className="form-control"
                                  name="last_name"
                                  value={this.state.last_name}
                                  onChange={this.onChange}
                                  required
                                />
                              </div>
                            </div>

                            <div className="form-group">
                              <label htmlFor="short_code">Email</label>
                              <div className="position-relative has-icon-left">
                                <input
                                  type="email"
                                  className="form-control"
                                  name="email"
                                  value={this.state.email}
                                  onChange={this.onChange}
                                  required
                                />
                              </div>
                            </div>

                            <div className="form-group">
                              <label htmlFor="short_code">Contact Number</label>
                              <div className="position-relative has-icon-left">
                                <input
                                  type="text"
                                  className="form-control"
                                  name="contact_number"
                                  value={this.state.contact_number}
                                  onChange={this.onChange}
                                />
                              </div>
                            </div>

                            <div className="form-group">
                              <label htmlFor="short_code">Password</label>
                              <div className="position-relative has-icon-left">
                                <input
                                  type="Password"
                                  className="form-control"
                                  name="auth_password"
                                  value={this.state.auth_password}
                                  onChange={this.onChange}
                                  required
                                />
                              </div>
                            </div>

                            <div className="form-actions right">
                              <button
                                type="button"
                                className="btn btn-warning mr-1"
                              >
                                <i className="ft-x" /> Cancel
                              </button>
                              <button type="submit" className="btn btn-primary">
                                <i className="fa fa-check-square-o" /> Save
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col>
                  <div className="card">
                    <div className="card-header">
                      <Row>
                        <Col xs={12} sm={12} md={12} lg={6} xl={7}>
                          <h4>Support Member List</h4>
                        </Col>
                        <Col lg={6} md={12} xl={5}>
                          <input
                            type="text"
                            className="card-search"
                            placeholder="Search... "
                            onChange={this.searchHandler}
                          />
                        </Col>
                      </Row>
                    </div>

                    <div className="card-content collpase show">
                      <div className="card-body">
                        <div className="filterlist">
                          <ul>
                            {showDataRows
                              ? showDataRows.map((el) => {
                                  return (
                                    <SupportMemberItem
                                      key={el.autoid}
                                      itemObject={el}
                                      onDelete={this.handleOnDelete}
                                      onUpdate={this.handleOnUpdate}
                                      handleOnResetPassword={
                                        this.handleOnResetPassword
                                      }
                                    />
                                  );
                                })
                              : ""}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default SupportMember;
