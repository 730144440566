import React, { Component } from "react";
import { Route } from "react-router-dom";
import {
  Typography,
  Fab,
  CircularProgress,
  FormControl,
  TextField,
  MenuItem,
  TableSortLabel,
  Tooltip,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  InputLabel,
  Select,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { AuthHelperAccessCheck } from "../businessjs/AuthHelper";
import { api } from "../businessjs/API";

function descendingComparator(a, b, orderBy) {
  if (orderBy !== "") {
    // console.log("date", isNaN(a[orderBy]));
    if (isNaN(parseFloat(a[orderBy]))) {
      // console.log("date", a[orderBy]);
      if (b[orderBy].toLowerCase() < a[orderBy].toLowerCase()) {
        return -1;
      }
      if (b[orderBy].toLowerCase() > a[orderBy].toLowerCase()) {
        return 1;
      }
      return 0;
    } else {
      // console.log("in else");
      if (parseFloat(b[orderBy]) < parseFloat(a[orderBy])) {
        return -1;
      }
      if (parseFloat(b[orderBy]) > parseFloat(a[orderBy])) {
        return 1;
      }
      return 0;
    }
  }
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  // { id: "autoid", numeric: false, label: "J.No." },
  { id: "title", numeric: false, label: "Job" },
  { id: "ticketSubject", numeric: false, label: "Ticket" },
  {
    id: "job_date",
    label: "Date",
    numeric: false,
    disablePadding: false,
  },
  { id: "location_name", label: "Location", numeric: false },
  {
    id: "start_time",
    label: "Starting",
    numeric: false,
    disablePadding: false,
  },
  { id: "end_time", numeric: false, label: "Ending" },
  { id: "ischargeable", numeric: false, label: "Chargeable" },
  { id: "type", numeric: false, label: "Type" },
  { id: "created_by", numeric: false, label: "Created" },
  { id: "company_name", numeric: false, label: "Subscriber" },
  { id: "status", numeric: false, label: "Status" },
  { id: "edit", label: "" },
];

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  buttonProgress: {
    color: "#2196f3",
    position: "absolute",
    left: "55%",
    marginTop: "4.5em",
  },
}));

function EnhancedTableHead(props) {
  const classes = useStyles();
  const { order, orderBy, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            // align={headCell.numeric ? "right" : "center"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

class JobCardsList extends Component {
  state = {
    loading: false,
    allJobCards: [],
    searchJobCard: "",
    subscriber_id: 0,
    subscribers: [],
    page: 0,
    rowsPerPage: 50,
    order: "asc",
    orderBy: "",
    expanded: false,
    jobCardBackup: [],
  };

  componentDidMount() {
    const jobCardListInputs = {
      subscriberId: this.state.subscriber_id,
    };
    this.getJobCardList(jobCardListInputs);
    this.getSubscriberForCmb();
  }

  getJobCardList = async (jobCardListInputs) => {
    this.setState({ loading: true });

    const res = await api.post("job-card/get-job-card-lst", jobCardListInputs);
    if (res && Array.isArray(res)) {
      this.setState({ allJobCards: res, loading: false, jobCardBackup: res });
    } else {
      this.setState({ loading: false });
      AuthHelperAccessCheck(res);
    }
  };

  getSubscriberForCmb = async () => {
    const subscribers = await api.post("sc-subscriber/get-all-for-cmb");
    if (subscribers && subscribers.userdata) {
      let subscriberArray = subscribers.userdata.map((opt) => ({
        label: opt.company_name,
        value: opt,
      }));
      this.setState({
        subscribers: subscriberArray,
      });
    } else {
      AuthHelperAccessCheck(subscribers);
    }
  };

  handleRequestSort = (event, property) => {
    const isAsc = this.state.orderBy === property && this.state.order === "asc";
    this.setState({ order: isAsc ? "desc" : "asc", orderBy: property });
  };

  editButton = (_jobCard) => {
    const EditButton = () => (
      <Route
        render={({ history }) => (
          <Tooltip title="Edit">
            <button
              type="button"
              className="btn btn-link btn-sm"
              onClick={() => {
                history.push({
                  pathname: "/edit-job-card",
                  state: {
                    jobcard: _jobCard,
                  },
                });
              }}
            >
              <i className="fa fa-pencil" aria-hidden="true" />
            </button>
          </Tooltip>
        )}
      />
    );
    return <EditButton />;
  };

  subscriberChange = (e) => {
    this.setState({ subscriber_id: e.target.value });
    const jobCardListInputs = {
      subscriberId: e.target.value,
    };
    this.getJobCardList(jobCardListInputs);
  };

  jobCardAdd = () => {
    const JobCardAddButton = () => (
      <Route
        render={({ history }) => (
          <Fab
            color="primary"
            variant="extended"
            size="small"
            onClick={() => {
              history.push({ pathname: "/job-card-creation" });
            }}
          >
            <AddIcon />
            Add
          </Fab>
        )}
      />
    );
    return <JobCardAddButton />;
  };

  handleChange = (panel) => (event, isExpanded) => {
    this.setState({ expanded: isExpanded ? panel : false });
  };

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: parseInt(event.target.value, 10), page: 0 });
  };

  /**
   * to get badge based on status fetch from DB.
   */
  getBadge = (status) => {
    let _classes = "badge badge-";
    _classes += status === "Completed" ? "success" : "primary";
    return _classes;
  };

  onSearchFunctionality = (searchKey) => {
    const { jobCardBackup } = this.state;
    if (searchKey !== "") {
      this.setState({ searchJobCard: searchKey });

      const data =
        jobCardBackup &&
        jobCardBackup.filter((item) => {
          return item.title.toUpperCase().includes(searchKey.toUpperCase());
        });
      if (data.length !== 0) {
        this.setState({ searchJobCard: searchKey, allJobCards: data });
      } else {
        this.setState({ allJobCards: [] });
      }
    } else {
      this.setState({
        searchJobCard: "",
        //  allJobCards: jobCardBackup
      });
    }
  };

  render() {
    let _showJobCards = this.state.allJobCards;
    const { classes } = this.props;

    return (
      <React.Fragment>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h3" style={{ fontWeight: "bold" }}>
              Job Cards
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ textAlign: "right" }}>
            {this.jobCardAdd()}
          </Grid>
          <Grid item xs={12} sm={12} style={{ textAlign: "left" }}>
            <Accordion
              expanded={this.state.expanded === "panel1"}
              onChange={this.handleChange("panel1")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography>Search</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={3}>
                  <Grid item xs={6} sm={5}>
                    <FormControl fullWidth>
                      <InputLabel>Subscriber</InputLabel>
                      <Select
                        value={this.state.subscriber_id}
                        onChange={this.subscriberChange}
                        fullWidth
                      >
                        <MenuItem value={0}>All</MenuItem>
                        {this.state.subscribers.map((subscriber, index) => (
                          <MenuItem value={subscriber.value.autoid} key={index}>
                            {subscriber.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={6} sm={4}>
                    <TextField
                      fullWidth
                      name="searchJobCard"
                      label="Job card..."
                      value={this.state.searchJobCard}
                      onChange={(event) =>
                        this.onSearchFunctionality(event.target.value)
                      }
                      inputProps={{ maxLength: 50 }}
                    />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item xs={12}>
            <Paper elevation={3} style={{ backgroundColor: "#fff" }}>
              {this.state.loading ? (
                this.state.loading && (
                  <Paper
                    elevation={3}
                    style={{
                      backgroundColor: "#fff",
                      minHeight: "15em",
                      align: "center",
                    }}
                  >
                    <CircularProgress
                      size={50}
                      style={{ marginLeft: "40%" }}
                    />
                  </Paper>
                )
              ) : (
                <div>
                  {" "}
                  <Table>
                    <EnhancedTableHead
                      order={this.state.order}
                      orderBy={this.state.orderBy}
                      onRequestSort={this.handleRequestSort}
                      rowCount={_showJobCards.length}
                    />
                    {_showJobCards.length !== 0 ? (
                      <TableBody>
                        {stableSort(
                          _showJobCards,
                          getComparator(this.state.order, this.state.orderBy)
                        )
                          .slice(
                            this.state.page * this.state.rowsPerPage,
                            this.state.page * this.state.rowsPerPage +
                              this.state.rowsPerPage
                          )
                          .map((jobcard, i) => (
                            <TableRow key={i}>
                              {/* <TableCell component="th" scope="row">
                                {jobcard.autoid}
                              </TableCell> */}
                              <TableCell component="th" scope="row">
                                {jobcard.title}
                              </TableCell>
                              <TableCell>
                                #
                                {jobcard.ticket_id +
                                  " " +
                                  jobcard.ticketSubject}
                              </TableCell>
                              <TableCell>{jobcard.job_date}</TableCell>
                              <TableCell align="center">
                                {jobcard.location_name}
                              </TableCell>

                              <TableCell>{jobcard.start_time}</TableCell>
                              <TableCell>{jobcard.end_time}</TableCell>
                              <TableCell align="center">
                                {jobcard.ischargeable}
                              </TableCell>
                              <TableCell>{jobcard.type}</TableCell>
                              <TableCell>{jobcard.created_by}</TableCell>
                              <TableCell>{jobcard.company_name}</TableCell>

                              <TableCell>
                                <span className={this.getBadge(jobcard.status)}>
                                  {jobcard.status}
                                </span>
                              </TableCell>

                              <TableCell>{this.editButton(jobcard)}</TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    ) : (
                      <Typography
                        style={{
                          textAlign: "center",
                          marginTop: "1em",
                          marginLeft: "-11em",
                        }}
                      >
                        No Records...............
                      </Typography>
                    )}
                  </Table>
                  <TablePagination
                    rowsPerPageOptions={[50, 200, 500]}
                    component="div"
                    count={_showJobCards.length}
                    rowsPerPage={this.state.rowsPerPage}
                    page={this.state.page}
                    onChangePage={this.handleChangePage}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                  />
                </div>
              )}
            </Paper>
          </Grid>
          {/* </Paper> */}
        </Grid>
      </React.Fragment>
    );
  }
}

export default JobCardsList;
