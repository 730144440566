import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Paper, Grid, Typography, Fab, Tooltip } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DescriptionIcon from "@material-ui/icons/Description";
import { Col, Row } from "reactstrap";
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
  Redirect,
} from "react-router-dom";
import { api } from "../../businessjs/API";
import moment from "moment";
import { saveAs } from "file-saver";

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

export const PurchaseBillView = (props) => {
  const classes = useStyles();
  const [purchaseBillDetails, setPurchaseBillDetails] = useState({});
  const [rows, setRows] = useState([]);
  const [subTotal, setSubTotal] = useState();
  const NavLink = React.forwardRef((props, ref) => (
    <RouterLink innerRef={ref} {...props} />
  ));
  useEffect(() => {
    const loadPurchaseDetails = async () => {
      try {
        const purchaseId = props.match.params.id;
        const purchaseBillDetail = await api.post(
          "sc-purchase/get-purchase-by-id",
          { purchaseId }
        );

        // if (purchaseBillDetail.status !== "failed") {
        if (purchaseBillDetail) {
          setPurchaseBillDetails(purchaseBillDetail.purchase);
          setRows(purchaseBillDetail.productSet);
          const sub =
            parseFloat(purchaseBillDetail.purchase.discount) +
            parseFloat(purchaseBillDetail.purchase.total_amount) -
            parseFloat(purchaseBillDetail.purchase.other_charge);
          setSubTotal(sub.toFixed(2));
        }
      } catch (e) {
        console.log(e);
      }
    };
    loadPurchaseDetails();
  }, [props.match.params.id]);
  const onDownloadPdf = async () => {
    const purchaseId = props.match.params.id;
    const pdfResult = await api.post("sc-purchase-pdf/get-purchase-pdf-by-id", {
      purchaseId,
    });
    if (pdfResult.status !== "failed") {
      const pdfInput = {
        fileName: pdfResult.fileName,
      };
      const fetchPurchaseOrder = await api.post(
        "sc-purchase-pdf/fetch-purchase-pdf",
        pdfInput,
        "blob"
      );
      if (fetchPurchaseOrder.status !== "failed") {
        const fileName = moment(new Date()).format("YYYYMMDDhhmmss");
        const pdfBlob = new Blob([fetchPurchaseOrder], {
          type: "application/pdf",
        });
        saveAs(pdfBlob, `${fileName}.pdf`);
      }
    }
  };
  return (
    <Grid container spacing={3} justify="center" alignItems="center">
      <Paper style={{ minHeight: 875, width: 800 }}>
        <Grid item xs={12} style={{ textAlign: "right", marginTop: "2em" }}>
          <Fab
            size="small"
            color="primary"
            aria-label="edit"
            style={{
              marginRight: "1em",
              display: purchaseBillDetails.status === 0 ? "none" : "",
            }}
            component={NavLink}
            to={`/purchase-bill/${props.match.params.id}`}
          >
            <Tooltip title="Edit">
              <EditIcon />
            </Tooltip>
          </Fab>

          <Tooltip title="Pdf">
            <Fab
              size="small"
              color="primary"
              aria-label="edit"
              style={{ marginRight: "1em" }}
              onClick={onDownloadPdf}
            >
              <DescriptionIcon />
            </Fab>
          </Tooltip>
        </Grid>
        <Grid item xs={12} style={{ margin: "2em" }}>
          <Grid container spacing={3} justify="space-evenly">
            <Grid item xs={6} style={{ marginTop: "2em" }}>
              <img
                src="https://webdev.cloudtel.qa/wp-content/uploads/2020/03/CloudTel-Logo.png"
                className="img-responsive"
                alt="logo"
              />
            </Grid>
            <Grid item xs={6} style={{ marginTop: "2em" }}>
              <Typography
                variant="h2"
                style={{ textAlign: "right", fontWeight: "bold" }}
              >
                Purchase Bill
              </Typography>
              <Typography
                variant="h6"
                style={{ textAlign: "right", fontWeight: "bold" }}
              >
                No : #{props.match.params.id}
              </Typography>
              <Typography
                variant="h6"
                style={{ textAlign: "right", fontWeight: "bold" }}
              >
                Date:
                {moment(purchaseBillDetails.purchase_date).format("DD/MM/YYYY")}
              </Typography>
            </Grid>
            <Grid item xs={12} style={{ marginTop: "2em" }}>
              <Typography
                variant="h5"
                style={{ textAlign: "left", fontWeight: "bold" }}
              >
                To,
              </Typography>
              <Typography
                variant="h5"
                style={{ textAlign: "left", fontWeight: "bold" }}
              >
                {purchaseBillDetails.account_name}
              </Typography>
              <Typography
                variant="h5"
                style={{
                  textAlign: "left",
                  fontWeight: "bold",
                  display:
                    purchaseBillDetails.city &&
                    purchaseBillDetails.state !== undefined
                      ? ""
                      : "none",
                }}
              >
                {purchaseBillDetails.city},{purchaseBillDetails.state}
              </Typography>
              <Typography
                variant="h5"
                style={{
                  textAlign: "left",
                  fontWeight: "bold",
                  display:
                    purchaseBillDetails.country !== undefined ? "" : "none",
                }}
              >
                {purchaseBillDetails.country}
              </Typography>
              <Typography
                variant="h5"
                style={{
                  textAlign: "left",
                  fontWeight: "bold",
                  display:
                    purchaseBillDetails.zipcode !== undefined ? "" : "none",
                }}
              >
                {purchaseBillDetails.zipcode}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} style={{ margin: "3em" }}>
          <Row style={{ marginTop: "10%" }}>
            <div className="table-responsive">
              <Col>
                <table className="table table-bordered" style={{ border: "1" }}>
                  <thead>
                    <tr>
                      <th>Description</th>
                      <th>Units</th>
                      <th style={{ textAlign: "end" }}>Unit Price</th>
                      <th style={{ textAlign: "end" }}>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {rows.map((row, i) => (
                      <React.Fragment key={i}>
                        <tr>
                          <td>{row.productName}</td>
                          <td>{row.quantity}</td>
                          <td style={{ textAlign: "end" }}>
                            {parseInt(row.price).toFixed(2)}
                          </td>
                          <td style={{ textAlign: "end" }}>
                            {parseInt(row.total).toFixed(2)}
                          </td>
                        </tr>
                      </React.Fragment>
                    ))}
                    <tr>
                      <td colSpan="3" style={{ textAlign: "end" }}>
                        SubTotal(Qr)
                      </td>
                      <td style={{ textAlign: "end" }}>{subTotal}</td>
                    </tr>
                    <tr>
                      <td colSpan="3" style={{ textAlign: "end" }}>
                        Other Charges(Qr)
                      </td>
                      <td style={{ textAlign: "end" }}>
                        {purchaseBillDetails.other_charge}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="3" style={{ textAlign: "end" }}>
                        Discount(Qr)
                      </td>
                      <td style={{ textAlign: "end" }}>
                        {purchaseBillDetails.discount}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="3" style={{ textAlign: "end" }}>
                        <b>Total(Qr)</b>
                      </td>
                      <td style={{ textAlign: "end" }}>
                        {purchaseBillDetails.total_amount}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Col>
            </div>
          </Row>
        </Grid>
        <Grid item xs={12} style={{ margin: "1em" }}>
          <Typography>Warranty Terms :</Typography>
          <Typography style={{ fontWeight: "bold" }}>
            {" "}
            For term and conditions follow "Annexure A" document.{" "}
          </Typography>
        </Grid>
        <Grid item xs={12} style={{ margin: "1em" }}>
          <Typography style={{ fontWeight: "bold" }}>
            Cloud Telematics, Office No. C-35, First Floor, Building No.164, Al
            Maha Commercial Complex Street No. J-29, Energy Street, New
            Industrial Area, Zone-81, Doha, Ar Rayyan 4473 1516
          </Typography>
        </Grid>
      </Paper>
    </Grid>
  );
};
