import React, { Component } from "react";
import { Route } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { Col, Row } from "reactstrap";

import Navbar from "../compon-styled/SupportCenterNavBar";
import ToastAlert from "./ToastAlert";
import { AuthHelperAccessCheck } from "../businessjs/AuthHelper";
import SubscriberSelect from "../compon-controls/SubscriberSelect";
import { Redirect } from "react-router-dom";
import { api } from "../businessjs/API";
import { Paper, TextField, Typography, Grid, Fab } from "@material-ui/core";
import { SnackBar } from "./Login/SnackBar";

class SubscriberLocationCreation extends Component {
  state = {
    locationName: "",
    geoPoints: "",
    locationType: "",
    toastItems: [],
    subscriber_id: null,
    errorMessage: "",
    caption: this.props.location.state.type === "add" ? "Add" : "Edit",
    color: "#d50000",
    isSaved: false,
  };

  componentDidMount = async () => {
    const accounts = await api.post("vehicle/getsubscribertypes");
    let dbArray = [];
    if (accounts) {
      if (Array.isArray(accounts)) {
        dbArray = accounts.map((opt) => ({
          label: opt.company_name,
          value: opt,
        }));
        this.setState({
          subscriberTypes: dbArray,
        });
      }
    }

    let _locationObject = this.props.location.state.locationObject;
    if (this.props.location.state.type !== "add") {
      this.setState({
        locationName: _locationObject.location_name,
        geoPoints: _locationObject.geopoints,
        // subscriber_id: _locationObject.subscriber_id,
        subscriber_id: this.getSubscriberType(
          _locationObject.subscriber_id,
          dbArray
        ),
        locationType: _locationObject.location_type,
      });
    }
  };

  getSubscriberType(value, subscriberTypes) {
    if (subscriberTypes) {
      let filteredDataRows = subscriberTypes.filter((el) => {
        return el.value.autoid === value;
      })[0];
      return filteredDataRows;
    }
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  cancelButton = () => {
    const CancelButton = () => (
      <Route
        render={({ history }) => (
          <Fab
            variant="extended"
            color="primary"
            size="small"
            onClick={() => {
              history.push({
                pathname: "/subscriber-locations",
              });
            }}
          >
            Cancel
          </Fab>
        )}
      />
    );
    return <CancelButton />;
  };

  onSubmit = async (e) => {
    e.preventDefault();

    if (this.state.subscriber_id === null) {
      this.setState({ errorMessage: "Please select subscriber" });
      return;
    } else {
      if (this.state.errorMessage) this.setState({ errorMessage: "" });
    }

    const token = localStorage.apiAuthAccessKey;
    const decoded = jwt_decode(token);
    const decodedUser = decoded.userAccessKey[0];
    /**
     * to add subscriber location.
     */
    if (this.props.location.state.type === "add") {
      const _locationAddInput = {
        subscriber_id: this.state.subscriber_id.value.autoid,
        locationName: this.state.locationName,
        locationType: this.state.locationType,
        geoPoints: this.state.geoPoints,
        userType: decodedUser.role,
      };

      const addSubscriberLocation = await api.post(
        "subscriberlocations/add-subscriber-locations",
        _locationAddInput
      );
      if (addSubscriberLocation) {
        AuthHelperAccessCheck(addSubscriberLocation);
        if (addSubscriberLocation.status === "success") {
          this.onToastItemAdd(
            addSubscriberLocation.status,
            addSubscriberLocation.message
          );
          this.setState({ isSaved: true });
        }
      }
    } else if (this.props.location.state.type === "edit") {
      /**
       * to edit subscriber location
       */
      const _locationEditInput = {
        subscriber_id: this.state.subscriber_id.value.autoid,
        locationName: this.state.locationName,
        locationType: this.state.locationType,
        geoPoints: this.state.geoPoints,
        userType: decodedUser.role,
        autoId: this.props.location.state.locationObject.autoid,
      };

      const editSubscriberLocation = await api.post(
        "subscriberlocations/edit-subscriber-locations",
        _locationEditInput
      );
      if (editSubscriberLocation) {
        AuthHelperAccessCheck(editSubscriberLocation);
        if (editSubscriberLocation.status === "success") {
          this.onToastItemAdd(
            editSubscriberLocation.status,
            editSubscriberLocation.message
          );
          this.setState({ isSaved: true });
        }
      }
    }
  };

  handleToastItemClose = (itemObject) => {
    const toastItems = this.state.toastItems.filter(
      (c) => c.id !== itemObject.id
    );
    this.setState({ toastItems });
  };

  onToastItemAdd(type, message) {
    let newid = this.state.toastItems.length + 1;
    if (
      this.state.toastItems.filter(function (e) {
        return e.id === newid;
      }).length > 0
    ) {
      newid = this.state.toastItems.length + Math.floor(Math.random() * 100);
    }

    let newToastItems = this.state.toastItems;
    let itemToast = {
      id: newid,
      type: type === "success" ? "success" : "danger",
      message: message,
    };

    newToastItems.push(itemToast);
    this.setState({ toastItems: newToastItems });

    setTimeout(() => {
      this.handleToastItemClose(itemToast);
    }, 5000);
  }
  onCloseSnackbar = () => {
    this.setState({ errorMessage: "" });
  };
  render() {
    const { errorMessage, color } = this.state;
    console.log("errorM",errorMessage)
    if (this.state.isSaved) return <Redirect to="/subscriber-locations" />;
    else
      return (
        <React.Fragment>
          <SnackBar
            status={errorMessage}
            onCloseError={this.onCloseSnackbar}
            color={color}
          />
          <Paper style={{ minHeight: "30em" }} elevation={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} style={{ marginLeft: "1em" }}>
                <Typography variant="h4" style={{ fontWeight: "bold" }}>
                  {this.state.caption} Subscriber Location
                </Typography>
              </Grid>
              <Grid item xs={4} style={{ marginLeft: "1em" }}>
                <Typography style={{ fontWeight: "bold" }}>Location</Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  placeholder="Location... "
                  onChange={this.onChange}
                  name="locationName"
                  value={this.state.locationName}
                  required
                  size="small"
                  // InputProps={{ style: { height: "2.3em" } }}
                />
              </Grid>
              <Grid item xs={4}>
                <Typography style={{ fontWeight: "bold" }}>
                  Location Type
                </Typography>
                <select
                  className="form-control"
                  name="locationType"
                  onChange={this.onChange}
                  value={this.state.locationType}
                  required
                >
                  <option value="">Select</option>
                  <option value="1">City</option>
                  <option value="2">Urban</option>
                  <option value="3">Rural</option>
                </select>
                {/* <TextField variant="outlined"  /> */}
              </Grid>
              <Grid item xs={3}>
                <Typography style={{ fontWeight: "bold" }}>
                  Geo Points
                </Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  placeholder="select... "
                  onChange={this.onChange}
                  name="geoPoints"
                  value={this.state.geoPoints}
                  size="small"
                />
              </Grid>
              <Grid item xs={4} style={{ marginLeft: "1em" }}>
                <Typography style={{ fontWeight: "bold" }}>
                  Subscriber
                </Typography>
                <SubscriberSelect
                  name="subscriber_id"
                  value={this.state.subscriber_id}
                  onChange={this.onChange}
                  showAll={false}
                  required
                />
              </Grid>
              <Grid item xs={6}></Grid>
              <Grid item xs={6}>
                <Fab
                  variant="extended"
                  color="primary"
                  size="small"
                  style={{ float: "right" }}
                  type="submit"
                  onClick={this.onSubmit}
                >
                  Save
                </Fab>
              </Grid>
              <Grid item xs={6}>
                {this.cancelButton()}
              </Grid>
            </Grid>
          </Paper>
        </React.Fragment>
      );
  }
}

export default SubscriberLocationCreation;
