import React, { Component } from "react";
import { Route } from "react-router-dom";
import Datetime from "react-datetime";
import jwt_decode from "jwt-decode";

import CmbSelect from "../compon-controls/CmbSelect";
import { AuthHelperAccessCheck } from "../businessjs/AuthHelper";
import { api } from "../businessjs/API";
import {
  Grid,
  TextField,
  Typography,
  MenuItem,
  Paper,
  Fab,
} from "@material-ui/core";
import { SnackBar } from "./Login/SnackBar";

class ServiceScheduleCreation extends Component {
  state = {
    toastItems: [],
    errorMessage: "",
    subscriber_id: "",
    ticketId: "",
    status: 0,
    scheduleDate: new Date(),
    remarks: "",
    subscriberList: [],
    location: "",
    locationList: [],
    ticketList: [],
    ticket: "",
  };

  componentDidMount = () => {
    this.getSubscriberForCmb();
    if (this.props.location.state.subscriberId) {
      const _input = {
        subscriber_id: this.props.location.state.subscriberId.value.autoid,
      };
      this.getTicketAndLocation(_input);
    }
  };

  getTicketAndLocation = async (ticketInput) => {
    const res = await api.post("job-card/get-ticket-location", ticketInput);
    if ((res && res.locationSet) || (res && res.ticketSet)) {
      let locationArray = res.locationSet.map((opt) => ({
        label: opt.location_name,
        value: opt,
      }));

      let ticketArray = res.ticketSet.map((opt) => ({
        label: opt.subject,
        value: opt,
      }));
      this.setState({
        location: locationArray[0],
        locationList: locationArray,
        ticket: ticketArray[0],
        ticketList: ticketArray,
      });
    } else {
      AuthHelperAccessCheck(res);
    }
  };

  getSubscriberForCmb = async () => {
    const subscribers = await api.post("sc-subscriber/get-all-for-cmb");
    if (subscribers && subscribers.userdata) {
      const subscriberId =
        this.props.location.state !== undefined
          ? this.props.location.state.subscriberId.value.autoid
          : "";
      let subscriberArray = subscribers.userdata.map((opt) => ({
        label: opt.company_name,
        value: opt,
      }));
      this.setState({
        subscriber_id: subscriberId
          ? this.getSubscriberType(subscriberId, subscriberArray)
          : subscriberArray[0],
        subscriberList: subscriberArray,
      });
      const _input = {
        subscriber_id: subscriberArray[0].value.autoid,
      };
      this.getTicketAndLocation(_input);
    } else {
      AuthHelperAccessCheck(subscribers);
    }
  };

  getSubscriberType(value, accountList) {
    if (accountList) {
      let filteredDataRows = accountList.filter((el) => {
        return el.value.autoid === value;
      })[0];

      return filteredDataRows;
    }
  }

  cancelButton = () => {
    const ButtonCancel = () => (
      <Route
        render={({ history }) => (
          <Fab
            color="primary"
            size="small"
            variant="extended"
            onClick={() => {
              history.push({
                pathname: "/service-schedule-list",
              });
            }}
          >
            Cancel
          </Fab>
        )}
      />
    );
    return <ButtonCancel />;
  };

  handleToastItemClose = (itemObject) => {
    const toastItems = this.state.toastItems.filter(
      (c) => c.id !== itemObject.id
    );
    this.setState({ toastItems });
  };

  onToastItemAdd(type, message) {
    let newid = this.state.toastItems.length + 1;
    if (
      this.state.toastItems.filter(function (e) {
        return e.id === newid;
      }).length > 0
    ) {
      newid = this.state.toastItems.length + Math.floor(Math.random() * 100);
    }

    let newToastItems = this.state.toastItems;
    let itemToast = {
      id: newid,
      type: type === "success" ? "success" : "danger",
      message: message,
    };

    newToastItems.push(itemToast);
    this.setState({ toastItems: newToastItems });

    setTimeout(() => {
      this.handleToastItemClose(itemToast);
    }, 5000);
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onSubscriberChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    const _locationCmbInput = {
      subscriber_id: e.target.value.value.autoid,
    };

    this.getTicketAndLocation(_locationCmbInput);
  };

  onDateChange = (e) => {
    this.setState({ scheduleDate: e._d });
  };

  onSubmit = async (e) => {
    e.preventDefault();

    const token = localStorage.apiAuthAccessKey;
    const decoded = jwt_decode(token);
    const decodedUser = decoded.userAccessKey[0];
    const _scheduleInput = {
      subscriber_id: this.state.subscriber_id.value.autoid,
      userType: decodedUser.role,
      ticketId: this.state.ticket.value.autoid,
      locationId: this.state.location.value.autoid,
      scheduleDate: this.state.scheduleDate,
      status: this.state.status,
      remarks: this.state.remarks,
    };

    const addService = await api.post(
      "ticket/add-service-schedule",
      _scheduleInput
    );
    if (addService) {
      AuthHelperAccessCheck(addService);
      if (addService.status === "success") {
        this.setState({ errorMessage: addService.message });
        this.props.history.push({
          pathname: `/service-schedule-list`,
        });
      }
    }
  };
  onCloseSnackbar = () => {
    this.setState({ errorMessage: "" });
  };
  render() {
    const { errorMessage } = this.state;
    return (
      <React.Fragment>
        <SnackBar
          status={errorMessage}
          onCloseError={this.onCloseSnackbar}
          color={"#d50000"}
        />
        <Paper style={{ minHeight: "38em" }} elevation={3}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography
                variant="h4"
                style={{ fontWeight: "bold", margin: "1em" }}
              >
                Service Schedule
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4} style={{ margin: "1em" }}>
              <Typography style={{ fontWeight: "bold" }}>Subscriber</Typography>
              <CmbSelect
                name="subscriber_id"
                value={this.state.subscriber_id}
                onChange={this.onSubscriberChange}
                dataSource={this.state.subscriberList}
              />
            </Grid>
            <Grid item xs={12} sm={3} style={{ margin: "1em" }}>
              <Typography style={{ fontWeight: "bold" }}>Ticket</Typography>
              <CmbSelect
                name="ticket"
                value={this.state.ticket}
                onChange={this.onChange}
                dataSource={this.state.ticketList}
              />
            </Grid>
            <Grid item xs={12} sm={4} style={{ margin: "1em" }}>
              <Typography style={{ fontWeight: "bold" }}>Location</Typography>
              <CmbSelect
                name="location"
                value={this.state.location}
                onChange={this.onChange}
                dataSource={this.state.locationList}
              />
            </Grid>
            <Grid item xs={12} sm={4} style={{ margin: "1em" }}>
              <Typography style={{ fontWeight: "bold" }}>Status</Typography>
              <TextField
                id="outlined-select-currency"
                select
                name="status"
                onChange={this.onChange}
                value={this.state.status}
                variant="outlined"
                fullWidth
              >
                <MenuItem key="0" value="0">
                  Open
                </MenuItem>
                <MenuItem key="1" value="1">
                  Closed
                </MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} sm={3} style={{ margin: "1em" }}>
              <Typography style={{ fontWeight: "bold" }}>Date</Typography>
              <Datetime
                defaultValue={this.state.scheduleDate}
                input={true}
                closeOnSelect={true}
                onChange={this.onDateChange}
                inputProps={{ style: { height: 58, width: 300 } }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} style={{ margin: "1em" }}>
              <Typography style={{ fontWeight: "bold" }}>Remarks</Typography>
              <TextField
                fullWidth
                multiline
                rows={3}
                rowsMax={10}
                variant="outlined"
                type="text"
                name="remarks"
                value={this.state.remarks}
                onChange={this.onChange}
              />
            </Grid>
            <Grid item xs={6}>
              <Fab
                type="submit"
                color="primary"
                size="small"
                variant="extended"
                style={{ marginRight: "1em", marginLeft: "1em" }}
                onClick={this.onSubmit}
              >
                Save
              </Fab>

              {this.cancelButton()}
            </Grid>
          </Grid>
        </Paper>
      </React.Fragment>
    );
  }
}

export default ServiceScheduleCreation;
