import React, { Component } from "react";
import { RiseLoader } from "react-spinners";
import { css } from "@emotion/core";
import { Route } from "react-router-dom";
import Pagination from "react-js-pagination";
import { Col, Row } from "reactstrap";

import NavBar from "../compon-styled/SupportCenterNavBar";
import { AuthHelperAccessCheck } from "../businessjs/AuthHelper";
import { api } from "../businessjs/API";
const loaderCSS = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

class ProductList extends Component {
  state = {
    allProduct: [],
    loading: false,
    activePage: undefined,
    itemsCountPerPage: 25,
    totalItemsCount: 0,
    status: 1
  };

  componentDidMount = () => {
    this.getProductCount();
  };

  getProductCount = async () => {
    this.setState({ loading: true });
    const _productCountInput = {
      divisionId: localStorage.apiCompanyId,
      company_id: localStorage.apiDivisionId,
      status: this.state.status
    };

    const res = await api.post(
      "sc-product/get-product-count",
      _productCountInput
    );
    if (res && res.length) {
      let _productCount = res[0].productCount;
      if (_productCount > 0) {
        this.setState({ totalItemsCount: _productCount, activePage: 1 });
        this.getProductList(1);
      } else {
        this.setState({
          allProduct: [],
          totalItemsCount: 0,
          activePage: 1,
          loading: false
        });
      }
    } else {
      this.setState({ loading: false });
      AuthHelperAccessCheck(res);
    }
  };

  getProductList = async pageNumber => {
    let start = (pageNumber - 1) * this.state.itemsCountPerPage;
    const _productListInputs = {
      startLimit: start,
      endLimit: this.state.itemsCountPerPage,
      divisionId: localStorage.apiCompanyId,
      company_id: localStorage.apiDivisionId,
      status: this.state.status
    };

    const res = await api.post(
      "sc-product/get-product-list",
      _productListInputs
    );

    if (res) {
      this.setState({ allProduct: res, loading: false });
      if (!this.state.activePage) {
        this.setState({ totalItemsCount: res.length, activePage: 1 });
      }
    } else {
      this.setState({ loading: false });
      AuthHelperAccessCheck(res);
    }
  };

  handlePageChange = pageNumber => {
    this.setState({ activePage: pageNumber });
    this.getProductList(pageNumber);
  };

  productAdd = () => {
    const ProductAddButton = () => (
      <Route
        render={({ history }) => (
          <button
            style={{ height: "fit-content" }}
            type="button"
            className="btn btn-primary btn-sm"
            onClick={() => {
              history.push({
                pathname: "/product",
                state: {
                  type: "add"
                }
              });
            }}
          >
            Add
          </button>
        )}
      />
    );
    return <ProductAddButton />;
  };

  onStatusChange = e => {
    this.setState({ [e.target.name]: e.target.value });
    this.getProductCount();
  };

  onDivisionChange = e => {
    this.setState({
      [e.target.name]: e.target.value,
      company_id: e.target.value.value.company_id
    });
  };

  editButton = _product => {
    const EditButton = () => (
      <Route
        render={({ history }) => (
          <button
            type="button"
            className="btn btn-link btn-sm"
            title="Edit"
            onClick={() => {
              history.push({
                pathname: "/product",
                state: {
                  type: "edit",
                  product_id: _product.autoid
                }
              });
            }}
          >
            <i className="fa fa-pencil" aria-hidden="true" />
          </button>
        )}
      />
    );
    return <EditButton />;
  };

  viewBundle = _product => {
    const ViewBundleButton = () => (
      <Route
        render={({ history }) => (
          <button
            type="button"
            className="btn btn-link btn-sm"
            title="View"
            onClick={() => {
              history.push({
                pathname: "/bundle-view",
                state: {
                  productId: _product.autoid,
                  bundle_id: _product.bundle_id
                }
              });
            }}
          >
            Bundle
          </button>
        )}
      />
    );
    return <ViewBundleButton />;
  };

  render() {
    let _showProduct = this.state.allProduct;
    return (
      <React.Fragment>
        {/* <NavBar /> */}
        <div className="container mt-4">
          <div className="card">
            <div className="card-header">
              <Row style={{ alignItems: "center" }}>
                <Col xl={7} lg={6} md={6} sm={4} xs={12}>
                  <h3>Products</h3>
                </Col>
                <Col xl={3} lg={3} md={3} sm={4} xs={6}>
                  <select
                    className="form-control"
                    name="status"
                    onChange={this.onStatusChange}
                    value={this.state.status}
                    required
                    //  2 - Inactive
                  >
                    <option value="0">All</option>
                    <option value="1">Active</option>
                    <option value="3">Suspended</option>
                  </select>
                </Col>
                <Col
                  style={{ display: "flex" }}
                  xl={2}
                  lg={2}
                  md={3}
                  sm={4}
                  xs={6}
                >
                  {this.productAdd()}
                  <div className="sweet-loading">
                    <RiseLoader
                      css={loaderCSS}
                      sizeUnit={"px"}
                      size={10}
                      color={"#123abc"}
                      loading={this.state.loading}
                    />
                  </div>
                </Col>
              </Row>
            </div>
          </div>
          <Row className="mt-2">
            <Col>
              <div className="card">
                <div className="table-responsive">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th style={{ width: "20%" }}>Product</th>
                        <th style={{ width: "10%" }}>Type</th>
                        <th style={{ width: "10%" }}>Price</th>
                        <th style={{ width: "10%" }}>Outi Side City Price</th>
                        <th>Active Days</th>
                        <th>Prior Warning</th>
                        <th>Suspend After</th>
                        <th style={{ width: "10%" }}>
                          NumberOfService / Visits
                        </th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {_showProduct.map((_product, i) => (
                        <React.Fragment key={i}>
                          <tr className="grid-rows-text">
                            <td>{_product.product_name}</td>
                            <td>{_product.type}</td>
                            <td>{_product.price}</td>
                            <td>{_product.out_side_city_price}</td>
                            <td>{_product.active_days}</td>
                            <td>{_product.prior_warning_days}</td>
                            <td>{_product.suspend_after_days}</td>
                            <td>{_product.number_of_service_visits}</td>
                            <td>{_product.status}</td>
                            <td>{this.editButton(_product)}</td>
                            <td>
                              {_product.bundle_id
                                ? this.viewBundle(_product)
                                : ""}
                            </td>
                          </tr>
                        </React.Fragment>
                      ))}
                    </tbody>
                  </table>
                  <center>
                    {_showProduct.length ? "" : "Currently No Products"}
                  </center>
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col>
              <Pagination
                activePage={this.state.activePage}
                itemsCountPerPage={this.state.itemsCountPerPage}
                totalItemsCount={this.state.totalItemsCount}
                pageRangeDisplayed={10}
                onChange={this.handlePageChange}
              />
            </Col>
          </Row>
        </div>
      </React.Fragment>
    );
  }
}

export default ProductList;
