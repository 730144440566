import React, { Component } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import ProductIcon from "@material-ui/icons/InsertDriveFile";
import ProductsBundleIcon from "@material-ui/icons/FileCopy";
import InvoiceIcon from "@material-ui/icons/Receipt";
import ProcessIcon from "@material-ui/icons/Payment";
import PaymentIcon from "@material-ui/icons/AccountBalance";
import { Typography } from "@material-ui/core";
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
    "&:hover": {
      boxShadow: "5px 5px 5px 5px rgba(187, 222, 251, 1)",
    },
  },
  iconSize: {
    fontSize: "4em",
    fill: "007bff",
  },
  font:{
    fontWeight:"bold"
  }
}));

export const ProductsHome = () => {
  const classes = useStyles();
  const NavLink = React.forwardRef((props, ref) => (
    <RouterLink innerRef={ref} {...props} />
  ));
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography color="primary" variant="h3">
          Products List
        </Typography>
      </Grid>
      <Grid item xs={6} sm={3}>
        <Paper className={classes.paper} variant="elevation">
          {" "}
          <Grid
            container
            component={NavLink}
            to="/product-list"
            style={{ textDecorationLine: "none" }}
          >
            <Grid item xs={12}>
              <ProductIcon className={classes.iconSize} color="primary" />
            </Grid>
            <Grid item xs={12}>
              <Typography color="secondary" variant="h5" className={classes.font} >
                Products
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={6} sm={3}>
        <Paper className={classes.paper} variant="elevation">
          <Grid
            container
            component={NavLink}
            to="/product-bundle-list"
            style={{ textDecorationLine: "none" }}
          >
            <Grid item xs={12}>
              <ProductsBundleIcon
                className={classes.iconSize}
                color="primary"
              />
            </Grid>
            <Grid item xs={12}>
              <Typography color="secondary" variant="h5" className={classes.font}>
                Product Bundles
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      {/* <Grid item xs={6} sm={3}>
        <Paper className={classes.paper} variant="elevation">
          {" "}
          <Grid
            container
            component={NavLink}
            to="/invoice-list"
            style={{ textDecorationLine: "none" }}
          >
            <Grid item xs={12}>
              <InvoiceIcon className={classes.iconSize} color="primary" />
            </Grid>
            <Grid item xs={12}>
              <Typography color="secondary" variant="h5" className={classes.font}>
                Invoices
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </Grid> */}
      {/* <Grid item xs={6} sm={3}>
        <Paper className={classes.paper} variant="elevation">
          {" "}
          <Grid
            container
            component={NavLink}
            to="/jobcard-process"
            style={{ textDecorationLine: "none" }}
          >
            <Grid item xs={12}>
              <ProcessIcon className={classes.iconSize} color="primary" />
            </Grid>
            <Grid item xs={12}>
              <Typography color="secondary" variant="h5" className={classes.font}>
                Process Job Card
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </Grid> */}
      <Grid item xs={6} sm={3}>
        <Paper className={classes.paper} variant="elevation">
          {" "}
          <Grid
            container
            component={NavLink}
            to="/payments-list"
            style={{ textDecorationLine: "none" }}
          >
            <Grid item xs={12}>
              <PaymentIcon className={classes.iconSize} color="primary" />
            </Grid>
            <Grid item xs={12}>
              <Typography color="secondary" variant="h5" className={classes.font}>
                Payments
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};
