import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { Col, Row } from "reactstrap";
import styled from "styled-components";

import {
  AuthHelperValidateOverToken,
  AuthHelperGerSubscriberRoleText,
  AuthHelperLogout
} from "../businessjs/AuthHelper";

class SupportCenterNavbar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      is_mobile: false,
      loginType: "general",
      userType: "guest",
      isExpanded: false
    };
  }

  componentDidMount() {
    if (AuthHelperValidateOverToken("layoutsubscriber")) {
      const token = localStorage.apiAuthAccessKey;
      this.updateState(token);
    } else {
      this.props.history.push(`/login`);
    }
  }

  updateState = token => {
    const decoded = jwt_decode(token);
    const decodedUser = decoded.userAccessKey[0];
    this.setState({
      token: token,
      subscriber_id: decodedUser.subscriber_id,
      first_name: decodedUser.first_name,
      last_name: decodedUser.last_name,
      email: decodedUser.email,
      account_type: decodedUser.account_type,
      loginType: decoded.accessType,
      userType: decoded.accessType,
      role: decodedUser.role,
      home_url:
        decoded.accessType === "subscriber"
          ? "/subscriberhome"
          : "/supportcenter",
      roleText: AuthHelperGerSubscriberRoleText(decodedUser.role),
      company_code: decodedUser.company_code,
      division_code: decodedUser.division_code
    });
  };

  onClickMenuItem_LogOut = e => {
    e.preventDefault();
    AuthHelperLogout("layoutsubscriber");

    this.setState({
      loginType: "general",
      userType: "guest"
    });
    this.props.history.push("/login");
  };

  header() {
    return (
      <header className="top">
        <div className="row">
          <div className="col-sm-12 col-md-6 animated slideInDown">
            <h1>Support Center Admin Panel </h1>
          </div>
          <div className="col-sm-12 col-md-6 animated slideInUp">
            <div className="pull-right cont">
              <ul>
                <li>
                  <span className="icon">
                    <i className="fa fa-user" aria-hidden="true" />
                  </span>
                  Hi, {this.state.first_name} |
                </li>

                <li>
                  <span className="icon">
                    <i className="fa fa-close" aria-hidden="true" />
                  </span>
                  <button
                    className="nobutton"
                    onClick={this.onClickMenuItem_LogOut}
                  >
                    Exit
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </header>
    );
  }

  handleToggle(e) {
    e.preventDefault();
    this.setState({
      isExpanded: !this.state.isExpanded
    });
  }

  sc_super_menu() {
    const { isExpanded } = this.state;
    return (
      <Navigation>
        <div>
          <i
            className="fa fa-bars"
            aria-hidden="true"
            onClick={e => this.handleToggle(e)}
          ></i>
          <ul
            className={`collapsed ${isExpanded ? "is-expanded" : ""}`}
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-between",
              paddingTop: "1%"
            }}
          >
            <li>
              <Link
                to="/supportcenter/subscriberlist"
                style={{ color: "#fff", flexWrap: "wrap" }}
              >
                Accounts
              </Link>
            </li>

            <li>
              <Link
                to="/vehicle-list"
                style={{ color: "#fff", flexWrap: "wrap" }}
              >
                Vehicles
              </Link>
            </li>

            <li>
              {/* <Link
                to="/products-home"
                style={{ color: "#fff", flexWrap: "wrap" }}
              >
                Products
              </Link> */}
            </li>

            <li>
              <Link
                to="/subscription-list"
                style={{ color: "#fff", flexWrap: "wrap" }}
              >
                Subscriptions
              </Link>
            </li>

            <li>
              <Link
                to="/subscriptionwithvehicle"
                style={{ color: "#fff", flexWrap: "wrap" }}
              >
                SubscriptionWithVehicle
              </Link>
            </li>

            <li>
              <Link to="/service" style={{ color: "#fff", flexWrap: "wrap" }}>
                Service
              </Link>
            </li>

            <li>
              <Link
                to="/supportcenter/member"
                style={{ color: "#fff", flexWrap: "wrap" }}
              >
                Members
              </Link>
            </li>
          </ul>
        </div>
      </Navigation>
    );
  }

  sc_admin_menu() {
    const { isExpanded } = this.state;
    return (
      <Navigation>
        <div>
          <i
            className="fa fa-bars"
            aria-hidden="true"
            onClick={e => this.handleToggle(e)}
          ></i>
          <ul
            className={`collapsed ${isExpanded ? "is-expanded" : ""}`}
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-between",
              paddingTop: "1%"
            }}
          >
            <li>
              <Link
                to="/supportcenter/subscriberlist"
                style={{ color: "#fff", flexWrap: "wrap" }}
              >
                Accounts
              </Link>
            </li>

            <li>
              <Link
                to="/vehicle-list"
                style={{ color: "#fff", flexWrap: "wrap" }}
              >
                Vehicles
              </Link>
            </li>

            {/* <li>
              <Link
                to="/products-home"
                style={{ color: "#fff", flexWrap: "wrap" }}
              >
                Products
              </Link>
            </li> */}

            <li>
              <Link
                to="/subscription-list"
                style={{ color: "#fff", flexWrap: "wrap" }}
              >
                Subscriptions
              </Link>
            </li>

            <li>
              <Link
                to="/subscriptionwithvehicle"
                style={{ color: "#fff", flexWrap: "wrap" }}
              >
                SubscriptionWithVehicle
              </Link>
            </li>

            <li>
              <Link to="/service" style={{ color: "#fff", flexWrap: "wrap" }}>
                Service
              </Link>
            </li>
          </ul>
        </div>
      </Navigation>
    );
  }

  sc_tech_menu() {
    const { isExpanded } = this.state;
    return (
      <Navigation>
        <div>
          <i
            className="fa fa-bars"
            aria-hidden="true"
            onClick={e => this.handleToggle(e)}
          ></i>
          <ul
            className={`collapsed ${isExpanded ? "is-expanded" : ""}`}
            style={{
              display: "flex",
              flexWrap: "wrap",
              paddingTop: "1%"
            }}
          >
            {/* <li>
              <Link
                to="/products-home"
                style={{ color: "#fff", flexWrap: "wrap" }}
              >
                Products
              </Link>
            </li> */}

            <li>
              <Link
                to="/subscriptionwithvehicle"
                style={{ color: "#fff", flexWrap: "wrap", marginRight: "20px" }}
              >
                SubscriptionWithVehicle
              </Link>
            </li>

            <li>
              <Link
                to="/service"
                style={{ color: "#fff", flexWrap: "wrap", marginRight: "20px" }}
              >
                Service
              </Link>
            </li>
          </ul>
        </div>
      </Navigation>
    );
  }

  showMenu() {
    //super user
    if (this.state.role === 10) return this.sc_super_menu();
    //admin user
    else if (this.state.role === 11) return this.sc_admin_menu();
    //technician user
    else if (this.state.role === 12) return this.sc_tech_menu();
  }

  render() {
    return (
      <React.Fragment>
        <Row>
          <Col
            style={{
              flexDirection: "column",
              display: "flex"
            }}
          >
            <Row>
              <div className="container">{this.header()}</div>
            </Row>

            <Row style={{ backgroundColor: "#5a768c" }}>
              <div className="container">{this.showMenu()}</div>
            </Row>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default withRouter(SupportCenterNavbar);

const Navigation = styled.header`
  width: 100%;

  .fa-bars {
    display: none;
    color: #222;
    font-size: 2rem;
  }

  @media only screen and (max-width: 600px) {
    height: auto;
    min-height: 50px;
    display: block;
    position: relative;

    .fa-bars {
      display: inline-block;
      position: absolute;
      top: 10px;
      right: 10px;
      cursor: pointer;
    }
    ul.collapsed {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex-wrap: wrap;

      overflow: hidden;
      max-height: 0;
      -moz-transition-duration: 0.4s;
      -webkit-transition-duration: 0.4s;
      -o-transition-duration: 0.4s;
      transition-duration: 0.4s;
      -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
      -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
      -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
      transition-timing-function: cubic-bezier(0, 1, 0.5, 1);

      &.is-expanded {
        overflow: hidden;
        max-height: 500px; /* approximate max height */
        -moz-transition-duration: 0.4s;
        -webkit-transition-duration: 0.4s;
        -o-transition-duration: 0.4s;
        transition-duration: 0.4s;
        -moz-transition-timing-function: ease-in;
        -webkit-transition-timing-function: ease-in;
        -o-transition-timing-function: ease-in;
        transition-timing-function: ease-in;
      }
      li {
        padding: 15px 10px;
        margin: 0px 0px;
        width: 100%;
      }
    }
  }
`;
