import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Grid,
  LinearProgress,
  Typography,
  makeStyles,
  colors
} from '@material-ui/core';
import InsertChartIcon from '@material-ui/icons/InsertChartOutlined';

const useStyles = makeStyles(() => ({
  root: {
    height: '100%'
  },
  avatar: {
    backgroundColor: "#64dd17",
    height: 56,
    width: 56
  }
}));

const CompletedTickets = ({ completedTicketsCount,className, ...rest }) => {
  const classes = useStyles();

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
      style={{borderRadius:"0.75em",minHeight:"9.7em"}}
    >
      <CardContent>
        <Grid
          container
          justify="space-between"
          spacing={3}
        >
          <Grid item>
            <Typography
              color="textSecondary"
              gutterBottom
              variant="h6"
            >
             COMPLETED TICKETS
            </Typography>
            <Typography
              color="textPrimary"
              variant="h3"
            >
              {completedTicketsCount!==undefined?completedTicketsCount:0}
            </Typography>
          </Grid>
          <Grid item>
            <Avatar className={classes.avatar}>
              <InsertChartIcon />
            </Avatar>
          </Grid>
        </Grid>
        <Box mt={3}>
          <LinearProgress
            value={completedTicketsCount}
            variant="determinate"
          />
        </Box>
      </CardContent>
    </Card>
  );
};

CompletedTickets.propTypes = {
  className: PropTypes.string
};

export default CompletedTickets;
