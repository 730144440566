import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  Card,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Paper,
  TableContainer,
  Table,
  TableBody,
  TablePagination,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  CircularProgress,
  Fab,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { makeStyles } from "@material-ui/core/styles";
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
  Redirect,
} from "react-router-dom";
import moment from "moment";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { AuthHelperAccessCheck } from "../../businessjs/AuthHelper";
import { api } from "../../businessjs/API";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 650,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

const headCells = [
  { id: "autoid", numeric: false, label: "Q.No." },
  { id: "quote_date", numeric: false, label: "Date" },
  {
    id: "account_name",
    numeric: false,

    label: "Account",
  },
  { id: "contact_name", numeric: false, label: "Contact" },
  {
    id: "total_amount",
    numeric: true,
    disablePadding: false,
    label: "Total(Qr)",
  },
  {
    id: "discount",
    numeric: true,
    disablePadding: false,
    label: "Discount(Qr)",
  },
  { id: "status_name", numeric: false, label: "Status" },
  { id: "View", numeric: false, label: "" },
  { id: "edit", numeric: false, label: "" },
];

const EnhancedTableHead = (props) => {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            // padding={headCell.disablePadding ? "none" : "default"}
            align={
              headCell.id === "total_amount" || headCell.id === "discount"
                ? "right"
                : "left"
            }
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy].toLowerCase() < a[orderBy].toLowerCase()) {
    return -1;
  }
  if (b[orderBy].toLowerCase() > a[orderBy].toLowerCase()) {
    return 1;
  }
  return 0;
};

const getComparator = (order, orderBy) => {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

export const QuotationList = (props) => {
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("account_name");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const classes = useStyles();
  const { rows } = props;
  const { onDeleteQuotation } = props;

  const NavLink = React.forwardRef((props, ref) => (
    <RouterLink innerRef={ref} {...props} />
  ));

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  /**
   * to get badge based on status fetch from DB.
   */
  const getBadge = (status) => {
    let _classes = "badge badge-";
    _classes += status === "Cancelled" ? "danger" : "success";
    return _classes;
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            {rows.length !== 0 ? (
              <TableBody>
                {stableSort(rows, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    return (
                      <TableRow hover tabIndex={-1} key={index}>
                        <TableCell>{row.autoid}</TableCell>
                        <TableCell>
                          {moment(row.quote_date).format("MM-DD-YYYY")}
                        </TableCell>
                        <TableCell>{row.account_name}</TableCell>
                        <TableCell>{row.contact_name}</TableCell>
                        <TableCell align="right">{row.total_amount}</TableCell>
                        <TableCell align="right">{row.discount}</TableCell>
                        <TableCell>
                          <span className={getBadge(row.status_name)}>
                            {row.status_name}
                          </span>
                        </TableCell>
                        <TableCell>
                          <Typography
                            component={NavLink}
                            to={`/quotation-view/${row.autoid}`}
                          >
                            View
                          </Typography>
                        </TableCell>
                        <TableCell
                          component={NavLink}
                          to={`/quotation/${row.autoid}`}
                        >
                          <IconButton
                            style={{
                              display:
                                row.status_name === "Cancelled" ? "none" : "",
                            }}
                          >
                            <Tooltip title="Edit">
                              <EditIcon color="primary" />
                            </Tooltip>
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            ) : (
              <Typography
                style={{
                  textAlign: "center",
                  marginTop: "1em",
                }}
              >
                No Records...............
              </Typography>
            )}
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[50, 200, 500]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
};
