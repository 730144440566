import React, { Component } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import ProductIcon from "@material-ui/icons/InsertDriveFile";
import LocationIcon from "@material-ui/icons/Room";
import ProcessIcon from "@material-ui/icons/Payment";
import PaymentIcon from "@material-ui/icons/AccountBalance";
import ScheduleIcon from "@material-ui/icons/EventNote";
import TicketsIcon from "@material-ui/icons/Assignment";
import QuotationIcon from "@material-ui/icons/LocalActivity";
import HelpIcon from "@material-ui/icons/Help";
import VendorIcon from "@material-ui/icons/Business";
import { Typography } from "@material-ui/core";
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
    "&:hover": {
      boxShadow: "5px 5px 5px 5px rgba(187, 222, 251, 1)",
    },
  },
  iconSize: {
    fontSize: "4em",
    fill: "007bff",
  },
  font: {
    fontWeight: "bold",
  },
}));

export const Service = () => {
  const classes = useStyles();
  const NavLink = React.forwardRef((props, ref) => (
    <RouterLink innerRef={ref} {...props} />
  ));
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography color="primary" variant="h3">
          Services
        </Typography>
      </Grid>
      {/* <Grid item xs={6} sm={3}>
        <Paper className={classes.paper} variant="elevation">
          {" "}
          <Grid
            container
            component={NavLink}
            to="/ticket-list"
            style={{ textDecorationLine: "none" }}
          >
            <Grid item xs={12}>
              <TicketsIcon className={classes.iconSize} color="primary" />
            </Grid>
            <Grid item xs={12}>
              <Typography
                color="secondary"
                variant="h5"
                className={classes.font}
              >
                Tickets
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </Grid> */}
      {/* <Grid item xs={6} sm={3}>
        <Paper className={classes.paper} variant="elevation">
          <Grid
            container
            component={NavLink}
            to="/job-card"
            style={{ textDecorationLine: "none" }}
          >
            <Grid item xs={12}>
              <ProcessIcon className={classes.iconSize} color="primary" />
            </Grid>
            <Grid item xs={12}>
              <Typography
                color="secondary"
                variant="h5"
                className={classes.font}
              >
                Job Cards
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </Grid> */}
      <Grid item xs={6} sm={3}>
        <Paper className={classes.paper} variant="elevation">
          {" "}
          <Grid
            container
            component={NavLink}
            to="/subscriber-locations"
            style={{ textDecorationLine: "none" }}
          >
            <Grid item xs={12}>
              <LocationIcon className={classes.iconSize} color="primary" />
            </Grid>
            <Grid item xs={12}>
              <Typography
                color="secondary"
                variant="h5"
                className={classes.font}
              >
                Service Locations
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={6} sm={3}>
        <Paper className={classes.paper} variant="elevation">
          {" "}
          <Grid
            container
            component={NavLink}
            to="/service-schedule-list"
            style={{ textDecorationLine: "none" }}
          >
            <Grid item xs={12}>
              <ScheduleIcon className={classes.iconSize} color="primary" />
            </Grid>
            <Grid item xs={12}>
              <Typography
                color="secondary"
                variant="h5"
                className={classes.font}
              >
                Service Schedules
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={6} sm={3}>
        <Paper className={classes.paper} variant="elevation">
          {" "}
          <Grid
            container
            component={NavLink}
            to="/visit-list"
            style={{ textDecorationLine: "none" }}
          >
            <Grid item xs={12}>
              <HelpIcon className={classes.iconSize} color="primary" />
            </Grid>
            <Grid item xs={12}>
              <Typography
                color="secondary"
                variant="h5"
                className={classes.font}
              >
                Visits
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      {/* <Grid item xs={6} sm={3}>
        <Paper className={classes.paper} variant="elevation">
          {" "}
          <Grid
            container
            component={NavLink}
            // to="/quotation-list"
            to="/quotation"
            style={{ textDecorationLine: "none" }}
          >
            <Grid item xs={12}>
              <QuotationIcon className={classes.iconSize} color="primary" />
            </Grid>
            <Grid item xs={12}>
              <Typography
                color="secondary"
                variant="h5"
                className={classes.font}
              >
                Quotation
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </Grid> */}
    </Grid>
  );
};
