import React, { Component } from "react";
import jwt_decode from "jwt-decode";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { Route } from "react-router-dom";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Col, Row } from "reactstrap";
import { withStyles } from "@material-ui/core/styles";
import ToastAlert from "./ToastAlert";
import ViewTicketItem from "./ViewTicketItem";
import { AuthHelperAccessCheck } from "../businessjs/AuthHelper";
import { api } from "../businessjs/API";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { Typography, TextField, Button, Fab } from "@material-ui/core";
import { SnackBar } from "../compon-controls/SnackBar";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing.unit * 2,
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
});
class ViewTicket extends Component {
  state = {
    ticketId: 0,
    editorState: EditorState.createEmpty(),
    updateText: null,
    subject: "",
    showTicketItems: [],
    toastItems: [],
    showDiv: false,
    commentButtonText: "+",
    subscriber_id: this.props.location.state.subscriber_id,
    errorMessage: "",
    status:
      this.props.location.state.status == 1 ||
      this.props.location.state.status == 50
        ? this.props.location.state.status
        : 1,
  };

  componentDidMount = async () => {
    const token = localStorage.apiAuthAccessKey;
    const decoded = jwt_decode(token);
    const decodedUser = decoded.userAccessKey[0];
    this.setState({
      ticketDate: new Date(),
      subscriber_id: this.props.location.state.subscriber_id,
      executive_id: decodedUser.autoid,
      role: decodedUser.role,
      ticketId: this.props.location.state.ticketId,
      subject: this.props.location.state.subject,
    });

    const _getSubjectInput = {
      ticketId: this.props.location.state.ticketId,
    };
    const ticketMessage = await api.post(
      "ticket/ticket-subject-select",
      _getSubjectInput
    );
    if (ticketMessage) {
      this.setState({ showTicketItems: ticketMessage });
    } else {
      AuthHelperAccessCheck(ticketMessage);
    }
  };

  onEditorStateChange = (editorState) => {
    this.setState({ editorState });
    let value = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    this.setState({
      updateText: value,
    });
  };

  onSubmit = async (e) => {
    e.preventDefault();
    if (this.state.updateText === null) {
      this.setState({ errorMessage: "Please enter your comment." });
      return;
    } else {
      if (this.state.errorMessage) this.setState({ errorMessage: "" });
    }
    let _ticketCommentAddData = {
      subscriber_id: this.state.subscriber_id,
      ticketId: this.props.location.state.ticketId,
      updateText: this.state.updateText,
      userType: 2, // user_type 1:system, 2:supportmembers, 3:executives,
      status: this.state.status,
    };

    const commentAddResult = await api.post(
      "ticket/ticket-comment-add",
      _ticketCommentAddData
    );
    if (commentAddResult) {
      AuthHelperAccessCheck(commentAddResult);
      this.onToastItemAdd(commentAddResult.status, commentAddResult.message);
      if (commentAddResult.status === "success") {
        const ticketComment = await api.post(
          "ticket/ticket-subject-select",
          _ticketCommentAddData
        );

        if (ticketComment) {
          this.setState({
            showTicketItems: ticketComment,
            editorState: EditorState.createEmpty(),
          });
          if (this.state.status == 50) {
            if (this.props.location.state.from === "account") {
              this.props.history.push({
                pathname: `/account-details`,
                state: {
                  companyId: this.state.subscriber_id,
                },
              });
            } else {
              this.props.history.push({
                pathname: `/ticket-list`,
              });
            }
          }
        } else {
          AuthHelperAccessCheck(ticketComment);
        }
      }
    }
  };

  handleToastItemClose = (itemObject) => {
    const toastItems = this.state.toastItems.filter(
      (c) => c.id !== itemObject.id
    );
    this.setState({ toastItems });
  };

  onToastItemAdd(type, message) {
    let newid = this.state.toastItems.length + 1;
    if (
      this.state.toastItems.filter(function (e) {
        return e.id === newid;
      }).length > 0
    ) {
      newid = this.state.toastItems.length + Math.floor(Math.random() * 100);
    }

    let newToastItems = this.state.toastItems;
    let itemToast = {
      id: newid,
      type: type === "success" ? "success" : "danger",
      message: message,
    };

    newToastItems.push(itemToast);
    this.setState({ toastItems: newToastItems });

    setTimeout(() => {
      this.handleToastItemClose(itemToast);
    }, 5000);
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  cancelButton = () => {
    const ButtonCancel = () => (
      <Route
        render={({ history }) => (
          <Fab
            size="small"
            variant="extended"
            color="primary"
            onClick={() => {
              if (this.props.location.state.from === "account") {
                this.props.history.push({
                  pathname: `/account-details`,
                  state: {
                    companyId: this.state.subscriber_id,
                  },
                });
              } else {
                this.props.history.push({
                  pathname: `/ticket-list`,
                });
              }
            }}
          >
            Cancel
          </Fab>
        )}
      />
    );
    return <ButtonCancel />;
  };

  handleOpen = () => {
    this.setState((prevState) => ({
      showDiv: !prevState.showDiv,
    }));
  };

  serviceScheduleButton = (_ticketId) => {
    const ServiceScheduleButton = () => (
      <Route
        render={({ history }) => (
          <Button
            type="button"
            // style={{float:"right"}}
            className="btn btn-link btn-sm"
            title="Service Schedule"
            onClick={() => {
              history.push({
                pathname: "/service-schedules",
                state: {
                  ticketId: _ticketId,
                  subscriber_id: this.state.subscriber_id,
                  from: this.props.location.state.from,
                },
              });
            }}
          >
            <i className="fa fa-wrench" aria-hidden="true" />
          </Button>
        )}
      />
    );
    return <ServiceScheduleButton />;
  };
  onCloseSnackbar = () => {
    this.setState({ errorMessage: "" });
  };
  render() {
    const { classes } = this.props;
    return (
      // <div>
      <div className={classes.root}>
        <SnackBar
          status={this.state.errorMessage}
          onCloseError={this.onCloseSnackbar}
          color={"#1b5e20"}
        />
        <Paper className={classes.paper}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography
                variant="h3"
                style={{ fontWeight: "bold", textAlign: "left",color:"#000" }}
              >
                View Ticket
              </Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Typography
                style={{
                  fontWeight: "bold",
                  textAlign: "start",
                  fontSize: "0.875rem",
                }}
              >
                Ticket ID :{" "}
                <span
                  style={{
                    fontSize: "0.885rem",
                  }}
                >
                  #{this.state.ticketId}
                </span>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <Typography
                style={{
                  textAlign: "start",
                }}
              >
                <span style={{ fontSize: "0.875rem", fontWeight: "bold" }}>
                  Subject:{" "}
                </span>
                <span
                  style={{
                    fontSize: "0.825rem",
                  }}
                >
                  {this.state.subject}
                </span>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Typography style={{ textAlign: "left", fontWeight: "bold",}}>
                    Reply
                  </Typography>
                </Grid>
                <Grid item xs={6} style={{ textAlign: "right" }}>
                  <Button
                    // style={{ float: "right" }}
                    type="button"
                    onClick={this.handleOpen}
                    className="icon-button"
                  >
                    {this.state.showDiv ? (
                      <i className="fa fa-minus" aria-hidden="true" />
                    ) : (
                      <i className="fa fa-plus" aria-hidden="true" />
                    )}
                  </Button>
                  {this.serviceScheduleButton(this.state.ticketId)}
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={4}
              style={{
                display: this.state.showDiv ? "block" : "none",
              }}
            >
              <Typography
                style={{
                  textAlign: "left",
                  fontWeight: "bold",
                  fontSize: "0.875rem",
                }}
              >
                Status
              </Typography>
              <select
                style={{ borderRadius: "5px", width: "100%", height: "40px" }}
                name="status"
                onChange={this.onChange}
                value={this.state.status}
                required
              >
                <option value="1">Open</option>
                <option value="2">Assigned</option>
                <option value="3">Answered</option>
                <option value="4">Pending</option>
                <option value="5">Closed</option>
              </select>
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                display: this.state.showDiv ? "block" : "none",
              }}
            >
              <Paper className={classes.paper}>
                <Editor
                  editorState={this.state.editorState}
                  wrapperClassName="wrapperClassName"
                  editorClassName="editorClassName"
                  onEditorStateChange={this.onEditorStateChange}
                />
              </Paper>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              style={{
                display: this.state.showDiv ? "block" : "none",
              }}
            >
              <Fab
                size="small"
                variant="extended"
                color="primary"
                onClick={this.onSubmit}
                // className="btn btn-primary btn-sm"
                style={{ marginRight: "1em" }}
              >
                Ok
              </Fab>
              {this.cancelButton()}
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Typography
                    variant="h4"
                    style={{ fontWeight: "bold", textAlign: "left",color:"#000" }}
                  >
                    Comment List
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <ul>
                    {this.state.showTicketItems.length !== 0
                      ? this.state.showTicketItems.map((el) => {
                          return (
                            <ViewTicketItem
                              key={el.autoid}
                              ticketId={this.props.location.state.ticketId}
                              itemObject={el}
                            />
                          );
                        })
                      : "No comments yet"}
                  </ul>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </div>
    );
  }
}

export default withStyles(styles)(ViewTicket);
