import React, { useState, useEffect, useRef } from "react";
import {
  Paper,
  makeStyles,
  Grid,
  Typography,
  TextField,
  Fab,
  Divider,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  CircularProgress,
} from "@material-ui/core";
import { Formik, Form } from "formik";
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from "react-router-dom";
import { api } from "../../businessjs/API";
import { SnackBar } from "../../compon-controls/SnackBar";
import { Redirect } from "react-router-dom";
import moment from "moment";
import { ProductTable } from "./ProductTable";
import { ProductAddForm } from "./ProductAddForm";

const useStyles = makeStyles((theme) => ({
  formControl: {
    // margin: theme.spacing(1),
    minWidth: 120,
  },
  table: {
    minWidth: 650,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  buttonProgress: {
    position: "absolute",
    left: "62.5%",
    marginTop: "0.5em",
  },
}));
const currencies = [
  {
    value: "USD",
    label: "USD",
  },
  {
    value: "EUR",
    label: "EUR",
  },
  {
    value: "QR",
    label: "QR",
  },
  {
    value: "INR",
    label: "INR",
  },
];
const statusOptions = [
  {
    value: 1,
    label: "Active",
  },
  { value: 0, label: "Cancelled" },
];
export const AddPurchaseBill = (props) => {
  const classes = useStyles();
  // const [countryOptions, setCountryOptions] = useState([]);
  const [error, setError] = useState(false);
  const [color, setColor] = useState("#d50000");
  const [infoMessgae, setInfoMessage] = useState(false);
  const [navigation, setNavigation] = useState(false);
  const [accountOptions, setAccountOptions] = useState([]);
  const [contactOptions, setContactOptions] = useState([]);
  const [allContacts, setAllContacts] = useState([]);
  const [pOOptions, setPoOptions] = useState([]);
  const [accountName, setAccountName] = useState("");
  const [addProductVisible, setAddProductVisible] = useState(false);
  const [productList, setProductList] = useState([]);
  const [productRow, setProductRow] = useState([]);
  const [poNumber, setPoNumber] = useState("");
  const [accountId, setAccountId] = useState("");
  const [accountStreetAddress, setAccountStreetAddress] = useState("");
  const [accountCity, setAccountCity] = useState("");
  const [accountState, setAccountState] = useState("");
  const [accountZip, setAccountZip] = useState("");
  const [accountContactName, setAccountContactName] = useState("");
  const [approvedBy, setApprovedBy] = useState("");
  const [comments, setComments] = useState("");
  const [subTotal, setSubTotal] = useState("");
  const [discount, setDiscount] = useState(0);
  const [totalPrice, setTotalPrice] = useState("");
  const [productObject, setProductObject] = useState();
  const [otherCharges, setOtherCharges] = useState(0);
  const [shipTo, setShipTo] = useState("Qatar");
  const [addProductFlag, setAddProductFlag] = useState(false);
  const [tableTotal, setTableTotal] = useState(0);
  const [currency, setCurrency] = useState("QR");
  const [serialArray, setSerialArray] = useState();
  const [purchaseDate, setPurchaseDate] = useState(
    moment().format("YYYY-MM-DD")
  );
  const [status, setStatus] = useState(1);
  const [disableAdd, setDisableAdd] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const [discountValueError, setDiscountValueError] = useState(false);
  const [accountNameDisable, setAccountNameDisable] = useState(false);
  const initialState = useRef({});
  const NavLink = React.forwardRef((props, ref) => (
    <RouterLink innerRef={ref} {...props} />
  ));
  useEffect(() => {
    const id = props.match.params.id;
    async function loadPurchaseOrders() {
      try {
        if (id !== "new") {
          setDataLoading(true);
          setIsLoading(true);
          const purchaseId = id;
          const purchaseDetails = await api.post(
            "sc-purchase/get-purchase-by-id",
            { purchaseId }
          );

          // if (
          //   purchaseDetails.status !== "failed" &&
          //   purchaseDetails !== undefined
          // ) {

          if (purchaseDetails) {
            initialState.current = { ...purchaseDetails };
            const purchase = purchaseDetails.purchase;
            const productArray = purchaseDetails.productSet;

            // const serialArray = purchaseDetails.productSerialization;
            setAccountName(purchase.account_id);
            setAddProductVisible(false);
            setProductRow(productArray);
            setPoNumber(parseInt(purchase.po_id));
            initialState.current.poNumber = parseInt(purchase.po_id);
            setAccountId(purchase.account_id);
            setAccountStreetAddress(purchase.address);
            setAccountCity(purchase.city);
            setAccountState(purchase.state);
            setAccountZip(purchase.zipcode);
            setAccountContactName(purchase.contact_id);
            setApprovedBy(purchase.approved_by);
            setComments(purchase.comments);
            setStatus(purchase.status);
            setSubTotal(
              parseFloat(purchase.discount) +
                parseFloat(purchase.total_amount) -
                parseFloat(purchase.other_charge)
            );
            initialState.current.subTotal =
              parseFloat(purchase.discount) +
              parseFloat(purchase.total_amount) -
              parseFloat(purchase.other_charge);
            setDiscount(purchase.discount);
            setTotalPrice(purchase.total_amount);
            setOtherCharges(purchase.other_charge);
            setShipTo(purchase.ship_to);
            setCurrency(purchase.currency);
            setSerialArray(purchase.serialArray);
            setPurchaseDate(
              moment(purchase.purchase_date).format("YYYY-MM-DD")
            );
            initialState.current.purchaseDate = moment(
              purchase.purchase_date
            ).format("YYYY-MM-DD");
          } else {
            setInfoMessage("Failed to load data because of slow network");
            setColor("#d50000");
          }
          setIsLoading(false);
          setDataLoading(false);
        } else {
        }
      } catch (e) {
        console.log(e);
      }
    }
    loadPurchaseOrders();
  }, [props.match.params.id]);
  useEffect(() => {
    async function loadAccounts_Products_Contacts_Po() {
      try {
        const purchaseOrderOptionList = await api.post(
          "sc-purchase-order/get-all-for-cmb"
        );
        if (purchaseOrderOptionList.status !== "failed") {
          setPoOptions(purchaseOrderOptionList.poSet);
        }
        const accountOptionsList = await api.post(
          "sc-account/get-all-vendor-accounts"
        );
        if (accountOptionsList && Array.isArray(accountOptionsList)) {
          setAccountOptions(accountOptionsList);
        }

        const inputProduct = {
          company_id: localStorage.apiCompanyId,
          division_id: localStorage.apiDivisionId,
        };
        const allContacts = await api.post("sc-contact/get-all-for-cmb");
        // if (allContacts !== undefined && allContacts.status !== "failed") {
        if (allContacts && Array.isArray(allContacts)) {
          setContactOptions(allContacts);
          setAllContacts(allContacts);
        }

        const allProducts = await api.post(
          "sc-product/get-all-product",
          inputProduct
        );
        if (allProducts && Array.isArray(allProducts)) {
          setProductList(allProducts);
        }
        // console.log("allProducts", allProducts);
      } catch (e) {
        console.log(e);
      }
    }
    loadAccounts_Products_Contacts_Po();
  }, [accountOptions.length]);

  const onEditTableRow = async (name) => {
    const inputProduct = {
      company_id: localStorage.apiCompanyId,
      division_id: localStorage.apiDivisionId,
    };
    const allProducts = await api.post(
      "sc-product/get-all-product",
      inputProduct
    );
    if (allProducts && Array.isArray(allProducts)) {
      setProductList(allProducts);
    }
    const productDetails =
      productRow && productRow.find((item) => item.productName === name);
    if (productDetails !== undefined) {
      setProductObject(productDetails);
      setAddProductVisible(true);
    }
  };
  const onDeleteRow = (name) => {
    const productDetails =
      productRow && productRow.filter((item) => item.productName !== name);
    setProductRow(productDetails);
  };
  const handleStatusChange = (value) => {
    setStatus(value);
    if (value === 0) {
      setDisableAdd(true);
    } else {
      setDisableAdd(false);
    }
  };
  const handleSelectChange = async (value) => {
    // console.log("values", value);
    setPoNumber(value);
    try {
      const purchaseOrderId = value;
      const purchaseOrderList = await api.post(
        "sc-purchase-order/get-purchase-order-by-id",
        { purchaseOrderId }
      );
      setDataLoading(true);
      // console.log("purchaseOrderList", purchaseOrderList.purchaseOrder);
      if (purchaseOrderList.status !== "failed") {
        const initialValuesPo = purchaseOrderList.purchaseOrder;
        if (initialValuesPo !== undefined) {
          setAccountId(initialValuesPo.account_id);
          setAccountName(initialValuesPo.account_id);
          setAccountStreetAddress(initialValuesPo.address);
          setAccountState(initialValuesPo.state);
          setAccountCity(initialValuesPo.city);
          setAccountZip(initialValuesPo.zipcode);
          setAccountNameDisable(true);
          const contactId =
            contactOptions &&
            contactOptions.find(
              (contact) => contact.first_name === initialValuesPo.contact_name
            );
          if (contactId !== undefined) {
            const id = contactId.autoid;
            setAccountContactName(id);
          }
          setApprovedBy(initialValuesPo.approved_by);
          setComments(initialValuesPo.comments);

          const ownContacts =
            allContacts &&
            allContacts.filter(
              (contact) => contact.account_id === initialValuesPo.account_id
            );
          console.log("ownContacts", ownContacts);
          setContactOptions(ownContacts);
        }
      }
      setDataLoading(false);
    } catch (e) {
      console.log(e);
      setDataLoading(false);
    }
  };
  const handleChangeAccountName = async (value) => {
    // console.log("value", value);
    // console.log("poNumber", poNumber);
    setAccountName(value);
    if (poNumber === "") {
      const autoId = value;
      const accountDetails = await api.post("sc-account/get-account-by-id", {
        autoId,
      });
      // console.log("accountDetails", accountDetails);
      if (accountDetails !== undefined && accountDetails.length !== 0) {
        setDataLoading(true);
        const initialValuesAccount = accountDetails.find((item) => item);

        setAccountId(initialValuesAccount.autoid);
        // setAccountName(initialValuesAccount.account_id);
        setAccountStreetAddress(initialValuesAccount.address);
        setAccountState(initialValuesAccount.state);
        setAccountCity(initialValuesAccount.city);
        setAccountZip(initialValuesAccount.zipcode);
        const contactId =
          contactOptions &&
          contactOptions.find(
            (contact) =>
              contact.first_name === initialValuesAccount.contact_name
          );
        if (contactId !== undefined) {
          const id = contactId.autoid;
          setAccountContactName(id);
        }
        setApprovedBy(
          initialValuesAccount.approved_by === undefined
            ? approvedBy
            : initialValuesAccount.approved_by
        );
        setComments(
          initialValuesAccount.comments === undefined
            ? comments
            : initialValuesAccount.comments
        );
        setDataLoading(false);

        const ownContacts =
          allContacts &&
          allContacts.filter((contact) => contact.account_id === value);
        // console.log("ownContacts", ownContacts);
        setContactOptions(ownContacts);
      } else {
        // setInfoMessage("Something went wrong...")
        // setColor("#d50000")
      }
    }
  };
  const handleSelectContactChange = (value) => {
    setAccountContactName(value);
  };
  const onCloseSnackbar = () => {
    setInfoMessage("");
  };
  const onAddProduct = () => {
    setAddProductVisible(true);
    setProductObject();
    setAddProductFlag(true);
    getFilteredProduct(productRow);
  };
  const addProductDialogClose = () => {
    setAddProductVisible(false);
  };
  const onProductAddSave = (values) => {
    // console.log("productValues", values);
    if (productRow.length === 0) {
      setProductRow([values]);
      setSubTotal(values.total);
      setTotalPrice(values.total);
      getFilteredProduct([values]);
    } else {
      setAddProductFlag(false);
      const duplicateProductRow = productRow.map((item) => ({ ...item }));

      const overlappedRow =
        duplicateProductRow &&
        duplicateProductRow.find(
          (item) => item.productName === values.productName
        );
      // console.log("overlapped", overlappedRow);
      if (overlappedRow) {
        const nonOverlappedRow =
          duplicateProductRow &&
          duplicateProductRow.filter(
            (item) => item.productName !== values.productName
          );
        // console.log("nonOverlappeds", nonOverlappedRow);
        overlappedRow.quantity = values.quantity;
        overlappedRow.total = values.total;
        overlappedRow.serialNumbers = values.serialNumbers;
        overlappedRow.serialization = values.serialization;
        const products = [overlappedRow, ...nonOverlappedRow];
        // console.log("prodcutsoverlapped", products);
        setProductRow(products);
        const priceArray =
          products && products.map((item) => parseFloat(item.total));
        // console.log("priceArray", priceArray);
        const total = priceArray.reduce((a, b) => a + b, 0);
        // console.log("total>>>>", total);
        setTableTotal(total);
        setSubTotal(total);
        setTotalPrice(total);
        getFilteredProduct(products);
      } else {
        const products = [...productRow, values];
        setProductRow(products);
        const priceArray =
          products && products.map((item) => parseFloat(item.total));
        // console.log("priceArray", priceArray);
        const total = priceArray.reduce((a, b) => a + b, 0);
        // console.log("total", total);
        setTableTotal(total);
        setSubTotal(total);
        setTotalPrice(total);
        getFilteredProduct(products);
      }
    }
    // console.log("values", values);
    setAddProductVisible(false);
  };

  const getFilteredProduct = (array) => {
    let filtered = productList.filter((item) =>
      array.every((product) => {
        return product.autoid !== item.autoid;
      })
    );
    // console.log("filtered", filtered);
    setProductList(filtered);
  };
  const onDiscountChange = (value) => {
    // console.log("value", value);
    if (value < 0) {
      setDiscount(value);
      setDiscountValueError(true);
    } else {
      setDiscount(value);
      setDiscountValueError(false);
      if (value === "") {
        setTotalPrice(parseFloat(subTotal) + parseFloat(otherCharges) - 0);
      } else {
        setTotalPrice(parseFloat(subTotal) + parseFloat(otherCharges) - value);
      }
    }
  };
  const onOtherChargesChange = (value) => {
    setOtherCharges(value);
    setTotalPrice(parseFloat(subTotal) + parseFloat(value) - discount);
  };
  const onOtherChargesBlur = (value) => {
    if (value === "") {
      setOtherCharges(0);
    }
  };
  const onDiscountBlur = () => {
    if (discount === "") {
      setDiscount(0);
    }
  };
  const onCancel = () => {
    setNavigation(true);
  };

  const productXML = () => {
    let xmlStr = "";
    if (productRow && productRow.length) {
      xmlStr += "<Items>";
      productRow.forEach(function (value) {
        xmlStr += '<Item product_id="' + value.autoid + '"  ';
        xmlStr += 'price="' + value.price + '" ';
        xmlStr += 'quantity="' + value.quantity + '" ';
        xmlStr += 'status="' + 1 + '" ';
        xmlStr += 'discount="' + value.discount + '" ';
        xmlStr += 'serialization="' + value.serialization + '"/> ';
      });
      xmlStr += "</Items>";
    }

    return xmlStr;
  };
  const stockXml = () => {
    let xmlStr = "<Stocks>";
    const quantityArray = productRow.map((product) =>
      parseInt(product.quantity)
    );
    const totalQty = quantityArray.reduce((a, b) => a + b);
    const expense = parseFloat(otherCharges) / parseFloat(totalQty);
    productRow.forEach((item) => {
      if (item.serialNumbers.value.length > 0) {
        for (let i = 0; i < item.serialNumbers.value.length; i++) {
          xmlStr += '<Stock product_id="' + item.serialNumbers.id + '"  ';
          xmlStr += 'serial_no="' + item.serialNumbers.value[i] + '" ';
          xmlStr += 'price="' + item.price + '" ';
          xmlStr += 'expense="' + expense.toFixed(2) + '"/> ';
        }
      } else {
        const quantity = parseInt(item.quantity);
        for (let i = 0; i < quantity; i++) {
          xmlStr += '<Stock product_id="' + item.serialNumbers.id + '"  ';
          xmlStr += 'serial_no="' + "" + '" ';
          xmlStr += 'price="' + item.price + '" ';
          xmlStr += 'expense="' + expense.toFixed(2) + '"/> ';
        }
      }
    });

    xmlStr += "</Stocks>";

    return xmlStr;
  };

  const purchaseOrderSubmit = async () => {
    if (productRow.length === 0) {
      setInfoMessage("Please Select atleast one product");
      setColor("#d50000");
    } else {
      if (props.match.params.id === "new") {
        if (accountName === "") {
          setInfoMessage("Please Select AccountName");
          setColor("#d50000");
        } else if (accountContactName === "") {
          setInfoMessage("Please Select ContactName");
          setColor("#d50000");
        } else {
          setIsLoading(true);
          const purchaseInput = {
            companyId: localStorage.apiCompanyId,
            divisionId: localStorage.apiDivisionId,
            accountId: accountId,
            approvedBy: approvedBy,
            comments: comments,
            totalAmount: totalPrice,
            otherCharge: otherCharges,
            discount: discount,
            shipTo: shipTo,
            poId: poNumber,
            purchaseDate: purchaseDate,
            address: accountStreetAddress,
            state: accountState,
            city: accountCity,
            zipcode: accountZip,
            contactId: accountContactName,
            status: status,
            currency: currency,
            purchaseItems: productXML(),
            stockItems: stockXml(),
          };
          const addResult = await api.post(
            "sc-purchase/add-purchase",
            purchaseInput
          );
          setIsLoading(false);
          if (addResult.status === "success") {
            setInfoMessage(addResult.message);
            setColor("#4caf50");
            setTimeout(() => {
              setNavigation(true);
            }, 2000);
          } else {
            setInfoMessage(addResult.message);
            setColor("#d50000");
            setNavigation(false);
          }
        }
      } else {
        const prevPurchaseValue = initialState.current.purchase;
        const prevProductSet = initialState.current.productSet;
        // console.log("initial",initialState)
        if (
          prevPurchaseValue.account_id === accountId &&
          prevPurchaseValue.approved_by === approvedBy &&
          prevPurchaseValue.comments === comments &&
          prevPurchaseValue.total_amount === totalPrice &&
          prevPurchaseValue.other_charge === otherCharges &&
          prevPurchaseValue.discount === discount &&
          prevPurchaseValue.ship_to === shipTo &&
          initialState.current.poNumber === poNumber &&
          prevPurchaseValue.address === accountStreetAddress &&
          prevPurchaseValue.state === accountState &&
          prevPurchaseValue.city === accountCity &&
          prevPurchaseValue.zipcode === accountZip &&
          prevPurchaseValue.status === status &&
          prevPurchaseValue.currency === currency &&
          initialState.current.purchaseDate === purchaseDate &&
          prevPurchaseValue.contact_id === accountContactName &&
          prevProductSet === productRow
        ) {
          setColor("#0091ea");
          setInfoMessage("Please change fields as required...");
        } else {
          setIsLoading(true);
          const purchaseInput = {
            companyId: localStorage.apiCompanyId,
            accountId: accountId,
            divisionId: localStorage.apiDivisionId,
            approvedBy: approvedBy,
            comments: comments,
            totalAmount: totalPrice,
            otherCharge: otherCharges,
            discount: discount,
            shipTo: shipTo,
            poId: poNumber,
            purchaseDate: purchaseDate,
            address: accountStreetAddress,
            state: accountState,
            city: accountCity,
            zipcode: accountZip,
            contactId: accountContactName,
            status: status,
            currency: currency,
            purchaseItems: productXML(),
            stockItems: stockXml(),
            purchaseId: props.match.params.id,
          };

          console.log("purchase edit", purchaseInput);
          const addResult = await api.post(
            "sc-purchase/edit-purchase",
            purchaseInput
          );
          setIsLoading(false);
          if (addResult.status === "success") {
            setInfoMessage(addResult.message);
            setColor("#4caf50");
            setTimeout(() => {
              setNavigation(true);
            }, 2000);
          } else {
            setInfoMessage(addResult.message);
            setColor("#d50000");
            setNavigation(false);
          }
        }
      }
    }
  };
  if (navigation) {
    return <Redirect to="/purchase-bill"></Redirect>;
  } else {
    return (
      <div>
        <SnackBar
          status={infoMessgae}
          onCloseError={onCloseSnackbar}
          color={color}
        />
        <Paper elevation={4} style={{ minHeight: "25em", minWidth: "50%" }}>
          <Formik>
            <Form>
              {/* <form> */}
              <Grid container spacing={3} style={{ padding: "2em" }}>
                <Grid item xs={12}>
                  <Typography variant="h4" style={{ fontWeight: "bold" }}>
                    {props.match.params.id === "new"
                      ? "Add new Purchase Bill"
                      : "Edit purchase Bill"}
                  </Typography>
                </Grid>

                <Grid item xs={12} style={{ marginTop: "1.5em" }}>
                  <Typography variant="h5" style={{ fontWeight: "bold" }}>
                    Purchase Order Details
                  </Typography>
                  <Divider />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
                    id="purchase-order"
                    select
                    label="Purchase Order Number"
                    disabled={dataLoading}
                    value={poNumber}
                    onChange={(event) => handleSelectChange(event.target.value)}
                    // helperText="Please select your currency"
                    variant="outlined"
                  >
                    {pOOptions.map((option) => (
                      <MenuItem key={option.autoid} value={option.autoid}>
                        {option.autoid}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                <Grid item xs={12} sm={4}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                    fullWidth
                  >
                    <InputLabel id="demo-simple-select-outlined-label">
                      Account Name
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={accountName}
                      disabled={dataLoading || accountNameDisable}
                      onChange={(event) =>
                        handleChangeAccountName(event.target.value)
                      }
                      label="Account Name"
                    >
                      {accountOptions &&
                        accountOptions.map((option) => (
                          <MenuItem value={option.autoid} key={option.autoid}>
                            {option.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    required
                    fullWidth
                    name="accountId"
                    label="Id"
                    variant="outlined"
                    value={accountId}
                    // disabled={dataLoading || accountNameDisable}
                    disabled
                    onChange={(event) => setAccountId(event.target.value)}
                    inputProps={{ maxLength: 50 }}
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
                    required
                    variant="outlined"
                    name="accountStreetAddress"
                    label="Street Address"
                    disabled={dataLoading}
                    value={accountStreetAddress}
                    onChange={(event) =>
                      setAccountStreetAddress(event.target.value)
                    }
                    inputProps={{ maxLength: 50 }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    required
                    fullWidth
                    variant="outlined"
                    name="accountCity"
                    label="City"
                    disabled={dataLoading}
                    value={accountCity}
                    onChange={(event) => setAccountCity(event.target.value)}
                    inputProps={{ maxLength: 50 }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
                    required
                    variant="outlined"
                    name="accountState"
                    label="State"
                    disabled={dataLoading}
                    value={accountState}
                    onChange={(event) => setAccountState(event.target.value)}
                    inputProps={{ maxLength: 50 }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
                    required
                    variant="outlined"
                    name="accountZip"
                    label="Zip Code"
                    disabled={dataLoading}
                    value={accountZip}
                    onChange={(event) => setAccountZip(event.target.value)}
                    inputProps={{ maxLength: 50 }}
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                    fullWidth
                  >
                    <InputLabel id="demo-simple-select-outlined-label">
                      Contact Name
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={accountContactName}
                      disabled={dataLoading}
                      onChange={(event) =>
                        handleSelectContactChange(event.target.value)
                      }
                      label="Account Name"
                    >
                      {contactOptions &&
                        contactOptions.map((option) => (
                          <MenuItem value={option.autoid} key={option.autoid}>
                            {option.first_name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
                    id="status"
                    select
                    label="Status"
                    value={status}
                    onChange={(event) => handleStatusChange(event.target.value)}
                    // helperText="Please select your currency"
                    variant="outlined"
                  >
                    {statusOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
                    // required
                    variant="outlined"
                    name="approvedBy"
                    label="Approved By"
                    disabled={dataLoading}
                    value={approvedBy}
                    onChange={(event) => setApprovedBy(event.target.value)}
                    inputProps={{ maxLength: 50 }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
                    // required
                    variant="outlined"
                    name="comments"
                    label="Comments"
                    disabled={dataLoading}
                    value={comments}
                    onChange={(event) => setComments(event.target.value)}
                    inputProps={{ maxLength: 50 }}
                  />
                </Grid>
                <Grid item xs={12} style={{ textAlign: "left" }}>
                  <Fab
                    variant="extended"
                    size="medium"
                    color="primary"
                    aria-label="add"
                    disabled={isLoading || disableAdd}
                    // type="submit"
                    onClick={onAddProduct}
                  >
                    Add Product
                  </Fab>
                </Grid>
                <Grid item xs={12}>
                  {addProductVisible ? (
                    <ProductAddForm
                      addProductVisible={addProductVisible}
                      addProductDialogClose={addProductDialogClose}
                      onProductAddSave={onProductAddSave}
                      productList={productList}
                      productObjectDetails={productObject}
                      serialArray={serialArray}
                    />
                  ) : (
                    ""
                  )}
                </Grid>
                <Grid item xs={12}>
                  <ProductTable
                    productRow={productRow}
                    onEditTableRow={onEditTableRow}
                    onDeleteRow={onDeleteRow}
                    total={tableTotal}
                    currency={currency}
                  />
                </Grid>
                <Grid item xs={12} style={{ marginTop: "1.5em" }}>
                  <Typography variant="h5" style={{ fontWeight: "bold" }}>
                    Purchase Details
                  </Typography>
                  <Divider />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    required
                    fullWidth
                    variant="outlined"
                    type="date"
                    name="purchaseDate"
                    label="Purchase Date"
                    defaultValue={purchaseDate}
                    value={purchaseDate}
                    onChange={(event) => setPurchaseDate(event.target.value)}
                    inputProps={{ maxLength: 50 }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
                    required
                    variant="outlined"
                    name="shipTo"
                    label="Ship To"
                    value={shipTo}
                    onChange={(event) => setShipTo(event.target.value)}
                    inputProps={{ maxLength: 50 }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    required
                    fullWidth
                    id="outlined-select-currency"
                    select
                    label="Currency"
                    value={currency}
                    onChange={(event) => setCurrency(event.target.value)}
                    // helperText="Please select your currency"
                    variant="outlined"
                  >
                    {currencies.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
                    required
                    disabled
                    variant="outlined"
                    name="subTotal"
                    label="Sub Total"
                    type="number"
                    value={subTotal}
                    onChange={(event) => setSubTotal(event.target.value)}
                    inputProps={{ maxLength: 50 }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
                    required
                    variant="outlined"
                    name="otherCharges"
                    label="Other Charges"
                    value={otherCharges}
                    onChange={(event) =>
                      onOtherChargesChange(event.target.value)
                    }
                    onBlur={onOtherChargesBlur}
                    inputProps={{ maxLength: 50 }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
                    required
                    variant="outlined"
                    name="discount"
                    label="Discount(Qr)"
                    type="number"
                    value={discount}
                    onChange={(event) => onDiscountChange(event.target.value)}
                    onBlur={onDiscountBlur}
                    inputProps={{ maxLength: 50 }}
                    error={discountValueError}
                    helperText={
                      discountValueError
                        ? "Discount Value cannot be a negative.."
                        : ""
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
                    required
                    variant="outlined"
                    name="totalPrice"
                    label="Order Total"
                    type="number"
                    disabled
                    value={totalPrice}
                    onChange={(event) => setTotalPrice(event.target.value)}
                    inputProps={{ maxLength: 50 }}
                  />
                </Grid>
                <Grid item xs={6}></Grid>
                <Grid item xs={3} sm={6} style={{ textAlign: "right" }}>
                  <Fab
                    variant="extended"
                    size="medium"
                    color="primary"
                    type="reset"
                    onClick={onCancel}
                  >
                    Cancel
                  </Fab>
                </Grid>
                <Grid item xs={3} sm={6} style={{ textAlign: "left" }}>
                  <Fab
                    variant="extended"
                    size="medium"
                    color="primary"
                    aria-label="add"
                    disabled={isLoading}
                    // type="submit"
                    onClick={purchaseOrderSubmit}
                  >
                    {props.match.params.id === "new" ? "Submit" : "Update"}
                  </Fab>
                  {isLoading && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </Grid>
              </Grid>
              {/* </form> */}
            </Form>
            {/* )} */}
          </Formik>
        </Paper>
      </div>
    );
  }
};
