import React, { Component } from "react";
import Navbar from "../compon-styled/SupportCenterNavBar";


class CommingSoon extends Component {
  state = {};
  render() {
    return (
      <React.Fragment>
        {/* <Navbar /> */}

        <section className="manage-colom">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <h1>Comming Soon </h1>
                <div className="row tile-colom">
                   <img src="/images/comingsoon.jpg" alt="soon" />
                   
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default CommingSoon;
