import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import {
  Grid,
  Typography,
  Fab,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  makeStyles,
} from "@material-ui/core";
import { Formik, Form, ErrorMessage } from "formik";

const useStyles = makeStyles((theme) => ({
  formControl: {
    // margin: theme.spacing(1),
    minWidth: 120,
  },
}));
export const ProductAddForm = ({
  addProductVisible,
  addProductDialogClose,
  onProductAddSave,
  productList,
  productObjectDetails,
}) => {
  const classes = useStyles();
  const [productObject, setProductObject] = useState({});
  const [productName, setProductName] = useState();
  const [quantity, setQuantity] = useState(1);
  const [price, setPrice] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [discountValueError, setDiscountValueError] = useState(false);

  useEffect(() => {
    // console.log("productObject",productObjectDetails)
    if (productObjectDetails !== undefined) {
      const productId = productList.find(
        (item) => item.product_name === productObjectDetails.productName
      ).autoid;
      setProductName(productId);
      setQuantity(productObjectDetails.quantity);
      setPrice(productObjectDetails.price);
      setDiscount(productObjectDetails.discount);
      setTotalPrice(productObjectDetails.total);
    }
  }, [productObjectDetails]);
  const handleProductSelectChange = (value) => {
    setProductName(value);
    const newProductList =
      productList && productList.filter((item) => item.autoid !== value);
    const product =
      productList && productList.find((product) => product.autoid === value);
    if (product !== undefined) {
      setProductObject(product);
      setPrice(parseFloat(product.price));
      setTotalPrice(parseFloat(product.price));
    }
  };
  const onQuantityChange = (quantityValue) => {
    setQuantity(quantityValue);
    // if (discount !== "") {

    setTotalPrice(
      parseFloat(price) * parseFloat(quantityValue) -
        (parseFloat(price) * parseFloat(quantityValue) * parseFloat(discount)) /
          100
    );
  };
  const onQuantityBlur = (value) => {
    if (value === "") {
      setQuantity(1);
      setTotalPrice(
        parseFloat(price) * 1 -
          (parseFloat(price) * 1 * parseFloat(discount)) / 100
      );
    }
  };
  const onPriceChange = (priceValue) => {
    setPrice(priceValue);
    setTotalPrice(
      parseFloat(priceValue) * parseFloat(quantity) -
        (parseFloat(priceValue) * parseFloat(quantity) * parseFloat(discount)) /
          100
    );
  };
  const onPriceBlur = (priceValue) => {
    if (priceValue === "") {
      setPrice(0);
    }
  };
  const onDiscountChange = (discountValue) => {
    if (discountValue > 100) {
      setDiscount(discountValue);
      setDiscountValueError(true);
    } else {
      setDiscount(discountValue);
      setDiscountValueError(false);
      setTotalPrice(
        parseFloat(price) * parseFloat(quantity) -
          (parseFloat(price) *
            parseFloat(quantity) *
            parseFloat(discountValue)) /
            100
      );
    }
  };
  const onDiscountBlur = (discountValue) => {
    if (discountValue === "") {
      setDiscount(0);
    }
  };
  const onSubmitProduct = () => {
    const productFinalObject = {
      autoid: productList.find((item) => item.autoid === productName).autoid,
      productName: productList.find((item) => item.autoid === productName)
        .product_name,
      quantity: quantity,
      price: price,
      discount: discount,
      total: totalPrice,
    };
    // console.log("productFinalObject",productFinalObject)
    onProductAddSave(productFinalObject);
  };
  return (
    <div>
      <Dialog
        open={addProductVisible}
        onClose={addProductDialogClose}
        aria-labelledby="form-dialog-title"
      >
        <form>
          <DialogTitle
            id="form-dialog-title"
            style={{ backgroundColor: "#007bff" }}
          >
            <Typography
              variant="h4"
              style={{ fontWeight: "bold", color: "#fff" }}
            >
              Add Product
            </Typography>
          </DialogTitle>
          <DialogContent style={{ minHeight: "19em" }}>
            <Grid container spacing={3} style={{ paddingTop: "2em" }}>
              <Grid item xs={12} sm={6}>
                <FormControl
                  variant="outlined"
                  className={classes.formControl}
                  fullWidth
                >
                  <InputLabel id="demo-simple-select-outlined-label">
                    Product Name
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={productName}
                    onChange={(event) =>
                      handleProductSelectChange(event.target.value)
                    }
                    label="Account Name"
                  >
                    {productList &&
                      productList.map((option) => (
                        <MenuItem value={option.autoid}>
                          {option.product_name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  required
                  variant="outlined"
                  name="quantity"
                  label="Quantity"
                  type="number"
                  value={quantity}
                  onChange={(event) => onQuantityChange(event.target.value)}
                  onBlur={(event) => onQuantityBlur(event.target.value)}
                  //   inputProps={{ maxLength: 50 }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="price"
                  label="Price"
                  value={price}
                  onChange={(event) => onPriceChange(event.target.value)}
                  onBlur={(event) => onPriceBlur(event.target.value)}
                  //   inputProps={{ maxLength: 50 }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="discount"
                  label="Discount(%)"
                  type="number"
                  placeholder="0.00"
                  defaultValue={0}
                  value={discount}
                  onChange={(event) => onDiscountChange(event.target.value)}
                  onBlur={(event) => onDiscountBlur(event.target.value)}
                  error={discountValueError}
                  helperText={
                    discountValueError
                      ? "Discount Value must be less than 100"
                      : ""
                  }
                  //   inputProps={{ maxLength: 50 }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  key="Total Price"
                  variant="outlined"
                  name="totalPrice"
                  label="Total Price"
                  value={totalPrice}
                  // onChange={handleChange}
                  //   inputProps={{ maxLength: 50 }}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Fab
              variant="extended"
              size="small"
              color="primary"
              onClick={addProductDialogClose}
            >
              Cancel
            </Fab>
            <Fab
              variant="extended"
              size="small"
              color="primary"
              type="submit"
              onClick={onSubmitProduct}
            >
              Save
            </Fab>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};
