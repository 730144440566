import React, { Component } from "react";
import {
  Typography,
  Fab,
  CircularProgress,
  FormControl,
  TextField,
  MenuItem,
  TableSortLabel,
  Tooltip,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  InputLabel,
  Select,
} from "@material-ui/core";
import PaymentIcon from "@material-ui/icons/Payment";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CmbSelect from "../compon-controls/CmbSelect";
import { AuthHelperAccessCheck } from "../businessjs/AuthHelper";
import { Route } from "react-router-dom";
import { api } from "../businessjs/API";

function descendingComparator(a, b, orderBy) {
  if (orderBy !== "") {
    if (isNaN(parseFloat(a[orderBy]))) {
      if (b[orderBy].toLowerCase() < a[orderBy].toLowerCase()) {
        return -1;
      }
      if (b[orderBy].toLowerCase() > a[orderBy].toLowerCase()) {
        return 1;
      }
      return 0;
    } else {
      if (parseFloat(b[orderBy]) < parseFloat(a[orderBy])) {
        return -1;
      }
      if (parseFloat(b[orderBy]) > parseFloat(a[orderBy])) {
        return 1;
      }
      return 0;
    }
  }
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  // { id: "autoid", numeric: false, label: "J.No." },
  { id: "invoice_id", numeric: false, label: "Invoice No." },
  { id: "company_name", numeric: false, label: "Subscriber" },
  { id: "payment_date", numeric: false, label: "Date" },
  {
    id: "payment_type",
    label: "Type",
    numeric: false,
    disablePadding: false,
  },
  { id: "amount", label: "Amount", numeric: false },
  { id: "status_type", label: "Status", numeric: false },
  { id: "edit", label: "" },
];

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  buttonProgress: {
    color: "#2196f3",
    position: "absolute",
    left: "55%",
    marginTop: "4.5em",
  },
}));

function EnhancedTableHead(props) {
  const classes = useStyles();
  const { order, orderBy, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            // align={headCell.numeric ? "right" : "center"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

class PaymentsList extends Component {
  state = {
    companyList: [],
    subscriber_id: 0,
    loading: false,
    paymentList: [],
    invoiceNo: "",
    status: 1,
    page: 0,
    rowsPerPage: 50,
    order: "desc",
    orderBy: "",
    expanded: false,
  };

  componentDidMount = () => {
    this.getSubscriberForCmb();
    this.getInvoiceForCmb();
    const paymentInput = {
      subscriber_id: this.state.subscriber_id,
      invoice_no: this.state.invoiceNo,
      status: this.state.status,
    };

    this.getPaymentList(paymentInput);
  };

  getSubscriberForCmb = async () => {
    const subscribers = await api.post("sc-subscriber/get-all-for-cmb");
    if (subscribers && subscribers.userdata) {
      let subscriberArray = subscribers.userdata.map((opt) => ({
        label: opt.company_name,
        value: opt,
      }));

      this.setState({
        companyList: subscriberArray,
      });
    } else {
      AuthHelperAccessCheck(subscribers);
    }
  };

  getInvoiceForCmb = async () => {
    const invoices = await api.post("sc-invoice/get-invoice-cmb");
    if (invoices && invoices.userdata) {
      let invoiceResult = invoices.userdata.map((opt) => ({
        label: opt.autoid,
        value: opt,
      }));
      invoiceResult.splice(0, 0, { label: "All", value: { autoid: 0 } });
      this.setState({
        invoiceList: invoiceResult,
      });
    } else {
      AuthHelperAccessCheck(invoices);
    }
  };

  getPaymentList = async (paymentInput) => {
    this.setState({ loading: true });
    try {
      const paymentByAccount = await api.post(
        "sc-invoice/get-payment-by-account",
        paymentInput
      );
      if (paymentByAccount) {
        this.setState({ paymentList: paymentByAccount, loading: false });
      } else {
        this.setState({ loading: false });
        AuthHelperAccessCheck(paymentByAccount);
      }
    } catch (error) {
      this.setState({ loading: false });
      console.log("something went wrong!", error);
    }
  };
  subscriberChange = (e) => {
    console.log(e.target.value);
    this.setState({ subscriber_id: e.target.value });

    const paymentInput = {
      subscriber_id: e.target.value.value.autoid,
      invoice_no: this.state.invoiceNo
        ? this.state.invoiceNo.value.autoid
        : this.state.invoiceNo,
      status: this.state.status,
    };

    this.getPaymentList(paymentInput);
  };
  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    const paymentInput = {
      subscriber_id: this.state.subscriber_id
        ? this.state.subscriber_id.value.autoid
        : this.state.subscriber_id,
      invoice_no: e.target.value.value.autoid,
      status: this.state.status,
    };
    this.getPaymentList(paymentInput);
  };

  view = (invoiceId, paymentId, paymentDate, subscriber_id) => {
    const ViewButton = () => (
      <Route
        render={({ history }) => (
          <button
            type="button"
            className="btn btn-link btn-sm"
            title="Payment"
            onClick={() => {
              history.push({
                pathname: "/invoice-payment",
                state: {
                  invoiceId: invoiceId,
                  from: "payment",
                  paymentId: paymentId,
                  paymentDate: paymentDate,
                  accountId: subscriber_id,
                },
              });
            }}
          >
            {/* View */}
            <Tooltip title="Payment">
              <PaymentIcon color="primary" />
            </Tooltip>
          </button>
        )}
      />
    );
    return <ViewButton />;
  };

  handleChange = (panel) => (event, isExpanded) => {
    this.setState({ expanded: isExpanded ? panel : false });
  };

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: parseInt(event.target.value, 10), page: 0 });
  };

  handleRequestSort = (event, property) => {
    const isAsc = this.state.orderBy === property && this.state.order === "asc";
    this.setState({ order: isAsc ? "desc" : "asc", orderBy: property });
  };

  onStatusChange = (value) => {
    this.setState({ status: value });
    const paymentInput = {
      subscriber_id: this.state.subscriber_id
        ? this.state.subscriber_id.value.autoid
        : this.state.subscriber_id,
      invoice_no: this.state.invoiceNo
        ? this.state.invoiceNo.value.autoid
        : this.state.invoiceNo,
      status: value,
    };

    this.getPaymentList(paymentInput);
  };

  /**
   * to get badge based on status fetch from DB.
   */
  getBadge = (status) => {
    let _classes = "badge badge-";
    _classes += status === "Cancelled" ? "danger" : "primary";
    return _classes;
  };

  render() {
    const _showPayments = this.state.paymentList;

    return (
      <React.Fragment>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h3" style={{ fontWeight: "bold" }}>
              Payments
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} style={{ textAlign: "left" }}>
            <Accordion
              expanded={this.state.expanded === "panel1"}
              onChange={this.handleChange("panel1")}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Search</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={3}>
                  <Grid item xs={4} sm={4}>
                    <Typography variant="h6" style={{ fontWeight: "bold" }}>
                      Subscriber
                    </Typography>
                    <CmbSelect
                      name="subscriber_id"
                      value={this.state.subscriber_id}
                      onChange={this.subscriberChange}
                      dataSource={this.state.companyList}
                      showAll={true}
                    />
                  </Grid>
                  <Grid item xs={4} sm={4}>
                    <Typography variant="h6" style={{ fontWeight: "bold" }}>
                      Invoice{" "}
                    </Typography>
                    <CmbSelect
                      name="invoiceNo"
                      value={this.state.invoiceNo}
                      onChange={this.onChange}
                      dataSource={this.state.invoiceList}
                    />
                  </Grid>
                  <Grid item xs={4} sm={4}>
                    <Typography variant="h6" style={{ fontWeight: "bold" }}>
                      status{" "}
                    </Typography>
                    <TextField
                      fullWidth
                      id="status"
                      select
                      name="status"
                      variant="outlined"
                      onChange={(e) => this.onStatusChange(e.target.value)}
                      value={this.state.status}
                    >
                      <MenuItem value="0">Cancelled</MenuItem>
                      <MenuItem value="1">Active</MenuItem>
                    </TextField>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item xs={12}>
            <Paper elevation={3} style={{ backgroundColor: "#fff" }}>
              {this.state.loading ? (
                this.state.loading && (
                  <Paper
                    elevation={3}
                    style={{
                      backgroundColor: "#fff",
                      minHeight: "15em",
                      align: "center",
                    }}
                  >
                    <CircularProgress size={50} style={{ marginLeft: "40%" }} />
                  </Paper>
                )
              ) : (
                <div>
                  {" "}
                  <Table style={{ minHeight: "15em" }}>
                    <EnhancedTableHead
                      order={this.state.order}
                      orderBy={this.state.orderBy}
                      onRequestSort={this.handleRequestSort}
                      rowCount={_showPayments.length}
                    />
                    {_showPayments.length !== 0 ? (
                      <TableBody>
                        {stableSort(
                          _showPayments,
                          getComparator(this.state.order, this.state.orderBy)
                        )
                          .slice(
                            this.state.page * this.state.rowsPerPage,
                            this.state.page * this.state.rowsPerPage +
                              this.state.rowsPerPage
                          )
                          .map((payment, i) => (
                            <TableRow key={i}>
                              <TableCell component="th" scope="row">
                                <span className="badge badge-success">
                                  {"#" + payment.invoice_id}
                                </span>
                              </TableCell>
                              <TableCell>{payment.company_name}</TableCell>
                              <TableCell>{payment.payment_date}</TableCell>
                              <TableCell>{payment.payment_type}</TableCell>
                              <TableCell>{payment.amount}</TableCell>
                              <TableCell>
                                <span
                                  className={this.getBadge(payment.status_type)}
                                >
                                  {payment.status_type}
                                </span>
                              </TableCell>
                              {payment.status != 0 ? (
                                <TableCell>
                                  {this.view(
                                    payment.invoice_id,
                                    payment.autoid,
                                    payment.payment_date,
                                    payment.subscriber_id
                                  )}
                                </TableCell>
                              ) : (
                                <TableCell />
                              )}
                            </TableRow>
                          ))}
                      </TableBody>
                    ) : (
                      <Typography
                        style={{
                          textAlign: "center",
                          marginTop: "1em",
                        }}
                      >
                        No Records...............
                      </Typography>
                    )}
                  </Table>
                  <TablePagination
                    rowsPerPageOptions={[50, 200, 500]}
                    component="div"
                    count={_showPayments.length}
                    rowsPerPage={this.state.rowsPerPage}
                    page={this.state.page}
                    onChangePage={this.handleChangePage}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                  />
                </div>
              )}
            </Paper>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

export default PaymentsList;
