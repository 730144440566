import React, { Component } from "react";
import { AuthHelperAccessCheck } from "../businessjs/AuthHelper";
import { api } from "../businessjs/API";
import {
  Grid,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  CircularProgress,
  Button,
  Paper,
  TableContainer,
  Table,
  TableBody,
  TablePagination,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  Fab,
  Badge,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import { makeStyles } from "@material-ui/core/styles";
import {
  Link,
  LinkProps as RouterLinkProps,
  Redirect,
  Route,
} from "react-router-dom";
import { Select } from "../compon-controls/Select";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const headCells = [
  {
    id: "bundle_name",
    numeric: false,
    label: "Bundle Name",
  },
  {
    id: "original_price",
    numeric: false,
    label: "Original Price(Qr)",
  },
  {
    id: "price",
    numeric: false,
    label: "Price(Qr)",
  },

  { id: "status", numeric: false, label: "Status" },
  { id: "view", label: "" },
  // { id: "view", label: "" },
];

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 650,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  contactTableRow: {
    paddingRight: "3em",
  },
  margin: {
    margin: theme.spacing(1),
  },
}));

const EnhancedTableHead = (props) => {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

function descendingComparator(a, b, orderBy) {
  if (orderBy !== "") {
    if (isNaN(parseFloat(a[orderBy]))) {
      if (b[orderBy].toLowerCase() < a[orderBy].toLowerCase()) {
        return -1;
      }
      if (b[orderBy].toLowerCase() > a[orderBy].toLowerCase()) {
        return 1;
      }
      return 0;
    } else {
      if (parseFloat(b[orderBy]) < parseFloat(a[orderBy])) {
        return -1;
      }
      if (parseFloat(b[orderBy]) > parseFloat(a[orderBy])) {
        return 1;
      }
      return 0;
    }
  }
}

const getComparator = (order, orderBy) => {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

const statusOptions = [
  { value: 0, label: "All" },
  { value: 1, label: "Active" },
  { value: 2, label: "Inactive" },
];

class ProductBundleList extends Component {
  state = {
    selectedDivision: { label: "", value: { autoid: 0 } },
    loading: true,
    allBundles: [],
    divisions: [],
    status: { value: 1, label: "Active" },
    order: "asc",
    orderBy: "",
    page: 0,
    rowsPerPage: 50,
    open: false,
    bundleName: "",
    bundleItems: undefined,
  };

  componentDidMount = () => {
    const _bundleInput = {
      division_id: localStorage.apiDivisionId,
      status: this.state.status.value,
    };
    this.getBundles(_bundleInput);
  };

  handleRequestSort = (event, property) => {
    const isAsc = this.state.orderBy === property && this.state.order === "asc";
    this.setState({ order: isAsc ? "desc" : "asc", orderBy: property });
  };

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: parseInt(event.target.value, 10), page: 0 });
  };

  getBundles = async (_bundleInput) => {
    this.setState({ loading: true });
    const res = await api.post("sc-bundle/get-bundle-list", _bundleInput);
    if (res) {
      if (Array.isArray(res)) {
        this.setState({ allBundles: res, loading: false });
      } else {
        this.setState({ loading: false });
        AuthHelperAccessCheck(res);
      }
    }
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onStatusChange = (e) => {
    console.log("status", e);
    this.setState({ status: e });

    const _bundleInput = {
      division_id: localStorage.apiDivisionId,
      status: e.value,
    };
    this.getBundles(_bundleInput);
  };

  editButton = (_bundle) => {
    const EditButton = () => (
      <Route
        render={({ history }) => (
          <button
            type="button"
            className="btn btn-link btn-sm"
            title="Edit"
            onClick={() => {
              history.push({
                pathname: "/bundle",
                state: {
                  bundle_id: _bundle.autoid,
                },
              });
            }}
          >
            <i className="fa fa-pencil" aria-hidden="true" />
          </button>
        )}
      />
    );
    return <EditButton />;
  };

  bundleAdd = () => {
    const BundleAddButton = () => (
      <Route
        render={({ history }) => (
          <Fab
            color="primary"
            variant="extended"
            size="medium"
            onClick={() => {
              history.push({
                pathname: "/bundle",
              });
            }}
          >
            <AddIcon />
            Add
          </Fab>
        )}
      />
    );
    return <BundleAddButton />;
  };

  /**
   * to get badge based on status fetch from DB.
   */
  getBadge = (status) => {
    let _classes = "badge badge-";
    _classes += status === "Inactive" ? "danger" : "success";
    return _classes;
  };

  handleClickOpen = (product) => {
    const bundleItemInputs = {
      bundle_id: product.autoid,
    };
    this.getProductBundleItems(bundleItemInputs);

    this.setState({ open: true });
  };

  getProductBundleItems = async (bundleItemInputs) => {
    this.setState({ loading: true });
    try {
      const bundleItems = await api.post(
        "sc-bundle/get-bundle-by-id",
        bundleItemInputs
      );
      console.log("bundleItems", bundleItems);
      if (bundleItems.bundle && bundleItems.productSet) {
        this.setState({
          loading: false,
          bundleName: bundleItems.bundle.bundle_name,
          bundleItems: bundleItems.productSet,
        });
      } else {
        this.setState({ loading: false });
      }
    } catch (error) {
      this.setState({ loading: false });
      console.log("something went wrong!", error);
    }
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  render() {
    let rows = this.state.allBundles;

    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h3" style={{ fontWeight: "bold" }}>
            Product Bundles
          </Typography>
        </Grid>

        <Grid item xs={4}>
          <Select
            style={{
              backgroundColor: "blue",
            }}
            name="status"
            onChange={this.onStatusChange}
            value={this.state.status}
            options={statusOptions}
          />
        </Grid>
        <Grid item xs={8} style={{ textAlign: "right" }}>
          {this.bundleAdd()}
        </Grid>
        <Grid item xs={12}>
          <Paper>
            <TableContainer>
              <Table aria-labelledby="tableTitle" aria-label="enhanced table">
                <EnhancedTableHead
                  order={this.state.order}
                  orderBy={this.state.orderBy}
                  onRequestSort={this.handleRequestSort}
                  rowCount={rows.length}
                />
                {rows.length !== 0 ? (
                  <TableBody>
                    {stableSort(
                      rows,
                      getComparator(this.state.order, this.state.orderBy)
                    )
                      .slice(
                        this.state.page * this.state.rowsPerPage,
                        this.state.page * this.state.rowsPerPage +
                          this.state.rowsPerPage
                      )
                      .map((row, index) => {
                        return (
                          <Tooltip title="Click to show the items">
                            <TableRow
                              hover
                              tabIndex={-1}
                              key={index}
                              onClick={() => this.handleClickOpen(row)}
                            >
                              <TableCell>{row.bundle_name}</TableCell>
                              <TableCell>{row.original_price}</TableCell>
                              <TableCell>{row.price}</TableCell>
                              <TableCell>
                                <span className={this.getBadge(row.status)}>
                                  {row.status}
                                </span>
                              </TableCell>
                              <TableCell>
                                {row.status !== "Inactive"
                                  ? this.editButton(row)
                                  : null}
                              </TableCell>
                            </TableRow>
                          </Tooltip>
                        );
                      })}
                  </TableBody>
                ) : (
                  <Typography
                    style={{
                      textAlign: "center",
                      marginTop: "1em",
                    }}
                  >
                    No Records...............
                  </Typography>
                )}
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[50, 200, 500]}
              component="div"
              count={rows.length}
              rowsPerPage={this.state.rowsPerPage}
              page={this.state.page}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
            />
          </Paper>
        </Grid>

        <Dialog
          fullWidth={true}
          open={this.state.open}
          TransitionComponent={Transition}
          keepMounted
          onClose={this.handleClose}
        >
          <DialogTitle id="alert-dialog-slide-title">
            {this.state.bundleName}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {this.state.loading === true ? (
                <CircularProgress size={24} />
              ) : (
                <TableContainer border={1}>
                  <Table>
                    <TableHead style={{ backgroundColor: "white" }}>
                      <TableRow>
                        <TableCell style={{ color: "black" }}>Name</TableCell>
                        <TableCell style={{ color: "black" }}>Price</TableCell>
                        <TableCell style={{ color: "black" }}>
                          Quantity
                        </TableCell>
                        <TableCell style={{ color: "black" }}>Total</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state.bundleItems &&
                        this.state.bundleItems.map((row, index) => {
                          return (
                            <TableRow hover tabIndex={-1} key={index}>
                              <TableCell>{row.product_name}</TableCell>
                              <TableCell>{row.price}</TableCell>
                              <TableCell>{row.product_quantity}</TableCell>
                              <TableCell>
                                {(
                                  row.original_price * row.product_quantity
                                ).toFixed(2)}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    );
  }
}

export default ProductBundleList;
