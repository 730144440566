import React, { Component } from "react";
import { Route, Redirect } from "react-router-dom";
import jwt_decode from "jwt-decode";
import {
  Paper,
  Grid,
  Typography,
  TextField,
  Fab,
  Divider,
} from "@material-ui/core";
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from "react-router-dom";
import { SnackBar } from "../compon-controls/SnackBar";
import moment from "moment";

import { AuthHelperAccessCheck } from "../businessjs/AuthHelper";
import CmbSelect from "../compon-controls/CmbSelect";
import { api } from "../businessjs/API";

const typeOptions = [
  { value: 1, label: "Installation" },
  { value: 2, label: "Service" },
];

const statusOptions = [
  { value: 1, label: "Completed" },
  { value: 2, label: "Open" },
];

const chargeableOptions = [
  { value: 0, label: "No" },
  { value: 1, label: "Yes" },
];

const NavLink = React.forwardRef((props, ref) => (
  <RouterLink innerRef={ref} {...props} />
));

class EditJobCard extends Component {
  constructor(props) {
    super(props);
    this.redirect = 0;
    this.state = {
      jobCardDetails: this.props.location.state.jobcard,
      status: 0,
      ischargeable: 0,
      title: "",
      start_time: this.props.location.state.jobcard.start_time,
      end_time: this.props.location.state.jobcard.end_time,
      location_id: 0,
      details: "",
      location_name: "",
      locationList: [],
      toastItems: [],
      type: 0,
      remarks: "",
      visit: "",
      visitList: [],
      service_list: [],
      selected_service: null,
      jobCards: [],
      products: [],
      visits: [],
      ticketList: [],
      ticket_id: 0,
      redirect: 0,
      errorMessage: "",
      color: "#d50000",
      infoMessgae: false,
      navigation: false,
      subscriberList: undefined,
    };
  }

  componentDidMount = () => {
    let _jobCardDetails = this.props.location.state.jobcard;
    const _input = {
      subscriber_id: _jobCardDetails.subscriber_id,
    };
    this.getTicketLocation(_input);
    this.getVisitForCmb(_input);
    this.getServiceProducts(_input);
  };

  getTicketLocation = async (locationTicketInput) => {
    const res = await api.post(
      "job-card/get-ticket-location",
      locationTicketInput
    );
    if (res) {
      let locationArray = res.locationSet.map((opt) => ({
        label: opt.location_name,
        value: opt,
      }));
      // locationArray.splice(0, 0, {
      //   label: "Select",
      //   value: { autoid: 0 },
      // });

      let ticketArray = res.ticketSet.map((opt) => ({
        label: opt.subject,
        value: opt,
      }));

      // ticketArray.splice(0, 0, {
      //   label: "Select",
      //   value: { autoid: 0 },
      // });
      this.setState({
        locationList: locationArray,
        ticketList: ticketArray,
        title: this.state.jobCardDetails.title,
        start_time: this.state.jobCardDetails.start_time,
        end_time: this.state.jobCardDetails.end_time,
        location_id: this.getCurrentLocation(
          this.state.jobCardDetails.location_id,
          locationArray
        ),
        ticket_id: this.getCurrentTicket(
          this.state.jobCardDetails.ticket_id,
          ticketArray
        ),

        details: this.state.jobCardDetails.details,
        remarks: this.state.jobCardDetails.remarks,
        // status: this.state.jobCardDetails.jobCardStatus,
        status: statusOptions.filter(
          (status) => status.value === this.state.jobCardDetails.jobCardStatus
        )[0],
        // ischargeable: this.state.jobCardDetails.jobCardChargeable,
        ischargeable: chargeableOptions.filter(
          (chargeable) =>
            chargeable.value === this.state.jobCardDetails.jobCardChargeable
        )[0],
        type: typeOptions.filter(
          (type) => type.value === this.state.jobCardDetails.jobCardType
        )[0],
      });
    } else {
      AuthHelperAccessCheck(res);
    }
  };

  getCurrentTicket = (ticketId, ticketArray) => {
    const currentTicket = ticketArray.filter((ticket) => {
      return ticket.value.autoid === ticketId;
    })[0];
    return currentTicket;
  };

  getVisitForCmb = async (_input) => {
    const res = await api.post("sc-visit/get-cmb-visit", _input);
    if (res) {
      let visitArray = res.map((opt) => ({
        label: opt.name,
        value: opt,
      }));
      visitArray.splice(0, 0, {
        label: "Select",
        value: { autoid: 0 },
      });

      this.setState({
        visitList: visitArray,
        visit: this.getCurrentVisit(
          this.state.jobCardDetails.visit_id,
          visitArray
        ),
      });
    } else {
      AuthHelperAccessCheck(res);
    }
  };

  getServiceProducts = async (_input) => {
    const res = await api.post(
      "sc-product/get-serice-visit-product-list",
      _input
    );
    if (res) {
      let productArray = res.map((opt) => ({
        label: opt.product_name,
        value: opt,
      }));
      productArray.splice(0, 0, {
        label: "Select",
        value: { autoid: 0 },
      });

      this.setState({
        service_list: productArray,
        selected_service: this.getCurrentService(
          this.state.jobCardDetails.service_product_id,
          productArray
        ),
      });
    } else {
      AuthHelperAccessCheck(res);
    }
  };

  getCurrentVisit = (_visit_id, visitArray) => {
    const currentVisit = visitArray.filter((visit) => {
      return visit.value.autoid === _visit_id;
    })[0];
    return currentVisit;
  };

  getCurrentLocation = (id, locationList) => {
    if (locationList) {
      let filteredDataRows = locationList.filter((el) => {
        return el.value.autoid === id;
      })[0];
      return filteredDataRows;
    }
  };

  getCurrentService = (service_product_id, productArray) => {
    const currentService = productArray.filter((service) => {
      return service.value.autoid === service_product_id;
    })[0];
    return currentService;
  };

  onVisitChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    this.getLocationByVisit(e.target.value.value.location_id);
  };

  getLocationByVisit = (_location_id) => {
    const currentLocation = this.state.locationList.filter(
      (location) => location.value.autoid === _location_id
    );
    this.setState({ location_id: currentLocation[0] });
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onStartDateChange = (e) => {
    this.setState({ start_time: e._d });
  };

  onEndDateChange = (e) => {
    this.setState({ end_time: e._d });
  };

  cancelButton = () => {
    const CancelButton = () => (
      <Route
        render={({ history }) => (
          <button
            type="button"
            className="btn btn-warning btn-sm mr-1"
            onClick={() => {
              history.push({
                pathname: "/job-card",
              });
            }}
          >
            Cancel
          </button>
        )}
      />
    );
    return <CancelButton />;
  };

  onSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.apiAuthAccessKey;
    const decoded = jwt_decode(token);
    const decodedUser = decoded.userAccessKey[0];

    const _jobCardEditInput = {
      subscriber_id: this.state.jobCardDetails.subscriber_id,
      title: this.state.title,
      location_id: this.state.location_id.value.autoid,
      start_time: this.state.start_time,
      end_time: this.state.end_time,
      status: this.state.status.value,
      ischargeable: this.state.ischargeable.value,
      details: this.state.details,
      userType: decodedUser.role,
      jobCardId: this.state.jobCardDetails.autoid,
      remarks: this.state.remarks,
      type: this.state.type.value,
      visit_id: this.state.visit.value.autoid,
      service_product_id: this.state.selected_service.value.autoid,
      ticket_id: this.state.ticket_id.value.autoid,
    };

    // console.log("_jobCardEditInput", _jobCardEditInput);

    const res = await api.post("job-card/edit-job-card", _jobCardEditInput);
    if (res) {
      AuthHelperAccessCheck(res);
      if (res.status === "success") {
        setTimeout(() => {
          this.setState({ errorMessage: res.message, color: "#1b5e20" });
          this.redirect = 1;
        }, 1000);
      } else {
        this.setState({ errorMessage: res.message });
      }
    }
  };

  onCloseSnackbar = () => {
    this.setState({ errorMessage: "" });
  };

  render() {
    if (this.redirect === 1)
      return (
        <Redirect
          to={{
            pathname: "/job-card",
          }}
        />
      );
    return (
      <React.Fragment>
        <SnackBar
          status={this.state.errorMessage}
          onCloseError={this.onCloseSnackbar}
          color={this.state.color}
        />
        <form className="form" onSubmit={this.onSubmit}>
          <Paper elevation={4}>
            <Grid container spacing={3} style={{ padding: "2em" }}>
              <Grid item xs={12}>
                <Typography variant="h4" style={{ fontWeight: "bold" }}>
                  Edit Job Card
                </Typography>
              </Grid>

              <Grid
                container
                xs={4}
                sm={6}
                direction="row"
                style={{ paddingLeft: "1em", marginTop: "1em" }}
              >
                <Typography variant="h6" style={{ fontWeight: "bold" }}>
                  JobCard Id :
                </Typography>

                <Typography
                  variant="h6"
                  style={{
                    fontWeight: "bold",
                    color: "darkgray",
                  }}
                >
                  {" # " + this.state.jobCardDetails.autoid}
                </Typography>
              </Grid>

              <Grid
                container
                xs={8}
                sm={6}
                direction="row"
                style={{ marginTop: "1em" }}
              >
                <Typography variant="h6" style={{ fontWeight: "bold" }}>
                  Subscriber :
                </Typography>
                <Typography
                  variant="h6"
                  style={{
                    fontWeight: "bold",
                    color: "darkgray",
                  }}
                >
                  {" " + this.state.jobCardDetails.company_name}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                {" "}
                <Divider />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography
                  variant="h6"
                  style={{ textAlign: "left", fontWeight: "bold" }}
                >
                  Title
                </Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  name="title"
                  required
                  placeholder="Title"
                  value={this.state.title}
                  onChange={this.onChange}
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <Typography
                  variant="h6"
                  style={{ textAlign: "left", fontWeight: "bold" }}
                >
                  Ticket
                </Typography>
                <CmbSelect
                  name="ticket_id"
                  value={this.state.ticket_id}
                  onChange={this.onChange}
                  dataSource={this.state.ticketList}
                />
              </Grid>
              {/* <Grid item xs={12} sm={4}>
                <Typography
                  variant="h6"
                  style={{ textAlign: "left", fontWeight: "bold" }}
                >
                  Date
                </Typography>
                <TextField
                  type="date"
                  fullWidth
                  variant="outlined"
                  name="job_date"
                  required
                  value={moment(this.state.job_date).format("YYYY-MM-DD")}
                  onChange={(event) => this.onDateChange(event.target.value)}
                />
              </Grid> */}

              <Grid item xs={12} sm={4}>
                <Typography
                  variant="h6"
                  style={{ textAlign: "left", fontWeight: "bold" }}
                >
                  Start
                </Typography>
                <TextField
                  type="date"
                  fullWidth
                  variant="outlined"
                  name="start_time"
                  required
                  value={moment(this.state.start_time).format("YYYY-MM-DD")}
                  onChange={(event) =>
                    this.onStartDateChange(event.target.value)
                  }
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography
                  variant="h6"
                  style={{ textAlign: "left", fontWeight: "bold" }}
                >
                  End
                </Typography>
                <TextField
                  type="date"
                  fullWidth
                  variant="outlined"
                  name="end_time"
                  required
                  value={moment(this.state.end_time).format("YYYY-MM-DD")}
                  onChange={(event) => this.onEndDateChange(event.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography
                  variant="h6"
                  style={{ textAlign: "left", fontWeight: "bold" }}
                >
                  Type
                </Typography>
                <CmbSelect
                  name="type"
                  onChange={this.onChange}
                  value={this.state.type} // default 2=service
                  dataSource={typeOptions}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography
                  variant="h6"
                  style={{ textAlign: "left", fontWeight: "bold" }}
                >
                  Status
                </Typography>
                <CmbSelect
                  name="status"
                  onChange={this.onChange}
                  value={this.state.status}
                  dataSource={statusOptions}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography
                  variant="h6"
                  style={{ textAlign: "left", fontWeight: "bold" }}
                >
                  Service
                </Typography>
                <CmbSelect
                  name="selected_service"
                  value={this.state.selected_service}
                  onChange={this.onChange}
                  dataSource={this.state.service_list}
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <Typography
                  variant="h6"
                  style={{ textAlign: "left", fontWeight: "bold" }}
                >
                  Visit
                </Typography>
                <CmbSelect
                  name="visit"
                  value={this.state.visit}
                  onChange={this.onVisitChange}
                  dataSource={this.state.visitList}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography
                  variant="h6"
                  style={{ textAlign: "left", fontWeight: "bold" }}
                >
                  Location
                </Typography>
                <CmbSelect
                  name="location_id"
                  value={this.state.location_id}
                  onChange={this.onChange}
                  dataSource={this.state.locationList}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography
                  variant="h6"
                  style={{ textAlign: "left", fontWeight: "bold" }}
                >
                  Chargeable
                </Typography>
                <CmbSelect
                  name="ischargeable"
                  onChange={this.onChange}
                  value={this.state.ischargeable}
                  dataSource={chargeableOptions}
                />
              </Grid>
              <Grid item xs={12} sm={4} />
              <Grid item xs={12} sm={6}>
                <Typography
                  variant="h6"
                  style={{ textAlign: "left", fontWeight: "bold" }}
                >
                  Details
                </Typography>
                <TextField
                  fullWidth
                  multiline
                  rows={3}
                  rowsMax={10}
                  variant="outlined"
                  type="text"
                  name="details"
                  value={this.state.details}
                  onChange={this.onChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography
                  variant="h6"
                  style={{ textAlign: "left", fontWeight: "bold" }}
                >
                  Remarks
                </Typography>
                <TextField
                  fullWidth
                  multiline
                  rows={3}
                  rowsMax={10}
                  variant="outlined"
                  type="text"
                  value={this.state.remarks}
                  onChange={this.onChange}
                  name="remarks"
                />
              </Grid>

              <Grid item xs={6} style={{ textAlign: "right" }}>
                <Fab
                  variant="extended"
                  size="small"
                  color="primary"
                  type="reset"
                  component={NavLink}
                  to="/job-card"
                >
                  Cancel
                </Fab>
              </Grid>
              <Grid item xs={6} style={{ textAlign: "left" }}>
                <Fab
                  variant="extended"
                  size="small"
                  color="primary"
                  aria-label="add"
                  type="submit"
                >
                  Update
                </Fab>
              </Grid>
            </Grid>
          </Paper>
        </form>
      </React.Fragment>
    );
  }
}

export default EditJobCard;
