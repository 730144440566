import React, { useState, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { Paper, TextField, Tooltip, Typography } from "@material-ui/core";
import { useEffect } from "react";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

export const ProductTable = ({
  productRow,
  onEditTableRow,
  onDeleteRow,
  total,
  currency
}) => {
  const classes = useStyles();
  let tableTotal = 0;
  if (productRow.length > 0) {
    const totalArray = productRow.map((item) => {
      return parseFloat(item.total);
    });

    if (totalArray.length > 0) {
      const reducer = (accumulator, currentValue) => accumulator + currentValue;
      tableTotal = totalArray.reduce(reducer);
    }
  }

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="center">Quantity</TableCell>
            <TableCell align="center">Description</TableCell>
            <TableCell align="right">{`Unit Price (${currency})`}</TableCell>
            {/* <TableCell align="right">Discount(%)</TableCell> */}
            <TableCell align="right">{`Total Price (${currency})`}</TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {productRow.map((row) => (
            <TableRow key={row.productName}>
              <TableCell align="center">{row.quantity}</TableCell>
              <TableCell component="th" scope="row" align="center">
                {row.productName}
              </TableCell>
              <TableCell align="right">{row.price}</TableCell>
              {/* <TableCell align="right">{row.discount}</TableCell> */}
              <TableCell align="right">{row.total}</TableCell>
              <TableCell align="center">
                <Tooltip title="Edit">
                  <EditIcon
                    color="primary"
                    onClick={() => onEditTableRow(row.productName)}
                  />
                </Tooltip>
              </TableCell>
              <TableCell align="center">
                <Tooltip title="Delete">
                  <DeleteIcon
                    color="primary"
                    onClick={() => onDeleteRow(row.productName)}
                  />
                </Tooltip>
              </TableCell>
            </TableRow>
          ))}
          {productRow.length !== 0 ? (
            <TableRow>
              <TableCell rowSpan={3} />
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell align="right">
              <Typography style={{ fontWeight: "bold" }}>
                  Total : {tableTotal.toFixed(2)}
                </Typography>
              </TableCell>
              <TableCell ></TableCell>
              <TableCell>
                
              </TableCell>
            </TableRow>
          ) : (
            ""
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
