const dateFormat = require("dateformat");

export const AppHelper_DateToString = param1 => {
  return dateFormat(param1, "fullDate");
};

export const AppHelper_DateAndTimeToString = param1 => {
  return dateFormat(param1, "d mmm yyyy h:MM:ss TT");
};

export const AppHelper_ShortDateToString = param1 => {
  return dateFormat(param1, "dmmmyyyy");
};

export const AppHelper_MinToString = paramMin => {
  if (paramMin > 0) {
    paramMin = paramMin * 60;

    var fm = [
      Math.floor(paramMin / 60 / 60 / 24), // DAYS
      Math.floor(paramMin / 60 / 60) % 24, // HOURS
      Math.floor(paramMin / 60) % 60, // MINUTES
      paramMin % 60 // SECONDS
    ];
    return fm[1] + ":" + fm[2];
  } else {
    return "-";
  }
};

export const AppHelper_ShortTimeToString = param1 => {
  return dateFormat(param1, "h:MM:ssTT");
};


export const AppHelper_ShortTimeToString_Exact = param1 => {
  return dateFormat( param1, "h:MM:ssTT", true);
};

export const AppHelper_DateToDBString = param1 => {
  // return dateFormat(param1 );
  let isoTime = dateFormat(param1, "isoTime");
  let isoDate = dateFormat(param1, "isoDate");
  return isoDate + " " + isoTime;
};

export const AppHelper_ShowTostRes = (ToastsStore, type, message) => {
  if (type === "success") {
    ToastsStore.success(message, 5000, "bg-success");
  } else {
    ToastsStore.error(message, 5000);
  }
};

export const AuthHelperGerSubscriberBusinessCircleTypeText = param1 => {
  switch (param1) {
    case 1:
      return "Approved";
    case 2:
      return "Rejected";
    case 3:
      return "Removed";
    default:
      return "Pending";
  }
};

export const AuthHelperGerSubscriberExecutiveTypeText = param1 => {
  switch (param1) {
    case 1:
      return "Owner";
    case 2:
      return "Admin";
    default:
      return "User";
  }
};

export const AuthHelperGerSubscriberLocationTypeText = param1 => {
  switch (param1) {
    case 1:
      return "Shipping Address";
    case 2:
      return "Collection Point";
    default:
      return "Unknown";
  }
};

export const CommonStatusCodeText = param1 => {
  switch (param1) {
    case 0:
      return "Deleted";
    case 1:
      return "Active";
    case 2:
      return "Suspended";
    default:
      return "Unknown";
  }
};

export const GetDateDifference=(dt1, dt2)=>
{
  const diff = Math.abs(dt2.getTime() - dt1.getTime());
  const days = Math.ceil(diff / (1000 * 60 * 60 * 24)); 
  return days
}

export const GetDate = date => { 
  return dateFormat(date, "d mmmm yyyy");
};