import React, { useState, FunctionComponent, useEffect } from "react";
import {
  Paper,
  Button,
  Grid,
  Typography,
  TextField,
  Link,
  Fab,
  RadioGroup,
  FormControlLabel,
  Radio,
  Snackbar,
  Stepper,
  Step,
  StepLabel,
  Card,
  CircularProgress,
} from "@material-ui/core";

import { Formik, Form, ErrorMessage } from "formik";
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from "react-router-dom";
import { api } from "../../businessjs/API";
import { SnackBar } from "../Login/SnackBar";
import { Redirect } from "react-router-dom";
import * as yup from "yup";
import moment from "moment";

import { QuotationGeneral } from "./QuotationGeneral";
import { QuotationProduct } from "./QuotationProduct";
import { QuotationDetails } from "./QuotationDetails";

const createValidationSchema = yup.object().shape({
  phone: yup.string().matches(/(^[+]?[0-9]*$)/, {
    message: "Invalid phone number format",
  }),
});

const getSteps = () => {
  return ["General", "Select Product", "Shipping Details"];
};

function getStepContent(stepIndex, formValues, changeFormValues) {
  switch (stepIndex) {
    case 0:
      return (
        <QuotationGeneral
          formValues={formValues}
          changeFormValues={changeFormValues}
        />
      );
    case 1:
      return (
        <QuotationProduct
          formValues={formValues}
          changeFormValues={changeFormValues}
        />
      );
    case 2:
      return (
        <QuotationDetails
          formValues={formValues}
          changeFormValues={changeFormValues}
        />
      );
    default:
      return "Unknown stepIndex";
  }
}
export const AddQuotation = (props) => {
  const [activeStep, setActiveStep] = useState(0);

  const [error, setError] = useState(false);
  const [color, setColor] = useState("#d50000");
  const [infoMessgae, setInfoMessage] = useState(false);
  const [navigation, setNavigation] = useState(false);
  const [accountOptions, setAccountOptions] = useState([]);
  const [formValues, changeFormValues] = useState({
    quoteDate: moment().format(),
    contactList: undefined,
    contactId: undefined,
    allContact: undefined,
    supportMemberList: undefined,
    supportMemberId: undefined,
    accountList: undefined,
    accountId: undefined,
    loading: false,
    contactPerson: "",
    address: "",
    location: "",
    building: "",
    city: "",
    state: "",
    country: "",
    salesRepName: "",
    phone: "",
    zipCode: "",
    productItems: undefined,
    productItemsBackUp: undefined,
    selectedProduct: undefined,
    product: "",
    quantity: "",
    price: 0,
    discount: 0,
    total: 0,
    subTotal: "",
    status: "",
    totalDiscount: 0,
    totalPrice: 0,
    bundleProducts: [],
    gridProduct: [],
    shippingMethod: [
      { value: 1, label: "Free Delivery" },
      { value: 2, label: "Contact us to get a shipping quote" },
    ],
    statusOptions: [
      {
        value: 1,
        label: "Created",
      },
      { value: 0, label: "Cancelled" },
    ],
    currentShipping: undefined,
    status: {
      value: 1,
      label: "Created",
    },
    shippingTerms: "This is my shipping terms",
    paymentTerms:'Warranty Terms :\nFor term and conditions follow "Annexure A" document.',
    deliveryDate: moment(new Date()).format(),
    dueDate: moment(new Date()).add(7, "days").calendar(),
    expiryDate: moment(new Date()).format(),
  });
  const NavLink = React.forwardRef((props, ref) => (
    <RouterLink innerRef={ref} {...props} />
  ));
  const steps = getSteps();

  useEffect(() => {
    const loadAccounts = async () => {
      try {
        const accounts = await api.post("sc-account/get-all-for-cmb");
        if (accounts && accounts.accountSet) {
          let accountArray = accounts.accountSet.map((opt) => ({
            label: opt.name,
            value: opt,
          }));
          setAccountOptions(accountArray);

          changeFormValues((prev) => ({ ...prev, accountList: accountArray }));
        } else {
          changeFormValues((prev) => ({ ...prev, accountList: [] }));
        }
      } catch (e) {
        console.log(e);
      }
    };
    if (!formValues.accountList) {
      loadAccounts();
    }
  }, [formValues.accountList]);

  useEffect(() => {
    const loadQuatationDetails = async () => {
      try {
        const quotationId = props.match.params.id;
        const quotationDetails = await api.post(
          "sc-quotation/get-quotation-by-id",
          { quotationId }
        );
        if (quotationDetails !== undefined) {
          const accounts = await api.post("sc-account/get-all-for-cmb");

          let accountArray = accounts.accountSet.map((opt) => ({
            label: opt.name,
            value: opt,
          }));

          const contacts = await api.post("sc-contact/get-all-for-cmb");

          let contactArray = contacts.map((opt) => ({
            label: opt.first_name,
            value: opt,
          }));

          const supportMember = await api.post(
            "supportmember/get-all-supportmember"
          );
          let supportMemberArray = supportMember.map((opt) => ({
            label: opt.first_name,
            value: opt,
          }));

          const _inputProduct = {
            company_id: localStorage.apiCompanyId,
            division_id: localStorage.apiDivisionId,
          };

          const res = await api.post(
            "sc-product/get-all-cmb-product",
            _inputProduct
          );
          let _productArray = res.map((opt) => ({
            label: opt.product_name,
            value: opt,
          }));

          changeFormValues((prev) => ({
            ...prev,
            quoteDate: quotationDetails.quotation.quote_date,
            accountId: accountArray.find(
              (account) =>
                account.value.autoid === quotationDetails.quotation.account_id
            ),
            contactId: contactArray.find(
              (contact) =>
                contact.value.autoid === quotationDetails.quotation.contact_id
            ),
            supportMemberId: supportMemberArray.find(
              (supportMember) =>
                supportMember.value.autoid ===
                quotationDetails.quotation.support_member_id
            ),
            contactPerson: quotationDetails.quotation.contact_name,
            address: quotationDetails.quotation.address,
            location: quotationDetails.quotation.location,
            building: quotationDetails.quotation.building,
            city: quotationDetails.quotation.city,
            state: quotationDetails.quotation.state,
            country: quotationDetails.quotation.country,
            shippingMethod: [
              { value: 1, label: "Local pickup" },
              { value: 2, label: "Contact us to get a shipping quote" },
            ],
            statusOptions: [
              {
                value: 1,
                label: "Created",
              },
              { value: 0, label: "Cancelled" },
            ],
            phone: quotationDetails.quotation.phone,
            zipCode: quotationDetails.quotation.zipcode,
            selectedProduct: quotationDetails.quotation.quotationProductSet,
            subTotal:
              parseFloat(quotationDetails.quotation.total_amount) +
              parseFloat(quotationDetails.quotation.discount),
            totalDiscount: quotationDetails.quotation.discount,
            totalPrice: quotationDetails.quotation.total_amount,
            gridProduct: quotationDetails.quotationProductSet,
            currentShipping: formValues.shippingMethod.find(
              (method) =>
                method.label === quotationDetails.quotation.shipping_method
            ),
            shippingTerms: quotationDetails.quotation.shipping_terms,
            paymentTerms: quotationDetails.quotation.payment_terms,
            deliveryDate: quotationDetails.quotation.delivery_date,
            dueDate: quotationDetails.quotation.due_date,
            expiryDate: quotationDetails.quotation.expiry_date,
            status: formValues.statusOptions.find(
              (option) => option.value === quotationDetails.quotation.status
            ),
            bundleProducts: quotationDetails.bundleSet,
          }));
          if (quotationDetails.bundleSet.length !== 0) {
            const gridEditViewProduct = quotationDetails.quotationProductSet.map(
              (product) => ({
                autoid: product.autoid,
                bundle_id: product.bundle_id,
                discount: product.discount,
                division_id: product.division_id,
                price: product.price,
                product_name: product.product_name,
                quantity: product.quantity,
                total: product.total,
                status: product.status,
                bundle_items: quotationDetails.bundleSet.filter(
                  (c) => c.bundle_id === product.bundle_id
                ),
              })
            );
            changeFormValues((prev) => ({
              ...prev,
              gridProduct: gridEditViewProduct,
            }));
          } else {
            changeFormValues((prev) => ({
              ...prev,
              bundleProducts: quotationDetails.bundleSet,
            }));
          }
          getFilteredProducts(
            _productArray,
            quotationDetails.quotationProductSet
          );
        }
      } catch (e) {
        console.log(e);
      }
    };
    if (props.match.params.id !== "new") {
      loadQuatationDetails();
    }
  }, [props.match.params.id]);

  const getFilteredProducts = (productArray, product) => {
    let filtered = productArray.filter((item) =>
      product.every((product) => {
        return product.autoid !== item.value.autoid;
      })
    );
    changeFormValues((prev) => ({
      ...prev,
      productItems: filtered,
    }));
  };

  useEffect(() => {
    const getProduct = async (_companyId, _divisionId) => {
      const _inputProduct = {
        company_id: _companyId,
        division_id: _divisionId,
      };

      const res = await api.post(
        "sc-product/get-all-cmb-product",
        _inputProduct
      );
      if (res && res.length > 0) {
        let _productArray = res.map((opt) => ({
          label: opt.product_name,
          value: opt,
        }));
        changeFormValues((prev) => ({
          ...prev,
          productItems: _productArray,
          productItemsBackUp: _productArray,
        }));
      } else {
        changeFormValues((prev) => ({ ...prev, productItems: [] }));
      }
    };
    if (!formValues.productItems) {
      getProduct(localStorage.apiCompanyId, localStorage.apiDivisionId);
    }
  }, [formValues.productItems]);

  useEffect(() => {
    const getBundleItems = async () => {
      const _inputProduct = {
        company_id: localStorage.apiCompanyId,
        division_id: localStorage.apiDivisionId,
      };

      const bundleItems = await api.post(
        "sc-bundle/get-all-bundle-products",
        _inputProduct
      );
      if (bundleItems && Array.isArray(bundleItems)) {
        changeFormValues((prev) => ({ ...prev, bundleProducts: bundleItems }));
      }
    };
    getBundleItems();
  }, []);

  useEffect(() => {
    const getContacts = async () => {
      try {
        const contacts = await api.post("sc-contact/get-all-for-cmb");
        if (contacts && contacts.length) {
          let contactArray = contacts.map((opt) => ({
            label: opt.first_name,
            value: opt,
          }));
          // changeFormValues((prev) => ({ ...prev, contactId: contactArray[0] }));
          changeFormValues((prev) => ({
            ...prev,
            contactList: contactArray,
            allContact: contactArray,
          }));
        } else {
          changeFormValues((prev) => ({ ...prev, contactList: [] }));
        }
      } catch (e) {
        console.log(e);
      }
    };
    if (!formValues.contactList) {
      getContacts();
    }
  }, [formValues.contactList]);

  useEffect(() => {
    const getSupportMembers = async () => {
      try {
        const supportMember = await api.post(
          "supportmember/get-all-supportmember"
        );
        if (supportMember && supportMember.length) {
          let supportMemberArray = supportMember.map((opt) => ({
            label: opt.first_name,
            value: opt,
          }));

          let currentMember = supportMemberArray.filter(
            (member) =>
              member.value.autoid === parseInt(localStorage.apiAuthAccessId)
          );
          changeFormValues((prev) => ({
            ...prev,
            supportMemberId: currentMember[0],
          }));

          changeFormValues((prev) => ({
            ...prev,
            supportMemberList: supportMemberArray,
          }));
        } else {
          changeFormValues((prev) => ({ ...prev, supportMemberList: [] }));
        }
      } catch (e) {
        console.log(e);
      }
    };
    if (!formValues.supportMemberList) {
      getSupportMembers();
    }
  }, [formValues.supportMemberList]);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const onCloseSnackbar = () => {
    setInfoMessage("");
  };

  const quotationXML = () => {
    const selected_products = formValues.gridProduct;
    let xmlStr = "";
    if (selected_products && selected_products.length) {
      xmlStr += "<Quotations>";
      selected_products.forEach(function (value) {
        xmlStr += '<Quotation product_id="' + value.autoid + '" ';
        xmlStr += 'price="' + value.price + '" ';
        xmlStr += 'quantity="' + value.quantity + '" ';
        xmlStr += 'status="' + value.status + '" ';
        xmlStr += 'discount="' + value.discount + '" />';
      });
      xmlStr += "</Quotations>";
    }

    return xmlStr;
  };

  const addQuotation = async () => {
    if (formValues.accountId === undefined) {
      setColor("#0091ea");
      setInfoMessage("Please select account...");
    } else if (formValues.contactId === undefined) {
      setColor("#0091ea");
      setInfoMessage("Please select Contact...");
    } else if (formValues.supportMemberId === undefined) {
      setColor("#0091ea");
      setInfoMessage("Please select Sales Rep.Name...");
    } else if (formValues.gridProduct.length === 0) {
      setColor("#0091ea");
      setInfoMessage("Please select Product...");
    } else if (formValues.currentShipping === undefined) {
      setColor("#0091ea");
      setInfoMessage("Please select Shipping method...");
    } else {
      if (props.match.params.id === "new") {
        const addInput = {
          company_id: localStorage.apiCompanyId,
          account_id: formValues.accountId.value.autoid,
          quoteDate: moment(formValues.quoteDate).format("YYYY-MM-DD"),
          deliveryDate: moment(formValues.deliveryDate).format("YYYY-MM-DD"),
          quotationDueDate: moment(formValues.dueDate).format("YYYY-MM-DD"),
          address: formValues.address,
          state: formValues.state,
          city: formValues.city,
          country: formValues.country,
          location: formValues.location,
          building: formValues.building,
          zipcode: formValues.zipCode,
          phone: formValues.phone,
          contactId: formValues.contactId.value.autoid,
          supportMemberId: formValues.supportMemberId.value.autoid,
          shippingMethod: formValues.currentShipping.label,
          shippingTerms: formValues.shippingTerms,
          paymentTerms: formValues.paymentTerms,
          total: formValues.totalPrice,
          discount: formValues.totalDiscount,
          status: formValues.status.value,
          quotationsItems: quotationXML(),
        };
        const addResult = await api.post(
          "sc-quotation/add-quotation",
          addInput
        );

        if (addResult.status === "success") {
          setInfoMessage(addResult.message);
          setColor("#4caf50");
          setTimeout(() => {
            setNavigation(true);
          }, 2000);
        } else {
          setColor("#d50000");
          setNavigation(false);
        }
        setNavigation(false);
      } else {
        const updateInput = {
          account_id: formValues.accountId.value.autoid,
          quoteDate: moment(formValues.quoteDate).format("YYYY-MM-DD"),
          deliveryDate: moment(formValues.deliveryDate).format("YYYY-MM-DD"),
          quotationDueDate: moment(formValues.dueDate).format("YYYY-MM-DD"),
          contactId: formValues.contactId,
          supportMemberId: formValues.supportMemberId,
          address: formValues.address,
          state: formValues.state,
          city: formValues.city,
          country: formValues.country,
          location: formValues.location,
          building: formValues.building,
          zipcode: formValues.zipCode,
          phone: formValues.phone,
          contactId: formValues.contactId.value.autoid,
          supportMemberId: formValues.supportMemberId.value.autoid,
          shippingMethod: formValues.currentShipping.label,
          shippingTerms: formValues.shippingTerms,
          paymentTerms: formValues.paymentTerms,
          total: formValues.totalPrice,
          discount: formValues.totalDiscount,
          status: formValues.status.value,
          quotationsItems: quotationXML(),
          quotationId: props.match.params.id,
        };
       
        const updateResult = await api.post(
          "sc-quotation/update-quotation",
          updateInput
        );

        if (updateResult.status === "success") {
          setColor("#4caf50");
          setInfoMessage(updateResult.message);
          const quotationListInputs = {
            accountId: 0,
            startDate: moment(new Date()).subtract(7, "days").format(),
            endDate: moment(new Date()).format(),
          };
          const quotationList = await api.post(
            "sc-quotation/get-quotation-list",
            quotationListInputs
          );

          setTimeout(() => {
            setNavigation(true);
          }, 2000);
        } else {
          setColor("#d50000");
          setNavigation(false);
        }
        setNavigation(false);
      }
    }
  };

  if (navigation) {
    return <Redirect to="/quotation"></Redirect>;
  } else {
    return (
      <div>
        <SnackBar
          status={infoMessgae}
          onCloseError={onCloseSnackbar}
          color={color}
        />

        <Grid
          container
          spacing={3}
          style={{ padding: "1em", backgroundColor: "#fff" }}
        >
          <Grid item xs={12}>
            <Typography
              variant="h4"
              style={{
                fontWeight: "bold",
              }}
            >
              Add Quotation
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Stepper activeStep={activeStep} alternativeLabel>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Grid>
          <Grid item xs={12}>
            <Card style={{ borderRadius: 3, paddingBottom: 15 }} elevation={4}>
              <Grid item xs={12}>
                {activeStep === steps.length
                  ? null
                  : getStepContent(activeStep, formValues, changeFormValues)}
              </Grid>

              <Grid item xs={12} style={{ padding: "1em" }}>
                <Button disabled={activeStep === 0} onClick={handleBack}>
                  Back
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={formValues.loading}
                  onClick={
                    activeStep === steps.length - 1 ? addQuotation : handleNext
                  }
                >
                  {activeStep === steps.length - 1 ? "Finish" : "Next"}
                  {formValues.loading === true ? (
                    <CircularProgress size={20} />
                  ) : null}
                </Button>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </div>
    );
  }
};
