import React, { Component } from "react";
import { Route } from "react-router-dom";
import Pagination from "react-js-pagination";
import { Col, Row } from "reactstrap";
import { RiseLoader } from "react-spinners";
import { css } from "@emotion/core";
import { Link } from "react-router-dom";

import { AuthHelperAccessCheck } from "../businessjs/AuthHelper";
import { AppHelper_DateAndTimeToString } from "../businessjs/AppHelperFn";
import { api } from "../businessjs/API";

const loaderCSS = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

class Ticket extends Component {
  state = {
    activePage: undefined,
    itemsCountPerPage: 25,
    totalItemsCount: 0,
    allTickets: [],
    loading: false,
    subscriber_id: this.props.accountid,
    searchSubject: "",
    status: 1
  };

  componentDidMount() {
    this.getTicketCount();
  }

  getTicketCount = async () => {
    this.setState({ loading: true });
    const _ticketCountInputs = {
      subscriber_id: this.state.subscriber_id,
      searchSubject: this.state.searchSubject,
      status: this.state.status
    };

    const ticketCount = await api.post(
      "sc-ticket/get-ticket-count",
      _ticketCountInputs
    );

    if (ticketCount) {
      let _ticketCount = ticketCount[0].ticket_count;
      if (_ticketCount > 0) {
        this.setState({
          totalItemsCount: _ticketCount,
          activePage: 1,
          loading: false
        });
        this.getTicketList(1);
      } else {
        this.setState({
          allTickets: [],
          totalItemsCount: 0,
          activePage: 1,
          loading: false
        });
      }
    } else {
      this.setState({ loading: false });
      AuthHelperAccessCheck(ticketCount);
    }
  };

  getTicketList = async pageNumber => {
    let start = (pageNumber - 1) * this.state.itemsCountPerPage;

    const _ticketListInputs = {
      subscriber_id: this.state.subscriber_id,
      searchSubject: this.state.searchSubject,
      startLimit: start,
      endLimit: this.state.itemsCountPerPage,
      status: this.state.status
    };

    const ticketList = await api.post(
      "sc-ticket/get-ticket-list",
      _ticketListInputs
    );

    if (ticketList) {
      this.setState({ allTickets: ticketList });
      if (!this.state.activePage) {
        this.setState({
          totalItemsCount: ticketList.length,
          activePage: 1,
          loading: false
        });
      }
    } else {
      this.setState({ loading: false });
      AuthHelperAccessCheck(ticketList);
    }
  };

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handlePageChange = pageNumber => {
    this.setState({ activePage: pageNumber });
    this.getTicketList(pageNumber);
  };

  ticketAdd = () => {
    const TicketAddButton = () => (
      <Route
        render={({ history }) => (
          <button
            type="button"
            style={{ height: "fit-content" }}
            className="btn btn-primary btn-sm ml-2"
            onClick={() => {
              history.push({
                pathname: "/ticket-creation",
                state: {
                  from: "ticket",
                  subscriber_id: this.state.subscriber_id
                }
              });
            }}
          >
            Add
          </button>
        )}
      />
    );
    return <TicketAddButton />;
  };

  serviceScheduleButton = item => {
    const ServiceScheduleButton = () => (
      <Route
        render={({ history }) => (
          <button
            type="button"
            className="btn btn-link btn-sm"
            title="Service Schedule"
            onClick={() => {
              history.push({
                pathname: "/service-schedules",
                state: {
                  ticketId: item.autoId,
                  subscriber_id: item.subscriber_id,
                  from :'account'
                }
              });
            }}
          >
            <i className="fa fa-wrench" aria-hidden="true" />
          </button>
        )}
      />
    );
    return <ServiceScheduleButton />;
  };

  commentButton = item => {
    const CommentButton = () => (
      <Route
        render={({ history }) => (
          <button
            type="button"
            className="btn btn-link btn-sm"
            title="Comment"
            onClick={() => {
              history.push({
                pathname: "/view-ticket",
                state: {
                  ticketId: item.autoId,
                  subject: item.subject,
                  subscriber_id: item.subscriber_id,
                  status: item.statusType,
                  from:'account'
                }
              });
            }}
          >
            <i className="fa fa-commenting" aria-hidden="true" />
          </button>
        )}
      />
    );
    return <CommentButton />;
  };

  /**
   * to get badge based on priority fetch from DB.
   */
  getBadge = priority => {
    let _classes = "badge badge-";
    _classes +=
      priority === "High"
        ? "danger"
        : priority === "Medium"
        ? "success"
        : "primary";
    return _classes;
  };

  render() {
    let _showTickets = this.state.allTickets;

    return (
      <React.Fragment>
        <Row
          style={{
            margin: "0"
          }}
        >
          <Col
            style={{
              flexDirection: "column",
              display: "flex"
            }}
          >
            <div className="container mt-4">
              <div className="card">
                <div className="card-header">
                  <Row>
                    <Col xl={3} lg={3} md={4} sm={3} xs={6}>
                      <input
                        type="text"
                        name="searchSubject"
                        placeholder="Subject"
                        onChange={this.onChange}
                        value={this.state.searchSubject}
                        style={{
                          paddingLeft: "2%",
                          width: "100%"
                        }}
                      />
                    </Col>
                    <Col xl={2} lg={2} md={3} sm={3} xs={6}>
                      <select
                        name="status"
                        onChange={this.onChange}
                        value={this.state.status}
                        required
                        style={{
                          paddingBottom: "2%",
                          width: "100%"
                        }}
                      >
                        <option value="0">All</option>
                        <option value="1">Open</option>
                        <option value="2">Assigned</option>
                        <option value="3">Answered</option>
                        <option value="4">Pending</option>
                        <option value="50">Closed</option>
                      </select>
                    </Col>
                    <Col
                      style={{
                        display: "flex"
                      }}
                    >
                      <button
                        type="button"
                        className="btn btn-primary btn-sm"
                        onClick={this.getTicketCount}
                        style={{ height: "fit-content" }}
                      >
                        Search
                      </button>
                      {this.ticketAdd()}

                      <div className="sweet-loading">
                        <RiseLoader
                          css={loaderCSS}
                          sizeUnit={"px"}
                          size={10}
                          color={"#123abc"}
                          loading={this.state.loading}
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
            <Row>
              <Col>
                <div className="container mt-2">
                  <div className="card">
                    <div className="table-responsive">
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th>Subject</th>
                            <th>Ticket Date</th>
                            <th>Status</th>
                            <th>Priority</th>
                            <th>Last Update</th>
                            <th>Account</th>
                            <th>CreatedBy</th>
                          </tr>
                        </thead>
                        <tbody>
                          {_showTickets.map((item, i) => (
                            <React.Fragment key={i}>
                              <tr>
                                <td>
                                  <Link
                                    to={{
                                      pathname: "/view-ticket",
                                      state: {
                                        subscriber_id: item.subscriber_id,
                                        ticketId: item.autoId,
                                        subject: item.subject,
                                        from :'account',
                                        status:item.statusType
                                      }
                                    }}
                                  >
                                    #{item.autoId + " " + item.subject}
                                  </Link>
                                </td>
                                <td>
                                  {AppHelper_DateAndTimeToString(
                                    item.ticket_date
                                  )}
                                </td>
                                <td>{item.status}</td>
                                <td>
                                  <span
                                    className={this.getBadge(item.priority)}
                                  >
                                    {item.priority}
                                  </span>
                                </td>
                                <td>
                                  {AppHelper_DateAndTimeToString(
                                    item.update_time
                                  )}
                                </td>
                                <td>{item.company_name}</td>
                                <td>
                                  {item.first_name
                                    ? item.first_name
                                    : "" + ", " + item.last_name
                                    ? item.last_name
                                    : ""}
                                </td>

                                <td>
                                  {this.commentButton(item)}

                                  {item.status !== "Closed"
                                    ? this.serviceScheduleButton(item)
                                    : null}
                                </td>
                              </tr>
                            </React.Fragment>
                          ))}
                        </tbody>
                      </table>
                      <center>
                        {_showTickets.length ? "" : "Currently No Tickets"}
                      </center>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="container">
                  <Pagination
                    activePage={this.state.activePage}
                    itemsCountPerPage={this.state.itemsCountPerPage}
                    totalItemsCount={this.state.totalItemsCount}
                    pageRangeDisplayed={10}
                    onChange={this.handlePageChange}
                  />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default Ticket;
