import React from "react";
import { Col, Row } from "reactstrap";
import { Route } from "react-router-dom";

import ToastAlert from "./ToastAlert";
import Navbar from "../compon-styled/SupportCenterNavBar";
import SubscriberSelect from "../compon-controls/SubscriberSelect";
import VehicleTypeSelect from "../compon-controls/VehicleTypeSelect";
import { api } from "../businessjs/API";

class SubscriptionWithVehicle extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      subscriber_id: 0,
      title: "Subscription",
      type: 1,
      device_id: "",
      subscription_code: "",
      re_newal_interval: 365,

      toastItems: [],
      subscribers: [],

      vtitle: "",
      plate_number: "",
      fleet_number: "",
      manufacture_name: "",
      model: "",
      year: "",
      fuel_type: "Diesel",
      engine: "",
      tyre: "",
      vehicleTypes: [],
      vehicle_type: {
        label: "Car",
        value: { autoid: 1, title: "Car", imagepath: "Car" },
      },
      sim: "",
      sim_provider: "",
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount = async () => {
    const types = await api.post("vehicle/getvehicletypes");
    if (types) {
      if (Array.isArray(types)) {
        let dbArray = types.map((opt) => ({
          label: opt.title,
          value: opt,
        }));
        this.setState({ vehicleTypes: dbArray });
      }
    }
  };

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  onSubmit = async (e) => {
    e.preventDefault();

    const regFormInputes = {
      subscriber_id: this.state.subscriber_id.value.autoid,
      title: this.state.title,
      type: this.state.type,
      device_id: this.state.device_id,
      subscription_code: this.state.subscription_code,
      re_newal_interval: this.state.re_newal_interval,

      vtitle: this.state.vtitle,
      plate_number: this.state.plate_number,
      fleet_number: this.state.fleet_number,
      manufacture_name: this.state.manufacture_name,
      model: this.state.model,
      year: this.state.year,
      fuel_type: this.state.fuel_type,
      engine: this.state.engine,
      tyre: this.state.tyre,
      vehicle_type: this.state.vehicle_type.value.autoid,
      sim: this.state.sim,
      sim_provider: this.state.sim_provider,
    };

    const vehicleAdd = await api.post(
      "subscription/newSubWithVehicle",
      regFormInputes
    );
    if (vehicleAdd) {
      this.onToastItemAdd(vehicleAdd.status, vehicleAdd.message);
      if (vehicleAdd.status === "success") {
        this.setState({
          title: "Subscription",
          type: 1,
          device_id: "",
          subscription_code: "",
          re_newal_interval: 365,
          vtitle: "",
          plate_number: "",
          fleet_number: "",
          manufacture_name: "",
          model: "",
          year: "",
          fuel_type: "Diesel",
          engine: "",
          tyre: "",
          vehicle_type: {
            label: "Car",
            value: { autoid: 1, title: "Car", imagepath: "Car" },
          },
        });
      }
    }
  };

  handleToastItemClose = (itemObject) => {
    const toastItems = this.state.toastItems.filter(
      (c) => c.id !== itemObject.id
    );
    this.setState({ toastItems });
  };

  onToastItemAdd(type, message) {
    let newid = this.state.toastItems.length + 1;
    if (
      this.state.toastItems.filter(function (e) {
        return e.id === newid;
      }).length > 0
    ) {
      newid = this.state.toastItems.length + Math.floor(Math.random() * 100);
    }

    let newToastItems = this.state.toastItems;
    let itemToast = {
      id: newid,
      type: type === "success" ? "success" : "danger",
      message: message,
    };

    newToastItems.push(itemToast);
    this.setState({ toastItems: newToastItems });

    setTimeout(() => {
      this.handleToastItemClose(itemToast);
    }, 5000);
  }

  cancelButton = () => {
    const ButtonCancel = () => (
      <Route
        render={({ history }) => (
          <button
            type="reset"
            className="btn btn-warning mr-1"
            onClick={() => {
              this.props.history.push(`/vehicle-list`);
            }}
          >
            Cancel
          </button>
        )}
      />
    );
    return <ButtonCancel />;
  };

  render() {
    return (
      <React.Fragment>
        {/* <Navbar /> */}
        <div className="container mt-4 mb-2">
          <Row
            style={{
              margin: "0",
            }}
          >
            <Col
              style={{
                flexDirection: "column",
                display: "flex",
              }}
            >
              <Row>
                <ToastAlert
                  toastItems={this.state.toastItems}
                  onItemClose={this.handleToastItemClose}
                />
                <Col>
                  <div className="card">
                    <div className="card-header">
                      <Row>
                        <Col>
                          <h4 className="card-title">
                            {" "}
                            Add Vehicle With Subscription{" "}
                          </h4>
                        </Col>
                      </Row>
                    </div>

                    <div className="card-content collpase show">
                      <div className="card-body">
                        <form className="form" onSubmit={this.onSubmit}>
                          <div className="form-body">
                            <Row>
                              <Col xl={6} lg={6} md={12}>
                                <Row>
                                  <Col>
                                    <label>Subscriber</label>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col>
                                    <SubscriberSelect
                                      name="subscriber_id"
                                      value={this.state.subscriber_id}
                                      onChange={this.onChange}
                                    />
                                  </Col>
                                </Row>
                              </Col>
                              <Col xl={3} lg={3} md={6}>
                                <Row>
                                  <Col>
                                    <label>Vehicle Title</label>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="vtitle"
                                      value={this.state.vtitle}
                                      onChange={this.onChange}
                                      required
                                    />
                                  </Col>
                                </Row>
                              </Col>
                              <Col xl={3} lg={3} md={6}>
                                <Row>
                                  <Col>
                                    <label>Type</label>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col>
                                    <VehicleTypeSelect
                                      name="vehicle_type"
                                      value={this.state.vehicle_type}
                                      onChange={this.onChange}
                                      dataSource={this.state.vehicleTypes}
                                    />
                                  </Col>
                                </Row>
                              </Col>
                            </Row>

                            <Row className="mt-3">
                              <Col xl={6} lg={6} md={12}>
                                <Row>
                                  <Col>
                                    <label>Title</label>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="title"
                                      value={this.state.title}
                                      onChange={this.onChange}
                                      required
                                    />
                                  </Col>
                                </Row>
                              </Col>
                              <Col xl={3} lg={3} md={6}>
                                <Row>
                                  <Col>
                                    <label>Plate Number</label>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="plate_number"
                                      value={this.state.plate_number}
                                      onChange={this.onChange}
                                      required
                                    />
                                  </Col>
                                </Row>
                              </Col>
                              <Col xl={3} lg={3} md={6}>
                                <Row>
                                  <Col>
                                    <label>Fleet Number</label>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="fleet_number"
                                      value={this.state.fleet_number}
                                      onChange={this.onChange}
                                    />
                                  </Col>
                                </Row>
                              </Col>
                            </Row>

                            <Row className="mt-3">
                              <Col xl={6} lg={6} md={12}>
                                <Row>
                                  <Col>
                                    <label>Device Id</label>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="device_id"
                                      value={this.state.device_id}
                                      onChange={this.onChange}
                                      required
                                    />
                                  </Col>
                                </Row>
                              </Col>
                              <Col xl={2} lg={2} md={4}>
                                <Row>
                                  <Col>
                                    <label>Manufacture Name</label>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="manufacture_name"
                                      value={this.state.manufacture_name}
                                      onChange={this.onChange}
                                    />
                                  </Col>
                                </Row>
                              </Col>
                              <Col xl={2} lg={2} md={4}>
                                <Row>
                                  <Col>
                                    <label>Model</label>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="model"
                                      value={this.state.model}
                                      onChange={this.onChange}
                                    />
                                  </Col>
                                </Row>
                              </Col>
                              <Col xl={2} lg={2} md={4}>
                                <Row>
                                  <Col>
                                    <label>Year</label>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="year"
                                      value={this.state.year}
                                      onChange={this.onChange}
                                    />
                                  </Col>
                                </Row>
                              </Col>
                            </Row>

                            <Row className="mt-3">
                              <Col xl={6} lg={6} md={12}>
                                <Row>
                                  <Col>
                                    <label>Type</label>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col>
                                    <select
                                      className="form-control"
                                      name="type"
                                      onChange={this.onChange}
                                      value={this.state.type}
                                    >
                                      <option value=""> Select </option>
                                      <option value="1">Tracking</option>
                                      <option value="2">Other</option>
                                    </select>
                                  </Col>
                                </Row>
                              </Col>
                              <Col xl={2} lg={2} md={4}>
                                <Row>
                                  <Col>
                                    <label>Fuel</label>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col>
                                    <select
                                      className="form-control"
                                      name="fuel_type"
                                      onChange={this.onChange}
                                      value={this.state.fuel_type}
                                    >
                                      <option value=""> Select </option>
                                      <option value="Petrol">Petrol</option>
                                      <option value="Diesel">Diesel</option>
                                      <option value="Gas">Gas</option>
                                      <option value="Other">Other</option>
                                    </select>
                                  </Col>
                                </Row>
                              </Col>
                              <Col xl={2} lg={2} md={4}>
                                <Row>
                                  <Col>
                                    <label>Engine</label>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="engine"
                                      value={this.state.engine}
                                      onChange={this.onChange}
                                    />
                                  </Col>
                                </Row>
                              </Col>
                              <Col xl={2} lg={2} md={4}>
                                <Row>
                                  <Col>
                                    <label>Tyre</label>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="tyre"
                                      value={this.state.tyre}
                                      onChange={this.onChange}
                                    />
                                  </Col>
                                </Row>
                              </Col>
                            </Row>

                            <Row className="mt-3">
                              <Col xl={6} lg={6} md={12}>
                                <Row>
                                  <Col>
                                    <label>Re-Newal Interval</label>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col>
                                    <select
                                      className="form-control"
                                      name="re_newal_interval"
                                      onChange={this.onChange}
                                      value={this.state.re_newal_interval}
                                    >
                                      <option value="0"> Select </option>
                                      <option value="30">1 Month</option>
                                      <option value="90">3 Month</option>
                                      <option value="365">1 Year</option>
                                      <option value="730">2 Year</option>
                                    </select>
                                  </Col>
                                </Row>
                              </Col>
                              <Col xl={3} lg={3} md={6}>
                                <Row>
                                  <Col>
                                    <label>Sim</label>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="sim"
                                      value={this.state.sim}
                                      onChange={this.onChange}
                                    />
                                  </Col>
                                </Row>
                              </Col>
                              <Col xl={3} lg={3} md={6}>
                                <Row>
                                  <Col>
                                    <label>Sim Provider</label>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col>
                                    <select
                                      className="form-control"
                                      name="sim_provider"
                                      onChange={this.onChange}
                                      value={this.state.sim_provider}
                                    >
                                      <option value=""> Select </option>
                                      <option value="Ooredoo">Ooredoo</option>
                                      <option value="Vodafone">Vodafone</option>
                                    </select>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>

                            <Row className="mt-3">
                              <Col>
                                {/* <button
                                  type="reset"
                                  className="btn btn-warning btn-sm mr-1"
                                >
                                  <i className="ft-x" /> Cancel
                                </button> */}
                                {this.cancelButton()}
                                <button
                                  type="submit"
                                  className="btn btn-primary btn-sm"
                                >
                                  <i className="fa fa-check-square-o" /> Submit
                                </button>
                              </Col>
                            </Row>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </React.Fragment>
    );
  }
}

export default SubscriptionWithVehicle;
