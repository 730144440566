import React, { Component } from "react";
import { Col, Row } from "reactstrap";
import { RiseLoader } from "react-spinners";
import { css } from "@emotion/core";

import NavBar from "../compon-styled/SupportCenterNavBar";
import { api } from "../businessjs/API";
const loaderCSS = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

class BundleView extends Component {
  state = {
    productBundleItems: [],
    bundleName: "",
    loading: false
  };

  componentDidMount = () => {
    const bundleItemInputs = {
      productId: this.props.location.state.productId,
      bundleId: this.props.location.state.bundle_id
    };
    this.getProductBundleItems(bundleItemInputs);
  };

  getProductBundleItems = async bundleItemInputs => {
    this.setState({ loading: true });
    try {
      const bundleItems = await api.post(
        "sc-bundle/get-product-bundle-items",
        bundleItemInputs
      );
      if (bundleItems.length != 0) {
        this.setState({
          productBundleItems: bundleItems,
          bundleName: bundleItems[0].bundle_name,
          loading: false
        });
      } else {
        this.setState({ loading: false });
      }
    } catch (error) {
      console.log("something went wrong!", error);
    }
  };

  render() {
    let _showProduct = this.state.productBundleItems;
    return (
      <React.Fragment>
        {/* <NavBar /> */}

        <div className="container mt-4">
          <Row
            style={{
              margin: "0"
            }}
          >
            <Col
              xl={{ size: 10, offset: 1 }}
              style={{
                flexDirection: "column",
                display: "flex"
              }}
            >
              <div className="card">
                <div className="card-header">
                  <Row>
                    <Col xl={10} lg={10} md={9} sm={8} xs={7}>
                      <h4>{this.state.bundleName}</h4>
                    </Col>
                    <Col
                      style={{ display: "flex" }}
                      xl={2}
                      lg={2}
                      md={3}
                      sm={4}
                      xs={5}
                    >
                      <div className="sweet-loading ml-2">
                        <RiseLoader
                          css={loaderCSS}
                          sizeUnit={"px"}
                          size={10}
                          color={"#123abc"}
                          loading={this.state.loading}
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
              <Row className="mt-2">
                <Col>
                  <div className="card">
                    <div className="table-responsive">
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th>Product Items</th>
                            <th>Price(Qr)</th>
                            <th>Original Price(Qr)</th>
                            <th>Quantity</th>
                          </tr>
                        </thead>
                        <tbody>
                          {_showProduct.map((_product, i) => (
                            <React.Fragment key={i}>
                              <tr>
                                <td>{_product.product_name}</td>

                                <td>{_product.price}</td>
                                <td>{_product.original_price}</td>
                                <td>{_product.product_quantity}</td>
                              </tr>
                            </React.Fragment>
                          ))}
                        </tbody>
                      </table>
                      <center>
                        {_showProduct.length ? "" : "Currently No Bundles"}
                      </center>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </React.Fragment>
    );
  }
}

export default BundleView;
