import React, { Component } from "react";
import Pagination from "react-js-pagination";
import { RiseLoader } from "react-spinners";
import { css } from "@emotion/core";
import { Route, Link } from "react-router-dom";
import { Col, Row } from "reactstrap";

import { AuthHelperAccessCheck } from "../businessjs/AuthHelper";
import { AppHelper_DateAndTimeToString } from "../businessjs/AppHelperFn";
import { api } from "../businessjs/API";

const loaderCSS = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

class Invoices extends Component {
  state = {
    subscriber_id: this.props.accountid,
    activePage: undefined,
    itemsCountPerPage: 25,
    totalItemsCount: 0,
    searchInvoiceNo: "",
    loading: false,
    allInvoice: [],
    toastItems: []
  };

  componentDidMount = () => {
    this.getInvoiceCount();
  };

  getInvoiceCount = async () => {
    this.setState({ loading: true });
    const _productCountInput = {
      subscriber_id: this.state.subscriber_id,
      invoice_no: this.state.searchInvoiceNo
    };

    const invoiceCount = await api.post(
      "sc-invoice/get-invoice-count",
      _productCountInput
    );
    if (invoiceCount && invoiceCount.length) {
      let _invoiceCount = invoiceCount[0].invoice_count;
      if (_invoiceCount > 0) {
        this.setState({ totalItemsCount: _invoiceCount, activePage: 1 });
        this.getInvoiceList(1);
      } else {
        this.setState({
          allInvoice: [],
          totalItemsCount: 0,
          activePage: 1,
          loading: false
        });
      }
    } else {
      this.setState({ loading: false });
      AuthHelperAccessCheck(invoiceCount);
    }
  };

  getInvoiceList = async pageNumber => {
    let start = (pageNumber - 1) * this.state.itemsCountPerPage;
    const _invoiceListInputs = {
      startLimit: start,
      endLimit: this.state.itemsCountPerPage,
      subscriber_id: this.state.subscriber_id,
      invoice_no: this.state.searchInvoiceNo
    };

    const invoiceList = await api.post(
      "sc-invoice/get-invoice-list",
      _invoiceListInputs
    );

    if (invoiceList) {
      this.setState({ allInvoice: invoiceList, loading: false });
      if (!this.state.activePage) {
        this.setState({ totalItemsCount: invoiceList.length, activePage: 1 });
      }
    } else {
      this.setState({ loading: false });
      AuthHelperAccessCheck(invoiceList);
    }
  };

  handlePageChange = pageNumber => {
    this.setState({ activePage: pageNumber });
    this.getInvoiceList(pageNumber);
  };

  invoiceAdd = () => {
    const InvoiceAddButton = () => (
      <Route
        render={({ history }) => (
          <button
            type="button"
            title="Add"
            className="btn btn-primary ml-2"
            onClick={() => {
              history.push({
                pathname: "/invoice",
                state: {
                  from: "invoice",
                  subscriber_id: this.state.subscriber_id
                }
              });
            }}
          >
            Add
          </button>
        )}
      />
    );
    return <InvoiceAddButton />;
  };

  editButton = invoice => {
    const EditButton = () => (
      <Route
        render={({ history }) => (
          <button
            type="button"
            className="btn btn-link btn-sm"
            title="Edit"
            onClick={() => {
              history.push({
                pathname: "/invoice",
                state: {
                  invoice_id: invoice.autoid,
                  invoice_date: invoice.invoice_date,
                  invoice_due_date: invoice.invoice_due_date
                }
              });
            }}
          >
            <i className="fa fa-pencil" aria-hidden="true" />
          </button>
        )}
      />
    );
    return <EditButton />;
  };

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onToastItemAdd(type, message) {
    let newid = this.state.toastItems.length + 1;
    if (
      this.state.toastItems.filter(function(e) {
        return e.id === newid;
      }).length > 0
    ) {
      newid = this.state.toastItems.length + Math.floor(Math.random() * 100);
    }

    let newToastItems = this.state.toastItems;
    let itemToast = {
      id: newid,
      type: type === "success" ? "success" : "danger",
      message: message
    };

    newToastItems.push(itemToast);
    this.setState({ toastItems: newToastItems });

    setTimeout(() => {
      this.handleToastItemClose(itemToast);
    }, 2000);
  }

  handleToastItemClose = itemObject => {
    const toastItems = this.state.toastItems.filter(
      c => c.id !== itemObject.id
    );

    this.setState({ toastItems });
  };

  payment = (invoiceId, accountId, grandTotal) => {
    const PaymentButton = () => (
      <Route
        render={({ history }) => (
          <button
            type="button"
            className="btn btn-primary btn-sm"
            title="Payment"
            onClick={() => {
              history.push({
                pathname: "/invoice-payment",
                state: {
                  invoiceId: invoiceId,
                  accountId: accountId,
                  grandTotal: grandTotal,
                  from: "account"
                }
              });
            }}
          >
            Payment
          </button>
        )}
      />
    );
    return <PaymentButton />;
  };

  render() {
    let _showInvoice = this.state.allInvoice;
    return (
      <React.Fragment>
        <div className="container mt-4">
          <div className="card">
            <div className="card-header">
              <Row>
                <Col xl={5} lg={5} md={5} sm={6} xs={12}>
                  <input
                    type="text"
                    name="searchInvoiceNo"
                    placeholder=" Invoice No."
                    onChange={this.onChange}
                    value={this.state.searchInvoiceNo}
                    style={{ height: "35px", width: "100%" }}
                  />
                </Col>
                <Col style={{ display: "flex" }}>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={this.getInvoiceCount}
                  >
                    Search
                  </button>
                  {this.invoiceAdd()}
                  <div className="sweet-loading">
                    <RiseLoader
                      css={loaderCSS}
                      sizeUnit={"px"}
                      size={10}
                      color={"#123abc"}
                      loading={this.state.loading}
                    />
                  </div>
                </Col>
              </Row>
            </div>
          </div>
          <Row className="mt-2">
            <Col>
              <div className="card">
                <div className="table-responsive">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th>Inv No #</th>
                        <th>Amt.(Qr)</th>
                        <th>Dis.(Qr)</th>
                        <th>Total(Qr)</th>
                        <th>Date</th>
                        <th>Due</th>
                        <th>Status</th>
                        <th>Type</th>
                      </tr>
                    </thead>
                    <tbody>
                      {_showInvoice.map((_invoice, i) => (
                        <React.Fragment key={i}>
                          <tr>
                            <td>{"# " + _invoice.autoid}</td>
                            <td>{_invoice.total_amount}</td>
                            <td>{_invoice.discount}</td>
                            <td>
                              {(
                                Number(_invoice.total_amount) -
                                Number(_invoice.discount)
                              ).toFixed(2)}
                            </td>
                            <td>
                              {AppHelper_DateAndTimeToString(
                                _invoice.invoice_date
                              )}
                            </td>
                            <td>
                              {AppHelper_DateAndTimeToString(
                                _invoice.invoice_due_date
                              )}
                            </td>
                            <td>{_invoice.status}</td>
                            <td>{_invoice.invoice_type}</td>

                            {_invoice.statusValue != 4 ? (
                              <td>
                                {_invoice.invoiceType === 1 ? (
                                  <Link
                                    to={{
                                      pathname:
                                        "/invoice-bill?id=" +
                                        _invoice.autoid +
                                        "&from=invoice"
                                    }}
                                    target="_blank"
                                  >
                                    View
                                  </Link>
                                ) : (
                                  <Link
                                    to={{
                                      pathname:
                                        "/job-card-invoice-bill?id=" +
                                        _invoice.autoid +
                                        "&sub_id=" +
                                        _invoice.subscriber_id
                                    }}
                                    target="_blank"
                                  >
                                    View
                                  </Link>
                                )}
                              </td>
                            ) : (
                              <td />
                            )}

                            {_invoice.statusValue == 1 ? (
                              <td>
                                {this.payment(
                                  _invoice.autoid,
                                  _invoice.subscriber_id,
                                  Number(_invoice.total_amount) -
                                    Number(_invoice.discount)
                                )}
                              </td>
                            ) : (
                              <td />
                            )}
                          </tr>
                        </React.Fragment>
                      ))}
                    </tbody>
                  </table>
                  <center>
                    {_showInvoice.length ? "" : "Currently No Invoices"}
                  </center>
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col>
              <Pagination
                activePage={this.state.activePage}
                itemsCountPerPage={this.state.itemsCountPerPage}
                totalItemsCount={this.state.totalItemsCount}
                pageRangeDisplayed={10}
                onChange={this.handlePageChange}
              />
            </Col>
          </Row>
        </div>
      </React.Fragment>
    );
  }
}

export default Invoices;
