import React, { useEffect, useState } from "react";
import {
  Grid,
  Button,
  Typography,
  Paper,
  Card,
  Divider,
  TextField,
  InputLabel,
  FormControl,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Box,
  Collapse,
  Tooltip,
} from "@material-ui/core";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
    borderBottom: "unset",
  },
});

const Row = (props) => {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell component="th" scope="row">
          {row.quantity}
        </TableCell>
        <TableCell>{row.product_name}</TableCell>

        <TableCell align="right"> {parseFloat(row.price).toFixed(2)}</TableCell>
        <TableCell align="right">{parseFloat(row.total).toFixed(2)}</TableCell>
        <TableCell align="center">
          <Tooltip title="Edit">
            <IconButton
              color="primary"
              aria-label="edit"
              component="span"
              size="small"
              onClick={() => {
                props.edit(row);
              }}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
        </TableCell>
        <TableCell>
          <Tooltip title="Delete">
            <IconButton
              color="primary"
              aria-label="delete"
              component="span"
              size="small"
              onClick={() => {
                props.delete(row);
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>
      {row.bundle_items && row.bundle_items.length
        ? row.bundle_items.map((_item, j) => (
            <TableRow
              key={j}
              style={{
                width: "100%",
                fontSize: "13px",
                fontStyle: "italic",
              }}
            >
              <TableCell></TableCell>
              <TableCell align="left">{_item.product_name}</TableCell>
              <TableCell align="right">{_item.price}</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
          ))
        : ""}
    </React.Fragment>
  );
};

export const QuotationProductTable = (props) => {
  const classes = useRowStyles();
  return (
    <Grid container item>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell>Quantity</TableCell>
              <TableCell>Description</TableCell>
              <TableCell align="right">Unit Price</TableCell>
              <TableCell align="right">Total</TableCell>
              <TableCell />
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {props.gridProduct &&
              props.gridProduct.map((row, index) => (
                <Row
                  key={index}
                  row={row}
                  delete={props.deleteProduct}
                  edit={props.editProduct}
                />
              ))}

            {props.gridProduct && props.gridProduct.length !== 0 ? (
              <TableRow>
                <TableCell rowSpan={3} />
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell align="right"></TableCell>
                <TableCell align="right"></TableCell>
                <TableCell align="right">
                  <Typography style={{ fontWeight: "bold" }}>
                    Total : {props.total}
                  </Typography>
                </TableCell>
              </TableRow>
            ) : (
              ""
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
};
