import React, { useState, useEffect } from "react";
import {
  Paper,
  Button,
  Grid,
  Typography,
  TextField,
  Link,
  Fab,
  RadioGroup,
  FormControl,
  Radio,
  Snackbar,
  CircularProgress,
  makeStyles,
  Select as AccountSelect,
  InputLabel,
  MenuItem,
} from "@material-ui/core";
import { Formik, Form, ErrorMessage } from "formik";
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from "react-router-dom";
import { api } from "../../businessjs/API";
import { Select } from "../../compon-controls/Select";
import { SnackBar } from "../../compon-controls/SnackBar";
import { Redirect } from "react-router-dom";
import * as yup from "yup";

const useStyles = makeStyles((theme) => ({
  buttonProgress: {
    color: "#2196f3",
    position: "absolute",
    left: "45%",
    // marginTop: "3.5em",
  },
}));
const createValidationSchema = yup.object().shape({
  contactNumber: yup.string().matches(/(^[+]?[0-9]*$)/, {
    message: "Invalid phone number",
  }),
});

const options = [
  { value: 1, label: "Active" },
  { value: 0, label: "Inactive", disabled: true },
];

export const AddContact = (props) => {
  const classes = useStyles();
  const [error, setError] = useState(false);
  const [color, setColor] = useState("#d50000");
  const [infoMessgae, setInfoMessage] = useState(false);
  const [navigation, setNavigation] = useState(false);
  const [loading, setLoading] = useState(false);
  const [accountList, setAccountList] = useState([]);
  const [formlValues, setFormlValues] = useState({
    accountId: "",
    firstName: "",
    email: "",
    lastName: "",
    contactNumber: "",
    status: options[0],
  });
  const NavLink = React.forwardRef((props, ref) => (
    <RouterLink innerRef={ref} {...props} />
  ));

  // useEffect(() => {}, [props.match.params.id]);

  const loadContact = async (accountArray) => {
    const id = props.match.params.id;
    try {
      if (id !== "new") {
        setLoading(true);
        const input = {
          autoId: id,
        };
        const contactList = await api.post(
          "sc-contact/get-contact-list-by-id",
          input
        );

        if (contactList && contactList.length) {
          // console.log("contactList", contactList[0]);
          setFormlValues({
            firstName: contactList[0].first_name,
            lastName: contactList[0].last_name,
            email: contactList[0].email,
            contactNumber: contactList[0].contact_number,
            // accountId: accountArray.find(
            //   (option) => option.value.autoid === contactList[0].account_id
            // ),
            accountId: contactList[0].account_id,
            status: options.find(
              (option) => option.value === contactList[0].status
            ),
          });
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    const id = props.match.params.id;
    if (id !== "new") {
      setLoading(true);
    }
    const loadAccounts = async () => {
      try {
        const account = await api.post("sc-account/get-all-for-cmb");
        if (account && account.accountSet) {
          let accountArray = account.accountSet.map((opt) => ({
            label: opt.name,
            value: opt,
          }));
          setAccountList(accountArray);
          // setFormlValues({ accountList: accountArray });
          loadContact(accountArray);
        }
      } catch (e) {
        console.log(e);
      }
    };
    loadAccounts();
  }, [props.match.params.id]);

  const onCloseSnackbar = () => {
    setInfoMessage("");
  };

  const addNewContact = async (values) => {
    if (values.accountId === undefined) {
      setColor("#0091ea");
      setInfoMessage("Please select account...");
    } else if (values.status === undefined) {
      setColor("#0091ea");
      setInfoMessage("Please select status...");
    } else {
      setLoading(true);
      const addInput = {
        // accountId: values.accountId.value.autoid,
        accountId: values.accountId,
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        contactNumber: values.contactNumber,
        status: values.status.value,
      };

      const addResult = await api.post("sc-contact/contact-add", addInput);
      setLoading(false);
      if (addResult.status === "success") {
        setInfoMessage(addResult.message);
        setColor("#4caf50");
        setTimeout(() => {
          setNavigation(true);
        }, 2000);
      } else {
        setInfoMessage(addResult.message);
        setColor("#d50000");
        setNavigation(false);
      }
      setNavigation(false);
    }
  };

  const updateContact = async (values) => {
    if (values === formlValues) {
      setColor("#0091ea");
      setInfoMessage("Please change fields as required...");
    } else {
      setLoading(true);
      const editInput = {
        // accountId: values.accountId.value.autoid,
        accountId: values.accountId,
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        contactNumber: values.contactNumber,
        status: values.status.value,
        autoId: props.match.params.id,
      };
      // console.log("edit", editInput);
      const editResult = await api.post("sc-contact/update-contact", editInput);
      setLoading(false);
      if (editResult.status === "success") {
        setInfoMessage(editResult.message);
        setColor("#4caf50");
        setTimeout(() => {
          setNavigation(true);
        }, 2000);
      } else {
        setInfoMessage(editResult.message);
        setColor("#d50000");
        setNavigation(false);
      }
      setNavigation(false);
    }
  };

  if (navigation) {
    return <Redirect to="/contact"></Redirect>;
  } else {
    return (
      <div>
        <SnackBar
          status={infoMessgae}
          onCloseError={onCloseSnackbar}
          color={color}
        />
        <Paper elevation={4} style={{ minHeight: "25em", minWidth: "50%" }}>
          <Formik
            enableReinitialize
            validationSchema={createValidationSchema}
            initialValues={formlValues}
            onSubmit={async (values) => {
              // console.log("values",values)
              if (props.match.params.id !== "new") {
                updateContact(values);
              } else {
                addNewContact(values);
              }
            }}
          >
            {({ values, handleChange, setFieldValue, touched, errors }) => (
              <Form>
                <Grid container spacing={2} style={{ padding: "2em" }}>
                  <Grid item xs={12}>
                    <Typography variant="h4" style={{ fontWeight: "bold" }}>
                      {props.match.params.id === "new"
                        ? "Add new Contact"
                        : "Edit Contact"}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                      fullWidth
                      required
                    >
                      <InputLabel id="demo-simple-select-outlined-label">
                        Account Name
                      </InputLabel>
                      <AccountSelect
                        value={values.accountId}
                        disabled={
                          props.match.params.id !== "new" ? true : false
                        }
                        onChange={(event) => {
                          setFieldValue("accountId", event.target.value);
                        }}
                        label="Account Name"
                      >
                        {/* <MenuItem value={0}>Select Account....</MenuItem> */}
                        {accountList &&
                          accountList.map((option) => (
                            <MenuItem
                              value={option.value.autoid}
                              key={option.value.autoid}
                            >
                              {option.label}
                            </MenuItem>
                          ))}
                      </AccountSelect>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      required
                      type="email"
                      variant="outlined"
                      name="email"
                      label="E-mail"
                      value={values.email}
                      onChange={handleChange}
                      inputProps={{ maxLength: 50 }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      fullWidth
                      variant="outlined"
                      name="firstName"
                      label="First Name"
                      value={values.firstName}
                      onChange={handleChange}
                      inputProps={{ maxLength: 50 }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      // required
                      variant="outlined"
                      name="lastName"
                      label="Last Name"
                      multiline
                      value={values.lastName}
                      onChange={handleChange}
                      inputProps={{ maxLength: 50 }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      // required
                      error={Boolean(
                        touched.contactNumber && errors.contactNumber
                      )}
                      helperText={touched.contactNumber && errors.contactNumber}
                      variant="outlined"
                      name="contactNumber"
                      label="Contact Number"
                      value={values.contactNumber}
                      onChange={handleChange}
                      inputProps={{ maxLength: 50 }}
                    />
                  </Grid>
                  {props.match.params.id !== "new" ? (
                    <Grid item xs={12} sm={6}>
                      <Select
                        required
                        style={{ minHeight: "2em", backgroundColor: "blue" }}
                        onChange={(value) => {
                          // setFormlValues({ status: value });
                          setFieldValue("status", value);
                        }}
                        // defaultValue={{ value: 1, label: "Active" }}
                        options={options}
                        placeholder={<div>Select Status....</div>}
                        value={values.status}
                      />
                    </Grid>
                  ) : (
                    <Grid item xs={12} sm={6} />
                  )}

                  <Grid item xs={6} style={{ textAlign: "right" }}>
                    <Fab
                      variant="extended"
                      size="medium"
                      color="primary"
                      component={NavLink}
                      to="/contact"
                    >
                      Cancel
                    </Fab>
                  </Grid>
                  <Grid item xs={6} style={{ textAlign: "left" }}>
                    <Fab
                      variant="extended"
                      size="medium"
                      color="primary"
                      type="submit"
                      disabled={loading}
                    >
                      {props.match.params.id === "new" ? "Submit" : "Update"}
                      {loading && (
                        <CircularProgress
                          size={24}
                          className={classes.buttonProgress}
                        />
                      )}
                    </Fab>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Paper>
      </div>
    );
  }
};
