import React, { Component } from "react";

import VehicleSelect from "../compon-controls/VehicleSelect";

class SubscriptionPopup extends Component {
  state = {
    subscriber_id: this.props.subscriber_id,
    vehicle_id: 0
  };

  productSave = () => {
    let subscription = {
      subscription_id: this.props.productObject.autoid,
      vehicle_id: this.state.vehicle_id.value.autoid,
      plate_number: this.props.productObject.plate_number,
      old_vehicle_id: this.props.productObject.old_vehicle_id
    };
    this.props.save(subscription);
    this.props.cancel();
  };

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  render() {
    return (
      <React.Fragment>
          <div className="col-12">
            <div className="card">
              <div className="card-header">
                <div className="row">
                  <div className="col-8">
                    <h5>Select Subscription</h5>
                  </div>
                </div>
              </div>

              <div className="form-body">
                <div className="row">
                  <div className="col-12">
                    <div className="row mt-3 ml-4">
                      <div className="col-3" style={{ textAlign: "start" }}>
                        <label>Vehicle</label>
                      </div>
                      <div className="col-7">
                        <div className="position-relative has-icon-left">
                          <VehicleSelect
                            subscriber_id={
                              this.state.subscriber_id.value.autoid
                            }
                            onChange={this.onChange}
                            value={this.state.vehicle_id}
                            name="vehicle_id"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row mt-3 ml-3 mb-2">
                      <div className="col-6">
                        <button
                          className="btn btn-primary btn-sm"
                          onClick={this.productSave}
                        >
                          Save
                        </button>
                        <button
                          className="btn btn-warning btn-sm ml-2 "
                          onClick={() => {
                            this.props.cancel();
                          }}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </React.Fragment>
    );
  }
}

export default SubscriptionPopup;
