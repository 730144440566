import React, { Component } from "react";
import { Col, Row } from "reactstrap";
import queryString from "query-string";
import * as moment from "moment";
import { saveAs } from "file-saver";
import { Route } from "react-router-dom";
import { Fab, Tooltip, CircularProgress } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DescriptionIcon from "@material-ui/icons/Description";
import "../App.css";
import { AuthHelperAccessCheck } from "../businessjs/AuthHelper";
import { api } from "../businessjs/API";
import { SnackBar } from "./Login/SnackBar";

class InvoiceBill extends Component {
  state = {
    invoiceId: "",
    subscriber_id: "",
    product: [],
    address: "",
    invoiceDate: "",
    from: "",
    serialNumArray: [],
    status: "",
    message: "",
    redirect: 0,
    loading: false,
    state: "",
    city: null,
    location: null,
    zipcode: null,
    building: null,
  };

  componentDidMount = () => {
    const values = queryString.parse(this.props.location.search);
    this.setState({ invoiceId: values.id, from: values.from });

    this.getInvoiceById(values.id);
  };

  getInvoiceById = async (invoiceId) => {
    const _input = {
      invoice_id: invoiceId,
    };
    const invoiceById = await api.post("sc-invoice/get-invoice-by-id", _input);
    // console.log("invoiceById", invoiceById);
    if (invoiceById) {
      if (invoiceById.invoice) {
        this.setState({
          address: invoiceById.invoice.invoice_address,
          invoiceDate: invoiceById.invoice.invoice_date,
          invoiceDueDate: invoiceById.invoice.invoice_due_date,
          subscriber_id: {
            label: invoiceById.invoice.company_name,
            value: {
              autoid: invoiceById.invoice.subscriber_id,
              company_name: invoiceById.invoice.company_name,
            },
          },
          mrp: invoiceById.invoice.total_amount,
          discount: invoiceById.invoice.discount,
          total_amount: invoiceById.invoice.total_amount,
          status: invoiceById.invoice.status,
          serialNumArray: invoiceById.serialNoSet,
          state: invoiceById.invoice.state,
          city: invoiceById.invoice.city,
          note: invoiceById.invoice.note,
          location: invoiceById.invoice.location,
          building: invoiceById.invoice.building,
          zipcode: invoiceById.invoice.zipcode,
        });
      }

      if (invoiceById.invoiceSet && invoiceById.invoiceSet.length) {
        let bundleSet = [];
        if (invoiceById.bundleSet && invoiceById.bundleSet.length) {
          bundleSet = invoiceById.bundleSet;
        }

        let invoice_items = invoiceById.invoiceSet.map((p) => ({
          type: p.type,
          active_days: p.active_days,
          autoid: p.product_id,
          price: p.price,
          quantity: p.quantity,
          mrp: (p.price * p.quantity).toFixed(2),
          total:
            p.price * p.quantity - (p.price * p.quantity * p.discount) / 100,
          status: p.status,
          product_name: p.product_name,
          bundle_name: p.bundle_name,
          discount: p.discount,
          bundle_items:
            bundleSet && bundleSet.length
              ? bundleSet.filter((pro) => pro.bundle_id === p.bundle_id)
              : null,
        }));
        let total = Object.keys(invoice_items).reduce((previous, key) => {
          return (previous += parseFloat(invoice_items[key].total));
        }, 0);

        this.setState({
          product: invoice_items,
          totalPrice: total,
        });
      }
    } else {
      AuthHelperAccessCheck(invoiceById);
    }
  };

  calculateSubTotal = (product) => {
    let mrpTotal = 0.0;
    if (product) {
      mrpTotal = Object.keys(product).reduce((previous, key) => {
        return (previous += parseFloat(product[key].mrp));
      }, 0);
    }
    return mrpTotal.toFixed(2);
  };

  calculateDiscount = (product) => {
    let discountTotal = 0.0;
    discountTotal = (product.price * product.quantity * product.discount) / 100;
    return discountTotal.toFixed(2);
  };

  totalDiscount = (product) => {
    let mrpTotal = 0.0;
    let total = 0.0;
    if (product) {
      mrpTotal = Object.keys(product).reduce((previous, key) => {
        return (previous += parseFloat(product[key].mrp));
      }, 0);

      total = Object.keys(product).reduce((previous, key) => {
        return (previous += parseFloat(product[key].total));
      }, 0);
    }
    return (mrpTotal - total).toFixed(2);
  };

  calculateTotal = (product) => {
    let total = 0.0;
    if (product) {
      total = Object.keys(product).reduce((previous, key) => {
        return (previous += parseFloat(product[key].total));
      }, 0);
    }
    return total.toFixed(2);
  };

  getInvoicePdfById = async () => {
    const _input = {
      invoice_id: this.state.invoiceId,
    };
    const invoiceById = await api.post(
      "sc-invoice-pdf/get-invoice-by-id",
      _input
    );
    if (invoiceById) {
      const pdfInput = {
        fileName: invoiceById.fileName,
      };
      const fetchInvoice = await api.post(
        "sc-invoice-pdf/fetch-jc-invoice-pdf",
        pdfInput,
        "blob"
      );
      if (fetchInvoice) {
        const fileName = moment(new Date()).format("YYYYMMDDhhmmss");
        const pdfBlob = new Blob([fetchInvoice], {
          type: "application/pdf",
        });
        saveAs(pdfBlob, `${fileName}.pdf`);
      }
    }
  };

  editButton = () => {
    const EditButton = () => (
      <Route
        render={({ history }) => (
          <Tooltip title="edit">
            <Fab
              // type="button"
              // className="btn btn-primary mr-1"
              // title="Edit"
              style={{ display: this.state.status === 4 ? "none" : "" }}
              size="small"
              color="primary"
              onClick={() => {
                history.push({
                  pathname: "/invoice",
                  state: {
                    invoice_id: this.state.invoiceId,
                    invoice_date: this.state.invoiceDate,
                    invoice_due_date: this.state.invoiceDueDate,
                    from: this.state.from,
                  },
                });
              }}
            >
              <EditIcon />
            </Fab>
          </Tooltip>
        )}
      />
    );
    return <EditButton />;
  };
  onCreateAndConfirm = async () => {
    this.setState({ loading: true });
    const { invoiceId } = this.state;
    const createInvoice = await api.post("sc-invoice/confirm-invoice", {
      invoiceId,
    });
    if (createInvoice.status === "success") {
      this.setState({
        message: "Sucessfully Created and Confirmed this invoice.....",
        loading: false,
      });
      setTimeout(() => {
        this.setState({ redirect: 1 });
      }, 2000);
    } else {
      // this.setState({
      //   message: "Error occured while creating this invoice.....",
      //   loading: false,
      // });
      this.setState({
        message: createInvoice.message,
        loading: false,
      });
    }
    // console.log("createInvoice", createInvoice);
  };
  onCloseSnackbar = () => {
    this.setState({ message: "" });
  };
  render() {
    let _showProduct = this.state.product;
    const { serialNumArray, message, loading } = this.state;
    if (this.state.redirect === 1) {
      this.props.history.push({
        pathname: `/invoice-list`,
      });
    }

    // console.log("_showProduct", _showProduct);
    // console.log("serialArray", serialNumArray);
    return (
      <React.Fragment>
        <SnackBar
          status={message}
          onCloseError={this.onCloseSnackbar}
          color={"#1b5e20"}
        />
        <div className="container mt-4">
          <div className="col-10 offset-1">
            <div
              className="card mt-4"
              style={{
                border: "solid",
                paddingLeft: "5%",
                paddingRight: "5%",
                paddingTop: "5%",
                marginBottom: "5%",
              }}
            >
              <Row>
                <Col>
                  <Row className="mb-4" style={{ paddingRight: "5%" }}>
                    <Col
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <Fab
                        color="primary"
                        size="small"
                        variant="extended "
                        disabled={loading}
                        style={{
                          marginRight: "1em",
                          marginTop: "0.3em",
                          display: this.state.status === 0 ? "" : "none",
                        }}
                        onClick={this.onCreateAndConfirm}
                      >
                        Confirm and Create invoice
                      </Fab>
                      {loading && (
                        <CircularProgress
                          size={24}
                          style={{
                            position: "absolute",
                            left: "68%",
                            marginTop: "0.5em",
                          }}
                        />
                      )}
                      {this.editButton()}
                      <Tooltip title="pdf">
                        <Fab
                          color="primary"
                          size="small"
                          style={{ marginLeft: "1em" }}
                          onClick={this.getInvoicePdfById}
                        >
                          <DescriptionIcon />
                        </Fab>
                      </Tooltip>
                    </Col>
                  </Row>
                  <Row>
                    <Col xl={9} lg={8} md={7}>
                      <img
                        src="https://webdev.cloudtel.qa/wp-content/uploads/2020/03/CloudTel-Logo.png"
                        className="img-responsive"
                        alt="logo"
                      />
                    </Col>
                    <Col xl={3} lg={4} md={5}>
                      <h2>INVOICE</h2>
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      style={{
                        flexDirection: "column",
                        paddingRight: "7%",
                      }}
                    >
                      <Row style={{ justifyContent: "flex-end" }}>
                        INVOIVE : {" #" + this.state.invoiceId}
                      </Row>
                      <Row style={{ justifyContent: "flex-end" }}>
                        DATE :{" "}
                        {" " + moment(this.state.invoiceDate).format("L")}
                      </Row>
                      <Row style={{ justifyContent: "flex-end" }}>
                        DUE DATE :{" "}
                        {" " + moment(this.state.invoiceDueDate).format("L")}
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <label>To:</label>
                      <br />
                      {this.state.subscriber_id
                        ? this.state.subscriber_id.value.company_name
                        : ""}
                      ,
                      <br />
                      {this.state.address ? this.state.address + " " : ""}
                      {this.state.city ? this.state.city + " " : ""}
                      {this.state.state ? this.state.state + " " : ""}
                      {this.state.building ? this.state.building + " " : ""}
                      {this.state.zipcode ? this.state.zipcode + " " : ""}
                    </Col>
                  </Row>
                  <Row style={{ marginTop: "10%" }}>
                    <div className="table-responsive">
                      <Col>
                        <table
                          className="table table-bordered"
                          style={{ border: "1" }}
                        >
                          <thead>
                            <tr>
                              <th>Description</th>
                              <th>Units</th>
                              <th style={{ textAlign: "end" }}>Unit Price</th>
                              <th style={{ textAlign: "end" }}>Total</th>
                            </tr>
                          </thead>
                          <tbody>
                            {_showProduct.map((_product, i) => (
                              <React.Fragment key={i}>
                                <tr>
                                  <td>
                                    {_product.product_name}
                                    <span style={{ fontSize: 12 }}>
                                      {_product.type === 3
                                        ? `   Subscription - Requiring ${_product.active_days} days. `
                                        : ""}
                                    </span>
                                  </td>
                                  <td>{_product.quantity}</td>
                                  <td style={{ textAlign: "end" }}>
                                    {parseInt(_product.price).toFixed(2)}
                                  </td>
                                  <td style={{ textAlign: "end" }}>
                                    {parseInt(_product.total).toFixed(2)}
                                  </td>
                                </tr>

                                {/* showing bundle items */}
                                {_product.bundle_items &&
                                _product.bundle_items.length
                                  ? _product.bundle_items.map((_item, j) => (
                                      <React.Fragment key={j}>
                                        <tr className="bundle-rows-text">
                                          <td></td>
                                          <td>{_item.product_name}</td>
                                          <td style={{ textAlign: "end" }}>
                                            {_item.price}
                                          </td>
                                          <td></td>
                                        </tr>
                                      </React.Fragment>
                                    ))
                                  : ""}
                                {/* end of showing bundle items  */}
                              </React.Fragment>
                            ))}
                            <tr>
                              <td colSpan="3" style={{ textAlign: "end" }}>
                                Subtotal(Qr)
                              </td>
                              <td style={{ textAlign: "end" }}>
                                {this.state.total_amount}
                              </td>
                            </tr>
                            <tr>
                              <td colSpan="3" style={{ textAlign: "end" }}>
                                Discount(Qr)
                              </td>
                              <td style={{ textAlign: "end" }}>
                                {this.state.discount}
                              </td>
                            </tr>
                            <tr>
                              <td colSpan="3" style={{ textAlign: "end" }}>
                                <b>Total(Qr)</b>
                              </td>
                              <td style={{ textAlign: "end" }}>
                                {(
                                  this.state.total_amount - this.state.discount
                                ).toFixed(2)}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </Col>
                    </div>
                  </Row>
                  <Row style={{ marginTop: "5%" }}>
                    <Col style={{ flexDirection: "column" }}>
                      {/* <Row>
                        <label> Warranty Terms :</label>
                      </Row> */}

                      <Row style={{ marginLeft: "1%", marginTop: "1px" }}>
                        <div style={{ whiteSpace: "pre-wrap" }}>
                          {" "}
                          {this.state.note}
                        </div>
                        {/* For term and conditions follow "Annexure A" document. */}
                      </Row>
                    </Col>
                  </Row>

                  <Row style={{ marginTop: "5%" }}>
                    <Col style={{ flexDirection: "column" }}>
                      <Row>
                        <label style={{ fontStyle: "oblique" }}>
                          Cloud Telematics, Office No. C-35, First Floor,
                          Building No.164, Al Maha Commercial Complex Street No.
                          J-29, Energy Street, New Industrial Area, Zone-81,
                          Doha, Ar Rayyan 4473 1516
                        </label>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </div>
          <div
            className="col-10 offset-1"
            style={{ display: serialNumArray.length !== 0 ? "" : "none" }}
          >
            <div
              className="card mt-4"
              style={{
                border: "solid",
                paddingLeft: "5%",
                paddingRight: "5%",
                paddingTop: "5%",
                marginBottom: "5%",
              }}
            >
              <Row>
                <Col>
                  <Row style={{ marginTop: "10%" }}>
                    <div className="table-responsive">
                      <Col>
                        <table
                          className="table table-bordered"
                          style={{ border: "1" }}
                        >
                          <thead>
                            <tr>
                              <th>No.</th>
                              <th>Description</th>
                              <th>Serial Numbers</th>
                            </tr>
                          </thead>
                          <tbody>
                            {/* to show serialNumber */}
                            {serialNumArray.length !== 0
                              ? serialNumArray.map((number, i) => (
                                  <React.Fragment key={i}>
                                    <tr>
                                      <td>{i + 1}</td>
                                      <td>{number.product_name}</td>
                                      <td>{number.serial_no}</td>
                                    </tr>
                                  </React.Fragment>
                                ))
                              : ""}
                          </tbody>
                        </table>
                      </Col>
                    </div>
                  </Row>
                  <Row style={{ marginTop: "5%" }}>
                    <Col style={{ flexDirection: "column" }}>
                      {/* <Row>
                        <label> Warranty Terms:</label>
                      </Row> */}

                      <Row style={{ marginLeft: "1%", marginTop: "1px" }}>
                        <div style={{ whiteSpace: "pre-wrap" }}>
                          {" "}
                          {this.state.note}
                        </div>
                        {/* For term and conditions follow "Annexure A" document. */}
                      </Row>
                    </Col>
                  </Row>

                  <Row style={{ marginTop: "5%" }}>
                    <Col style={{ flexDirection: "column" }}>
                      <Row>
                        <label style={{ fontStyle: "oblique" }}>
                          Cloud Telematics, Office No. C-35, First Floor,
                          Building No.164, Al Maha Commercial Complex Street No.
                          J-29, Energy Street, New Industrial Area, Zone-81,
                          Doha, Ar Rayyan 4473 1516
                        </label>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default InvoiceBill;
