import { Grid, Paper, Typography, Divider } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { Component, useEffect, useState } from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));
export const ProductDetailPanel = (props) => {
  const { value, index, items, batch, ...other } = props;
  const classes = useStyles();
  const product = items;
  // const product = Array(children);
  const [productName, setProductName] = useState("");
  const [status, setStatus] = useState("");

  /**
   * to get badge based on status fetch from DB.
   */
  const getBadge = (status) => {
    let _classes = "badge badge-";
    _classes +=
      status === "Suspended"
        ? "danger"
        : status === "Active"
        ? "success"
        : "primary";
    return _classes;
  };

  return (
    // <Grid container spacing={3} style={{backgroundColor:"yellow"}}>
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      variant="fullWidth"
      {...other}
    >
      {/* <Grid container spacing={2}> */}
      {value === index && (
        <Grid container spacing={3}>
          <Grid item xs={12} style={{ margin: "2em" }}>
            <Paper className={classes.paper}>
              <Grid container spacing={3}>
                <Grid item xs={6} sm={1}>
                  {" "}
                  <span className={getBadge(product.status_value)}>
                    {product.status_value}
                  </span>
                </Grid>
                <Grid item xs={6} sm={5}>
                  <Typography variant="h4">
                    Name : {product.product_name}
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={5}>
                  <Typography>
                    {product.price} QAR - {product.stock} stock available
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={4}>
                  <Typography>
                    Warranty :{" "}
                    {product.active_days ? product.active_days + "days" : ""}
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={3}>
                    {batch.map((item) => (
                      <Grid
                        item
                        xs={2}
                        style={{ margin: "1em", borderRight: "1px solid gray" }}
                      >
                        <Typography>Batch Name: {item.batch_name}</Typography>
                        <Typography>
                          Status:{" "}
                          <span
                            style={{
                              color:
                                item.status_value === "Available"
                                  ? "green"
                                  : "orange",
                              fontWeight: "bold",
                            }}
                          >
                            {item.status_value}
                          </span>
                        </Typography>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          {/* <Box p={3}> */}
          {/* <Grid item xs={12}>
              <span className={getBadge(product.status_value)}>
                {product.status_value}
              </span>
            </Grid> */}

          {/* <Grid xs={12} item>
              <Typography variant="h4" style={{ marginTop: "1em" }}>
                Name : {product.product_name}
              </Typography> */}

          {/* <Typography style={{ marginTop: "1em" }}>
                  Price : {product.price} QAR
                </Typography> */}
          {/* </Grid> */}

          {/* <Grid xs={12} item>
              <Typography style={{ marginTop: "1em" }}>
                {product.price} QAR - {product.stock} stock available
              </Typography>

              <Typography style={{ marginTop: "1em" }}>
                Warranty :{" "}
                {product.active_days ? product.active_days + "days" : ""}
              </Typography>

              <Typography style={{ marginTop: "3em" }}>Updating...</Typography>
            </Grid> */}
          {/* <Grid item xs={12} style={{ marginTop: "1em" }}>
              {batch.map((item) => (
                <Grid container spacing={3}>
                  <Grid item xs={4} style={{ margin: "1em" }}>
                    <Typography>Batch Name: {item.batch_name}</Typography>
                    <Typography>Status: {item.status_value}</Typography>
                  </Grid>
                </Grid>
              ))}
            </Grid> */}
          {/* </Box> */}
        </Grid>
      )}
      {/* </Grid> */}
    </div>
  );
};
