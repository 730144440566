import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  Card,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Paper,
  TableContainer,
  Table,
  TableBody,
  TablePagination,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  CircularProgress,
  Fab,
  Badge,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { makeStyles } from "@material-ui/core/styles";
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
  Redirect,
} from "react-router-dom";

import { api } from "../../businessjs/API";
import { SnackBar } from "../../compon-controls/SnackBar";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 650,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  contactTableRow: {
    paddingRight: "3em",
  },
  margin: {
    margin: theme.spacing(1),
  },
}));

const headCells = [
  {
    id: "account_name",
    numeric: false,
    label: "Account Name",
  },
  {
    id: "first_name",
    numeric: false,
    label: "First Name",
  },
  {
    id: "last_name",
    numeric: false,
    label: "Last Name",
  },
  {
    id: "email",
    numeric: false,
    label: "E-mail",
  },
  {
    id: "contact_number",
    numeric: false,
    label: "Phone",
  },
  { id: "status_type", numeric: false, label: "Status" },
  { id: "view", label: "" },
  // { id: "view", label: "" },
];

const EnhancedTableHead = (props) => {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy].toUpperCase() < a[orderBy].toUpperCase()) {
    return -1;
  }
  if (b[orderBy].toUpperCase() > a[orderBy].toUpperCase()) {
    return 1;
  }
  return 0;
};

const getComparator = (order, orderBy) => {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

export const ContactsList = (props) => {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("account_name");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [color, setColor] = useState("#d50000");
  const [infoMessgae, setInfoMessage] = useState(false);
  const classes = useStyles();
  const { rows } = props;

  const NavLink = React.forwardRef((props, ref) => (
    <RouterLink innerRef={ref} {...props} />
  ));

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  /**
   * to get badge based on status fetch from DB.
   */
  const getBadge = (status) => {
    let _classes = "badge badge-";
    _classes += status === "Inactive" ? "danger" : "success";
    return _classes;
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            {rows.length !== 0 ? (
              <TableBody>
                {stableSort(rows, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    return (
                      <TableRow hover tabIndex={-1} key={index}>
                        <TableCell
                          className={classes.contactTableRow}
                          // align="center"
                        >
                          {row.account_name}
                        </TableCell>
                        <TableCell
                          className={classes.contactTableRow}
                          // align="center"
                        >
                          {row.first_name}
                        </TableCell>
                        <TableCell
                          className={classes.contactTableRow}
                          // align="center"
                        >
                          {row.last_name}
                        </TableCell>
                        <TableCell
                          className={classes.contactTableRow}
                          // align="center"
                        >
                          {row.email}
                        </TableCell>
                        <TableCell
                          className={classes.contactTableRow}
                          // align="center"
                        >
                          {row.contact_number}
                        </TableCell>
                        <TableCell
                          className={classes.contactTableRow}
                          // align="center"
                        >
                          {/* {row.status_type} */}
                          <span className={getBadge(row.status_type)}>
                            {row.status_type}
                          </span>
                        </TableCell>
                        <TableCell className={classes.contactTableRow}>
                          {/* <Fab
                          variant="extended"
                          size="small"
                          style={{ backgroundColor: "#00bcd4" }}
                          component={NavLink}
                          to={`/contact/${row.autoid}`}
                        >
                          View
                        </Fab> */}

                          <IconButton
                            component={NavLink}
                            to={`/contact/${row.autoid}`}
                            style={{
                              display:
                                row.status_type === "Active" ? "" : "none",
                            }}
                          >
                            <Tooltip title="Edit">
                              <EditIcon color="primary" />
                            </Tooltip>
                          </IconButton>
                        </TableCell>
                        {/* <TableCell className={classes.contactTableRow}> */}
                        {/* <IconButton
                            aria-label="delete"
                            className={classes.margin}
                            style={{display:row.status_type==="Inactive"?"none":""}}
                            onClick={() => props.deleteContact(row.autoid)}
                            // onClick={deleteContact}
                          >
                            <DeleteIcon color="primary" />
                          </IconButton> */}
                        {/* </TableCell> */}
                      </TableRow>
                    );
                  })}
              </TableBody>
            ) : (
              <TableBody>
                <TableRow hover>
                  <TableCell
                    className={classes.contactTableRow}
                    // align="center"
                  ></TableCell>
                  <TableCell
                    className={classes.contactTableRow}
                    // align="center"
                  ></TableCell>
                  <TableCell
                    className={classes.contactTableRow}
                    // align="center"
                  ></TableCell>
                  <TableCell
                    className={classes.contactTableRow}
                    // align="center"
                  >
                    <Typography
                      style={{
                        textAlign: "center",
                        marginTop: "1em",
                      }}
                    >
                      No Records...............
                    </Typography>
                  </TableCell>
                  <TableCell
                    className={classes.contactTableRow}
                    // align="center"
                  ></TableCell>
                  <TableCell
                    className={classes.contactTableRow}
                    // align="center"
                  ></TableCell>
                  <TableCell className={classes.contactTableRow}></TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[50, 200, 500]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
};
