import React, { Component } from "react";
import Pagination from "react-js-pagination";
import { RiseLoader } from "react-spinners";
import { css } from "@emotion/core";
import { Route, Link } from "react-router-dom";
import { Col, Row } from "reactstrap";
import CmbSelect from "../compon-controls/CmbSelect";
import { AuthHelperAccessCheck } from "../businessjs/AuthHelper";
import { AppHelper_DateAndTimeToString } from "../businessjs/AppHelperFn";
import { api } from "../businessjs/API";
import Grid from "@material-ui/core/Grid";
import AddIcon from "@material-ui/icons/Add";
import {
  Typography,
  InputBase,
  IconButton,
  Fab,
  CircularProgress,
  FormControl,
  TextField,
  MenuItem,
  TableSortLabel,
  Tooltip,
} from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import PaymentIcon from "@material-ui/icons/Payment";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import moment from "moment";
const loaderCSS = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;
const styles = (theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
    overflowX: "auto",
  },
  table: {
    minWidth: 700,
  },
  SearchInput: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  searchPaper: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: 400,
  },
  margin: {
    margin: theme.spacing(1),
  },
  buttonProgress: {
    color: "#2196f3",
    position: "absolute",
    left: "55%",
    marginTop: "4.5em",
  },
});
function descendingComparator(a, b, orderBy) {
  if (orderBy !== "") {
    // console.log("date", isNaN(a[orderBy]));
    if (isNaN(parseFloat(a[orderBy]))) {
      // console.log("date", a[orderBy]);
      if (b[orderBy].toLowerCase() < a[orderBy].toLowerCase()) {
        return -1;
      }
      if (b[orderBy].toLowerCase() > a[orderBy].toLowerCase()) {
        return 1;
      }
      return 0;
    } else {
      console.log("in else");
      if (parseFloat(b[orderBy]) < parseFloat(a[orderBy])) {
        return -1;
      }
      if (parseFloat(b[orderBy]) > parseFloat(a[orderBy])) {
        return 1;
      }
      return 0;
    }
  }
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: "autoid", label: "Inv. No", numeric: true },
  { id: "client", label: "Subscriber" },
  { id: "total_amount", label: "Amount", numeric: true, disablePadding: false },
  {
    id: "discount",
    label: "Discount",
    numeric: true,
    disablePadding: false,
  },
  {
    id: "grand_total",
    label: "Grand Total",
    numeric: true,
    disablePadding: false,
  },
  { id: "invoice_date", label: "Date" },
  { id: "invoice_due_date", label: "Due Date" },

  { id: "invoice_type", label: "Type" },
  { id: "status", label: "Status" },
  { id: "edit", label: "" },
  { id: "payment", label: "" },
];
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));
function EnhancedTableHead(props) {
  const classes = useStyles();
  const { order, orderBy, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "center"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
class InvoiceList extends Component {
  state = {
    subscriber_id: 0,

    itemsCountPerPage: 25,
    totalItemsCount: 0,
    searchInvoiceNo: "",
    loading: false,
    allInvoice: [],
    companyList: [],
    page: 0,
    rowsPerPage: 50,
    order: "desc",
    orderBy: "",
    expanded: false,
  };

  componentDidMount = () => {
    this.getSubscriberForCmb();
    // this.setState({ loading: true });
    const _invoiceListInputs = {
      subscriber_id: this.state.subscriber_id,
      invoice_no: this.state.searchInvoiceNo,
    };
    this.getInvoiceList(_invoiceListInputs);
  };

  handleChange = (panel) => (event, isExpanded) => {
    this.setState({ expanded: isExpanded ? panel : false });
  };

  getSubscriberForCmb = async () => {
    const subscribers = await api.post("sc-subscriber/get-all-for-cmb");
    if (subscribers && subscribers.userdata) {
      let subscriberArray = subscribers.userdata.map((opt) => ({
        label: opt.company_name,
        value: opt,
      }));
      this.setState({
        // subscriber_id: subscriberArray[0],
        companyList: subscriberArray,
      });

      const _invoiceListInputs = {
        // subscriber_id: subscriberArray[0].value.autoid,
        invoice_no: this.state.searchInvoiceNo,
      };
      // this.getInvoiceList(_invoiceListInputs);
    } else {
      AuthHelperAccessCheck(subscribers);
    }
  };
  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: parseInt(event.target.value, 10), page: 0 });
  };

  getInvoiceList = async (_invoiceListInputs) => {
    const invoiceList = await api.post(
      "sc-invoice/get-invoice-list",
      _invoiceListInputs
    );
    if (invoiceList && Array.isArray(invoiceList)) {
      // console.log("invoice list", invoiceList);

      this.setState({ allInvoice: invoiceList, loading: false });
    } else {
      this.setState({ allInvoice: [], loading: false });
      AuthHelperAccessCheck(invoiceList);
    }
  };

  handleRequestSort = (event, property) => {
    const isAsc = this.state.orderBy === property && this.state.order === "asc";
    this.setState({ order: isAsc ? "desc" : "asc", orderBy: property });
  };
  invoiceAdd = () => {
    const { classes } = this.props;

    const InvoiceAddButton = () => (
      <Route
        render={({ history }) => (
          <Fab
            color="primary"
            variant="extended"
            size="medium"
            onClick={() => {
              history.push({ pathname: "/invoice" });
            }}
          >
            <AddIcon className={classes.extendedIcon} />
            Add
          </Fab>
        )}
      />
    );
    return <InvoiceAddButton />;
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    // console.log("in sub", e.target.value, this.state.subscriber_id);

    const _invoiceListInputs = {
      // subscriber_id: e.target.value.value.autoid,
      subscriber_id: this.state.subscriber_id,
      invoice_no: e.target.value,
    };
    this.getInvoiceList(_invoiceListInputs);
  };

  payment = (invoiceId, accountId, grandTotal) => {
    const PaymentButton = () => (
      <Route
        render={({ history }) => (
          <button
            type="button"
            className="btn btn-link btn-sm"
            onClick={() => {
              history.push({
                pathname: "/invoice-payment",
                state: {
                  invoiceId: invoiceId,
                  accountId: accountId,
                  grandTotal: grandTotal,
                  from: "invoice",
                },
              });
            }}
          >
            <Tooltip title="Payment">
              <PaymentIcon color="primary" />
            </Tooltip>
          </button>
        )}
      />
    );
    return <PaymentButton />;
  };

  /**
   * to get badge based on status fetch from DB.
   */
  getBadge = (status) => {
    if (status === "Draft") {
      return "badge badge-info";
    } else if (status === "Payment Pending") {
      return "badge badge-danger";
    } else if (status === "Paid") {
      return "badge badge-success";
    } else if (status === "Partially Paid") {
      return "badge badge-warning";
    } else if (status === "Cancelled") {
      return "badge badge-primary";
    }
  };

  render() {
    let _showInvoice = this.state.allInvoice;
    const { expanded } = this.state;
    const { classes } = this.props;
    return (
      <React.Fragment>
        {/* <NavBar /> */}
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h3" style={{ fontWeight: "bold" }}>
              Invoices
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ textAlign: "right" }}>
            {this.invoiceAdd()}
          </Grid>
          {/* <Paper> */}
          <Grid item xs={12} sm={12} style={{ textAlign: "left" }}>
            <Accordion
              expanded={expanded === "panel1"}
              onChange={this.handleChange("panel1")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography className={classes.heading}>Search</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={3}>
                  <Grid item xs={6} sm={5}>
                    <TextField
                      fullWidth
                      id="subscribersId"
                      select
                      label="Subscriber Name"
                      name="subscriber_id"
                      value={this.state.subscriber_id}
                      // onChange={this.onChange}
                      onChange={(event) => {
                        this.setState({ subscriber_id: event.target.value });
                        // console.log("sub", event.target.value);
                        const _invoiceListInputs = {
                          subscriber_id: event.target.value
                            ? event.target.value.value.autoid
                            : 0,
                          invoice_no: this.state.searchInvoiceNo,
                        };
                        this.getInvoiceList(_invoiceListInputs);
                      }}
                    >
                      <MenuItem value={0}>All</MenuItem>
                      {this.state.companyList.map((option) => (
                        <MenuItem key={option.value} value={option}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>

                  <Grid item xs={6} sm={4}>
                    <TextField
                      fullWidth
                      name="searchInvoiceNo"
                      label="Invoice No."
                      value={this.state.searchInvoiceNo}
                      onChange={this.onChange}
                      inputProps={{ maxLength: 50 }}
                    />
                  </Grid>

                  <Grid
                    item
                    xs={6}
                    sm={4}
                    style={{ textAlign: "right" }}
                  ></Grid>
                </Grid>
                {/* </Paper> */}
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item xs={12}>
            <Paper elevation={3} style={{ backgroundColor: "#fff" }}>
              {this.state.loading ? (
                this.state.loading && (
                  <Paper
                    elevation={3}
                    style={{ backgroundColor: "#fff", minHeight: "15em" }}
                  >
                    <CircularProgress
                      size={50}
                      className={classes.buttonProgress}
                    />
                  </Paper>
                )
              ) : (
                <div>
                  {" "}
                  <Table className={classes.table}>
                    <EnhancedTableHead
                      classes={classes}
                      // numSelected={selected.length}
                      order={this.state.order}
                      orderBy={this.state.orderBy}
                      // onSelectAllClick={handleSelectAllClick}
                      onRequestSort={this.handleRequestSort}
                      rowCount={_showInvoice.length}
                    />
                    {_showInvoice.length !== 0 ? (
                      <TableBody>
                        {stableSort(
                          _showInvoice,
                          getComparator(this.state.order, this.state.orderBy)
                        )
                          .slice(
                            this.state.page * this.state.rowsPerPage,
                            this.state.page * this.state.rowsPerPage +
                              this.state.rowsPerPage
                          )
                          .map((_invoice, i) => (
                            <TableRow key={i}>
                              <TableCell
                                component="th"
                                scope="row"
                                className={classes.subscriberTableRow}
                                // align="center"
                              >
                                {"# " + _invoice.autoid}
                              </TableCell>
                              <TableCell
                                className={classes.subscriberTableRow}
                                // align="left"
                              >
                                {_invoice.client}
                              </TableCell>
                              <TableCell
                                className={classes.subscriberTableRow}
                                align="right"
                              >
                                {_invoice.total_amount}
                              </TableCell>
                              <TableCell
                                className={classes.subscriberTableRow}
                                align="right"
                              >
                                {_invoice.discount}
                              </TableCell>
                              <TableCell
                                className={classes.subscriberTableRow}
                                align="right"
                              >
                                {_invoice.grand_total}
                              </TableCell>
                              <TableCell
                                className={classes.subscriberTableRow}
                                align="center"
                              >
                                {moment(_invoice.invoice_date).format(
                                  "MMMM Do YYYY"
                                )}
                              </TableCell>
                              <TableCell
                                className={classes.subscriberTableRow}
                                align="center"
                              >
                                {moment(_invoice.invoice_due_date).format(
                                  "MMMM Do YYYY"
                                )}
                              </TableCell>
                              <TableCell
                                className={classes.subscriberTableRow}
                                align="center"
                              >
                                {_invoice.invoice_type}
                              </TableCell>
                              <TableCell
                                className={classes.subscriberTableRow}
                                align="center"
                              >
                                <span
                                  className={this.getBadge(_invoice.status)}
                                >
                                  {_invoice.status}
                                </span>
                                {/* {_invoice.status} */}
                              </TableCell>

                              {_invoice.statusValue != 4 ? (
                                <TableCell
                                  className={classes.subscriberTableRow}
                                  align="center"
                                >
                                  {_invoice.invoiceType === 1 ? (
                                    <Link
                                      to={{
                                        pathname:
                                          "/invoice-bill?id=" +
                                          _invoice.autoid +
                                          "&from=invoice",
                                      }}
                                      target="_blank"
                                    >
                                      View
                                    </Link>
                                  ) : (
                                    <Link
                                      to={{
                                        pathname:
                                          "/job-card-invoice-bill?id=" +
                                          _invoice.autoid +
                                          "&sub_id=" +
                                          _invoice.subscriber_id,
                                      }}
                                      target="_blank"
                                    >
                                      View
                                    </Link>
                                  )}
                                </TableCell>
                              ) : (
                                <TableCell />
                              )}
                              {_invoice.statusValue === 1 ||
                              _invoice.statusValue === 2 ||
                              _invoice.statusValue === 3 ? (
                                <TableCell>
                                  {this.payment(
                                    _invoice.autoid,
                                    _invoice.subscriber_id,
                                    Number(_invoice.total_amount) -
                                      Number(_invoice.discount)
                                  )}
                                </TableCell>
                              ) : (
                                <TableCell />
                              )}
                            </TableRow>
                          ))}
                      </TableBody>
                    ) : (
                      <TableRow align="center">
                        <TableCell />
                        <TableCell />
                        <TableCell />
                        <TableCell />
                        <TableCell />
                        <TableCell>
                          <Typography
                            style={{
                              textAlign: "center",
                              marginTop: "1em",
                              marginLeft: "-11em",
                            }}
                          >
                            No Records...............
                          </Typography>
                        </TableCell>
                        <TableCell />
                        <TableCell />
                        <TableCell />
                        <TableCell />
                      </TableRow>
                    )}
                  </Table>
                  <TablePagination
                    rowsPerPageOptions={[50, 200, 500]}
                    component="div"
                    count={_showInvoice.length}
                    rowsPerPage={this.state.rowsPerPage}
                    page={this.state.page}
                    onChangePage={this.handleChangePage}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                  />
                </div>
              )}
            </Paper>
          </Grid>
          {/* </Paper> */}
        </Grid>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(InvoiceList);
