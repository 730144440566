import React, { Component } from "react";
import { css } from "@emotion/core";
import { Route } from "react-router-dom";
import { Select } from "../compon-controls/Select";
import SubscriberSelect from "../compon-controls/SubscriberSelect";
import { AuthHelperAccessCheck } from "../businessjs/AuthHelper";
import { AppHelper_DateAndTimeToString } from "../businessjs/AppHelperFn";
import { api } from "../businessjs/API";
import TablePagination from "@material-ui/core/TablePagination";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Typography, Grid, Fab, CircularProgress } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import TableSortLabel from "@material-ui/core/TableSortLabel";

function descendingComparator(a, b, orderBy) {
  if (orderBy !== "") {
    if (isNaN(parseFloat(a[orderBy]))) {
      if (b[orderBy].toLowerCase() < a[orderBy].toLowerCase()) {
        return -1;
      }
      if (b[orderBy].toLowerCase() > a[orderBy].toLowerCase()) {
        return 1;
      }
      return 0;
    } else {
      if (parseFloat(b[orderBy]) < parseFloat(a[orderBy])) {
        return -1;
      }
      if (parseFloat(b[orderBy]) > parseFloat(a[orderBy])) {
        return 1;
      }
      return 0;
    }
  }
}

const getComparator = (order, orderBy) => {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

const headCells = [
  { id: "location_name", label: "Location" },
  { id: "ticket_id", label: "Ticket" },
  { id: "schedule_date", label: "Schedule Date" },
  { id: "status", label: "Status" },
  { id: "remarks", label: "Remarks" },
  { id: "company_name", label: "Subscriber" },
  { id: "edit", label: "" },
];

const EnhancedTableHead = (props) => {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="left"
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

class ServiceScheduleList extends Component {
  state = {
    loading: false,
    allSchedules: [],
    activePage: undefined,
    itemsCountPerPage: 25,
    totalItemsCount: 0,
    subscriber_id: { value: 0, label: "All" },
    order: "asc",
    orderBy: "",
    page: 0,
    rowsPerPage: 50,
  };

  componentDidMount() {
    this.getScheduleList(this.state.subscriber_id);
  }
  handleRequestSort = (event, property) => {
    const { order, orderBy } = this.state;
    const isAsc = orderBy === property && order === "asc";
    this.setState({ order: isAsc ? "desc" : "asc", orderBy: property });
  };
  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: parseInt(event.target.value, 10), page: 0 });
  };
  getServiceScheduleCount = async () => {
    this.setState({ loading: true });

    const _scheduleCountInputs = {
      subscriber_id: this.state.subscriber_id.value
        ? this.state.subscriber_id.value.autoid
        : 0,
    };

    const scheduleCount = await api.post(
      "sc-ticket/get-service-schedule-count",
      _scheduleCountInputs
    );

    if (Array.isArray(scheduleCount)) {
      let _serviceScheduleCount = scheduleCount[0].serviceScheduleCount;
      if (_serviceScheduleCount > 0) {
        this.setState({
          totalItemsCount: _serviceScheduleCount,
          activePage: 1,
          loading: false,
        });
        this.getScheduleList(1);
      } else {
        this.setState({
          allSchedules: [],
          totalItemsCount: 0,
          activePage: 1,
          loading: false,
        });
      }
    } else {
      this.setState({ loading: false });
      AuthHelperAccessCheck(scheduleCount);
    }
  };

  getScheduleList = async (subscriber) => {
    const _scheduleListInputs = {
      subscriber_id: subscriber.value ? subscriber.value.autoid : 0,

      // startLimit: start,
      // endLimit: this.state.itemsCountPerPage,
    };

    const scheduleList = await api.post(
      "sc-ticket/get-service-schedule-list",
      _scheduleListInputs
    );
    if (scheduleList && Array.isArray(scheduleList)) {
      this.setState({ allSchedules: scheduleList });
      if (!this.state.activePage) {
        this.setState({
          totalItemsCount: scheduleList.length,
          activePage: 1,
        });
      }
    } else {
      this.setState({ loading: false });
      AuthHelperAccessCheck(scheduleList);
    }
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    this.getScheduleList(e.target.value);
  };

  editButton = (_schedule) => {
    const EditButton = () => (
      <Route
        render={({ history }) => (
          <button
            type="button"
            className="btn btn-link btn-sm"
            title="Edit"
            onClick={() => {
              history.push({
                pathname: "/service-schedule-edit",
                state: {
                  schedule: _schedule,
                },
              });
            }}
          >
            <EditIcon />
          </button>
        )}
      />
    );
    return <EditButton />;
  };

  scheduleAdd = () => {
    const ScheduleAddButton = () => (
      <Route
        render={({ history }) => (
          <Fab
            variant="extended"
            color="primary"
            size="small"
            onClick={() => {
              history.push({
                pathname: "/service-schedule-creation",
                state: {
                  subscriberId: this.state.subscriber_id,
                },
              });
            }}
          >
            Add{" "}
          </Fab>
        )}
      />
    );
    return <ScheduleAddButton />;
  };

  render() {
    let _showSchedules = this.state.allSchedules || [];
    const { order, orderBy, rowsPerPage, page } = this.state;

    return (
      <React.Fragment>
        {/* <NavBar /> */}
        <Grid container>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={8}>
                <Typography variant="h3" style={{ fontWeight: "bold" }}>
                  Service Schedule
                </Typography>
              </Grid>
              <Grid item xs={4} style={{ textAlign: "right" }}>
                {this.scheduleAdd()}
              </Grid>
              <Grid item xs={6}>
                <SubscriberSelect
                  name="subscriber_id"
                  value={this.state.subscriber_id}
                  onChange={this.onChange}
                  showAll={true}
                  placeholder={<div>Select Account....</div>}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} style={{ marginTop: "1em" }}>
            {this.state.loading ? (
              this.state.loading && (
                <Paper
                  elevation={3}
                  style={{ backgroundColor: "#fff", minHeight: "15em" }}
                >
                  <CircularProgress
                    size={50}
                    style={{
                      color: "#2196f3",
                      position: "absolute",
                      left: "55%",
                      marginTop: "4.5em",
                    }}
                  />
                </Paper>
              )
            ) : (
              <Paper>
                <TableContainer>
                  <Table
                    style={{ minWidth: 650 }}
                    size="small"
                    aria-label="a dense table"
                  >
                    <EnhancedTableHead
                      order={order}
                      orderBy={orderBy}
                      onRequestSort={this.handleRequestSort}
                      rowCount={_showSchedules.length}
                    />
                    {_showSchedules.length !== 0 ? (
                      <TableBody>
                        {stableSort(
                          _showSchedules,
                          getComparator(order, orderBy)
                        )
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((_schedule, i) => (
                            <TableRow key={i}>
                              <TableCell component="th" scope="row">
                                {_schedule.location_name}
                              </TableCell>
                              <TableCell>
                                {" "}
                                {"#" +
                                  _schedule.ticket_id +
                                  " " +
                                  _schedule.subject}
                              </TableCell>
                              <TableCell>
                                {AppHelper_DateAndTimeToString(
                                  _schedule.schedule_date
                                )}
                              </TableCell>
                              <TableCell>{_schedule.status}</TableCell>
                              <TableCell>{_schedule.remarks}</TableCell>
                              <TableCell>{_schedule.company_name}</TableCell>

                              <TableCell>
                                {this.editButton(_schedule)}
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    ) : (
                      <TableBody>
                        <TableRow>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                          <TableCell>
                            <Typography
                              variant="h5"
                              style={{
                                textAlign: "center",
                                marginTop: "1em",
                                marginBottom: "1em",
                              }}
                            >
                              Currently No Locations
                            </Typography>
                          </TableCell>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>

                <TablePagination
                  rowsPerPageOptions={[50, 200, 500]}
                  component="div"
                  count={_showSchedules.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onChangePage={this.handleChangePage}
                  onChangeRowsPerPage={this.handleChangeRowsPerPage}
                />
              </Paper>
            )}
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

export default ServiceScheduleList;
