import React from "react";

import ToastAlert from "./ToastAlert";
import Navbar from "../compon-styled/SupportCenterNavBar";
import SubscriberSelect from "../compon-controls/SubscriberSelect";
import SubscriptionItem from "./SubscriptionItem";
import { api } from "../businessjs/API";

class Subscription extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      subscriber_id: 0,
      title: "",
      type: "",
      device_id: "",
      subscription_code: "",
      re_newal_interval: "",

      toastItems: [],
      subscribers: [],

      allDataRows: [],
      filteredDataRows: []
    };

    this.searchHandler = this.searchHandler.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  searchHandler(event) {
    let searcjQery = event.target.value,
      filteredDataRows = this.state.allDataRows.filter(el => {
        let searchValue = el.title;
        return searchValue.indexOf(searcjQery) !== -1;
      });
    this.setState({
      filteredDataRows: filteredDataRows
    });
  }

  componentDidMount = async () => {
    const allSubscription = await api.post("subscription/getAll");
    if (allSubscription) {
      this.setState({ allDataRows: allSubscription });
      this.setState({ filteredDataRows: allSubscription });
    }

    const allSubscriber = await api.post("subscriber/getAll");

    if (allSubscriber) {
      if (allSubscriber.userdata) {
        var jsonArr = [];
        allSubscriber.userdata.map(data => {
          jsonArr.push({ value: data.autoid, text: data.company_name });
          return 0;
        });

        this.setState({ subscribers: jsonArr });
      }
    }
  };

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  onSubmit = async e => {
    e.preventDefault();

    const regFormInputes = {
      subscriber_id: this.state.subscriber_id.value.autoid,
      title: this.state.title,
      type: this.state.type,
      device_id: this.state.device_id,
      subscription_code: this.state.subscription_code,
      re_newal_interval: this.state.re_newal_interval
    };
    const subscription = await api.post("subscription/new", regFormInputes);
    if (subscription) {
      this.onToastItemAdd(subscription.status, subscription.message);
      if (subscription.status === "success") {
        const allSubscription = await api.post("subscription/getAll");
        if (allSubscription) {
          this.setState({ allDataRows: allSubscription });
          this.setState({ filteredDataRows: allSubscription });
        }
      }
    }
  };

  handleOnDelete = async itemObject => {
    const newFiltedItems = this.state.filteredDataRows.filter(
      c => c.autoid !== itemObject.autoid
    );

    const newAllItems = this.state.allDataRows.filter(
      c => c.autoid !== itemObject.autoid
    );

    this.setState({ allDataRows: newAllItems });
    this.setState({ filteredDataRows: newFiltedItems });

    const statusUpdate = await api.post(
      "subscription/statusUpdate",
      itemObject
    );
    if (statusUpdate) {
      this.onToastItemAdd(statusUpdate.status, statusUpdate.message);
    }
  };

  handleVehicleAssign = async itemObject => {
    const assignToVehicle = await api.post(
      "subscription/subscriptionAssignToVehicle",
      itemObject
    );

    if (assignToVehicle) {
      this.onToastItemAdd(assignToVehicle.status, assignToVehicle.message);
    }
  };

  handleOnUpdate = async itemObject => {
    const updateSubscription = await api.post(
      "subscription/update",
      itemObject
    );
    if (updateSubscription) {
      this.onToastItemAdd(
        updateSubscription.status,
        updateSubscription.message
      );

      if (updateSubscription.status === "success") {
        const allSubscription = await api.post("subscription/getAll");
        if (Array.isArray(allSubscription)) {
          this.setState({ allDataRows: allSubscription });
          this.setState({ filteredDataRows: allSubscription });
        }
      }
    }
  };

  handleToastItemClose = itemObject => {
    const toastItems = this.state.toastItems.filter(
      c => c.id !== itemObject.id
    );
    this.setState({ toastItems });
  };

  onToastItemAdd(type, message) {
    let newid = this.state.toastItems.length + 1;
    if (
      this.state.toastItems.filter(function(e) {
        return e.id === newid;
      }).length > 0
    ) {
      newid = this.state.toastItems.length + Math.floor(Math.random() * 100);
    }

    let newToastItems = this.state.toastItems;
    let itemToast = {
      id: newid,
      type: type === "success" ? "success" : "danger",
      message: message
    };

    newToastItems.push(itemToast);
    this.setState({ toastItems: newToastItems });

    setTimeout(() => {
      this.handleToastItemClose(itemToast);
    }, 5000);
  }

  render() {
    let showDataRows = this.state.filteredDataRows;

    return (
      <React.Fragment>
        {/* <Navbar /> */}
        <main role="main">
          <section>
            <div className="container mt-4">
              <div className="container">
                <h1> Subscription </h1>
                <div className="row">
                  <ToastAlert
                    toastItems={this.state.toastItems}
                    onItemClose={this.handleToastItemClose}
                  />
                  <div className="col-md-6 col-12">
                    <div className="card mb-1">
                      <div className="card-header">
                        <h4 className="card-title"> Form</h4>
                      </div>
                      <div className="card-content collpase show">
                        <div className="card-body">
                          <form className="form" onSubmit={this.onSubmit}>
                            <div className="form-body">
                              <div className="row">
                                <div className="col-12">
                                  <div className="form-group">
                                    <label>Subscriber</label>
                                    <div className="position-relative has-icon-left">
                                      <SubscriberSelect
                                        name="subscriber_id"
                                        value={this.state.subscriber_id}
                                        onChange={this.onChange}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-12">
                                  <div className="form-group">
                                    <label>Title</label>
                                    <div className="position-relative has-icon-left">
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="title"
                                        value={this.state.title}
                                        onChange={this.onChange}
                                        required
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-12">
                                  <div className="form-group">
                                    <label>Device Id</label>
                                    <div className="position-relative has-icon-left">
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="device_id"
                                        value={this.state.device_id}
                                        onChange={this.onChange}
                                        required
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-6 col-12">
                                  <div className="form-group">
                                    <label>Type</label>
                                    <div className="position-relative has-icon-left">
                                      <select
                                        className="form-control"
                                        name="type"
                                        onChange={this.onChange}
                                        value={this.state.type}
                                      >
                                        <option value=""> Select </option>
                                        <option value="1">Tracking</option>
                                        <option value="2">Other</option>
                                      </select>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-6 col-12">
                                  <div className="form-group">
                                    <label>Re-Newal Interval</label>
                                    <div className="position-relative has-icon-left">
                                      <select
                                        className="form-control"
                                        name="re_newal_interval"
                                        onChange={this.onChange}
                                        value={this.state.re_newal_interval}
                                      >
                                        <option value="0"> Select </option>
                                        <option value="30">1 Month</option>
                                        <option value="90">3 Month</option>
                                        <option value="365">1 Year</option>
                                        <option value="730">2 Year</option>
                                      </select>
                                    </div>
                                  </div>
                                </div>{" "}
                              </div>
                              <div className="form-actions right">
                                <button
                                  type="reset"
                                  className="btn btn-warning mr-1"
                                >
                                  <i className="ft-x" /> Cancel
                                </button>
                                <button
                                  type="submit"
                                  className="btn btn-primary"
                                >
                                  <i className="fa fa-check-square-o" /> Submit
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-12">
                    <div className="card">
                      <div className="card-header">
                        <input
                          type="text"
                          className="card-search float-right"
                          placeholder="Search... "
                          onChange={this.searchHandler}
                        />
                        <h4 className="card-title"> List</h4>
                      </div>
                      <div className="card-content collpase show">
                        <div className="card-body">
                          <div className="filterlist">
                            <ul>
                              {showDataRows.map(el => {
                                return (
                                  <SubscriptionItem
                                    key={el.autoid}
                                    itemObject={el}
                                    onDelete={this.handleOnDelete}
                                    onUpdate={this.handleOnUpdate}
                                    onAssign={this.handleVehicleAssign}
                                    subscribers={this.state.subscribers}
                                  />
                                );
                              })}
                            </ul>
                            <center>
                              {showDataRows.length
                                ? ""
                                : "No records available"}
                            </center>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </React.Fragment>
    );
  }
}

export default Subscription;
