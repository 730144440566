import React, { Component } from "react";
import Datetime from "react-datetime";
import jwt_decode from "jwt-decode";
import { Route } from "react-router-dom";
import { Col, Row } from "reactstrap";

import ToastAlert from "./ToastAlert";
import Navbar from "../compon-styled/SupportCenterNavBar";
import CmbSelect from "../compon-controls/CmbSelect";
import { AuthHelperAccessCheck } from "../businessjs/AuthHelper";
import { AppHelper_DateAndTimeToString } from "../businessjs/AppHelperFn";
import { api } from "../businessjs/API";
import { withStyles } from "@material-ui/core/styles";
import {
  Paper,
  Grid,
  Typography,
  TextField,
  MenuItem,
  Fab,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
} from "@material-ui/core";
import { SnackBar } from "./Login/SnackBar";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing.unit * 2,
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  table: {
    minWidth: 700,
  },
});

class ServiceSchedules extends Component {
  state = {
    scheduleDate: new Date(),
    location_name: undefined,
    grid_location_name: undefined,
    locationList: [],
    locationId: 0,
    status: 0,
    remarks: "",
    toastItems: [],
    serviceScheduleItems: [],
    subscriber_id: this.props.location.state.subscriber_id,
    errorMessage: "",
  };

  componentDidMount = async () => {
    const token = localStorage.apiAuthAccessKey;
    const decoded = jwt_decode(token);
    const decodedUser = decoded.userAccessKey[0];
    this.setState({
      subscriber_id: this.props.location.state.subscriber_id,
      executive_id: decodedUser.autoid,
    });

    const _locationInput = {
      subscriber_id: this.props.location.state.subscriber_id,
      executive_id: decodedUser.autoid,
      ticketId: this.props.location.state.ticketId,
    };
    const subscriberLocation = await api.post(
      "ticket/get-subscriber-location",
      _locationInput
    );

    if (subscriberLocation) {
      if (Array.isArray(subscriberLocation)) {
        let dbArray = subscriberLocation.map((opt) => ({
          label: opt.location_name,
          value: opt,
        }));
        dbArray.splice(0, 0, {
          label: "Select",
          value: { autoid: 0 },
        });
        this.setState({ locationList: dbArray });
      } else {
        AuthHelperAccessCheck(subscriberLocation);
      }
    }

    const serviceSchedule = await api.post(
      "ticket/get-service-schedule",
      _locationInput
    );
    if (serviceSchedule) {
      this.setState({ serviceScheduleItems: serviceSchedule });
    } else {
      AuthHelperAccessCheck(serviceSchedule);
    }
  };

  onSubmit = async (e) => {
    e.preventDefault();
    if (this.state.locationId == 0) {
      this.setState({ errorMessage: "Please select Location." });
      return;
    } else {
      this.setState({ errorMessage: "" });
      const token = localStorage.apiAuthAccessKey;
      const decoded = jwt_decode(token);
      const decodedUser = decoded.userAccessKey[0];
      const _scheduleInput = {
        subscriber_id: this.state.subscriber_id,
        executive_id: decodedUser.autoid,
        userType: decodedUser.role,
        ticketId: this.props.location.state.ticketId,
        locationId: this.state.locationId,
        scheduleDate: this.state.scheduleDate,
        status: this.state.status,
        remarks: this.state.remarks,
      };

      const addService = await api.post(
        "ticket/add-service-schedule",
        _scheduleInput
      );
      if (addService) {
        AuthHelperAccessCheck(addService);
        if (addService.status === "success") {
          this.onToastItemAdd(addService.status, addService.message);
          this.addSevicesToGrid();
        }
      }
    }
  };

  addSevicesToGrid = () => {
    let items = this.state.serviceScheduleItems;
    let length = this.state.serviceScheduleItems.length;
    items.splice(length, 0, {
      location_name: this.state.grid_location_name,
      schedule_date: this.state.scheduleDate,
      remarks: this.state.remarks,
      status: this.state.status === 0 ? "Open" : "Closed",
    });

    this.setState({ serviceScheduleItems: items });
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onDateChange = (e) => {
    this.setState({ scheduleDate: e._d });
  };

  locationChange = (e) => {
    this.setState({
      locationId: e.target.value.value.autoid,
      grid_location_name: e.target.value.value.location_name,
    });
  };

  handleToastItemClose = (itemObject) => {
    const toastItems = this.state.toastItems.filter(
      (c) => c.id !== itemObject.id
    );
    this.setState({ toastItems });
  };

  onToastItemAdd(type, message) {
    let newid = this.state.toastItems.length + 1;
    if (
      this.state.toastItems.filter(function (e) {
        return e.id === newid;
      }).length > 0
    ) {
      newid = this.state.toastItems.length + Math.floor(Math.random() * 100);
    }

    let newToastItems = this.state.toastItems;
    let itemToast = {
      id: newid,
      type: type === "success" ? "success" : "danger",
      message: message,
    };

    newToastItems.push(itemToast);
    this.setState({ toastItems: newToastItems });

    setTimeout(() => {
      this.handleToastItemClose(itemToast);
    }, 5000);
  }

  cancelButton = () => {
    const ButtonCancel = () => (
      <Route
        render={({ history }) => (
          <Fab
            color="primary"
            size="small"
            variant="extended"
            onClick={() => {
              if (this.props.location.state.from === "account") {
                this.props.history.push({
                  pathname: `/account-details`,
                  state: {
                    companyId: this.state.subscriber_id,
                  },
                });
              } else {
                history.push({
                  pathname: "/ticket-list",
                });
              }
            }}
          >
            Cancel
          </Fab>
        )}
      />
    );
    return <ButtonCancel />;
  };
  onCloseSnackbar = () => {
    this.setState({ errorMessage: "" });
  };
  render() {
    const { classes } = this.props;
    const { errorMessage } = this.state;
    return (
      <div className={classes.root}>
        <SnackBar
          status={errorMessage}
          onCloseError={this.onCloseSnackbar}
          color={"#d50000"}
        />
        <Paper className={classes.paper} elevation={3}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography
                variant="h3"
                color="textSecondary"
                style={{
                  fontWeight: "bold",
                  textAlign: "start",
                }}
              >
                Service Schedules
              </Typography>
            </Grid>

            <Grid item xs={6} sm={3}>
              <Typography
                style={{
                  fontWeight: "bold",
                  // marginTop: "2em",
                  textAlign: "start",
                  fontSize: "0.875rem",
                }}
              >
                Ticket ID{" "}
              </Typography>

              <TextField
                disabled
                value={`# ${this.props.location.state.ticketId}`}
                variant="outlined"
                fullWidth
              />
            </Grid>

            <Grid item xs={6} sm={3}>
              <Typography
                style={{
                  fontWeight: "bold",
                  textAlign: "start",
                  fontSize: "0.875rem",
                }}
              >
                Date
              </Typography>
              <Datetime
                defaultValue={this.state.scheduleDate}
                input={true}
                closeOnSelect={true}
                onChange={this.onDateChange}
                inputProps={{ style: { height: 57, width: 205 } }}
                fullWidth
              />
            </Grid>
            <Grid item xs={6} sm={3}>
              <Typography
                style={{
                  fontWeight: "bold",
                  textAlign: "start",
                  fontSize: "0.875rem",
                }}
              >
                Location
              </Typography>
              <CmbSelect
                name="location_name"
                value={this.state.location_name}
                onChange={this.locationChange}
                dataSource={this.state.locationList}
              />
            </Grid>
            <Grid item xs={6} sm={3}>
              <Typography
                style={{
                  fontWeight: "bold",
                  textAlign: "start",
                  fontSize: "0.875rem",
                }}
              >
                Status
              </Typography>
              <TextField
                id="outlined-select-currency"
                select
                name="status"
                onChange={this.onChange}
                value={this.state.status}
                variant="outlined"
                fullWidth
              >
                <MenuItem key="0" value="0">
                  Open
                </MenuItem>
                <MenuItem key="1" value="1">
                  Closed
                </MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <Typography
                style={{
                  fontWeight: "bold",
                  textAlign: "start",
                  fontSize: "0.875rem",
                }}
              >
                Remarks
              </Typography>
              <TextField
                fullWidth
                multiline
                rows={3}
                rowsMax={10}
                variant="outlined"
                type="text"
                name="remarks"
                value={this.state.remarks}
                onChange={this.onChange}
              />
            </Grid>
            <Grid item xs={6} style={{ textAlign: "start" }}>
              <Fab
                type="submit"
                color="primary"
                size="small"
                variant="extended"
                style={{ marginRight: "1em", marginLeft: "1em" }}
                onClick={this.onSubmit}
              >
                Save
              </Fab>

              {this.cancelButton()}
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="h4"
                color="textSecondary"
                style={{
                  fontWeight: "bold",
                }}
              >
                Service Schedule List
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell>Location</TableCell>
                    <TableCell>Schedule Date</TableCell>
                    <TableCell>Remarks</TableCell>
                    <TableCell>Status</TableCell>
                  </TableRow>
                </TableHead>
                {this.state.serviceScheduleItems.length !== 0 ? (
                  <TableBody>
                    {this.state.serviceScheduleItems.map((item, i) => (
                      <TableRow key={i}>
                        <TableCell component="th" scope="row">
                          {item.location_name}
                        </TableCell>
                        <TableCell>
                          {AppHelper_DateAndTimeToString(item.schedule_date)}
                        </TableCell>
                        <TableCell>{item.remarks}</TableCell>
                        <TableCell>{item.status}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                ) : (
                  <TableBody>
                    <TableRow>
                      <TableCell> </TableCell>
                      <TableCell></TableCell>
                      <TableCell>
                        {" "}
                        <Typography variant="h5" style={{ margin: "1em" }}>
                          Currently No Service Schedule
                        </Typography>
                      </TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </Grid>
          </Grid>
        </Paper>
      </div>
    );
  }
}

export default withStyles(styles)(ServiceSchedules);
