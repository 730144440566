import React, { Component } from "react";
import Pagination from "react-js-pagination";
import Popup from "reactjs-popup";
import { RiseLoader } from "react-spinners";
import { css } from "@emotion/core";
import { Col, Row } from "reactstrap";
import {
  Grid,
  Typography,
  Paper,
  TableContainer,
  Table,
  TableBody,
  TablePagination,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  Fab,
  CircularProgress,
} from "@material-ui/core";
import { SnackBar } from "../compon-controls/SnackBar";
import CmbSelect from "../compon-controls/CmbSelect";
import { AuthHelperAccessCheck } from "../businessjs/AuthHelper";
import SubscriptionPopup from "./SubscriptionPopup";
import ToastAlert from "./ToastAlert";
import { api } from "../businessjs/API";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  fab: {
    width: "90px",
    height: "40px",
  },
});

const headCells = [
  {
    id: "autoid",
    numeric: true,
    label: "#",
  },
  {
    id: "product_name",
    numeric: false,
    label: "Name",
  },
  {
    id: "plate_number",
    numeric: false,
    label: "Vehicle",
  },

  { id: "view", label: "" },
];

const EnhancedTableHead = (props) => {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

const getComparator = (order, orderBy) => {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

class SubscriptionList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      subscriber_id: 0,
      activePage: undefined,
      itemsCountPerPage: 25,
      totalItemsCount: 0,
      loading: false,
      allSubscription: [],
      toastItems: [],
      order: "asc",
      orderBy: "",
      page: 0,
      rowsPerPage: 50,
      open: false,
      infomessage: "",
    };
  }

  componentDidMount = () => {
    this.getSubscriberForCmb();
  };

  getSubscriberForCmb = async () => {
    const subscribers = await api.post("sc-subscriber/get-all-for-cmb");

    if (subscribers && subscribers.userdata) {
      let subscriberArray = subscribers.userdata.map((opt) => ({
        label: opt.company_name,
        value: opt,
      }));
      this.setState({
        subscriber_id: subscriberArray[0],
        companyList: subscriberArray,
      });
      this.getSubscriptionList(subscriberArray[0]);
    } else {
      AuthHelperAccessCheck(subscribers);
    }
  };

  handleRequestSort = (event, property) => {
    const isAsc = this.state.orderBy === property && this.state.order === "asc";
    this.setState({ order: isAsc ? "desc" : "asc", orderBy: property });
  };

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: parseInt(event.target.value, 10), page: 0 });
  };

  getSubscriptionList = async (subsriber) => {
    const _subscriptionListInputs = {
      subscriber_id: subsriber.value.autoid,
    };

    const subscriptionList = await api.post(
      "sc-subscription/get-subscription-list",
      _subscriptionListInputs
    );
    console.log("subscriptionList", subscriptionList);
    if (subscriptionList) {
      this.setState({ allSubscription: subscriptionList, loading: false });
      if (!this.state.activePage) {
        this.setState({
          totalItemsCount: subscriptionList.length,
          activePage: 1,
        });
      }
    } else {
      this.setState({ loading: false });
      AuthHelperAccessCheck(subscriptionList);
    }
  };

  handlePageChange = (pageNumber) => {
    this.setState({ activePage: pageNumber });
    this.getSubscriptionList(pageNumber);
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    this.getSubscriptionList(e.target.value);
  };

  // className = (subscription) => {
  //   if (!subscription.plate_number) {
  //     return "btn btn-primary btn-sm";
  //   } else {
  //     return "btn btn-secondary btn-sm";
  //   }
  // };

  className = (subscription) => {
    if (!subscription.plate_number) {
      return "badge badge-pill badge-success"; // assign
    } else {
      return "badge badge-pill badge-warning";
    }
  };

  getColor = (subscription) => {
    if (!subscription.plate_number) {
      return "primary"; // assign
    } else {
      return "secondary";
    }
  };

  buttonText = (subscription) => {
    if (!subscription.plate_number) {
      return "Assign";
    } else {
      return "Re-Assign";
    }
  };

  subscriptionAdd = async (subscriptionObject) => {
    console.log("subscriptionObject", subscriptionObject);
    const assignSubscription = await api.post(
      "sc-subscription/assign-subscription-to-vehicle",
      subscriptionObject
    );
    console.log("assignSubscription", assignSubscription);

    if (assignSubscription) {
      this.setState({ errorMessage: assignSubscription.message });
      // this.onToastItemAdd(
      //   assignSubscription.status,
      //   assignSubscription.message
      // );
      this.setState({ errorMessage: assignSubscription.message });
      this.getSubscriptionList(this.state.subscriber_id);
    }
  };

  handleToastItemClose = (itemObject) => {
    const toastItems = this.state.toastItems.filter(
      (c) => c.id !== itemObject.id
    );
    this.setState({ toastItems });
  };

  onToastItemAdd(type, message) {
    let newid = this.state.toastItems.length + 1;
    if (
      this.state.toastItems.filter(function (e) {
        return e.id === newid;
      }).length > 0
    ) {
      newid = this.state.toastItems.length + Math.floor(Math.random() * 100);
    }

    let newToastItems = this.state.toastItems;
    let itemToast = {
      id: newid,
      type: type === "success" ? "success" : "danger",
      message: message,
    };

    newToastItems.push(itemToast);
    this.setState({ toastItems: newToastItems });

    setTimeout(() => {
      this.handleToastItemClose(itemToast);
    }, 5000);
  }

  onCloseSnackbar = () => {
    this.setState({ errorMessage: "" });
  };

  render() {
    const { classes } = this.props;
    let _showSubscription = this.state.allSubscription;
    return (
      <React.Fragment>
        <Grid container spacing={3}>
          <SnackBar
            status={this.state.errorMessage}
            onCloseError={this.onCloseSnackbar}
            color={"#1b5e20"}
          />
          <Grid item xs={12}>
            <Typography variant="h3" style={{ fontWeight: "bold" }}>
              Subscription
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <CmbSelect
              name="subscriber_id"
              value={this.state.subscriber_id}
              onChange={this.onChange}
              dataSource={this.state.companyList}
            />
          </Grid>

          <Grid item xs={12}>
            <Paper>
              {this.state.loading ? (
                this.state.loading && (
                  <Paper
                    elevation={3}
                    style={{
                      backgroundColor: "#fff",
                      minHeight: "15em",
                      align: "center",
                    }}
                  >
                    <CircularProgress size={50} style={{ marginLeft: "40%" }} />
                  </Paper>
                )
              ) : (
                <TableContainer>
                  <Table
                    aria-labelledby="tableTitle"
                    aria-label="enhanced table"
                  >
                    <EnhancedTableHead
                      order={this.state.order}
                      orderBy={this.state.orderBy}
                      onRequestSort={this.handleRequestSort}
                      rowCount={_showSubscription.length}
                    />
                    {_showSubscription.length !== 0 ? (
                      <TableBody>
                        {stableSort(
                          _showSubscription,
                          getComparator(this.state.order, this.state.orderBy)
                        )
                          .slice(
                            this.state.page * this.state.rowsPerPage,
                            this.state.page * this.state.rowsPerPage +
                              this.state.rowsPerPage
                          )
                          .map((subscription, index) => {
                            return (
                              <TableRow hover tabIndex={-1} key={index}>
                                <TableCell>
                                  {"# " + subscription.autoid}
                                </TableCell>
                                <TableCell>
                                  {subscription.product_name}
                                </TableCell>
                                <TableCell>
                                  {subscription.plate_number}
                                </TableCell>
                                <TableCell>
                                  <Popup
                                    trigger={
                                      <Fab
                                        size="small"
                                        variant="extended"
                                        color={this.getColor(subscription)}
                                      >
                                        {this.buttonText(subscription)}
                                      </Fab>
                                    }
                                    modal
                                    closeOnDocumentClick
                                  >
                                    {(close) => (
                                      <SubscriptionPopup
                                        save={this.subscriptionAdd}
                                        cancel={close}
                                        subscriber_id={this.state.subscriber_id}
                                        productObject={subscription}
                                      />
                                    )}
                                  </Popup>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    ) : (
                      <Typography
                        style={{
                          textAlign: "center",
                          marginTop: "1em",
                        }}
                      >
                        No Records...............
                      </Typography>
                    )}
                  </Table>
                </TableContainer>
              )}
              <TablePagination
                rowsPerPageOptions={[50, 200, 500]}
                component="div"
                count={_showSubscription.length}
                rowsPerPage={this.state.rowsPerPage}
                page={this.state.page}
                onChangePage={this.handleChangePage}
                onChangeRowsPerPage={this.handleChangeRowsPerPage}
              />
            </Paper>
          </Grid>
        </Grid>
        {/* <Row
          style={{
            margin: "0",
          }}
        >
          <Col
            style={{
              flexDirection: "column",
              display: "flex",
            }}
          >
            <Row>
              <ToastAlert
                toastItems={this.state.toastItems}
                onItemClose={this.handleToastItemClose}
              />
              <Col>
                <div className="container mt-4">
                  <div className="card">
                    <div className="card-header">
                      <Row style={{ alignItems: "center" }}>
                        <Col xl={7} lg={7} md={5} sm={12} xs={12}>
                          <h3>Subscription</h3>
                        </Col>
                        <Col xl={3} lg={3} md={4} sm={8} xs={12}>
                          <CmbSelect
                            name="subscriber_id"
                            value={this.state.subscriber_id}
                            onChange={this.onChange}
                            dataSource={this.state.companyList}
                          />
                        </Col>

                        <Col
                          xl={2}
                          lg={2}
                          md={3}
                          sm={4}
                          xs={12}
                          style={{ display: "flex" }}
                        >
                          <button
                            type="button"
                            className="btn btn-primary btn-sm"
                            onClick={this.getSubscriptionCount}
                            style={{ height: "fit-content" }}
                          >
                            Search
                          </button>
                          <div className="sweet-loading">
                            <RiseLoader
                              css={loaderCSS}
                              sizeUnit={"px"}
                              size={10}
                              color={"#123abc"}
                              loading={this.state.loading}
                            />
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="container mt-2">
                  <div className="card">
                    <div className="table-responsive">
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th># </th>
                            <th>Name </th>
                            <th>Vehicle</th>
                          </tr>
                        </thead>
                        <tbody>
                          {_showSubscription.map((_subscription, i) => (
                            <React.Fragment key={i}>
                              <tr className="grid-rows-text">
                                <td>{"# " + _subscription.autoid}</td>
                                <td>{_subscription.product_name}</td>
                                <td>{_subscription.plate_number}</td>
                                <td>
                                  <Popup
                                    trigger={
                                      <button
                                        className={this.className(
                                          _subscription
                                        )}
                                      >
                                        {this.buttonText(_subscription)}
                                      </button>
                                    }
                                    modal
                                    closeOnDocumentClick
                                  >
                                    {(close) => (
                                      <SubscriptionPopup
                                        save={this.subscriptionAdd}
                                        cancel={close}
                                        subscriber_id={this.state.subscriber_id}
                                        productObject={_subscription}
                                      />
                                    )}
                                  </Popup>
                                </td>
                              </tr>
                            </React.Fragment>
                          ))}
                        </tbody>
                      </table>
                      <center>
                        {_showSubscription.length
                          ? ""
                          : "Currently No Subscriptions"}
                      </center>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="container">
                  <Pagination
                    activePage={this.state.activePage}
                    itemsCountPerPage={this.state.itemsCountPerPage}
                    totalItemsCount={this.state.totalItemsCount}
                    pageRangeDisplayed={10}
                    onChange={this.handlePageChange}
                  />
                </div>
              </Col>
            </Row>
          </Col>
        </Row> */}
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(SubscriptionList);
