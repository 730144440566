import {
  TableContainer,
  Table,
  TableBody,
  TablePagination,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  Grid,
  Box,
  Typography,
  IconButton,
  Tooltip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  CircularProgress,
} from "@material-ui/core";
import React, { Component, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
  Route,
} from "react-router-dom";
import EditIcon from "@material-ui/icons/Edit";
import { Button } from "reactstrap";
import { api } from "../../businessjs/API";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 650,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  contactTableRow: {
    paddingRight: "3em",
  },
  margin: {
    margin: theme.spacing(1),
  },
  dialogBoxTable: {
    minWidth: 500,
  },
}));

const headCells = [
  {
    id: "product_name",
    numeric: false,
    label: "Name",
  },
  {
    id: "price",
    numeric: false,
    label: "Price(Qr)",
  },

  {
    id: "active_days",
    numeric: false,
    label: "warranty(Days)",
  },

  { id: "status", numeric: false, label: "Status" },
  { id: "edit", label: "" },
];

const EnhancedTableHead = (props) => {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead style={{ backgroundColor: "white" }}>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            // padding={headCell.disablePadding ? "none" : "default"}
            padding={"default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              style={{ color: "black" }}
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

const getComparator = (order, orderBy) => {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

export const BundleListTabPanel = (props) => {
  const { children, value, index, ...other } = props;

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("account_name");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [color, setColor] = useState("#d50000");
  const [infoMessgae, setInfoMessage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [bundleName, setBundleName] = useState("");
  const [productBundleItems, setProductBundleItems] = useState(undefined);
  const classes = useStyles();
  const rows = children;
  //   console.log("in service", props);
  // const { rows } = [];

  // console.log("props", props);
  const NavLink = React.forwardRef((props, ref) => (
    <RouterLink innerRef={ref} {...props} />
  ));

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const editButton = (product) => {
    const ProductEditButton = () => (
      <Route
        render={({ history }) => (
          <IconButton
          // size="medium"
          >
            <Tooltip title="Edit">
              <EditIcon
                className={classes.extendedIcon}
                color="primary"
                onClick={() => {
                  history.push({
                    pathname: "/product",
                    state: {
                      type: "edit",
                      product_id: product.autoid,
                    },
                  });
                }}
              />
            </Tooltip>{" "}
          </IconButton>
        )}
      />
    );
    return <ProductEditButton />;
  };

  /**
   * to get badge based on status fetch from DB.
   */
  const getBadge = (status) => {
    let _classes = "badge badge-";
    _classes +=
      status === "Suspended"
        ? "danger"
        : status === "Active"
        ? "success"
        : "primary";
    return _classes;
  };

  const handleClickOpen = (product) => {
    // setOpen(true);
    const bundleItemInputs = {
      productId: product.autoid,
      bundleId: product.bundle_id,
    };
    getProductBundleItems(bundleItemInputs);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const getProductBundleItems = async (bundleItemInputs) => {
    setLoading(true);
    try {
      const bundleItems = await api.post(
        "sc-bundle/get-product-bundle-items",
        bundleItemInputs
      );
      console.log("bundleItems", bundleItems);
      if (bundleItems && Array.isArray(bundleItems)) {
        setBundleName(bundleItems[0].bundle_name);
        setProductBundleItems(bundleItems);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log("something went wrong!", error);
    }
  };

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography style={{ paddingBottom: "1em" }}>Bundle List</Typography>
          <TableContainer border={1}>
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              aria-label="enhanced table"
            >
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                // rowCount={rows.length}
                rowCount={Array.isArray(rows) && rows.length}
              />

              {Array.isArray(rows) && rows.length !== 0 ? (
                <TableBody>
                  {stableSort(rows, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      return (
                        <Tooltip title="Click to show the items">
                          <TableRow
                            hover
                            tabIndex={-1}
                            key={index}
                            onClick={() => handleClickOpen(row)}
                          >
                            <TableCell>{row.product_name}</TableCell>
                            <TableCell>{row.price}</TableCell>
                            <TableCell>{row.active_days}</TableCell>
                            <TableCell>
                              <span className={getBadge(row.status)}>
                                {row.status}
                              </span>
                            </TableCell>
                            <TableCell>{editButton(row)}</TableCell>
                          </TableRow>
                        </Tooltip>
                      );
                    })}
                </TableBody>
              ) : (
                <Typography
                  style={{
                    textAlign: "center",
                    marginTop: "1em",
                  }}
                >
                  No Records...............
                </Typography>
              )}
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[50, 200, 500]}
            component="div"
            count={Array.isArray(rows) && rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Box>
      )}

      <Dialog
        fullWidth={true}
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{bundleName}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {loading === true ? (
              <CircularProgress size={24} />
            ) : (
              <TableContainer border={1}>
                <Table className={classes.dialogBoxTable}>
                  <TableHead style={{ backgroundColor: "white" }}>
                    <TableRow>
                      <TableCell style={{ color: "black" }}>Name</TableCell>
                      <TableCell style={{ color: "black" }}>Price</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {productBundleItems &&
                      productBundleItems.map((row, index) => {
                        return (
                          <TableRow hover tabIndex={-1} key={index}>
                            <TableCell>{row.product_name}</TableCell>
                            <TableCell>{row.price}</TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
