import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Fab, Grid, CircularProgress } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { Link as RouterLink } from "react-router-dom";

import { Select } from "../../compon-controls/Select";
import { api } from "../../businessjs/API";
import { ContactsList } from "./ContactsList";
import { SnackBar } from "../../compon-controls/SnackBar";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },

  searchInput: {
    alignItems: "center",
    width: 400,
  },
  buttonProgress: {
    color: "#2196f3",
    position: "absolute",
    left: "55%",
    marginTop: "7em",
  },
}));

export const Contact = () => {
  const classes = useStyles();
  const [accountList, setAccountList] = useState([]);
  const [accountId, setAccountId] = useState(0);
  const [contactList, setContactList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [color, setColor] = useState("#d50000");
  const [infoMessgae, setInfoMessage] = useState(false);
  const NavLink = React.forwardRef((props, ref) => (
    <RouterLink innerRef={ref} {...props} />
  ));

  useEffect(() => {
    const loadAccounts = async () => {
      try {
        const account = await api.post("sc-account/get-all-for-cmb");
        if (account && account.accountSet) {
          let accountArray = account.accountSet.map((opt) => ({
            label: opt.name,
            value: opt,
          }));

          accountArray.splice(0, 0, {
            label: "Select",
            value: { autoid: 0 },
          });
          setAccountList(accountArray);
        }
      } catch (e) {
        console.log(e);
      }
    };
    loadAccounts();
  }, [accountList.length]);

  useEffect(() => {
    const _inputs = {
      accountId: accountId,
    };
    loadContactList(_inputs);
  }, [contactList.length]);

  const loadContactList = async (body) => {
    setLoading(true);
    try {
      const contactLists = await api.post("sc-contact/get-contact-list", body);
      if (Array.isArray(contactLists)) {
        setContactList(contactLists);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const deleteContact = async (autoId) => {
    const input = {
      autoId: autoId,
    };
    const deleteResult = await api.post("sc-contact/delete-contact", input);
    if (deleteResult.status === "success") {
      setInfoMessage(deleteResult.message);
      setColor("#4caf50");

      const _inputs = {
        accountId: accountId,
      };
      loadContactList(_inputs);
    } else {
      setInfoMessage(deleteResult.message);
      setColor("#d50000");
    }
  };

  const onCloseSnackbar = () => {
    setInfoMessage("");
  };

  return (
    <Grid container spacing={3}>
      <SnackBar
        status={infoMessgae}
        onCloseError={onCloseSnackbar}
        color={color}
      />
      <Grid item xs={12}>
        <Typography variant="h3" style={{ fontWeight: "bold" }}>
          Contacts List
        </Typography>
      </Grid>
      <Grid item xs={12} sm={8} style={{ textAlign: "left" }}>
        <Grid className={classes.searchInput}>
          <Select
            required
            style={{
              backgroundColor: "blue",
            }}
            onChange={(value) => {
              setAccountId(value);
              const _inputs = {
                accountId: value.value.autoid,
              };
              loadContactList(_inputs);
            }}
            options={accountList}
            placeholder={<div>Select Account....</div>}
            value={accountId}
            name="account_id"
          />
        </Grid>
      </Grid>
      <Grid item xs={4} style={{ textAlign: "right" }}>
        <Fab
          variant="extended"
          size="medium"
          color="primary"
          aria-label="add"
          disabled={loading}
          className={classes.margin}
          component={NavLink}
          to={`/contact/${"new"}`}
        >
          <AddIcon className={classes.extendedIcon} />
          Add
          {/* {loading === true ? <CircularProgress size={35} /> : null} */}
        </Fab>
      </Grid>

      <Grid item xs={12} style={{ minHeight: "25em" }}>
        {loading ? (
          loading && (
            <CircularProgress
              size={50}
              className={classes.buttonProgress}
            />
          )
        ) : (
          <ContactsList rows={contactList} deleteContact={deleteContact} />
        )}
      </Grid>
    </Grid>
  );
};
