import React, { Component } from "react";
import { Link as RouterLink, Route, Redirect } from "react-router-dom";
import { api } from "../businessjs/API";
import {
  Paper,
  Grid,
  Typography,
  Fab,
  TextField,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
} from "@material-ui/core";
import { SnackBar } from "./Login/SnackBar";
import { withStyles } from "@material-ui/core/styles";
import moment from "moment";

import CmbSelect from "../compon-controls/CmbSelect";
import { AuthHelperAccessCheck } from "../businessjs/AuthHelper";

const NavLink = React.forwardRef((props, ref) => (
  <RouterLink innerRef={ref} {...props} />
));

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing.unit * 2,
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
});

class Visit extends Component {
  constructor(props) {
    super(props);
    this.redirect = 0;

    this.state = {
      subscriber_id: this.props.location.state
        ? this.props.location.state.subscriber_id
        : undefined,
      subscriberList: [],
      location: undefined,
      locationList: [],
      visitDate: new Date(),
      toastItems: [],
      title: "",
      redirect: 0,
      visits: [],
      services: [],
      errorMessage: "",
      color: "#d50000",
    };
  }

  componentDidMount = () => {
    const _cmbInput = {
      subscriber_id: this.props.location.state.subscriber_id.value.autoid,
    };
    this.getLocationForCmb(_cmbInput);
    this.getSubscriberForCmb();
    this.getVisitServiceData(_cmbInput);
  };

  getLocationForCmb = async (_locationInput) => {
    const allLocation = await api.post(
      "sc-subscriber-location/get-subscriber-location",
      _locationInput
    );
    if (allLocation) {
      let locationArray = allLocation.map((opt) => ({
        label: opt.location_name,
        value: opt,
      }));
      this.setState({
        location: locationArray[0],
        locationList: locationArray,
      });
    } else {
      AuthHelperAccessCheck(allLocation);
    }
  };

  getSubscriberForCmb = async () => {
    const subscribers = await api.post("sc-subscriber/get-all-for-cmb");
    if (subscribers) {
      let subscriberArray = subscribers.userdata.map((opt) => ({
        label: opt.company_name,
        value: opt,
      }));
      this.setState({
        subscriberList: subscriberArray,
      });
    } else {
      AuthHelperAccessCheck(subscribers);
    }
  };

  cancelButton = () => {
    const ButtonCancel = () => (
      <Route
        render={({ history }) => (
          <button
            type="button"
            className="btn btn-warning btn-sm"
            onClick={() => {
              history.push({
                pathname: "/visit-list",
              });
            }}
          >
            Cancel
          </button>
        )}
      />
    );
    return <ButtonCancel />;
  };

  onDateChange = (e) => {
    this.setState({ visitDate: e });
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onSubscriberChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    const _cmbInput = {
      subscriber_id: e.target.value.value.autoid,
    };
    this.getLocationForCmb(_cmbInput);
    this.getVisitServiceData(_cmbInput);
  };

  getVisitServiceData = async (input) => {
    const res = await api.post("sc-visit/get-visit-service-data", input);
    if (res) {
      this.setState({
        services: res.services,
        visits: res.visits,
      });
    }
  };

  onSubmit = async (e) => {
    e.preventDefault();
    if (this.state.location === undefined) {
      this.setState({ errorMessage: "Please select location." });
      return;
    }
    const _visitAddInput = {
      subscriber_id: this.state.subscriber_id.value.autoid,
      location_id: this.state.location.value.autoid,
      visit_date: this.state.visitDate,
      name: this.state.title,
    };
    const res = await api.post("sc-visit/visit-add", _visitAddInput);

    if (res.status === "success") {
      this.setState({ errorMessage: res.message, color: "#4caf50" });
      AuthHelperAccessCheck(res);
      this.redirect = 1;
    }
  };

  getRowClass = (service) => {
    let _classes = "table-";
    _classes +=
      service.number_of_service_visits < service.service_completed
        ? "danger"
        : "light";
    return _classes;
  };

  onCloseSnackbar = () => {
    this.setState({ errorMessage: "" });
  };

  render() {
    const { classes } = this.props;
    if (this.redirect === 1) return <Redirect to="/visit-list" />;
    return (
      <React.Fragment>
        <SnackBar
          status={this.state.errorMessage}
          onCloseError={this.onCloseSnackbar}
          color={this.state.color}
        />
        <form className="form" onSubmit={this.onSubmit}>
          <Paper elevation={3} className={classes.paper}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography
                  variant="h3"
                  style={{
                    fontWeight: "bold",
                    textAlign: "start",
                  }}
                >
                  New Visit
                </Typography>
              </Grid>

              <Grid item xs={12} sm={4}>
                <Typography
                  style={{
                    fontWeight: "bold",
                    textAlign: "start",
                    fontSize: "0.875rem",
                  }}
                >
                  Subscriber
                </Typography>
                <CmbSelect
                  name="subscriber_id"
                  value={this.state.subscriber_id}
                  onChange={this.onSubscriberChange}
                  dataSource={this.state.subscriberList}
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <Typography
                  style={{
                    fontWeight: "bold",
                    textAlign: "start",
                    fontSize: "0.875rem",
                  }}
                >
                  Location
                </Typography>
                <CmbSelect
                  name="location"
                  value={this.state.location}
                  onChange={this.onChange}
                  dataSource={this.state.locationList}
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <Typography
                  variant="h6"
                  style={{ textAlign: "left", fontWeight: "bold" }}
                >
                  Date
                </Typography>
                <TextField
                  type="date"
                  fullWidth
                  variant="outlined"
                  name="visitDate"
                  required
                  value={moment(this.state.visitDate).format("YYYY-MM-DD")}
                  onChange={(event) => this.onDateChange(event.target.value)}
                />
              </Grid>

              <Grid item xs={12}>
                <Typography
                  style={{
                    fontWeight: "bold",
                    textAlign: "start",
                    fontSize: "0.875rem",
                  }}
                >
                  Title
                </Typography>
                <TextField
                  fullWidth
                  size="small"
                  variant="outlined"
                  name="title"
                  required
                  value={this.state.title}
                  onChange={this.onChange}
                />
              </Grid>

              <Grid
                container
                direction="row"
                style={{
                  paddingLeft: "1em",
                  marginTop: "1em",
                  marginBottom: "1em",
                }}
              >
                <Fab
                  variant="extended"
                  size="small"
                  color="primary"
                  type="reset"
                  component={NavLink}
                  to="/visit-list"
                >
                  Cancel
                </Fab>
                <Fab
                  variant="extended"
                  size="small"
                  color="primary"
                  aria-label="add"
                  type="submit"
                >
                  Add
                </Fab>
              </Grid>
            </Grid>
          </Paper>
        </form>

        {/* <Paper elevation={3} className={classes.paper}> */}
        <Grid container style={{ paddingTop: "2em" }}>
          <Grid item xs={12}>
            <Typography
              variant="h4"
              style={{
                // fontWeight: "bold",
                textAlign: "start",
              }}
              className="text-primary"
            >
              Products
            </Typography>
          </Grid>

          <Grid item xs={12} style={{ marginTop: "1em" }}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Visits</TableCell>
                  <TableCell>Completed</TableCell>
                  <TableCell>Max.Services/Visit</TableCell>
                  <TableCell>Quantity</TableCell>
                  <TableCell>Additional Price</TableCell>
                </TableRow>
              </TableHead>
              {this.state.services.length !== 0 ? (
                <TableBody>
                  {this.state.services.map((service, i) => (
                    <TableRow key={i} className={this.getRowClass(service)}>
                      <TableCell component="th" scope="row">
                        {service.product_name}
                      </TableCell>
                      <TableCell>{service.number_of_service_visits}</TableCell>
                      <TableCell>{service.service_completed}</TableCell>
                      <TableCell>{service.max_services_per_visit}</TableCell>
                      <TableCell>{service.quantity}</TableCell>
                      <TableCell>{service.additional_per_unit_price}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              ) : (
                <TableBody>
                  <TableRow>
                    <TableCell> </TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                      {" "}
                      <Typography variant="h5" style={{ margin: "1em" }}>
                        Currently No Products
                      </Typography>
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(Visit);
