import React, { useState, useEffect } from "react";
import {
  Paper,
  Grid,
  Typography,
  TextField,
  Fab,
  MenuItem,
} from "@material-ui/core";
import { Formik, Form } from "formik";
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from "react-router-dom";
import { api } from "../../businessjs/API";
import { SnackBar } from "../Login/SnackBar";
import { Redirect } from "react-router-dom";
import moment from "moment";

export const ProductStocksForm = (props) => {
  const [color, setColor] = useState("#d50000");
  const [infoMessgae, setInfoMessage] = useState(false);
  const [navigation, setNavigation] = useState(false);
  const [clientObject, setClientObject] = useState({});
  const [initialValues, setInitialValues] = useState({
    name: "",
    serial: "",
    productType: "",
    price: "",
    activeDays: "",
    priorWarning: "",
    suspendAfter: "",
    numOfService: "",
    status: "",
    startDate: moment().format("YYYY-MM-DD"),
    nextRenewal: moment().format("YYYY-MM-DD"),
  });
  const NavLink = React.forwardRef((props, ref) => (
    <RouterLink innerRef={ref} {...props} />
  ));
  const statusOptions = [
    { value: 1, label: "Active" },
    { value: 2, label: "Inactive" },
    { value: 3, label: "Suspend" },
  ];
  useEffect(() => {
    const clientId = props.match.params.id;
    async function loadClientDetails() {
      try {
        if (clientId) {
          const clientDetails = await api.post(
            "sc-client_product/get-client-products-by-id",
            { clientId }
          );
          // if (
          //   clientDetails !== undefined &&
          //   clientDetails.status !== "failed"
          // ) {
          if (clientDetails && Array.isArray(clientDetails)) {
            setClientObject(clientDetails);
            setInitialValues({
              name: clientDetails.product_name,
              serial: clientDetails.serial_no,
              productType: clientDetails.type,
              price: clientDetails.price,
              activeDays: clientDetails.active_days,
              priorWarning: clientDetails.prior_warning_days,
              suspendAfter: clientDetails.suspend_after_days,
              numOfService: clientDetails.number_of_service_visits,
              status: clientDetails.status,
              startDate: moment(clientDetails.start_date).format("YYYY-MM-DD"),
              nextRenewal: moment(clientDetails.next_renewable_date).format(
                "YYYY-MM-DD"
              ),
              // companyId: editUserDetails.accountId,
            });
          }
        } else {
        }
      } catch (e) {
        console.log(e);
      }
    }
    loadClientDetails();
  }, [props.match.params.id]);
  const editProductValues = async (values) => {
    if (values === initialValues) {
      setColor("#0091ea");
      setInfoMessage("Please change fields as required...");
    } else {
      const updateInput = {
        activeDays: values.activeDays,
        priorWarning: values.priorWarning,
        suspendAfter: values.suspendAfter,
        noOfService: values.numOfService,
        startDate: values.startDate,
        nextRewableDate: values.nextRenewal,
        clientProductId: clientObject.autoid,
        status: values.status,
      };
      const createResult = await api.post(
        "sc-client_product/edit-client-product",
        updateInput
      );
      if (createResult.status === "success") {
        setInfoMessage(`${clientObject.product_name} is updated....`);
        setColor("#4caf50");
        setTimeout(() => {
          setNavigation(true);
        }, 2000);
      } else {
        setInfoMessage(createResult.message);
        setColor("#d50000");
        setNavigation(false);
      }
      setNavigation(false);
    }
  };

  const onCloseSnackbar = () => {
    setInfoMessage("");
  };
  if (navigation) {
    return <Redirect to="/product-stocks"></Redirect>;
  } else {
    return (
      <div>
        <SnackBar
          status={infoMessgae}
          onCloseError={onCloseSnackbar}
          color={color}
        />
        <Paper elevation={4} style={{ minHeight: "25em", minWidth: "50%" }}>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            onSubmit={async (values) => {
              editProductValues(values);
            }}
          >
            {({
              values,
              handleChange,
              setFieldValue,
              resetForm,
              touched,
              errors,
            }) => (
              <Form>
                <Grid container spacing={3} style={{ padding: "2em" }}>
                  <Grid item xs={12}>
                    <Typography variant="h4" style={{ fontWeight: "bold" }}>
                      Edit product
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      required
                      fullWidth
                      variant="outlined"
                      name="name"
                      label="Name"
                      disabled
                      value={values.name}
                      onChange={handleChange}
                      inputProps={{ maxLength: 50 }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      fullWidth
                      required
                      variant="outlined"
                      name="serial"
                      label="Serial Number"
                      disabled
                      value={values.serial}
                      onChange={handleChange}
                      inputProps={{ maxLength: 50 }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      label="Product Type"
                      disabled
                      //   style={{ minHeight: "2em", backgroundColor: "blue" }}
                      value={values.productType}
                    ></TextField>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      fullWidth
                      required
                      variant="outlined"
                      name="price"
                      label="Price"
                      disabled
                      value={values.price}
                      onChange={handleChange}
                      inputProps={{ maxLength: 50 }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      fullWidth
                      required
                      variant="outlined"
                      name="activeDays"
                      label="Active Days"
                      value={values.activeDays}
                      onChange={handleChange}
                      inputProps={{ maxLength: 50 }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      fullWidth
                      required
                      variant="outlined"
                      name="priorWarning"
                      label="Prior Warning"
                      value={values.priorWarning}
                      onChange={handleChange}
                      inputProps={{ maxLength: 50 }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <TextField
                      fullWidth
                      required
                      variant="outlined"
                      name="suspendAfter"
                      label="Suspend After"
                      value={values.suspendAfter}
                      onChange={handleChange}
                      inputProps={{ maxLength: 50 }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      fullWidth
                      required
                      variant="outlined"
                      name="numOfService"
                      label="No.of Services/Visits"
                      value={values.numOfService}
                      onChange={handleChange}
                      inputProps={{ maxLength: 50 }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      fullWidth
                      select
                      required
                      variant="outlined"
                      label="Status"
                      //   style={{ minHeight: "2em", backgroundColor: "blue" }}
                      onChange={(value) => {
                        setFieldValue("status", value);
                      }}
                      value={values.status}
                    >
                      {statusOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      fullWidth
                      required
                      variant="outlined"
                      name="startDate"
                      label="Start Date"
                      value={values.startDate}
                      type="date"
                      onChange={handleChange}
                      inputProps={{ maxLength: 50 }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <TextField
                      fullWidth
                      required
                      variant="outlined"
                      name="nextRenewal"
                      label="Next Renewal"
                      type="date"
                      value={values.nextRenewal}
                      onChange={handleChange}
                      inputProps={{ maxLength: 50 }}
                    />
                  </Grid>

                  <Grid item xs={6} style={{ textAlign: "right" }}>
                    <Fab
                      variant="extended"
                      size="medium"
                      color="primary"
                      type="reset"
                    >
                      Cancel
                    </Fab>
                  </Grid>
                  <Grid item xs={6} style={{ textAlign: "left" }}>
                    <Fab
                      variant="extended"
                      size="medium"
                      color="primary"
                      aria-label="add"
                      type="submit"
                    >
                      Update
                    </Fab>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Paper>
      </div>
    );
  }
};
