import React, { useState, useEffect } from "react";
import "date-fns";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import {
  Typography,
  TextField,
  Fab,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  CircularProgress,
} from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AddIcon from "@material-ui/icons/Add";
import ExportIcon from "@material-ui/icons/ArrowUpward";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { CSVLink } from "react-csv";
import { Link as RouterLink } from "react-router-dom";
import { api } from "../../businessjs/API";
import { SnackBar } from "../Login/SnackBar";
import { PurchaseBillList } from "./PurchaseBillList";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  SearchInput: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  searchPaper: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    // width: 400,
  },
  margin: {
    margin: theme.spacing(1),
  },
  searchFab: {
    textAlign: "right",
    marginTop: "1em",
  },
  buttonProgress: {
    color: "#2196f3",
    position: "absolute",
    left: "55%",
    marginTop: "3.5em",
  },
}));

const headers = [
  { label: "Q.No", key: "autoid" },
  { label: "Purchase Number", key: "po_id" },
  { label: "Account", key: "account_name" },
  { label: "Date", key: "purchase_date" },
  { label: "Ship To", key: "ship_to" },
  { label: "Discount", key: "discount" },
  { label: "Order Total", key: "total_amount" },
];

export const PurchaseBill = () => {
  const classes = useStyles();
  const [rows, setRows] = useState([]);
  const [dataToExport, setDataToExport] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const [accountName, setAccountName] = useState(0);
  const [poNumber, setPoNumber] = useState("");
  const [purchaseNumber, setPurchaseNumber] = useState("");
  const [purchaseFromDate, setPurchaseFromDate] = useState(
    moment(new Date()).subtract(7, "days").format()
  );
  const [purchaseToDate, setPurchaseToDate] = useState(new Date());
  const [accountOptions, setAccountOptions] = useState([]);
  const [infoMessage, setInfoMessage] = useState("");
  const [color, setColor] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [expanded, setExpanded] = React.useState(false);
  // const [exportButtonDisabled, setExportButtonDisabled] = useState(true);
  const NavLink = React.forwardRef((props, ref) => (
    <RouterLink innerRef={ref} {...props} />
  ));

  useEffect(() => {
    setIsLoading(true);
    async function loadPurchaseBillLists() {
      try {
        const searchInput = {
          accountId: accountName,
          startDate: purchaseFromDate,
          endDate: purchaseToDate,
          poNumber: poNumber,
          purchaseNumber: purchaseNumber,
        };
        const purchaseBillList = await api.post(
          "sc-purchase/get-purchase-list",
          searchInput
        );
        setIsLoading(false);
        // if (
        //   purchaseBillList.status !== "failed" &&
        //   purchaseBillList !== undefined
        // ) {
        if (purchaseBillList && Array.isArray(purchaseBillList)) {
          setRows(purchaseBillList);
          setDataToExport(purchaseBillList);
          setRowCount(purchaseBillList.length);
          setDataToExport(purchaseBillList);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      } catch (e) {
        console.log(e);
      }
    }
    loadPurchaseBillLists();
  }, [rows.length]);
  useEffect(() => {
    async function loadAccounts() {
      try {
        const accountList = await api.post("sc-account/get-all-for-cmb");

        setAccountOptions(accountList.accountSet);
      } catch (e) {
        console.log("error", e);
      }
    }
    loadAccounts();
  }, []);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const handleFromDateChange = (date) => {
    setPurchaseFromDate(date);
    const searchInput = {
      accountId: accountName,
      startDate: date,
      endDate: purchaseToDate,
      poNumber: poNumber,
      purchaseNumber: purchaseNumber,
    };
    onSearchFunctionality(searchInput);
  };
  const handleToDateChange = (date) => {
    setPurchaseToDate(date);
    const searchInput = {
      accountId: accountName,
      startDate: purchaseFromDate,
      endDate: date,
      poNumber: poNumber,
      purchaseNumber: purchaseNumber,
    };
    onSearchFunctionality(searchInput);
  };
  const handlePoNumberChange = (value) => {
    setPoNumber(value);
    const searchInput = {
      accountId: accountName,
      startDate: purchaseFromDate,
      endDate: purchaseToDate,
      poNumber: value,
      purchaseNumber: purchaseNumber,
    };
    onSearchFunctionality(searchInput);
  };
  const handlePurchaseNumberChange = (value) => {
    setPurchaseNumber(value);
    const searchInput = {
      accountId: accountName,
      startDate: purchaseFromDate,
      endDate: purchaseToDate,
      poNumber: poNumber,
      purchaseNumber: value,
    };
    onSearchFunctionality(searchInput);
  };
  const handleSelectChange = (value) => {
    setAccountName(value);
    const searchInput = {
      accountId: value,
      startDate: purchaseFromDate,
      endDate: purchaseToDate,
      poNumber: poNumber,
      purchaseNumber: purchaseNumber,
    };
    onSearchFunctionality(searchInput);
  };
  const onSearchFunctionality = async (searchInput) => {
    setIsLoading(true);
    try {
      const purchaseBillList = await api.post(
        "sc-purchase/get-purchase-list",
        searchInput
      );
      setIsLoading(false);
      // if (
      //   PurchaseBillList !== undefined &&
      //   purchaseBillList.status !== "failed"
      // ) {
      if (purchaseBillList && Array.isArray(purchaseBillList)) {
        setRows(purchaseBillList);
        setDataToExport(purchaseBillList);
      }
    } catch (e) {
      console.log(e);
    }
  };
  const onDeletePurchaseBill = async (purchaseId) => {
    const purchaseDeleteList =
      rows && rows.filter((row) => row.autoid !== purchaseId);
    setRows(purchaseDeleteList);
    const purchaseListDeleted = await api.post("sc-purchase/delete-purchase", {
      purchaseId,
    });
    if (purchaseListDeleted.status === "success") {
      setInfoMessage(purchaseListDeleted.message);
      setColor("#2196f3");
    } else {
      setInfoMessage(purchaseListDeleted.message);
      setColor("#d50000");
    }
  };
  const onCloseSnackbar = () => {
    setInfoMessage("");
  };

  return (
    <Grid container spacing={3}>
      <SnackBar
        status={infoMessage}
        onCloseError={onCloseSnackbar}
        color={color}
      />
      <Grid item xs={12}>
        <Typography variant="h3" style={{ fontWeight: "bold" }}>
          Purchase Bills List
        </Typography>
      </Grid>
      <Grid item xs={12} style={{ textAlign: "right" }}>
        {/* <RouterLink to="/accountnew"> */}
        <Fab
          variant="extended"
          size="medium"
          color="primary"
          aria-label="add"
          disabled={isLoading}
          className={classes.margin}
          component={NavLink}
          to={`/purchase-bill/${"new"}`}
        >
          <AddIcon className={classes.extendedIcon} />
          Add New
        </Fab>
        {/* </RouterLink> */}
        <CSVLink
          data={dataToExport}
          headers={headers}
          filename={"purchasedetails.csv"}
        >
          <Fab
            variant="extended"
            size="medium"
            color="primary"
            style={{ display: rowCount !== 0 ? "" : "none" }}
            // disabled={exportButtonDisabled}
            aria-label="export"
            className={classes.margin}
          >
            <ExportIcon className={classes.extendedIcon} />
            Export
          </Fab>
        </CSVLink>
      </Grid>

      <Grid item xs={12} sm={12} style={{ textAlign: "left" }}>
        <Paper className={classes.searchPaper}>
          <Grid container spacing={3} style={{ padding: "2em" }}>
            <Grid item xs={12}>
              <Accordion
                expanded={expanded === "panel1"}
                onChange={handleChange("panel1")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography className={classes.heading}>Search</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {/* <Paper elevation={3}> */}
                  <Grid
                    container
                    spacing={3}
                    justify="center"
                    style={{ padding: "1em" }}
                  >
                    <Grid item xs={6} sm={2}>
                      <FormControl className={classes.formControl} fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Account Name
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          fullWidth
                          value={accountName}
                          onChange={(event) =>
                            handleSelectChange(event.target.value)
                          }
                        >
                          <MenuItem value={0}>All</MenuItem>
                          {accountOptions &&
                            accountOptions.map((option) => (
                              <MenuItem value={option.autoid} key={option.autoid}>
                                {option.name}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </Grid>

                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <Grid item xs={6} sm={3}>
                        <KeyboardDatePicker
                          disableToolbar
                          fullWidth
                          variant="inline"
                          format="dd-MM-yyyy"
                          id="date"
                          label="From Date"
                          value={purchaseFromDate}
                          onChange={handleFromDateChange}
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                        />
                      </Grid>
                    </MuiPickersUtilsProvider>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <Grid item xs={6} sm={3}>
                        <KeyboardDatePicker
                          disableToolbar
                          fullWidth
                          variant="inline"
                          format="dd-MM-yyyy"
                          id="date"
                          label="To Date"
                          value={purchaseToDate}
                          onChange={handleToDateChange}
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                        />
                      </Grid>
                    </MuiPickersUtilsProvider>
                    <Grid item xs={6} sm={2}>
                      <TextField
                        fullWidth
                        name="poNumber"
                        label="PO No."
                        value={poNumber}
                        onChange={(event) =>
                          handlePoNumberChange(event.target.value)
                        }
                        inputProps={{ maxLength: 50 }}
                      />
                    </Grid>
                    <Grid item xs={6} sm={2}>
                      <TextField
                        fullWidth
                        name="purchaseNumber"
                        label="Purchase No."
                        value={purchaseNumber}
                        onChange={(event) =>
                          handlePurchaseNumberChange(event.target.value)
                        }
                        inputProps={{ maxLength: 50 }}
                      />
                    </Grid>
                  </Grid>
                  {/* </Paper> */}
                </AccordionDetails>
              </Accordion>
            </Grid>
            <Grid item xs={12} style={{ marginTop: "2em", minHeight: "15em" }}>
              {isLoading ? (
                isLoading && (
                  <CircularProgress
                    size={50}
                    className={classes.buttonProgress}
                  />
                )
              ) : (
                <PurchaseBillList
                  rows={rows}
                  onDeletePurchaseBill={onDeletePurchaseBill}
                />
              )}
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};
