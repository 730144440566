import {
  TableContainer,
  Table,
  TableBody,
  TablePagination,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  Grid,
  Box,
  Typography,
  IconButton,
  Tooltip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  CircularProgress,
} from "@material-ui/core";
import React, { Component, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
  Route,
} from "react-router-dom";

export const SerialNumbersPanel = (props) => {
  const { children, value, index, ...other } = props;
  const rows = children;
  // console.log("in pdt tab", props);
  /**
   * to get badge based on status fetch from DB.
   */
  const getBadge = (status) => {
    let _classes = "badge badge-";
    _classes += status === "Sold up" ? "danger" : "success";
    return _classes;
  };

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <TableContainer border={1}>
            <Table>
              <TableHead style={{ backgroundColor: "white" }}>
                <TableRow>
                  <TableCell style={{ color: "black" }}>Batch Name</TableCell>
                  <TableCell style={{ color: "black" }}>Serial No</TableCell>
                  <TableCell style={{ color: "black" }}>Status</TableCell>
                </TableRow>
              </TableHead>
              {Array.isArray(rows) && rows.length !== 0 ? (
                <TableBody>
                  {rows.map((row, index) => {
                    return (
                      //   <Tooltip title="Click to show the items">
                      <TableRow
                        hover
                        tabIndex={-1}
                        key={index}
                        //   onClick={() => handleClickOpen(row)}
                      >
                        <TableCell>{row.batch_name}</TableCell>
                        <TableCell>{row.serial_no}</TableCell>
                        {/* <TableCell>
                            {row.status === 0 ? "Active" : "Sold"}
                          </TableCell> */}
                        <TableCell>
                          <span className={getBadge(row.status_value)}>
                            {row.status_value}
                          </span>
                        </TableCell>
                      </TableRow>
                      //   </Tooltip>
                    );
                  })}
                </TableBody>
              ) : (
                <Typography
                  style={{
                    textAlign: "center",
                    marginTop: "1em",
                  }}
                >
                  No Records...............
                </Typography>
              )}
            </Table>
          </TableContainer>
        </Box>
      )}
    </div>
  );
};
