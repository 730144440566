import ApiUrl from "../businessjs/APIConfig";
let proxyServer = ApiUrl.serverapi;

class API {
  setCredentials = (key = "", id = "") => {
    const authFields = {
      user_token: key,
      user_token_id: id,
    };

    localStorage.setItem("pronto_auth", JSON.stringify(authFields));
  };
  onAuthChanged = () => {};
  clearCredentials = () => {
    localStorage.removeItem("pronto_auth");
  };

  post = async (url, objItem, responseType = "json") => {
    const _url = proxyServer + url;
    const authFields = JSON.parse(localStorage.getItem("pronto_auth"));
    let response = await fetch(_url, {
      method: "POST",
      // mode: "cors",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ objItem: { ...objItem, ...authFields } }),
    });
    if (responseType === "json") {
      const result = await response.json();
      if (result.error_code === "5001") {
        this.onAuthChanged();
        return;
      } else {
        return result;
      }
    } else if (responseType === "blob") {
      return await response.blob();
    } else if (responseType === "text") {
      return await response.text();
    }
  };
}

export const api = new API();
