import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import {
  Typography,
  InputBase,
  IconButton,
  Fab,
  TableHead,
  TableCell,
  CircularProgress,
  TableRow,
  Table,
  TableBody,
  TableContainer,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { AccountList } from "./AccountList";
import SearchIcon from "@material-ui/icons/Search";
import ExportIcon from "@material-ui/icons/ArrowUpward";
import { CSVLink } from "react-csv";
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
  Redirect,
} from "react-router-dom";
import { api } from "../../businessjs/API";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  SearchInput: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  searchPaper: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: 400,
  },
  margin: {
    margin: theme.spacing(1),
  },
  buttonProgress: {
    marginTop: "5em",
  },
}));

const headers = [
  { label: "Q.No", key: "autoid" },

  { label: "Name", key: "name" },
  { label: "Account Type", key: "account_type_name" },
  { label: "Address", key: "address" },
  { label: "Building", key: "building" },
  { label: "City", key: "city" },
  { label: "State", key: "state" },
  { label: "Country", key: "country" },
  { label: "Location", key: "location" },
  { label: "Email", key: "email" },
  { label: "Phone No.", key: "phone" },
  { label: "Contact Name", key: "contact_name" },
  { label: "Note", key: "note" },
  // { label: "Update Time", key: "updatetime" },
  { label: "Zipcode", key: "zipcode" },
  { label: "Code", key: "code" },
];

export const Account = () => {
  const classes = useStyles();
  const [searchText, setSearchText] = useState("");
  const [rows, setRows] = useState([]);
  const [navigate, setNavigate] = useState(false);
  const [dataToExport, setDataToExport] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const [loading, setLoading] = useState(false);

  // const [exportButtonDisabled, setExportButtonDisabled] = useState(true);
  const NavLink = React.forwardRef((props, ref) => (
    <RouterLink innerRef={ref} {...props} />
  ));

  useEffect(() => {
    setLoading(true);
    async function loadAccounts() {
      try {
        setLoading(true);
        const accountList = await api.post("sc-account/get-account-list");

        // if (accountList.status !== "failed" && accountList !== undefined) {
        if (accountList && Array.isArray(accountList)) {
          setRows(accountList);
          setDataToExport(accountList);
          setRowCount(accountList.length);
          setLoading(false);
        } else {
          setLoading(false);
          setNavigate(true);
        }
      } catch (e) {
        console.log(e);
      }
    }
    loadAccounts();
  }, [rows.length]);
  const onSearchFunctionality = (searchKey) => {
    if (searchKey !== "") {
      setSearchText(searchKey);
      const data =
        rows &&
        rows.filter((item) => {
          return item.name.toUpperCase().includes(searchKey.toUpperCase());
        });
      if (data.length !== 0) {
        setDataToExport(data);
        setRowCount(data.length);
      } else {
        setRowCount(0);
        setDataToExport([]);
      }
    } else {
      setSearchText("");
      setRowCount(rows.length);
    }
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h3" style={{ fontWeight: "bold" }}>
          Accounts List
        </Typography>
      </Grid>
      <Grid item xs={12} sm={8} style={{ textAlign: "left" }}>
        <Paper className={classes.searchPaper}>
          <InputBase
            className={classes.SearchInput}
            value={searchText}
            placeholder="Search...."
            // inputProps={{ "aria-label": "search " }}
            onChange={(event) => onSearchFunctionality(event.target.value)}
          />
          <IconButton aria-label="search">
            <SearchIcon />
          </IconButton>
        </Paper>
      </Grid>
      <Grid item xs={4} style={{ textAlign: "right" }}>
        {/* <RouterLink to="/accountnew"> */}
        <Fab
          variant="extended"
          size="medium"
          color="primary"
          aria-label="add"
          className={classes.margin}
          component={NavLink}
          to={`/account/${"new"}`}
        >
          <AddIcon className={classes.extendedIcon} />
          Add Account
        </Fab>
        {/* </RouterLink> */}
        <CSVLink
          data={dataToExport}
          headers={headers}
          filename={"accountdetails.csv"}
        >
          <Fab
            variant="extended"
            size="medium"
            color="primary"
            style={{ display: rowCount !== 0 ? "" : "none" }}
            // disabled={exportButtonDisabled}
            aria-label="export"
            className={classes.margin}
          >
            <ExportIcon className={classes.extendedIcon} />
            Export
          </Fab>
        </CSVLink>
      </Grid>
      {/* <Grid item xs={4} sm={2} style={{marginLeft:"-2.5em"}}>
         
        </Grid> */}
      <Grid item xs={12}>
        {loading ? (
          loading && (
            <Paper
              elevation={3}
              style={{
                backgroundColor: "#fff",
                minHeight: "15em",
                textAlign: "center",
              }}
            >
              <CircularProgress size={50} className={classes.buttonProgress} />
            </Paper>
          )
        ) : rows.length !== 0 ? (
          <AccountList
            rows={
              rows &&
              rows.filter((item) => {
                if (searchText) {
                  return item.name
                    .toUpperCase()
                    .includes(searchText.toUpperCase());
                }
                return true;
              })
            }
          />
        ) : (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Address</TableCell>
                  <TableCell>Phone</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Contact Name</TableCell>
                  <TableCell>Account Type</TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow align="center">
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell>
                    <Typography
                      style={{
                        textAlign: "center",
                        marginTop: "1em",
                      }}
                    >
                      No Records...............
                    </Typography>
                  </TableCell>
                  <TableCell></TableCell>
                  <TableCell />
                  <TableCell />
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Grid>
    </Grid>
  );
};
