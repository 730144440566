import React, { Component } from "react";
import Select from "react-select";

class CmbSelect extends Component {
  constructor(props) {
    super(props);

    this.state = { selectedOption: null, optionsArray: [] };
  }

  handleChange = (selectedOption) => {
    this.setState({ selectedOption });

    let target = {};
    target.value = selectedOption;
    target.name = this.props.name;

    let event = {};
    event.target = target;
    this.props.onChange(event);
  };

  render() {
    return (
      <Select
        isDisabled={this.props.isDisabled}
        name={this.props.name}
        value={this.props.value}
        onChange={this.handleChange}
        options={this.props.dataSource}
        styles={{ container: (base) => ({ ...base, zIndex: 100 }) }}
        styles={customStyles}
        maxMenuHeight={150}
        isOptionDisabled={this.props.isOptionDisabled}
      />
    );
  }
}

const customStyles = {
  control: (base) => ({
    ...base,
    height: 55,
    minHeight: 55,
  }),
};
export default CmbSelect;
