import React, { useEffect, useState } from "react";
import moment from "moment";
import { Typography, Card, Divider, TextField, Grid, Paper } from "@material-ui/core";
import { Formik, Form, ErrorMessage, useFormik } from "formik";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { Route } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";

import { api } from "../businessjs/API";
import { SnackBar } from "../compon-controls/SnackBar";
// import { Select } from "./Select";
import Select from "./CmbSelect";

const styles = {
  resize: {
    "&::placeholder": {
      fontSize: "0.813rem",
    },
  },
};

const AssignAccount = (props) => {
  const [accountList, setAccountList] = useState(undefined);
  const [error, setError] = useState(false);
  const [color, setColor] = useState("#d50000");
  const [infoMessgae, setInfoMessage] = useState(false);
  const [accountId, setAccountId] = useState(undefined);
  const [messageText, setMessageText] = useState("");
  const [snackbarColor, setSnackbarColor] = useState("");
  const [loading, setLoading] = useState(false);
  //   console.log("props", props.accountid);
  const { classes } = props;
  useEffect(() => {
    const getSubscriber = async () => {
      setLoading(true);
      try {
        const getSubscriberInputs = {
          companyId: props.accountid,
        };
        const subscriberDetails = await api.post(
          "sc-subscriber/get-subscriber-details-by-id",
          getSubscriberInputs
        );
        console.log("subscriberDetails", subscriberDetails);
        if (subscriberDetails.length !== 0) {
          setAccountId(
            subscriberDetails[0].account_id
              ? subscriberDetails[0].account_id
              : undefined
          );
          setLoading(false);
          loadAccounts(
            subscriberDetails[0].account_id
              ? subscriberDetails[0].account_id
              : undefined
          );
        }
      } catch (error) {
        setLoading(false);
        console.log("something went wrong!", error);
      }
    };
    if (props.accountid) {
      getSubscriber();
    }
  }, [props.accountid]);

  const loadAccounts = async (account) => {
    setLoading(true);
    try {
      const accounts = await api.post("sc-account/get-all-for-cmb");

      if (accounts && accounts.accountSet) {
        let accountArray = accounts.accountSet.map((opt) => ({
          label: opt.name,
          value: opt,
        }));
        setAccountList(accountArray);
        // console.log("accountArray", accountArray);
        setLoading(false);
        if (account) {
          setAccountId(
            accountArray.filter(
              (accounts) => accounts.value.autoid === account
            )[0]
          );
        }
      } else {
        setAccountList([]);
      }
    } catch (error) {
      setLoading(false);
      console.log("something went wrong!", error);
    }
  };

  const cancelButton = () => {
    const ButtonCancel = () => (
      <Route
        render={({ history }) => (
          <button
            type="reset"
            className="btn btn-sm btn-warning mr-1"
            onClick={() => {
              history.goBack({
                pathname: "/supportcenter/subscriberlist",
              });
            }}
          >
            Cancel
          </button>
        )}
      />
    );
    return <ButtonCancel />;
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    if (accountId === undefined) {
      setColor("#0091ea");
      setInfoMessage("Please select account...");
    } else {
      const _input = {
        subscriberId: props.accountid,
        accountId: accountId.value.autoid,
      };

      const updateResult = await api.post(
        "sc-subscriber/update-subscriber-account",
        _input
      );
      // console.log("updateResult", updateResult);
      if (updateResult.status === "success") {
        setMessageText(updateResult.message);
        setSnackbarColor("#4BB543");
      } else {
        setMessageText(updateResult.message);
        setSnackbarColor("#d50000");
      }
    }
  };

  const onCloseSnackbar = () => {
    setMessageText("");
  };

  return (
    <Grid style={{ padding: 15 }}>
      <SnackBar
        status={messageText}
        onCloseError={onCloseSnackbar}
        color={snackbarColor}
      />
      {/* <Card> */}
      <form onSubmit={onSubmit}>
        <Grid
          container
          // spacing={5}
          item
          style={{ padding: 15,}}
          component={Paper}
        >
          <Grid
            item
            xs={6}
            // style={{
            //   marginTop: 5,
            // }}
          >
            <Typography style={{ fontSize: 14 }}>Account</Typography>
            <Select
              required
              style={{ backgroundColor: "blue" }}
              onChange={(e) => {
                setAccountId(e.target.value);
              }}
              // options={accountList}
              dataSource={accountList}
              placeholder={<div>Select Account....</div>}
              value={accountId}
            />
          </Grid>

          <Grid container item style={{ paddingTop: 15 }}>
            {cancelButton()}
            <button
              type="submit"
              className="btn btn-primary btn-sm"
              disabled={loading}
            >
              <i className="fa fa-check-square-o" />
              Assign
              {/* {loading && <CircularProgress size={24} />} */}
            </button>
            {loading && <CircularProgress size={24} />}
          </Grid>
        </Grid>
      </form>
      {/* </Card> */}
    </Grid>
  );
};

export default withStyles(styles)(AssignAccount);
