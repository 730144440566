import React, { Component } from "react";
import Select from "react-select";
import { api } from "../businessjs/API";

class VehicleSelect extends Component {
  constructor(props) {
    super(props);

    this.state = { selectedOption: null, optionsArray: [] };
  }

  componentDidMount = async () => {
    const vehiclesInput = {
      subscriber_id: this.props.subscriber_id
    };
    const vehicles = await api.post("vehicle/getUnAssigned", vehiclesInput);
    if (vehicles) {
      if (Array.isArray(vehicles)) {
        let dbArray = vehicles.map(opt => ({
          label: opt.plate_number,
          value: opt
        }));
        this.setState({
          selectedOption: this.props.value,
          optionsArray: dbArray
        });
      }
    }
  };

  handleChange = selectedOption => {
    this.setState({ selectedOption });

    let target = {};
    target.value = selectedOption;
    target.name = this.props.name;

    let event = {};
    event.target = target;
    this.props.onChange(event);
  };

  render() {
    return (
      <Select
        name={this.props.name}
        value={this.props.value}
        onChange={this.handleChange}
        options={this.state.optionsArray}
      />
    );
  }
}

export default VehicleSelect;
