import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import {
  Typography,
  InputBase,
  IconButton,
  Fab,
  TableHead,
  TableCell,
  CircularProgress,
  TableRow,
  Table,
  TableBody,
  TableContainer,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { StockDetailsList } from "./StockDetailsList";
import SearchIcon from "@material-ui/icons/Search";
import ExportIcon from "@material-ui/icons/ArrowUpward";
import { CSVLink } from "react-csv";
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
  Redirect,
} from "react-router-dom";
import { api } from "../../businessjs/API";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  SearchInput: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  searchPaper: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: 400,
  },
  margin: {
    margin: theme.spacing(1),
  },
  buttonProgress: {
    marginTop: "10em",
  },
}));

export const StockDetails = () => {
  const classes = useStyles();
  const [searchText, setSearchText] = useState("");
  const [rows, setRows] = useState([]);
  const [navigate, setNavigate] = useState(false);
  const [dataToExport, setDataToExport] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const [loading, setLoading] = useState(false);
  // const [exportButtonDisabled, setExportButtonDisabled] = useState(true);
  const NavLink = React.forwardRef((props, ref) => (
    <RouterLink innerRef={ref} {...props} />
  ));

  useEffect(() => {
    setLoading(true);
    async function loadStockList() {
      try {
        const productName = "";
        const stockDetails = await api.post("sc-purchase/get-products-stocks", {
          productName,
        });
        if (stockDetails && Array.isArray(stockDetails)) {
          setRows(stockDetails);
          setDataToExport(stockDetails);
          setRowCount(stockDetails.length);
          setLoading(false);
        } else {
          setNavigate(true);
          setLoading(false);
          setRows([]);
        }
      } catch (e) {
        console.log(e);
      }
    }
    loadStockList();
  }, [rows.length]);
  const onSearchFunctionality = (searchKey) => {
    if (searchKey !== "") {
      setSearchText(searchKey);

      const data =
        rows &&
        rows.filter((item) => {
          return item.product_name
            .toUpperCase()
            .includes(searchKey.toUpperCase());
        });
      if (data.length !== 0) {
        setDataToExport(data);
        setRowCount(data.length);
      } else {
        setRowCount(0);
        setDataToExport([]);
      }
    } else {
      setSearchText("");
      setRowCount(rows.length);
    }
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h3" style={{ fontWeight: "bold" }}>
          Stock List
        </Typography>
      </Grid>
      <Grid item xs={12} sm={8} style={{ textAlign: "left" }}>
        <Paper className={classes.searchPaper}>
          <InputBase
            className={classes.SearchInput}
            value={searchText}
            placeholder="Search...."
            // inputProps={{ "aria-label": "search " }}
            onChange={(event) => onSearchFunctionality(event.target.value)}
          />
          <IconButton aria-label="search">
            <SearchIcon />
          </IconButton>
        </Paper>
      </Grid>

      <Grid item xs={12}>
        {loading ? (
          loading && (
            <Paper
              elevation={3}
              style={{
                backgroundColor: "#fff",
                minHeight: "30em",
                textAlign: "center",
              }}
            >
              <CircularProgress size={50} className={classes.buttonProgress} />
            </Paper>
          )
        ) : rows.length !== 0 ? (
          <StockDetailsList
            rows={
              rows &&
              rows.filter((item) => {
                if (searchText) {
                  return item.product_name
                    .toUpperCase()
                    .includes(searchText.toUpperCase());
                }
                return true;
              })
            }
          />
        ) : (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Product</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Price</TableCell>
                  <TableCell>Outside City Price</TableCell>
                  <TableCell>Active Days</TableCell>
                  <TableCell>PriorWarning</TableCell>
                  <TableCell>Suspend After</TableCell>
                  <TableCell>No.of Services/Visits</TableCell>
                  <TableCell>Stock</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow align="center">
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell>
                    <Typography
                      style={{
                        textAlign: "center",
                        marginTop: "1em",
                      }}
                    >
                      No Records...............
                    </Typography>
                  </TableCell>
                  <TableCell />
                  <TableCell></TableCell>
                  <TableCell />
                  <TableCell />
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Grid>
    </Grid>
  );
};
