import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  InputBase,
  IconButton,
  Fab,
  Paper,
  Grid,
  FormControl,
  InputLabel,
  MenuItem,
  Card,
  Button,
  Select,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import SearchIcon from "@material-ui/icons/Search";
import ExportIcon from "@material-ui/icons/ArrowUpward";
import { Link as RouterLink } from "react-router-dom";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { CSVLink } from "react-csv";
import moment from "moment";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { api } from "../../businessjs/API";
import { AuthHelperAccessCheck } from "../../businessjs/AuthHelper";
import { QuotationList } from "./QuotationList";

const useStyles = makeStyles((theme) => ({
  searchInput: {
    alignItems: "center",
    width: 400,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  margin: {
    margin: theme.spacing(1),
  },
  buttonProgress: {
    marginTop: "10em",
  },
}));

const headers = [
  { key: "autoid", label: "Q.No." },
  { key: "quote_date", label: "Date" },
  {
    key: "account_name",
    numeric: false,
    disablePadding: false,
    label: "Account",
  },
  { key: "contact_name", label: "Contact" },
  {
    key: "total_amount",
    label: "Total(Qr)",
  },
  {
    key: "discount",
    label: "Discount(Qr)",
  },
  { key: "status", label: "Status" },
];

export const Quotation = () => {
  const classes = useStyles();
  const [quotationList, setQuotationList] = useState([]);
  const [startDate, setStartDate] = useState(
    moment(new Date()).subtract(7, "days").format()
  );
  const [accountList, setAccountList] = useState([]);
  const [accountId, setAccountId] = useState(0);
  const [endDate, setEndDate] = useState(moment(new Date()).format());
  const [loading, setLoading] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [dateDifferenceAlert, setDateDifferenceAlert] = useState(false);

  const NavLink = React.forwardRef((props, ref) => (
    <RouterLink innerRef={ref} {...props} />
  ));
  const handleExpandChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    const loadAccounts = async () => {
      try {
        const accounts = await api.post("sc-account/get-all-for-cmb");

        if (accounts && accounts.accountSet) {
          let accountArray = accounts.accountSet.map((opt) => ({
            label: opt.name,
            value: opt,
          }));
          setAccountList(accountArray);
        } else {
          AuthHelperAccessCheck(accounts);
        }
      } catch (e) {
        console.log(e);
      }
    };
    loadAccounts();
  }, [accountList.length]);

  useEffect(() => {
    const _quotationListInputs = {
      accountId: accountId,
      startDate: moment(startDate).format("YYYY-MM-DD"),
      endDate: moment(endDate).format("YYYY-MM-DD"),
    };
    getQuotationList(_quotationListInputs);
  }, [quotationList.length]);

  const getQuotationList = async (body) => {
    setLoading(true);
    try {
      const quotationList = await api.post(
        "sc-quotation/get-quotation-list",
        body
      );
      if (Array.isArray(quotationList)) {
        setQuotationList(quotationList);
        setLoading(false);
      } else {
        setLoading(false);
        AuthHelperAccessCheck(quotationList);
      }
    } catch (e) {
      console.log(e);
    }
  };
  const onDeleteQuotation = async (quotationId) => {
    const deleteQuotation = await api.post("sc-quotation/delete-quotation", {
      quotationId,
    });
    const deletedUser =
      quotationList &&
      quotationList.filter((quotation) => quotation.autoid !== quotationId);
    setQuotationList(deletedUser);
  };
  const handleChange = (event) => {
    setAccountId(event.target.value);
    const _quotationCountInputs = {
      accountId: event.target.value,
      startDate: startDate,
      endDate: endDate,
    };
    getQuotationList(_quotationCountInputs);
  };

  const handleStartDate = (date) => {
    setStartDate(date);
    const start = moment(date);
    const end = moment(endDate);
    const dateDiffrence = end.diff(start, "months");
    if (dateDiffrence > 3) {
      setDateDifferenceAlert(true);
    } else {
      setDateDifferenceAlert(false);
      const _quotationCountInputs = {
        accountId: accountId,
        startDate: date,
        endDate: endDate,
      };
      getQuotationList(_quotationCountInputs);
    }
  };
  const handleEndDate = (date) => {
    setEndDate(date);
    const start = moment(startDate);
    const end = moment(date);
    const dateDiffrence = end.diff(start, "months");
    if (dateDiffrence > 3) {
      setDateDifferenceAlert(true);
    } else {
      setDateDifferenceAlert(false);
      const _quotationCountInputs = {
        accountId: accountId,
        startDate: startDate,
        endDate: date,
      };
      getQuotationList(_quotationCountInputs);
    }
  };
  const handleDateAlertClose = () => {
    setDateDifferenceAlert(false);
  };
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h3" style={{ fontWeight: "bold" }}>
          Quotation List
        </Typography>
      </Grid>
      <Grid item xs={12} style={{ textAlign: "right" }}>
        <Fab
          variant="extended"
          size="medium"
          color="primary"
          aria-label="add"
          disabled={loading}
          className={classes.margin}
          component={NavLink}
          to={`/quotation/${"new"}`}
        >
          <AddIcon className={classes.extendedIcon} />
          Add
          {/* {loading === true ? <CircularProgress size={35} /> : null} */}
        </Fab>

        <CSVLink
          data={quotationList}
          headers={headers}
          filename={"quoatation_details.csv"}
        >
          <Fab
            variant="extended"
            size="medium"
            color="primary"
            style={{ display: quotationList.length !== 0 ? "" : "none" }}
            aria-label="export"
            className={classes.margin}
          >
            <ExportIcon className={classes.extendedIcon} />
            Export
          </Fab>
        </CSVLink>
      </Grid>
      <Grid item xs={12} style={{ textAlign: "left" }}>
        <Accordion
          expanded={expanded === "panel1"}
          onChange={handleExpandChange("panel1")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography className={classes.heading}>Search</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {/* <Card variant="outlined" style={{ padding: 10 }}> */}
            <Grid
              spacing={3}
              container
              direction="row"
              justify="space-between"
              alignItems="flex-start"
            >
              <Grid item xs={4}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-helper-label">
                    Account
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    value={accountId}
                    fullWidth
                    onChange={handleChange}
                  >
                    <MenuItem value={0}>All</MenuItem>
                    {accountList.map((account, index) => (
                      <MenuItem value={account.value.autoid} key={index}>
                        {account.value.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    fullWidth
                    format="MM/dd/yyyy"
                    id="date-picker-inline"
                    label="Start Date"
                    value={startDate}
                    onChange={handleStartDate}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                    autoOk={true}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={4}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    fullWidth
                    id="date-picker-dialog"
                    label="End Date"
                    format="MM/dd/yyyy"
                    value={endDate}
                    onChange={handleEndDate}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                    autoOk={true}
                    // maxDate={ moment(new Date()).subtract(1, "days").format()}
                    // minDate={endDate}
                    // error={endDate >= startDate ? false : true}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              {/* <Grid item>
              
            </Grid> */}
            </Grid>
            {/* </Card> */}
          </AccordionDetails>
        </Accordion>
      </Grid>

      <Grid item xs={12}>
        {loading ? (
          loading && (
            <Paper
              elevation={3}
              style={{
                backgroundColor: "#fff",
                minHeight: "25em",
                textAlign: "center",
              }}
            >
              <CircularProgress size={50} className={classes.buttonProgress} />
            </Paper>
          )
        ) : (
          <QuotationList
            rows={quotationList}
            onDeleteQuotation={onDeleteQuotation}
          />
        )}
      </Grid>
      <Dialog
        open={dateDifferenceAlert}
        onClose={handleDateAlertClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Can't search... Start and end date difference must be less than or
            equal to 3 months...
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Fab
            onClick={handleDateAlertClose}
            color="primary"
            variant="extended"
            size="small"
          >
            Ok
          </Fab>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};
