import { createMuiTheme, colors } from "@material-ui/core";
import shadows from "./shadows";
import typography from "./typography";

const theme = createMuiTheme({
  palette: {
    background: {
      dark: "#F4F6F8",
      default: colors.common.white,
      paper: colors.common.white,
    },
    primary: {
      main: "#007bff",
    },
    secondary: {
      main: "#9e9e9e",
    },
    text: {
      primary: colors.blueGrey[900],
      secondary: colors.blueGrey[600],
    },
  },
  shadows,
  typography,
  overrides: {
    MuiTableCell: {
      head: {
        fontSize: "0.875rem",
        textTransform: "capitalize",
        fontWeight: "bold",
        color: "#fff",
      },
      body: {
        fontSize: "0.813rem",
        color: "#000000",
        fontWeight: "normal",
      },
    },
    MuiTableHead: {
      root: {
        backgroundColor: "#007bff",
        color: "#fff",
      },
    },
    MuiTableSortLabel: {
      root: {
        color: "white",
        "&:hover": {
          color: "white",
        },
        "&$active": {
          color: "white",
        },
      },
      icon: {
        fill: "#fff",
        '& path': {
            fill: '#fff'
        },
    }
    },
    MuiFab: {
      label: {
        fontSize: "1rem",
        textTransform: "none",
        color: "#fff",
        minWidth:"5em"

      },
    },
   
  },
});

export default theme;
