import React, { Component, Suspense } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { withStyles } from "@material-ui/core";
import SupportMember from "../compon/SupportMember";
import SupportCenterHome from "../compon/SupportCenterHome/SupportCenterHome";
import SupportCenterLogin from "../compon/Login/SupportCenterLogin";
import SubscriberList from "../compon/SubscriberList";
import Subscription from "../compon/Subscription";

import SubscriptionWithVehicle from "../compon/SubscriptionWithVehicle";
import ChangePassword from "../compon/ChangePassword";
import CommingSoon from "../compon/CommingSoon";

import VerifyEmail from "../compon/VerifyEmail";
import ChangeEmail from "../compon/ChangeEmail";
import SupportCenterVehicleList from "../compon/SupportCenterVehicleList";
import VehicleAdd from "../compon/VehicleAdd";
import TicketList from "../compon/TicketList";
import JobCard from "../compon/JobCardList";
import JobCardCreation from "../compon/JobCardCreation";
import EditJobCard from "../compon/EditJobCard";
import TicketCreation from "../compon/TicketCreation";
import SubscriberLocationsList from "../compon/SubscriberLocationsList";
import SubscriberLocationCreation from "../compon/SubscriberLocationCreation";
import ServiceSchedules from "../compon/ServiceSchedules";
import ViewTicket from "../compon/ViewTicket";
import ServiceScheduleList from "../compon/ServiceScheduleList";
import ServiceScheduleEdit from "../compon/ServiceScheduleEdit";
import { Service } from "../compon/Service";
import { ProductsHome } from "../compon/ProductsHome";
import Product from "../compon/Product";
import ProductList from "../compon/ProductList";
import ProductBundleList from "../compon/ProductBundleList";
import Bundle from "../compon/Bundle";
import InvoiceList from "../compon/InvoiceList";
import Invoice from "../compon/Invoice";
import SubscriptionList from "../compon/SubscriptionList";
import ProcessJobCard from "../compon/ProcessJobCard";
import VisitList from "../compon/VisitList";
import Visit from "../compon/Visit";
import JobCardInvoice from "../compon/JobCardInvoice";
import InvoiceBill from "../compon/InvoiceBill";
import JobCardInvoiceBill from "../compon/JobCardInvoiceBill";
import AccountDetails from "../compon/AccountDetails";
import SubscriberAdd from "../compon/SubscriberAdd";
import BundleView from "../compon/BundleView";
import ServiceScheduleCreation from "../compon/ServiceScheduleCreation";
import InvoicePayment from "../compon/InvoicePayment";
import PaymentsList from "../compon/PaymentsList";
import Navbar from "../compon/SupportCenterAppBar";
import { Account } from "../compon/AccountManagement";
import { AddAccount } from "../compon/AccountManagement/AddAccount";
// import { ExecutivesList } from "../compon/ExecutivesList";
// import { Contact } from "../compon/Contacts";
import { Executive } from "../compon/ExecutiveManagement";
import { AddExecutive } from "../compon/ExecutiveManagement/AddExecutive";
import { Contact } from "../compon/Contacts";
import { AddContact } from "../compon/Contacts/AddContact";
import { Quotation } from "../compon/QuotationManagement";
import { AddQuotation } from "../compon/QuotationManagement/AddQuotation";
// import { AddContact } from "../compon/Contacts/AddContact";
import { PurchaseOrder } from "../compon/PurchaseOrderManagement";
import { PurchaseOrderForm } from "../compon/PurchaseOrderManagement/PurchaseOrderForm";

import { ChangedDevice } from "../compon/ChangedDevice";
import { QuotationView } from "../compon/QuotationManagement/QuotationView";
import { PurchaseOrderView } from "../compon/PurchaseOrderManagement/PurchaseOrderView";
import { PurchaseBill } from "../compon/PurchaseBillManagement";
import { AddPurchaseBill } from "../compon/PurchaseBillManagement/AddPurchaseBill";
import { PurchaseBillView } from "../compon/PurchaseBillManagement/PurchaseBillView";
import { ProductStocks } from "../compon/ProductStocksManagement/ProductStocks";
import { ProductStocksForm } from "../compon/ProductStocksManagement/ProductStocksForm";
import { StockDetails } from "../compon/StockManagement/StockDetails";
import { ProductHome } from "../compon/ProductManagement";
import { StockView } from "../compon/StockManagement/StockView";

require("dotenv").config();
const styles = (theme) => ({
  wrapper: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
    paddingTop: 84,
    // paddingRight:16,
    [theme.breakpoints.up("lg")]: {
      paddingLeft: 265,
    },
    // [theme.breakpoints.down('md')]: {

    // }
  },
  contentContainer: {
    // display: "flex",
    flex: "1 1 auto",
    width: "100%",
    overflowX: "auto",
    paddingTop: "2em",
    paddingRight: "1em",
    paddingBottom: "1em",
    paddingLeft: "1em",
  },
  content: {
    flex: "1 1 auto",
    height: "100%",
    width: "100%",
  },
});
class AppView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      path: undefined,
    };
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        <Navbar />
        <div className={classes.wrapper}>
          <div className={classes.contentContainer}>
            <div className={classes.content}>
              <Suspense fallback={<h1>loading...</h1>}>
                <Switch>
                  {/* <Route exact path="/" component={SupportCenterHome} /> */}
                  {/* <Route exact path="/" component={SupportCenterLogin} /> */}
                  {/* <Route exact path="/login" component={SupportCenterLogin} /> */}
                  <Route exact path="/" component={SupportCenterHome} />
                  <Route
                    exact
                    path="/supportcenter/member"
                    component={SupportMember}
                  />
                  <Route
                    exact
                    path="/supportcenter/subscriberlist"
                    component={SubscriberList}
                  />
                  {/* <Route
                    exact
                    path="/supportcenter/Login"
                    component={SupportCenterLogin}
                  /> */}

                  <Route exact path="/subscription" component={Subscription} />
                  <Route
                    exact
                    path="/subscriptionwithvehicle"
                    component={SubscriptionWithVehicle}
                  />

                  <Route
                    exact
                    path="/changepassword"
                    component={ChangePassword}
                  />

                  <Route exact path="/commingsoon" component={CommingSoon} />

                  <Route
                    exact
                    path="/verifyemail/:id"
                    component={VerifyEmail}
                  />
                  <Route exact path="/changeemail" component={ChangeEmail} />
                  <Route
                    exact
                    path="/vehicle-list"
                    component={SupportCenterVehicleList}
                  />
                  <Route exact path="/vehicleadd" component={VehicleAdd} />
                  <Route exact path="/ticket-list" component={TicketList} />
                  <Route exact path="/job-card" component={JobCard} />
                  <Route exact path="/edit-job-card" component={EditJobCard} />
                  <Route
                    exact
                    path="/job-card-creation"
                    component={JobCardCreation}
                  />
                  <Route
                    exact
                    path="/ticket-creation"
                    component={TicketCreation}
                  />
                  <Route
                    exact
                    path="/subscriber-locations"
                    component={SubscriberLocationsList}
                  />

                  <Route
                    exact
                    path="/subscriber-location-creation"
                    component={SubscriberLocationCreation}
                  />
                  <Route
                    exact
                    path="/service-schedules"
                    component={ServiceSchedules}
                  />
                  <Route exact path="/view-ticket" component={ViewTicket} />
                  <Route
                    exact
                    path="/service-schedule-list"
                    component={ServiceScheduleList}
                  />
                  <Route
                    exact
                    path="/service-schedule-edit"
                    component={ServiceScheduleEdit}
                  />
                  <Route exact path="/service" component={Service} />
                  {/* <Route exact path="/products-home" component={ProductHome} /> */}
                  <Route exact path="/product" component={Product} />
                  <Route exact path="/product-list" component={ProductHome} />

                  <Route
                    exact
                    path="/product-bundle-list"
                    component={ProductBundleList}
                  />
                  <Route exact path="/bundle" component={Bundle} />
                  <Route
                    exact
                    path="/subscription-list"
                    component={SubscriptionList}
                  />

                  <Route exact path="/invoice-list" component={InvoiceList} />
                  <Route exact path="/invoice" component={Invoice} />
                  <Route
                    exact
                    path="/jobcard-process"
                    component={ProcessJobCard}
                  />
                  <Route exact path="/visit-list" component={VisitList} />
                  <Route exact path="/visit" component={Visit} />
                  <Route
                    exact
                    path="/jobcard-invoice"
                    component={JobCardInvoice}
                  />
                  <Route exact path="/invoice-bill" component={InvoiceBill} />
                  <Route
                    exact
                    path="/job-card-invoice-bill"
                    component={JobCardInvoiceBill}
                  />
                  <Route
                    exact
                    path="/account-details"
                    component={AccountDetails}
                  />
                  <Route
                    exact
                    // path="/subscriber-add"
                    path={`/subscriber-add/:id`}
                    component={SubscriberAdd}
                  />
                  <Route exact path="/bundle-view" component={BundleView} />
                  <Route
                    exact
                    path="/service-schedule-creation"
                    component={ServiceScheduleCreation}
                  />
                  <Route
                    exact
                    path="/invoice-payment"
                    component={InvoicePayment}
                  />
                  <Route exact path="/payments-list" component={PaymentsList} />
                  <Route exact path="/quotation" component={Quotation} />
                  <Route
                    exact
                    path={`/quotation/:id`}
                    component={AddQuotation}
                  />
                  <Route
                    exact
                    path="/quotation-view/:id"
                    component={QuotationView}
                  />
                  {/* <Route
                    exact
                    path="/quotation-list"
                    component={QuotationList}
                  />

                  <Route exact path="/quotation" component={Quotation} /> */}
                  <Route exact path="/account" component={Account} />
                  <Route exact path={`/account/:id`} component={AddAccount} />
                  {/* <Route exact path="/contact" component={Contact} />

                  <Route
                    exact
                    path="/executive-list"
                    component={ExecutivesList}
                  /> */}
                  <Route exact path="/contact" component={Contact} />
                  <Route exact path={`/contact/:id`} component={AddContact} />

                  <Route exact path="/executive" component={Executive} />
                  <Route
                    exact
                    path={`/executive/:id`}
                    component={AddExecutive}
                  />
                  <Route exact path="/contact/new" component={AddContact} />
                  <Route
                    exact
                    path="/purchase-order"
                    component={PurchaseOrder}
                  />
                  <Route
                    exact
                    path={`/purchase-order/:id`}
                    component={PurchaseOrderForm}
                  />
                  <Route
                    exact
                    path="/purchase-order-view/:id"
                    component={PurchaseOrderView}
                  />
                  <Route
                    exact
                    path="/changed-device"
                    component={ChangedDevice}
                  />
                  <Route exact path="/purchase-bill" component={PurchaseBill} />
                  <Route
                    exact
                    path={`/purchase-bill/:id`}
                    component={AddPurchaseBill}
                  />
                  <Route
                    exact
                    path="/purchase-bill-view/:id"
                    component={PurchaseBillView}
                  />
                  <Route
                    exact
                    path="/product-stocks"
                    component={ProductStocks}
                  />
                  <Route
                    exact
                    path="/product-stocks/:id"
                    component={ProductStocksForm}
                  />
                  <Route exact path="/stocks" component={StockDetails} />
                  <Route exact path="/stocks-view/:id" component={StockView} />
                </Switch>
              </Suspense>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(AppView);
