import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import {
  Grid,
  Paper,
  Typography,
  Fab,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  makeStyles,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Chip,
} from "@material-ui/core";
import { Formik, Form, ErrorMessage } from "formik";
import AddIcon from "@material-ui/icons/Add";
import { SnackBar } from "../Login/SnackBar";

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  formControl: {
    // margin: theme.spacing(1),
    minWidth: 120,
  },
  dividerMargin: {
    marginTop: "0.9em",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));
export const ProductAddForm = ({
  addProductVisible,
  addProductDialogClose,
  onProductAddSave,
  productList,
  productObjectDetails,
  serialArray,
}) => {
  const classes = useStyles();
  const [productObject, setProductObject] = useState({});
  const [checked, setChecked] = useState(false);
  const [productName, setProductName] = useState();
  const [quantity, setQuantity] = useState(1);
  const [price, setPrice] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [serialNumbers, setSerialNumbers] = useState([]);
  const [fromSerialNumber, setFromSerialNumber] = useState("");
  const [toSerialNumber, setToSerialNumber] = useState("");
  const [discountValueError, setDiscountValueError] = useState(false);
  const [error, setError] = useState("");
  const [color, setcolor] = useState("#d50000");

  useEffect(() => {
    // console.log("productObject", productObjectDetails);
    //  console.log("productList",productList);
    if (productObjectDetails !== undefined) {
      const productId = productList.find(
        (item) => item.product_name === productObjectDetails.productName
      ).autoid;
      const serialNumberArray = productObjectDetails.serialNumbers.value;
      const checkValue = productObjectDetails.serialization;
      setProductName(productId);
      setQuantity(productObjectDetails.quantity);
      setPrice(productObjectDetails.price);
      setDiscount(productObjectDetails.discount);
      setTotalPrice(productObjectDetails.total);
      setChecked(checkValue === 1 ? true : false);
      setSerialNumbers(serialNumberArray);
    }
  }, [productObjectDetails]);
  const handleProductSelectChange = (value) => {
    setProductName(value);
    const newProductList =
      productList && productList.filter((item) => item.autoid !== value);
    const product =
      productList && productList.find((product) => product.autoid === value);
    if (product !== undefined) {
      setProductObject(product);
      setPrice(parseFloat(product.price));
      setTotalPrice(parseFloat(product.price));
    }
  };
  const handleChangeCheckBox = (event) => {
    setChecked(event.target.checked);
    if (event.target.checked === false) {
      setSerialNumbers([]);
    }
  };
  const onQuantityChange = (quantityValue) => {
    setQuantity(quantityValue);
    // if (discount !== "") {

    setTotalPrice(
      parseFloat(price) * parseFloat(quantityValue) -
        (parseFloat(price) * parseFloat(quantityValue) * parseFloat(discount)) /
          100
    );
  };
  const onQuantityBlur = (value) => {
    if (value === "") {
      setQuantity(1);
      setTotalPrice(
        parseFloat(price) * 1 -
          (parseFloat(price) * 1 * parseFloat(discount)) / 100
      );
    }
  };
  const onPriceChange = (priceValue) => {
    setPrice(priceValue);
    setTotalPrice(
      parseFloat(priceValue) * parseFloat(quantity) -
        (parseFloat(priceValue) * parseFloat(quantity) * parseFloat(discount)) /
          100
    );
  };
  const onPriceBlur = (priceValue) => {
    if (priceValue === "") {
      setPrice(0);
    }
  };
  const onDiscountChange = (discountValue) => {
    if (discountValue > 100) {
      setDiscount(discountValue);
      setDiscountValueError(true);
    } else {
      setDiscount(discountValue);
      setDiscountValueError(false);
      setTotalPrice(
        parseFloat(price) * parseFloat(quantity) -
          (parseFloat(price) *
            parseFloat(quantity) *
            parseFloat(discountValue)) /
            100
      );
    }
  };
  const onDiscountBlur = (discountValue) => {
    if (discountValue === "") {
      setDiscount(0);
    }
  };
  const getSerialNumbers = (from, to) => {
    // console.log("to", to);
    if (from !== "") {
      if (to !== "") {
        var numbers = [];
        for (var i = from; i <= to; i++) {
          numbers.push(i.toString());
        }
        if (serialNumbers.length !== 0) {
          const mergedArray = serialNumbers.concat(numbers);
          const uniqueArray = [...new Set(mergedArray)];
          setSerialNumbers(uniqueArray);
        } else {
          setSerialNumbers(numbers);
        }
      } else {
        // console.log("else case")
        let number = from;
        // console.log("number from",number)
        if (serialNumbers.length !== 0) {
          const mergedArray = [...serialNumbers, number];
          const uniqueArray = [...new Set(mergedArray)];
          setSerialNumbers(uniqueArray);
        } else {
          setSerialNumbers([number]);
        }
      }
      // console.log(from, to);
    }
  };
  const onDeleteSerialNumber = (num) => {
    // console.log("number", num);
    const filteredArray =
      serialNumbers && serialNumbers.filter((number) => number !== num);
    // console.log("filter", filteredArray);
    setSerialNumbers(filteredArray);
  };
  const onSubmitProduct = () => {
    // console.log("serialNumber", serialNumbers.length);
    // console.log("quantiyt", quantity);
    // if (serialNumbers.length > 0) {
    // console.log("ssss")
    if (checked === true) {
      if (parseInt(quantity) !== serialNumbers.length) {
        setError("Number of Serial numbers should be equal to quantity..");
      } else {
        if (productList !== undefined) {
          setError("");
          const productFinalObject = {
            autoid: productList.find((item) => item.autoid === productName)
              .autoid,
            productName: productList.find((item) => item.autoid === productName)
              .product_name,
            quantity: quantity,
            price: price,
            discount: discount,
            total: totalPrice,
            serialization: checked === true ? 1 : 0,
            serialNumbers: {
              id: productList.find((item) => item.autoid === productName)
                .autoid,
              price: productList.find((item) => item.autoid === productName)
                .price,
              // expense: totalPrice/quantity,
              value: serialNumbers,
            },
          };
          // console.log("productFinalObject", productFinalObject);
          onProductAddSave(productFinalObject);
        }
      }
    } else {
      if (productList !== undefined) {
        setError("");
        const productFinalObject = {
          autoid: productList.find((item) => item.autoid === productName)
            .autoid,
          productName: productList.find((item) => item.autoid === productName)
            .product_name,
          quantity: quantity,
          price: price,
          discount: discount,
          total: totalPrice,
          serialization: checked === true ? 1 : 0,
          serialNumbers: {
            id: productList.find((item) => item.autoid === productName).autoid,
            price: productList.find((item) => item.autoid === productName)
              .price,
            // expense: totalPrice/quantity,
            value: serialNumbers,
          },
        };
        // console.log("productFinalObject", productFinalObject);
        onProductAddSave(productFinalObject);
      }
    }
  };
  const onCloseSnackbar = () => {
    setError("");
  };
  return (
    <div>
      {/* {console.log("serialNumbers", serialNumbers)} */}
      <SnackBar status={error} onCloseError={onCloseSnackbar} color={color} />
      <Dialog
        open={addProductVisible}
        onClose={addProductDialogClose}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
        fullWidth
      >
        {/* <Formik>
          <Form> */}
        <DialogTitle
          id="form-dialog-title"
          style={{ backgroundColor: "#007bff" }}
        >
          <Typography
            variant="h4"
            style={{ fontWeight: "bold", color: "#fff" }}
          >
            Add Product
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={checked ? 6 : 12}>
              <Paper className={classes.paper}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={checked ? 12 : 6}>
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                      fullWidth
                      required
                    >
                      <InputLabel id="demo-simple-select-outlined-label">
                        Product Name
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={productName}
                        onChange={(event) =>
                          handleProductSelectChange(event.target.value)
                        }
                        label="Account Name"
                      >
                        {productList &&
                          productList.map((option) => (
                            <MenuItem value={option.autoid}>
                              {option.product_name}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={checked ? 12 : 6}>
                    <TextField
                      fullWidth
                      required
                      variant="outlined"
                      name="quantity"
                      label="Quantity"
                      type="number"
                      value={quantity}
                      onChange={(event) => onQuantityChange(event.target.value)}
                      onBlur={(event) => onQuantityBlur(event.target.value)}
                      //   inputProps={{ maxLength: 50 }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={checked ? 12 : 6}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      name="price"
                      label="Price"
                      value={price}
                      onChange={(event) => onPriceChange(event.target.value)}
                      onBlur={(event) => onPriceBlur(event.target.value)}
                      //   inputProps={{ maxLength: 50 }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={checked ? 12 : 6}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      name="discount"
                      label="Discount(%)"
                      type="number"
                      placeholder="0.00"
                      defaultValue={0}
                      value={discount}
                      onChange={(event) => onDiscountChange(event.target.value)}
                      onBlur={(event) => onDiscountBlur(event.target.value)}
                      error={discountValueError}
                      helperText={
                        discountValueError
                          ? "Discount Value must be less than 100"
                          : ""
                      }
                      //   inputProps={{ maxLength: 50 }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={checked ? 12 : 6}>
                    <TextField
                      fullWidth
                      key="Total Price"
                      variant="outlined"
                      name="totalPrice"
                      label="Total Price"
                      value={totalPrice}
                      // onChange={handleChange}
                      //   inputProps={{ maxLength: 50 }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormGroup row>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={checked}
                            onChange={handleChangeCheckBox}
                            name="checked"
                            color="primary"
                          />
                        }
                        label="Add serial number"
                      />
                    </FormGroup>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              style={{ display: checked ? "" : "none" }}
            >
              <Paper className={classes.paper}>
                {" "}
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Typography variant="body1" style={{ fontStyle: "italic" }}>
                      *if you wish to enter serial number one by one , you will
                      only need to enter the Starting serial No. texfield
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={5}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      name="fromSerialNumber"
                      label="Starting Serial No."
                      value={fromSerialNumber}
                      onChange={(event) =>
                        setFromSerialNumber(event.target.value)
                      }
                      // onBlur={(event) => onPriceBlur(event.target.value)}
                      //   inputProps={{ maxLength: 50 }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={5}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      name="toSerialNumber"
                      label="Ending Serial No."
                      value={toSerialNumber}
                      onChange={(event) =>
                        setToSerialNumber(event.target.value)
                      }
                      // onBlur={(event) => onPriceBlur(event.target.value)}
                      //   inputProps={{ maxLength: 50 }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <Fab
                      size="small"
                      color="primary"
                      aria-label="add"
                      className={classes.margin}
                      onClick={() =>
                        getSerialNumbers(fromSerialNumber, toSerialNumber)
                      }
                    >
                      <AddIcon />
                    </Fab>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Paper className={classes.paper}>
                      <Grid container spacing={3}>
                        {serialNumbers.length !== 0 ? (
                          serialNumbers &&
                          serialNumbers.map((number) => (
                            <Grid item>
                              <Chip
                                key={number}
                                label={number}
                                style={{ minWidth: "8em" }}
                                onDelete={() => onDeleteSerialNumber(number)}
                                color="primary"
                                variant="outlined"
                              />
                            </Grid>
                          ))
                        ) : (
                          <Typography>No Data...</Typography>
                        )}
                      </Grid>
                    </Paper>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Fab
            variant="extended"
            size="small"
            color="primary"
            onClick={addProductDialogClose}
          >
            Cancel
          </Fab>
          <Fab
            variant="extended"
            size="small"
            color="primary"
            type="submit"
            onClick={onSubmitProduct}
          >
            Save
          </Fab>
        </DialogActions>
        {/* </Form>
        </Formik> */}
      </Dialog>
    </div>
  );
};
