import React, { Component } from "react";
import { Col, Row } from "reactstrap";
import { Route } from "react-router-dom";

import { AuthHelperAccessCheck } from "../businessjs/AuthHelper";
import Pagination from "react-js-pagination";
import { RiseLoader } from "react-spinners";
import { css } from "@emotion/core";
import { api } from "../businessjs/API";

const loaderCSS = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

class Vehicles extends Component {
  state = {
    toastItems: [],
    filteredDataRows: [],
    allDataRows: [],
    subscriber_id: this.props.accountid,
    totalItemsCount: 0,
    itemsCountPerPage: 25,
    activePage: 1,
    searchPlateNumber: "",
    loading: true
  };

  componentDidMount() {
    this.getVehicleCount();
  }

  getVehicleCount = async () => {
    this.setState({ loading: true });

    const getVehicleCountInput = {
      subscriber_id: this.state.subscriber_id,
      searchPlateNumber: this.state.searchPlateNumber
    };

    const vehicleCount = await api.post(
      "sc-vehicle/get-vehicle-count",
      getVehicleCountInput
    );

    if (vehicleCount && vehicleCount.length) {
      let vehicle_count = vehicleCount[0].vehicle_count;
      if (vehicle_count > 0) {
        this.setState({ totalItemsCount: vehicle_count, activePage: 1 });
        this.getVehicles(1);
      } else {
        this.setState({
          allDataRows: [],
          filteredDataRows: [],
          totalItemsCount: 0,
          activePage: 1,
          loading: false
        });
      }
    } else {
      this.setState({ loading: false });
      AuthHelperAccessCheck(vehicleCount);
    }
  };

  getVehicles = async pageNumber => {
    const { itemsCountPerPage } = this.state;

    let start = (pageNumber - 1) * this.state.itemsCountPerPage;
    const vehicleListInput = {
      subscriber_id: this.state.subscriber_id,
      searchPlateNumber: this.state.searchPlateNumber,
      startLimit: start,
      endLimit: itemsCountPerPage
    };

    const vehicleList = await api.post(
      "sc-vehicle/get-vehicle-list",
      vehicleListInput
    );

    if (vehicleList) {
      this.setState({
        allDataRows: vehicleList,
        filteredDataRows: vehicleList,
        loading: false
      });

      if (!this.state.activePage) {
        this.setState({ totalItemsCount: vehicleList.length, activePage: 1 });
      } else {
      }
    } else {
      this.setState({ loading: false });
      AuthHelperAccessCheck(vehicleList);
    }
  };

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handlePageChange = pageNumber => {
    this.setState({ activePage: pageNumber });
    this.getVehicles(pageNumber);
  };

  vehicleAdd = () => {
    const ButtonAdd = () => (
      <Route
        render={({ history }) => (
          <button
            style={{ height: "fit-content" }}
            type="button"
            className="btn btn-primary btn-sm ml-2"
            onClick={() => {
              history.push({
                pathname: "/vehicleadd",
                state: {
                  type: "add",
                  from: "account",
                  subscriber_id: this.state.subscriber_id
                }
              });
            }}
          >
            Add
          </button>
        )}
      />
    );
    return <ButtonAdd />;
  };

  vehicleEdit = Item => {
    const ButtonEdit = () => (
      <Route
        render={({ history }) => (
          <button
            type="button"
            className="btn btn-primary btn-sm"
            onClick={() => {
              history.push({
                pathname: "/vehicleadd",
                state: {
                  vehicleId: Item.autoid,
                  type: "edit",
                  from: "account"
                }
              });
            }}
          >
            Edit
          </button>
        )}
      />
    );
    return <ButtonEdit />;
  };

  handleToastItemClose = itemObject => {
    const toastItems = this.state.toastItems.filter(
      c => c.id !== itemObject.id
    );
    this.setState({ toastItems });
  };

  onToastItemAdd(type, message) {
    let newid = this.state.toastItems.length + 1;
    if (
      this.state.toastItems.filter(function(e) {
        return e.id === newid;
      }).length > 0
    ) {
      newid = this.state.toastItems.length + Math.floor(Math.random() * 100);
    }

    let newToastItems = this.state.toastItems;
    let itemToast = {
      id: newid,
      type: type === "success" ? "success" : "danger",
      message: message
    };

    newToastItems.push(itemToast);
    this.setState({ toastItems: newToastItems });

    setTimeout(() => {
      this.handleToastItemClose(itemToast);
    }, 5000);
  }

  formateBody = () => {
    let showDataRows = this.state.filteredDataRows;
    return (
      <React.Fragment>
        <div className="container mt-4">
          <Row
            style={{
              margin: "0"
            }}
          >
            <Col
              style={{
                flexDirection: "column",
                display: "flex"
              }}
            >
              <Row>
                <Col>
                  <div className="card">
                    <div className="card-header">
                      <Row>
                        <Col xl={4} lg={4} md={4} sm={5} xs={12}>
                          <input
                            type="text"
                            name="searchPlateNumber"
                            placeholder="title..."
                            onChange={this.onChange}
                            value={this.state.searchPlateNumber}
                            style={{
                              width: "100%"
                            }}
                          />
                        </Col>
                        <Col
                          style={{
                            display: "flex"
                          }}
                          xl={3}
                          lg={3}
                          md={{ size: 4 }}
                          sm={7}
                          xs={12}
                        >
                          <button
                            style={{ height: "fit-content" }}
                            type="button"
                            className="btn btn-primary btn-sm"
                            onClick={this.getVehicleCount}
                          >
                            Search
                          </button>
                          {this.vehicleAdd()}
                          <div className="sweet-loading">
                            <RiseLoader
                              css={loaderCSS}
                              sizeUnit={"px"}
                              size={10}
                              color={"#123abc"}
                              loading={this.state.loading}
                            />
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Col>
              </Row>

              <Row className="mt-2">
                <Col>
                  <div className="card" style={{ width: "100%" }}>
                    <div className="table-responsive">
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th>Title</th>
                            <th>Plate Number </th>
                            <th>Fleet Number</th>
                            <th>Manufacture</th>
                            <th> Model</th>
                            <th>Fuel</th>
                          </tr>
                        </thead>
                        <tbody>
                          {showDataRows.map((Item, i) => (
                            <React.Fragment key={i}>
                              <tr>
                                <td>{Item.title}</td>
                                <td>{Item.plate_number}</td>
                                <td>{Item.fleet_number}</td>
                                <td>{Item.manufacture_name}</td>
                                <td>{Item.model}</td>
                                <td>{Item.fuel_type}</td>

                                <td>
                                  <div className="btn-group float-right">
                                    {this.vehicleEdit(Item)}
                                  </div>
                                </td>
                              </tr>
                            </React.Fragment>
                          ))}
                        </tbody>
                      </table>
                      <center>
                        {showDataRows.length ? "" : "No vehicles available"}
                      </center>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Pagination
                    activePage={this.state.activePage}
                    itemsCountPerPage={this.state.itemsCountPerPage}
                    totalItemsCount={this.state.totalItemsCount}
                    pageRangeDisplayed={10}
                    onChange={this.handlePageChange}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </React.Fragment>
    );
  };

  render() {
    return this.formateBody();
  }
}

export default Vehicles;
