import React from "react";
import { Col, Row } from "reactstrap";
import { Route } from "react-router-dom";
import { RiseLoader } from "react-spinners";
import { css } from "@emotion/core";

import ToastAlert from "./ToastAlert";
import Navbar from "../compon-styled/SupportCenterNavBar";
import { AppHelper_DateAndTimeToString } from "../businessjs/AppHelperFn";
import { api } from "../businessjs/API";
import PropTypes from "prop-types";
import SearchIcon from "@material-ui/icons/Search";
import AddIcon from "@material-ui/icons/Add";
import Grid from "@material-ui/core/Grid";
import {
  Typography,
  InputBase,
  IconButton,
  Fab,
  CircularProgress,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import { AuthHelperAccessCheck } from "../businessjs/AuthHelper";

const loaderCSS = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

function descendingComparator(a, b, orderBy) {
  if (orderBy !== "") {
    if (isNaN(parseFloat(a[orderBy]))) {
      if (b[orderBy].toLowerCase() < a[orderBy].toLowerCase()) {
        return -1;
      }
      if (b[orderBy].toLowerCase() > a[orderBy].toLowerCase()) {
        return 1;
      }
      return 0;
    } else {
      if (parseFloat(b[orderBy]) < parseFloat(a[orderBy])) {
        return -1;
      }
      if (parseFloat(b[orderBy]) > parseFloat(a[orderBy])) {
        return 1;
      }
      return 0;
    }
  }
}

const getComparator = (order, orderBy) => {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

const headCells = [
  { id: "logo_image", label: "Logo" },
  { id: "company_name", label: "Name" },
  { id: "company_code", label: "Code" },
  { id: "statuscode", label: "Status" },
  { id: "createdtime", label: "Registered Date" },
  { id: "expirytime", label: "Expiry Date" },
  { id: "edit", label: "" },
];

const EnhancedTableHead = (props) => {
  const {
    order,
    orderBy,

    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="center"
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
const styles = (theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
    overflowX: "auto",
  },
  table: {
    minWidth: 700,
  },
  SearchInput: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  searchPaper: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: 400,
  },
  subscriberTableRow: {
    // paddingRight: "3em",
  },
  subscriberTableHead: {
    // paddingLeft: "3em",
  },
  margin: {
    margin: theme.spacing(1),
  },
  buttonProgress: {
    color: "#1b5e20",
    position: "absolute",
    left: "94%",
    marginTop: "0.95em",
  },
});
class SubscriberList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      allDataRows: [],
      filteredDataRows: [],
      toastItems: [],
      loading: false,
      isOnDeleteMode: false,
      order: "asc",
      orderBy: "",
      page: 0,
      rowsPerPage: 50,
    };

    this.searchHandler = this.searchHandler.bind(this);
  }

  searchHandler(event) {
    let searcjQery = event.target.value.toLowerCase(),
      filteredDataRows = this.state.allDataRows.filter((el) => {
        let searchValue = el.company_name.toLowerCase();
        return searchValue.indexOf(searcjQery) !== -1;
      });
    this.setState({
      filteredDataRows: filteredDataRows,
    });
  }
  handleRequestSort = (event, property) => {
    const { order, orderBy } = this.state;
    const isAsc = orderBy === property && order === "asc";
    this.setState({ order: isAsc ? "desc" : "asc", orderBy: property });
  };
  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: parseInt(event.target.value, 10), page: 0 });
  };
  componentDidMount = () => {
    this.getAllSubscribers();
  };

  getAllSubscribers = async () => {
    this.setState({ loading: true });

    const subscriberLists = await api.post("sc-subscriber/get-all-subscribers");
    // console.log("subscriberLists", subscriberLists);
    if (subscriberLists && subscriberLists.userdata) {
      this.setState({
        allDataRows: subscriberLists.userdata,
        filteredDataRows: subscriberLists.userdata,
        loading: false,
      });
    } else {
      this.setState({ loading: false });
      AuthHelperAccessCheck(subscriberLists);
    }
  };

  handleToastItemClose = (itemObject) => {
    const toastItems = this.state.toastItems.filter(
      (c) => c.id !== itemObject.id
    );
    this.setState({ toastItems });
  };

  onToastItemAdd(type, message) {
    let newid = this.state.toastItems.length + 1;
    if (
      this.state.toastItems.filter(function (e) {
        return e.id === newid;
      }).length > 0
    ) {
      newid = this.state.toastItems.length + Math.floor(Math.random() * 100);
    }

    let newToastItems = this.state.toastItems;
    let itemToast = {
      id: newid,
      type: type === "success" ? "success" : "danger",
      message: message,
    };

    newToastItems.push(itemToast);
    this.setState({ toastItems: newToastItems });

    setTimeout(() => {
      this.handleToastItemClose(itemToast);
    }, 3000);
  }

  checkIsEmailVerified = () => {
    return (
      <button type="button" className="btn btn-default btn-sm">
        Resend
      </button>
    );
  };

  subscriberAdd = (loading) => {
    const { classes } = this.props;
    const ButtonAdd = () => (
      <Route
        render={({ history }) => (
          <Fab
            variant="extended"
            size="medium"
            color="primary"
            disabled={loading}
            aria-label="add"
            className={classes.margin}
            // style={{ height: "fit-content" }}
            // type="button"
            // className="btn btn-primary btn-sm"
            onClick={() => {
              history.push({
                // pathname: "/subscriber-add",
                pathname: `/subscriber-add/${"new"}`,
              });
            }}
          >
            <AddIcon className={classes.extendedIcon} />
            Add
          </Fab>
        )}
      />
    );
    return <ButtonAdd />;
  };

  edit = (companyId, companyName, status) => {
    const { classes } = this.props;
    const EditButton = () => (
      <Route
        render={({ history }) => (
          <Fab
            variant="extended"
            style={{ backgroundColor: "#00bcd4" }}
            size="small"
            color="primary"
            aria-label="edit"
            className={classes.margin}
            onClick={() => {
              history.push({
                pathname: "/account-details",
                state: {
                  companyId: companyId,
                  companyName: companyName,
                  statusCode: status,
                },
              });
            }}
          >
            View
          </Fab>
        )}
      />
    );
    return <EditButton />;
  };

  render() {
    let showDataRows = this.state.filteredDataRows;
    const { classes } = this.props;
    const { order, orderBy, rowsPerPage, page } = this.state;

    return (
      <React.Fragment>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h3" style={{ fontWeight: "bold" }}>
              Subscribers List
            </Typography>
          </Grid>
          <Grid item xs={12} sm={8} style={{ textAlign: "left" }}>
            <Paper className={classes.searchPaper}>
              <InputBase
                className={classes.SearchInput}
                placeholder="Search...."
                inputProps={{ "aria-label": "search " }}
                onChange={this.searchHandler}
              />
              <IconButton aria-label="search">
                <SearchIcon />
              </IconButton>
            </Paper>
          </Grid>
          <Grid item xs={6} sm={4} style={{ textAlign: "right" }}>
            {this.subscriberAdd(this.state.loading)}
            {this.state.loading && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </Grid>
          <Grid item xs={12} sm={12}>
            <Paper className={classes.root}>
              <Table className={classes.table}>
                <EnhancedTableHead
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={this.handleRequestSort}
                  rowCount={showDataRows.length}
                />
                <TableBody>
                  {stableSort(showDataRows, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, i) => (
                      <TableRow key={row.id}>
                        <TableCell component="th" scope="row" align="center">
                          <img
                            style={{ width: "4em" }}
                            src={
                              row.logo_image === ""
                                ? "https://sc.ctraac.com/images/no_image.jpg"
                                : "https://files.ctraac.com/app-images/company-logos/" +
                                  row.logo_image
                            }
                            alt="logo"
                          />
                        </TableCell>
                        <TableCell align="center">{row.company_name}</TableCell>
                        <TableCell align="center">{row.company_code}</TableCell>
                        <TableCell align="center">
                          {row.statuscode === 1 ? "Active" : "Suspended"}
                        </TableCell>
                        <TableCell align="center">
                          {AppHelper_DateAndTimeToString(row.createdtime)}
                        </TableCell>
                        <TableCell align="center">
                          {AppHelper_DateAndTimeToString(row.expirytime)}
                        </TableCell>
                        <TableCell align="center">
                          {" "}
                          {this.edit(
                            row.autoid,
                            row.company_name,
                            row.statuscode
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>

              <TablePagination
                rowsPerPageOptions={[50, 200, 500]}
                component="div"
                count={showDataRows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={this.handleChangePage}
                onChangeRowsPerPage={this.handleChangeRowsPerPage}
              />
            </Paper>
          </Grid>
        </Grid>
        {/* </Col>
          </Row> */}
        {/* </div> */}
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(SubscriberList);
