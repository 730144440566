import { makeStyles } from "@material-ui/styles";

export const useNavStyles = makeStyles(
  theme=> ({
    supportCenterHeading:{
        fontSize:"0.925rem",
        fontWeight:"normal",
        color:"#007bff"
    },
    mobileDrawer: {
        width: 256
      },
      desktopDrawer: {
        width: 256,
        // top: 64,
        // height: 'calc(100% - 64px)'
      },
      avatar: {
        cursor: 'pointer',
        width: 64,
        height: 64
      }
  })
);
