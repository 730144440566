import React, { useState, useEffect } from "react";
import "date-fns";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { Typography, TextField, Fab, MenuItem } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import ExportIcon from "@material-ui/icons/ArrowUpward";
import { CSVLink } from "react-csv";
import { api } from "../../businessjs/API";
import { ProductStocksList } from "./ProductStocksList";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  SearchInput: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  searchPaper: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    // width: 400,
  },
  margin: {
    margin: theme.spacing(1),
  },
  searchFab: {
    textAlign: "center",
    marginTop: "1em",
  },
  buttonProgress: {
    color: "#1b5e20",
    position: "absolute",
    left: "54.5%",
    marginTop: "0.6em",
  },
}));
const statusOptions = [
  { value: 0, label: "All" },
  { value: 1, label: "Active" },
  { value: 2, label: "Inactive" },
  { value: 3, label: "Suspend" },
];
const headers = [
  { label: "Q.No", key: "autoid" },
  { label: "Product Name", key: "product_name" },
  { label: "Type", key: "type" },
  { label: "Serial No.", key: "serial_no" },
  { label: "status", key: "status" },
  { label: "Client", key: "client_name" },
  { label: "Start Date", key: "startDate" },
  { label: "Next renewal", key: "next_renewable_date" },
];

export const ProductStocks = () => {
  const classes = useStyles();
  const [row, setRow] = useState([]);
  const [clientOptions, setClientOptions] = useState([]);
  const [client, setClient] = useState(0);
  const [name, setName] = useState("");
  const [status, setStatus] = useState(0);
  const [serialNumber, setSerialNumber] = useState("");
  const [loading, setLoading] = useState(false);
  const [dataToExport, setDataToExport] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  useEffect(() => {
    async function loadClientList() {
      try {
        setLoading(true);
        const subscribers = await api.post("sc-subscriber/get-all-for-cmb");
        if (subscribers !== undefined && subscribers.status !== "failed") {
          let subscriberArray = subscribers.userdata.map((opt) => ({
            label: opt.company_name,
            value: opt.autoid,
          }));
          setClientOptions(subscriberArray);
        }
        const input = {
          productName: "",
          status: 0,
          subscriberId: 0,
          serialNo: "",
        };
        const clientResult = await api.post(
          "sc-client_product/get-client-products-list",
          input
        );

        if (clientResult && Array.isArray(clientResult)) {
          setRow(clientResult);
          setDataToExport(clientResult);
          setRowCount(clientResult.length);
          setLoading(false);
        }
      } catch (e) {
        console.log("error", e);
        setLoading(false);
      }
    }
    loadClientList();
  }, []);
  const onSearchFunctionality = async () => {
    setLoading(true);
    try {
      const input = {
        productName: name,
        status: status,
        subscriberId: client,
        serialNo: serialNumber,
      };
      const searchResult = await api.post(
        "sc-client_product/get-client-products-list",
        input
      );
      setLoading(false);
      if (searchResult.status !== "failed" && searchResult !== undefined) {
        setRow(searchResult);
        setDataToExport(searchResult);
        setRowCount(searchResult.length);
      }
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };
  const onClearFunctionality = async () => {
    setLoading(true);
    setClient(0);
    setName("");
    setStatus(0);
    setSerialNumber("");
    try {
      const input = {
        productName: "",
        status: 0,
        subscriberId: 0,
        serialNo: "",
      };
      const clientResult = await api.post(
        "sc-client_product/get-client-products-list",
        input
      );
      setLoading(false);
      if (clientResult.status !== "failed" && clientResult !== undefined) {
        setRow(clientResult);
        setDataToExport(clientResult);
        setRowCount(clientResult.length);
      }
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h3" style={{ fontWeight: "bold" }}>
          Client Product List
        </Typography>
      </Grid>
      <Grid item xs={12} style={{ textAlign: "right" }}>
        <CSVLink
          data={dataToExport}
          headers={headers}
          filename={"productdetails.csv"}
        >
          <Fab
            variant="extended"
            size="medium"
            color="primary"
            style={{ display: rowCount !== 0 ? "" : "none" }}
            aria-label="export"
            className={classes.margin}
          >
            <ExportIcon className={classes.extendedIcon} />
            Export
          </Fab>
        </CSVLink>
      </Grid>

      <Grid item xs={12} sm={12} style={{ textAlign: "left" }}>
        <Paper className={classes.searchPaper}>
          <Grid
            container
            spacing={3}
            justify="center"
            style={{ padding: "2em" }}
          >
            <Grid item xs={12}>
              <Paper elevation={3}>
                <Grid
                  container
                  spacing={3}
                  justify="center"
                  style={{ padding: "1em" }}
                >
                  <Grid item xs={6} sm={3}>
                    <TextField
                      required
                      fullWidth
                      name="client"
                      label="Subscriber"
                      select
                      value={client}
                      inputProps={{ maxLength: 50 }}
                      onChange={(event) => setClient(event.target.value)}
                    >
                      <MenuItem key={0} value={0}>
                        All
                      </MenuItem>
                      {clientOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <TextField
                      required
                      fullWidth
                      label="Name"
                      name="name"
                      value={name}
                      onChange={(event) => setName(event.target.value)}
                      inputProps={{ maxLength: 50 }}
                    />
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <TextField
                      required
                      fullWidth
                      label="Status"
                      name="status"
                      value={status}
                      select
                      inputProps={{ maxLength: 50 }}
                      onChange={(event) => setStatus(event.target.value)}
                    >
                      {statusOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <TextField
                      required
                      fullWidth
                      name="serialNumber"
                      label="Serial Number"
                      value={serialNumber}
                      inputProps={{ maxLength: 50 }}
                      onChange={(event) => setSerialNumber(event.target.value)}
                    />
                  </Grid>

                  <Grid item xs={6} sm={6} className={classes.searchFab}>
                    <Fab
                      variant="extended"
                      size="small"
                      color="primary"
                      disabled={loading}
                      style={{ marginRight: "1em" }}
                      onClick={onSearchFunctionality}
                    >
                      Search
                    </Fab>
                    {loading && (
                      <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                      />
                    )}
                    <Fab
                      variant="extended"
                      size="small"
                      color="primary"
                      onClick={onClearFunctionality}
                    >
                      Clear
                    </Fab>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>

            <Grid item xs={12} style={{ marginTop: "2em" }}>
              <ProductStocksList
                rows={row}
                //   onDeletePurchaseBill={onDeletePurchaseBill}
              />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      {/* <Grid item xs={4} sm={2} style={{marginLeft:"-2.5em"}}>
         
        </Grid> */}
    </Grid>
  );
};
