import React, { useEffect, useState } from "react";
import moment from "moment";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { Typography, Card, Divider, TextField, Grid } from "@material-ui/core";
import { Formik, Form, ErrorMessage, useFormik } from "formik";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { Route } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";

import { api } from "../businessjs/API";
import { ArrowLeftCircle } from "react-feather";
import { SnackBar } from "../compon-controls/SnackBar";

const styles = {
  resize: {
    "&::placeholder": {
      fontSize: "0.813rem",
    },
  },
};

const SubscriberMoreInfo = (props) => {
  const [address, setAddress] = useState("");
  const [location, setLocation] = useState("");
  const [building, setBuilding] = useState("");
  const [oldValues, setoldValues] = useState([]);
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [incomingSubscriber, setIncomingSubscriber] = useState([]);
  const [isToUpdate, setIsToUpdate] = useState(true);
  const [autoid, setAutoid] = useState(0);
  const [messageText, setMessageText] = useState("");
  const [snackbarColor, setSnackbarColor] = useState("");
  const [loading, setLoading] = useState(false);
    // console.log("props", props.accountid);
  const { classes } = props;
  useEffect(() => {
    const getSubscriberProfile = async () => {
      setLoading(true);
      try {
        const getSubscriberInputs = {
          companyId: props.accountid,
        };
        const subscriberDetails = await api.post(
          "sc-subscriber/get-subscriber-profile-by-id",
          getSubscriberInputs
        );

        if (subscriberDetails.length !== 0) {
          setoldValues(subscriberDetails[0]);
          setIncomingSubscriber(subscriberDetails);
          setAddress(subscriberDetails[0].address);
          setLocation(subscriberDetails[0].location);
          setBuilding(subscriberDetails[0].building);
          setCity(subscriberDetails[0].city);
          setState(subscriberDetails[0].state);
          setZipcode(subscriberDetails[0].zipcode);
          setAutoid(subscriberDetails[0].autoid);
          setLoading(false);
        }
        else{
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        console.log("something went wrong!", error);
      }
    };
    if (props.accountid) {
      getSubscriberProfile();
    }
  }, [props.accountid]);

  const cancelButton = () => {
    const ButtonCancel = () => (
      <Route
        render={({ history }) => (
          <button
            type="reset"
            className="btn btn-sm btn-warning mr-1"
            onClick={() => {
              history.goBack({
                pathname: "/supportcenter/subscriberlist",
              });
            }}
          >
            Cancel
          </button>
        )}
      />
    );
    return <ButtonCancel />;
  };

  const checkChangeValues = (newValues) => {
    if (
      oldValues.address === newValues.address &&
      oldValues.location === newValues.location &&
      oldValues.building === newValues.building &&
      oldValues.city === newValues.city &&
      oldValues.state === newValues.state &&
      oldValues.zipcode === newValues.zipcode
    ) {
      setIsToUpdate(true);
    } else {
      setIsToUpdate(false);
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    const _input = {
      // autoId: autoid,
      autoId: props.accountid,
      address: address,
      state: state,
      city: city,
      location: location,
      building: building,
      zipcode: zipcode,
    };
    // console.log("_input", _input);

    const updateResult = await api.post(
      "sc-subscriber/update-subscriber-profile",
      _input
    );
    // console.log("updateResult", updateResult);
    if (updateResult.status === "success") {
      setMessageText(updateResult.message);
      setSnackbarColor("#4BB543");
    } else {
      setMessageText(updateResult.message);
      setSnackbarColor("#d50000");
    }
  };

  const onCloseSnackbar = () => {
    setMessageText("");
  };

  return (
    <Grid style={{ padding: 15 }}>
      <SnackBar
        status={messageText}
        onCloseError={onCloseSnackbar}
        color={snackbarColor}
      />
      <Card>
        <form onSubmit={onSubmit}>
          <Grid
            container
            spacing={5}
            item
            style={{ padding: 15, marginTop: 10 }}
          >
            <Grid
              item
              xs={4}
              style={{
                marginTop: -25,
              }}
            >
              <Typography style={{ fontSize: 12 }}>Address</Typography>
              <div className="text_container">
                <TextField
                  variant="outlined"
                  name="address"
                  placeholder="Address"
                  fullWidth
                  InputProps={{
                    classes: {
                      input: classes.resize,
                    },
                  }}
                  required
                  value={address}
                  onChange={(event) => {
                    setAddress(event.target.value);
                    let currentValue = {
                      address: event.target.value,
                      location: location,
                      building: building,
                      city: city,
                      state: state,
                      zipcode: zipcode,
                    };
                    checkChangeValues(currentValue);
                  }}
                />
              </div>
            </Grid>

            <Grid
              item
              xs={4}
              style={{
                marginTop: -25,
              }}
            >
              <Typography style={{ fontSize: 12 }}>Location</Typography>
              <div className="text_container">
                <TextField
                  variant="outlined"
                  name="location"
                  placeholder="Location"
                  fullWidth
                  InputProps={{
                    classes: {
                      input: classes.resize,
                    },
                  }}
                  // required
                  value={location}
                  onChange={(event) => {
                    setLocation(event.target.value);

                    let currentValue = {
                      address: address,
                      location: event.target.value,
                      building: building,
                      city: city,
                      state: state,
                      zipcode: zipcode,
                    };
                    checkChangeValues(currentValue);
                  }}
                />
              </div>
            </Grid>
            <Grid
              item
              xs={4}
              style={{
                marginTop: -25,
              }}
            >
              <Typography style={{ fontSize: 12 }}>Building</Typography>
              <div className="text_container">
                <TextField
                  variant="outlined"
                  name="building"
                  placeholder="Building"
                  fullWidth
                  InputProps={{
                    classes: {
                      input: classes.resize,
                    },
                  }}
                  // required
                  value={building}
                  onChange={(event) => {
                    setBuilding(event.target.value);
                    let currentValue = {
                      address: address,
                      location: location,
                      building: event.target.value,
                      city: city,
                      state: state,
                      zipcode: zipcode,
                    };
                    checkChangeValues(currentValue);
                  }}
                />
              </div>
            </Grid>

            <Grid
              item
              xs={4}
              style={{
                marginTop: -25,
              }}
            >
              <Typography style={{ fontSize: 12 }}>City</Typography>
              <div className="text_container">
                <TextField
                  variant="outlined"
                  name="city"
                  placeholder="City"
                  fullWidth
                  InputProps={{
                    classes: {
                      input: classes.resize,
                    },
                  }}
                  // required
                  value={city}
                  onChange={(event) => {
                    setCity(event.target.value);
                    let currentValue = {
                      address: address,
                      location: location,
                      building: building,
                      city: event.target.value,
                      state: state,
                      zipcode: zipcode,
                    };
                    checkChangeValues(currentValue);
                  }}
                />
              </div>
            </Grid>
            <Grid
              item
              xs={4}
              style={{
                marginTop: -25,
              }}
            >
              <Typography style={{ fontSize: 12 }}>State</Typography>
              <div className="text_container">
                <TextField
                  variant="outlined"
                  name="state"
                  placeholder="State"
                  fullWidth
                  InputProps={{
                    classes: {
                      input: classes.resize,
                    },
                  }}
                  // required
                  value={state}
                  onChange={(event) => {
                    let currentValue = {
                      address: address,
                      location: location,
                      building: building,
                      city: city,
                      state: event.target.value,
                      zipcode: zipcode,
                    };
                    checkChangeValues(currentValue);
                    setState(event.target.value);
                  }}
                />
              </div>
            </Grid>
            <Grid
              item
              xs={4}
              style={{
                marginTop: -25,
              }}
            >
              <Typography style={{ fontSize: 12 }}>Zip Code</Typography>
              <div className="text_container">
                <TextField
                  variant="outlined"
                  name="zipcode"
                  placeholder="Zip Code"
                  fullWidth
                  InputProps={{
                    classes: {
                      input: classes.resize,
                    },
                  }}
                  // required
                  value={zipcode}
                  onChange={(event) => {
                    setZipcode(event.target.value);
                    let currentValue = {
                      address: address,
                      location: location,
                      building: building,
                      city: city,
                      state: state,
                      zipcode: event.target.value,
                    };
                    checkChangeValues(currentValue);
                  }}
                />
              </div>
            </Grid>

            <Grid container item style={{ padding: 15 }}>
              {cancelButton()}
              <button
                type="submit"
                className="btn btn-primary btn-sm"
                disabled={isToUpdate}
              >
                <i className="fa fa-check-square-o" />
                Update
              </button>
              {loading && <CircularProgress size={24} />}
            </Grid>
          </Grid>
        </form>
      </Card>
    </Grid>
  );
};

export default withStyles(styles)(SubscriberMoreInfo);
