import React, { Component } from "react";
import { Route, Redirect } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { Paper, Grid, Typography, TextField, Fab } from "@material-ui/core";
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from "react-router-dom";
import { SnackBar } from "../compon-controls/SnackBar";
import moment from "moment";

import CmbSelect from "../compon-controls/CmbSelect";
import { AuthHelperAccessCheck } from "../businessjs/AuthHelper";
import { AppHelper_ShortDateToString } from "../businessjs/AppHelperFn";
import { api } from "../businessjs/API";

const typeOptions = [
  { value: 1, label: "Installation" },
  { value: 2, label: "Service" },
];

const statusOptions = [
  { value: 1, label: "Completed" },
  { value: 2, label: "Open" },
];

const chargeableOptions = [
  { value: 0, label: "No" },
  { value: 1, label: "Yes" },
];

const NavLink = React.forwardRef((props, ref) => (
  <RouterLink innerRef={ref} {...props} />
));
class JobCardCreation extends Component {
  constructor(props) {
    super(props);
    this.redirect = 0;
  }

  state = {
    loading: false,
    ticketList: [],
    locationList: [],
    location_name: null,
    service_list: [],
    selected_service: null,
    subject: null,
    job_date: new Date(),
    start_time: new Date(),
    end_time: new Date(),
    subscriber_id: null,
    title: "",
    details: "",
    remarks: "",
    toastItems: [],
    type: { value: 2, label: "Service" },
    errorMessage: "",
    visit: null,
    visits_count: [],
    redirect: "",
    status: { value: 1, label: "Completed" },
    ischargeable: { value: 1, label: "Yes" },
    error: false,
    color: "#d50000",
    infoMessgae: false,
    navigation: false,
    subscriberList: undefined,
  };

  componentDidMount = () => {
    this.getSubscriberForCmb();
  };

  getSubscriberForCmb = async () => {
    const subscribers = await api.post("sc-subscriber/get-all-for-cmb");
    if (subscribers && subscribers.userdata) {
      let subscriberArray = subscribers.userdata.map((opt) => ({
        label: opt.company_name,
        value: opt,
      }));

      this.setState({
        subscriberList: subscriberArray,
      });
    } else {
      AuthHelperAccessCheck(subscribers);
    }
  };

  cancelButton = () => {
    const CancelButton = () => (
      <Route
        render={({ history }) => (
          <button
            type="button"
            className="btn btn-warning btn-sm mr-1"
            onClick={() => {
              history.push({
                pathname: "/job-card",
              });
            }}
          >
            Cancel
          </button>
        )}
      />
    );
    return <CancelButton />;
  };

  validateServiceProduct = (service) => {
    const { visit } = this.state;
    let result = true;

    if (service && visit) {
      if (
        service.value.number_of_service_visits * service.value.quantity <=
        service.value.service_count
      ) {
        let visitValue = this.validateVisit(visit, service);
        if (!visitValue) {
          this.setErrorMessage(
            "Service quota reached. Maximum service count is " +
              service.value.number_of_service_visits
          );
        }
        result = visitValue;
      } else {
        this.setErrorMessage("");
        result = this.validateVisit(visit, service);
      }
    } else {
      //result = false;
    }
    return result;
  };

  setErrorMessage = (msg) => {
    this.setState({
      errorMessage: msg,
    });
  };

  validateVisit = (visit, selected_service) => {
    const { visits_count } = this.state;
    let result = true;
    if (visit) {
      let visitDate = AppHelper_ShortDateToString(visit.value.visit_date);
      let jobDate = AppHelper_ShortDateToString(this.state.job_date);

      if (visitDate !== jobDate) {
        this.setErrorMessage("Job card date and Vist date should be same");
        result = false;
      } else if (selected_service) {
        let max_visits = selected_service.value.max_services_per_visit;
        const visit_cnt = visits_count.filter(
          (row) =>
            row.service_product_id === selected_service.value.autoid &&
            row.visit_id === visit.value.autoid
        );

        let visit_count = 0;
        if (visit_cnt && visit_cnt.length > 0)
          visit_count = visit_cnt[0].service_count;

        if (visit_count >= max_visits) {
          let additional_charge =
            selected_service.value.additional_per_unit_price;
          this.setErrorMessage(
            "Maximum Service per visit is " +
              max_visits +
              ". Reached the maximum. Needs to pay additional charge (" +
              additional_charge +
              ")"
          );
          // result = false;
        } else {
          if (
            selected_service.value.number_of_service_visits *
              selected_service.value.quantity <=
            selected_service.value.service_count
          ) {
            this.setErrorMessage(
              "Service quota reached. Maximum service count is " +
                selected_service.value.number_of_service_visits
            );
            result = false;
          } else {
            this.setErrorMessage("");
          }
        }
      } else {
        this.setErrorMessage("");
      }
    }

    return result;
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onServiceProductChange = (e) => {
    if (this.validateServiceProduct(e.target.value)) {
      this.setState({ [e.target.name]: e.target.value });
    }
  };

  onVisitChange = (e) => {
    if (this.validateVisit(e.target.value, this.state.selected_service)) {
      this.setState({ [e.target.name]: e.target.value });
      this.getLocationByVisit(e.target.value.value.location_id);
    }
  };

  getLocationByVisit = (_location_id) => {
    const currentLocation = this.state.locationList.filter(
      (location) => location.value.autoid === _location_id
    );
    this.setState({ location_name: currentLocation[0] });
  };

  subscriberChange = (e) => {
    this.setState({ [e.target.name]: e.target.value, loading: true });
    const _input = {
      subscriber_id: e.target.value.value.autoid,
    };
    this.getVisitForCmb(_input);
    this.getVisitCount(_input);
    this.getServiceProducts(_input);
    this.getSelectLocationTicket(_input);
  };

  getSelectLocationTicket = async (_input) => {
    const res = await api.post("job-card/get-ticket-location", _input);
    if (res) {
      let locationArray = res.locationSet.map((opt) => ({
        label: opt.location_name,
        value: opt,
      }));
      // locationArray.splice(0, 0, {
      //   label: "Select",
      //   value: { autoid: 0 },
      // });

      let ticketArray = res.ticketSet.map((opt) => ({
        label: opt.subject,
        value: opt,
      }));
      // ticketArray.splice(0, 0, {
      //   label: "Select",
      //   value: { autoid: 0 },
      // });
      this.setState({
        locationList: locationArray,
        ticketList: ticketArray,
        loading: false,
      });
    } else {
      this.setState({ loading: false });
    }
  };

  getVisitForCmb = async (_input) => {
    const res = await api.post("sc-visit/get-cmb-visit", _input);
    if (res) {
      let visitArray = res.map((opt) => ({
        label: opt.name,
        value: opt,
      }));
      // visitArray.splice(0, 0, {
      //   label: "Select",
      //   value: { autoid: 0 },
      // });

      this.setState({
        visitList: visitArray,
      });
    } else {
      AuthHelperAccessCheck(res);
    }
  };

  getVisitCount = async (_input) => {
    const res = await api.post("sc-visit/get-visit-count-by-service", _input);

    if (res) {
      this.setState({
        visits_count: res,
      });
    } else {
      AuthHelperAccessCheck(res);
    }
  };

  getServiceProducts = async (_input) => {
    const res = await api.post(
      "sc-product/get-serice-visit-product-list",
      _input
    );
    if (res) {
      let productArray = res.map((opt) => ({
        label: opt.product_name,
        value: opt,
      }));
      // productArray.splice(0, 0, {
      //   label: "Select",
      //   value: { autoid: 0 },
      // });

      this.setState({
        service_list: productArray,
      });
    } else {
      AuthHelperAccessCheck(res);
    }
  };

  onSubmit = (e) => {
    e.preventDefault();

    if (this.state.subscriber_id === null) {
      this.setState({ errorMessage: "Please select subscriber." });
      return;
    } else if (this.state.subject === null) {
      this.setState({ errorMessage: "Please select ticket." });
      return;
    } else if (this.state.location_name === null) {
      this.setState({ errorMessage: "Please select location." });
      return;
    } else if (this.state.visit === null) {
      this.setState({ errorMessage: "Please select visit." });
      return;
    } else if (this.state.selected_service === null) {
      this.setState({ errorMessage: "Please select service." });
      return;
    } else {
      if (this.state.errorMessage) this.setState({ errorMessage: "" });
    }
    this.saveJobCard();
  };

  saveJobCard = async () => {
    const token = localStorage.apiAuthAccessKey;
    const decoded = jwt_decode(token);
    const decodedUser = decoded.userAccessKey[0];

    const _jobCardAddInput = {
      subscriber_id: this.state.subscriber_id.value.autoid,
      title: this.state.title,
      location_id: this.state.location_name.value.autoid,
      start_time: this.state.start_time,
      end_time: this.state.end_time,
      status: this.state.status.value,
      ischargeable: this.state.ischargeable.value,
      details: this.state.details,
      userType: decodedUser.role,
      job_date: this.state.job_date,
      ticket_id: this.state.subject.value.autoid,
      remarks: this.state.remarks,
      type: this.state.type.value,
      subscription_id: 0, // default
      visit_id: this.state.visit.value.autoid,
      service_product_id: this.state.selected_service.value.autoid,
    };

    console.log("add ob", _jobCardAddInput);
    const res = await api.post("job-card/add-job-card", _jobCardAddInput);
    if (res) {
      AuthHelperAccessCheck(res);
      if (res.status === "success") {
        // this.onToastItemAdd(res.status, res.message);

        // this.redirect = 1;
        setTimeout(() => {
          this.setState({ errorMessage: res.message, color: "#1b5e20" });
          this.redirect = 1;
        }, 1000);
      }
    }
  };

  onStartDateChange = (e) => {
    this.setState({ start_time: e._d });
  };

  onEndDateChange = (e) => {
    this.setState({ end_time: e._d });
  };

  onDateChange = (e) => {
    this.setState({ job_date: e._d });
  };

  onCloseSnackbar = () => {
    this.setState({ errorMessage: "" });
  };

  onDateChange = (e) => {
    this.setState({ job_date: e });
  };

  onStartDateChange = (e) => {
    this.setState({ start_time: e });
  };

  onEndDateChange = (e) => {
    this.setState({ end_time: e });
  };

  render() {
    if (this.redirect === 1) return <Redirect to="/job-card" />;

    return (
      <React.Fragment>
        <SnackBar
          status={this.state.errorMessage}
          onCloseError={this.onCloseSnackbar}
          color={this.state.color}
        />
        <form className="form" onSubmit={this.onSubmit}>
          <Paper elevation={4}>
            <Grid container spacing={3} style={{ padding: "2em" }}>
              <Grid item xs={12}>
                <Typography variant="h4" style={{ fontWeight: "bold" }}>
                  Add new Job Card
                </Typography>
              </Grid>

              <Grid item xs={6} sm={4}>
                <Typography
                  variant="h6"
                  style={{ textAlign: "left", fontWeight: "bold" }}
                >
                  Subscriber
                </Typography>
                <CmbSelect
                  name="subscriber_id"
                  value={this.state.subscriber_id}
                  onChange={this.subscriberChange}
                  dataSource={this.state.subscriberList}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography
                  variant="h6"
                  style={{ textAlign: "left", fontWeight: "bold" }}
                >
                  Title
                </Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  name="title"
                  required
                  placeholder="Title"
                  value={this.state.title}
                  onChange={this.onChange}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography
                  variant="h6"
                  style={{ textAlign: "left", fontWeight: "bold" }}
                >
                  Type
                </Typography>
                <CmbSelect
                  name="type"
                  onChange={this.onChange}
                  value={this.state.type} // default 2=service
                  dataSource={typeOptions}
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <Typography
                  variant="h6"
                  style={{ textAlign: "left", fontWeight: "bold" }}
                >
                  Ticket
                </Typography>
                <CmbSelect
                  name="subject"
                  value={this.state.subject}
                  onChange={this.onChange}
                  dataSource={this.state.ticketList}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography
                  variant="h6"
                  style={{ textAlign: "left", fontWeight: "bold" }}
                >
                  Date
                </Typography>
                <TextField
                  type="date"
                  fullWidth
                  variant="outlined"
                  name="job_date"
                  required
                  value={moment(this.state.job_date).format("YYYY-MM-DD")}
                  onChange={(event) => this.onDateChange(event.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography
                  variant="h6"
                  style={{ textAlign: "left", fontWeight: "bold" }}
                >
                  Status
                </Typography>
                <CmbSelect
                  name="status"
                  onChange={this.onChange}
                  value={this.state.status}
                  dataSource={statusOptions}
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <Typography
                  variant="h6"
                  style={{ textAlign: "left", fontWeight: "bold" }}
                >
                  Start
                </Typography>
                <TextField
                  type="date"
                  fullWidth
                  variant="outlined"
                  name="start_time"
                  required
                  value={moment(this.state.start_time).format("YYYY-MM-DD")}
                  onChange={(event) =>
                    this.onStartDateChange(event.target.value)
                  }
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography
                  variant="h6"
                  style={{ textAlign: "left", fontWeight: "bold" }}
                >
                  End
                </Typography>
                <TextField
                  type="date"
                  fullWidth
                  variant="outlined"
                  name="end_time"
                  required
                  value={moment(this.state.end_time).format("YYYY-MM-DD")}
                  onChange={(event) => this.onEndDateChange(event.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography
                  variant="h6"
                  style={{ textAlign: "left", fontWeight: "bold" }}
                >
                  Service
                </Typography>
                <CmbSelect
                  name="selected_service"
                  value={this.state.selected_service}
                  onChange={this.onServiceProductChange}
                  dataSource={this.state.service_list}
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <Typography
                  variant="h6"
                  style={{ textAlign: "left", fontWeight: "bold" }}
                >
                  Visit
                </Typography>
                <CmbSelect
                  name="visit"
                  value={this.state.visit}
                  onChange={this.onVisitChange}
                  dataSource={this.state.visitList}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography
                  variant="h6"
                  style={{ textAlign: "left", fontWeight: "bold" }}
                >
                  Location
                </Typography>
                <CmbSelect
                  name="location_name"
                  value={this.state.location_name}
                  onChange={this.onChange}
                  dataSource={this.state.locationList}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography
                  variant="h6"
                  style={{ textAlign: "left", fontWeight: "bold" }}
                >
                  Chargeable
                </Typography>
                <CmbSelect
                  name="ischargeable"
                  onChange={this.onChange}
                  value={this.state.ischargeable}
                  dataSource={chargeableOptions}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography
                  variant="h6"
                  style={{ textAlign: "left", fontWeight: "bold" }}
                >
                  Details
                </Typography>
                <TextField
                  fullWidth
                  multiline
                  rows={3}
                  rowsMax={10}
                  variant="outlined"
                  type="text"
                  name="details"
                  value={this.state.details}
                  onChange={this.onChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography
                  variant="h6"
                  style={{ textAlign: "left", fontWeight: "bold" }}
                >
                  Remarks
                </Typography>
                <TextField
                  fullWidth
                  multiline
                  rows={3}
                  rowsMax={10}
                  variant="outlined"
                  type="text"
                  value={this.state.remarks}
                  onChange={this.onChange}
                  name="remarks"
                />
              </Grid>

              <Grid item xs={6} style={{ textAlign: "right" }}>
                <Fab
                  variant="extended"
                  size="small"
                  color="primary"
                  type="reset"
                  component={NavLink}
                  to="/job-card"
                >
                  Cancel
                </Fab>
                {/* {this.cancelButton()} */}
              </Grid>
              <Grid item xs={6} style={{ textAlign: "left" }}>
                <Fab
                  variant="extended"
                  size="small"
                  color="primary"
                  aria-label="add"
                  type="submit"
                >
                  Add
                </Fab>
              </Grid>
            </Grid>
          </Paper>
        </form>
      </React.Fragment>
    );
  }
}

export default JobCardCreation;
