import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  InputBase,
  IconButton,
  Fab,
  Paper,
  Grid,
  FormControl,
  InputLabel,
  MenuItem,
  CircularProgress,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import SearchIcon from "@material-ui/icons/Search";
import ExportIcon from "@material-ui/icons/ArrowUpward";
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from "react-router-dom";
import moment from "moment";

import Select from "../../compon-controls/CmbSelect";
import { api } from "../../businessjs/API";
import { SnackBar } from "../../compon-controls/SnackBar";
import { ExecutiveList } from "./ExecutiveList";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },

  searchInput: {
    alignItems: "center",
    width: 400,
  },
}));

export const Executive = () => {
  const classes = useStyles();
  const [subscriberId, setSubscriberId] = useState(0);
  const [subscriberList, setSubscriberList] = useState([]);
  const [executiveList, setExecutiveList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [poNumber, setPoNumber] = useState("3");
  const [color, setColor] = useState("#d50000");
  const [infoMessgae, setInfoMessage] = useState(false);
  const NavLink = React.forwardRef((props, ref) => (
    <RouterLink innerRef={ref} {...props} />
  ));

  useEffect(() => {
    getSubscriberForCmb();
  }, [subscriberList.length]);

  const getSubscriberForCmb = async () => {
    setLoading(true);
    const subscribers = await api.post("sc-subscriber/get-all-for-cmb");
    if (subscribers && subscribers.userdata) {
      let subscriberArray = subscribers.userdata.map((opt) => ({
        label: opt.company_name,
        value: opt,
      }));

      subscriberArray.splice(0, 0, {
        label: "Select",
        value: { autoid: 0 },
      });

      setSubscriberList(subscriberArray);
      const _executiveListInputs = {
        subscriber_id: subscriberId,
      };
      getExecutiveList(_executiveListInputs);
    }
  };

  const getExecutiveList = async (_executiveListInputs) => {
    setLoading(true);
    const executiveList = await api.post(
      "sc-executive/get-executive-list",
      _executiveListInputs
    );

    if (executiveList && Array.isArray(executiveList)) {
      setExecutiveList(executiveList);
      setLoading(false);
    } else {
      setLoading(false);
      setExecutiveList([]);
    }
  };

  const onCloseSnackbar = () => {
    setInfoMessage("");
  };

  const onChange = (event) => {
    setSubscriberId(event.target.value);
    const _input = {
      subscriber_id: event.target.value.value.autoid,
    };
    getExecutiveList(_input);
  };

  const deleteExecutive = async (autoId) => {
    const input = {
      autoId: autoId,
    };
    // setInfoMessage(autoId);
    const deleteResult = await api.post("sc-executive/delete-executive", input);
    if (deleteResult.status === "success") {
      setInfoMessage(deleteResult.message);
      setColor("#4caf50");

      const _inputs = {
        subscriber_id: subscriberId ? subscriberId.value.autoid : 0,
      };
      getExecutiveList(_inputs);
    } else {
      setInfoMessage(deleteResult.message);
      setColor("#d50000");
    }
  };

  return (
    <Grid container spacing={3}>
      <SnackBar
        status={infoMessgae}
        onCloseError={onCloseSnackbar}
        color={color}
      />
      <Grid item xs={12}>
        <Typography variant="h3" style={{ fontWeight: "bold" }}>
          Executive List
        </Typography>
      </Grid>
      <Grid item xs={12} sm={8} style={{ textAlign: "left" }}>
        <Grid className={classes.searchInput}>
          <Select
            name="account_id"
            value={subscriberId}
            onChange={onChange}
            dataSource={subscriberList}
          />
        </Grid>
      </Grid>
      <Grid item xs={4} style={{ textAlign: "right" }}>
        <Fab
          variant="extended"
          size="medium"
          color="primary"
          aria-label="add"
          disabled={loading}
          className={classes.margin}
          component={NavLink}
          to={`/executive/${"new"}`}
        >
          <AddIcon className={classes.extendedIcon} />
          Add
          {loading === true ? <CircularProgress size={35} /> : null}
        </Fab>
      </Grid>

      <Grid item xs={12}>
        <ExecutiveList rows={executiveList} deleteExecutive={deleteExecutive} />
      </Grid>
    </Grid>
  );
};
