import React, { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import { makeStyles } from "@material-ui/core/styles";
import { Paper, Grid, Typography, Fab, Tooltip } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DescriptionIcon from "@material-ui/icons/Description";
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from "react-router-dom";
import { api } from "../../businessjs/API";
import moment from "moment";
import { saveAs } from "file-saver";

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

export const QuotationView = (props) => {
  const classes = useStyles();
  const [quotationDetails, setQuotationDetails] = useState({});
  const [rows, setRows] = useState([]);
  const [subTotal, setSubTotal] = useState("");
  const NavLink = React.forwardRef((props, ref) => (
    <RouterLink innerRef={ref} {...props} />
  ));
  useEffect(() => {
    const loadQuatationDetails = async () => {
      try {
        // console.log("id", props.match.params.id);
        const quotationId = props.match.params.id;
        const quotationDetail = await api.post(
          "sc-quotation/get-quotation-by-id",
          { quotationId }
        );

        console.log("quotationDetails", quotationDetail);
        if (
          quotationDetail !== undefined &&
          quotationDetail.status !== "failed"
        ) {
          setQuotationDetails(quotationDetail.quotation);
          setRows(quotationDetail.quotationProductSet);
          const sub =
            parseFloat(quotationDetail.quotation.discount) +
            parseFloat(quotationDetail.quotation.total_amount);
          setSubTotal(sub);
          if (quotationDetail.bundleSet.length !== 0) {
            // console.log("productSet", quotationDetail.quotationProductSet);
            const gridEditViewProduct = quotationDetail.quotationProductSet.map(
              (product) => ({
                autoid: product.autoid,
                active_days:product.active_days,
                type: product.type,
                bundle_id: product.bundle_id,
                discount: product.discount,
                division_id: product.division_id,
                price: product.price,
                product_name: product.product_name,
                quantity: product.quantity,
                total: product.total,
                bundle_items: quotationDetail.bundleSet.filter(
                  (c) => c.bundle_id === product.bundle_id
                ),
              })
            );
            setRows(gridEditViewProduct);
          }
        }
      } catch (e) {
        console.log(e);
      }
    };
    loadQuatationDetails();
  }, [props.match.params.id]);

  const onDownloadPdf = async () => {
    const quotationId = props.match.params.id;
    const pdfResult = await api.post(
      "sc-quotation-pdf/get-quotation-pdf-by-id",
      { quotationId }
    );
    // console.log("pdfResult", pdfResult);
    if (pdfResult) {
      const pdfInput = {
        fileName: pdfResult.fileName,
      };
      const fetchQuation = await api.post(
        "sc-quotation-pdf/fetch-quotation-pdf",
        pdfInput,
        "blob"
      );
      if (fetchQuation) {
        const fileName = moment(new Date()).format("YYYYMMDDhhmmss");
        const pdfBlob = new Blob([fetchQuation], {
          type: "application/pdf",
        });
        saveAs(pdfBlob, `${fileName}.pdf`);
      }
    }
  };
  return (
    <Grid container spacing={3} justify="center" alignItems="center">
      <Paper style={{ minHeight: 875, width: 800 }}>
        <Grid item xs={12} style={{ textAlign: "right", marginTop: "2em" }}>
          <Fab
            size="small"
            color="primary"
            aria-label="edit"
            style={{
              marginRight: "1em",
              display: quotationDetails.status === 0 ? "none" : "",
            }}
            component={NavLink}
            to={`/quotation/${props.match.params.id}`}
          >
            <Tooltip title="Edit">
              <EditIcon />
            </Tooltip>
          </Fab>
          <Tooltip title="Pdf">
            <Fab
              size="small"
              color="primary"
              aria-label="edit"
              style={{ marginRight: "1em" }}
              onClick={onDownloadPdf}
            >
              <DescriptionIcon />
            </Fab>
          </Tooltip>
        </Grid>
        <Grid item xs={12} style={{ margin: "2em" }}>
          <Grid container spacing={3} justify="space-evenly">
            <Grid item xs={6} style={{ marginTop: "2em" }}>
              <img
                src="https://webdev.cloudtel.qa/wp-content/uploads/2020/03/CloudTel-Logo.png"
                className="img-responsive"
                alt="logo"
              />
            </Grid>
            <Grid item xs={6} style={{ marginTop: "2em" }}>
              <Typography
                variant="h2"
                style={{ textAlign: "right", fontWeight: "bold" }}
              >
                Quotation
              </Typography>
              <Typography
                variant="h6"
                style={{ textAlign: "right", fontWeight: "bold" }}
              >
                No : #{props.match.params.id}
              </Typography>
              <Typography
                variant="h6"
                style={{ textAlign: "right", fontWeight: "bold" }}
              >
                Date:
                {moment(quotationDetails.quote_date).format("DD/MM/YYYY")}
              </Typography>
              <Typography
                variant="h6"
                style={{ textAlign: "right", fontWeight: "bold" }}
              >
                Due Date:
                {moment(quotationDetails.due_date).format("DD/MM/YYYY")}
              </Typography>
            </Grid>
            <Grid item xs={12} style={{ marginTop: "2em" }}>
              <Typography
                variant="h5"
                style={{ textAlign: "left", fontWeight: "bold" }}
              >
                To,
              </Typography>
              <Typography
                variant="h5"
                style={{ textAlign: "left", fontWeight: "bold" }}
              >
                {quotationDetails.account_name}
              </Typography>
              <Typography
                variant="h5"
                style={{
                  textAlign: "left",
                  fontWeight: "bold",
                  display:
                    quotationDetails.building &&
                    quotationDetails.location !== undefined
                      ? ""
                      : "none",
                }}
              >
                {quotationDetails.building},{quotationDetails.location}
              </Typography>
              <Typography
                variant="h5"
                style={{
                  textAlign: "left",
                  fontWeight: "bold",
                  display:
                    quotationDetails.city &&
                    quotationDetails.state !== undefined
                      ? ""
                      : "none",
                }}
              >
                {quotationDetails.city},{quotationDetails.state}
              </Typography>
              <Typography
                variant="h5"
                style={{
                  textAlign: "left",
                  fontWeight: "bold",
                  display: quotationDetails.country !== undefined ? "" : "none",
                }}
              >
                {quotationDetails.country}
              </Typography>
              <Typography
                variant="h5"
                style={{
                  textAlign: "left",
                  fontWeight: "bold",
                  display: quotationDetails.zipcode !== undefined ? "" : "none",
                }}
              >
                {quotationDetails.zipcode}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} style={{ margin: "3em" }}>
          <Row style={{ marginTop: "10%" }}>
            <div className="table-responsive">
              <Col>
                <table className="table table-bordered" style={{ border: "1" }}>
                  <thead>
                    <tr>
                      <th>Description</th>
                      <th>Units</th>
                      <th style={{ textAlign: "end" }}>Unit Price</th>
                      <th style={{ textAlign: "end" }}>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {rows.map((row, i) => (
                      <React.Fragment key={i}>
                        <tr>
                          <td>
                            {row.product_name}
                            <span style={{ fontSize: 12 }}>
                              {row.type === 3
                                ? `   Subscription - Requiring ${row.active_days} days. `
                                : ""}
                            </span>
                          </td>
                          <td>{row.quantity}</td>
                          <td style={{ textAlign: "end" }}>
                            {parseInt(row.price).toFixed(2)}
                          </td>
                          <td style={{ textAlign: "end" }}>
                            {parseInt(row.total).toFixed(2)}
                          </td>
                        </tr>

                        {/* showing bundle items */}
                        {row.bundle_items && row.bundle_items.length
                          ? row.bundle_items.map((_item, j) => (
                              <React.Fragment key={j}>
                                <tr className="bundle-rows-text">
                                  <td></td>
                                  <td>{_item.product_name}</td>
                                  <td style={{ textAlign: "end" }}>
                                    {_item.price}
                                  </td>
                                  <td></td>
                                </tr>
                              </React.Fragment>
                            ))
                          : ""}
                        {/* end of showing bundle items  */}
                      </React.Fragment>
                    ))}
                    <tr>
                      <td colSpan="3" style={{ textAlign: "end" }}>
                        SubTotal(Qr)
                      </td>
                      <td style={{ textAlign: "end" }}>{subTotal}</td>
                    </tr>
                    <tr>
                      <td colSpan="3" style={{ textAlign: "end" }}>
                        Discount(Qr)
                      </td>
                      <td style={{ textAlign: "end" }}>
                        {quotationDetails.discount}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="3" style={{ textAlign: "end" }}>
                        <b>Total(Qr)</b>
                      </td>
                      <td style={{ textAlign: "end" }}>
                        {quotationDetails.total_amount}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Col>
            </div>
          </Row>
          {/* <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="spanning table">
              <TableHead style={{ backgroundColor: "#fff", color: "#000" }}>
                <TableRow>
                  <TableCell align="center" style={{ color: "#000" }}>
                    Description
                  </TableCell>
                  <TableCell align="center" style={{ color: "#000" }}>
                    Quantity
                  </TableCell>
                  <TableCell align="right" style={{ color: "#000" }}>
                    Unit Price
                  </TableCell>
                  <TableCell align="right" style={{ color: "#000" }}>
                    Total Price
                  </TableCell> */}
          {/* <TableCell ></TableCell> */}
          {/* </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row,i) => (
                  <React.Fragment key={i}>
                    <TableRow key={row.product_name}>
                      <TableCell align="center">{row.product_name}</TableCell>
                      <TableCell align="center">{row.quantity}</TableCell>
                      <TableCell align="right">{row.price}</TableCell>
                      <TableCell align="right">{row.total}</TableCell>
                    </TableRow>
                    {row.bundle_items && row.bundle_items.length
                      ? row.bundle_items.map((_item, j) => (
                          <TableRow
                            style={{
                              width: "100%",
                              fontSize: "13px",
                              fontStyle: "italic",
                            }}
                          >
                            <TableCell></TableCell>
                            <TableCell align="center">
                              {_item.product_name}
                            </TableCell> */}
          {/* <TableCell></TableCell> */}
          {/* <TableCell align="right">{_item.price}</TableCell> */}
          {/* <TableCell></TableCell> */}
          {/* <TableCell></TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                        ))
                      : ""}
                  </React.Fragment>
                ))} */}

          {/* <TableRow>
                  <TableCell rowSpan={3} />
                  <TableCell colSpan={2}></TableCell> */}
          {/* <TableCell colSpan={2}>Subtotal</TableCell> */}
          {/* <TableCell align="right" style={{ fontWeight: "bold" }}>
                    Sub Total: {subTotal}
                  </TableCell>
                </TableRow> */}

          {/* <TableRow>
                  <TableCell></TableCell>
                  <TableCell align="right"></TableCell>
                  <TableCell align="right" style={{ fontWeight: "bold" }}>
                    Discount: {quotationDetails.discount}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell align="right"></TableCell>
                  <TableCell align="right" style={{ fontWeight: "bold" }}>
                    Total: {quotationDetails.total_amount}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer> */}
        </Grid>
        <Grid item xs={12} style={{ margin: "1em" }}>
          {/* <Typography>Warranty Terms :</Typography> */}
          <Typography style={{ fontWeight: "bold" }}>
            {" "}
            <div style={{ whiteSpace: "pre-wrap" }}>
              {" "}
              {quotationDetails.payment_terms}
            </div>
            {/* For term and conditions follow "Annexure A" document.{" "} */}
          </Typography>
        </Grid>
        <Grid item xs={12} style={{ margin: "1em" }}>
          <Typography style={{ fontWeight: "bold" }}>
            Cloud Telematics, Office No. C-35, First Floor, Building No.164, Al
            Maha Commercial Complex Street No. J-29, Energy Street, New
            Industrial Area, Zone-81, Doha, Ar Rayyan 4473 1516
          </Typography>
        </Grid>
      </Paper>
    </Grid>
  );
};
