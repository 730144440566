import React, { Component } from "react";
import Pagination from "react-js-pagination";
import { RiseLoader } from "react-spinners";
import { css } from "@emotion/core";
import { Route } from "react-router-dom";
import { Col, Row } from "reactstrap";
import {
  Typography,
  Fab,
  CircularProgress,
  FormControl,
  TextField,
  MenuItem,
  TableSortLabel,
  Tooltip,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  InputLabel,
  Select,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { SnackBar } from "../compon-controls/SnackBar";
import CmbSelect from "../compon-controls/CmbSelect";
import { AuthHelperAccessCheck } from "../businessjs/AuthHelper";

import { api } from "../businessjs/API";

function descendingComparator(a, b, orderBy) {
  if (orderBy !== "") {
    if (isNaN(parseFloat(a[orderBy]))) {
      if (b[orderBy].toLowerCase() < a[orderBy].toLowerCase()) {
        return -1;
      }
      if (b[orderBy].toLowerCase() > a[orderBy].toLowerCase()) {
        return 1;
      }
      return 0;
    } else {
      if (parseFloat(b[orderBy]) < parseFloat(a[orderBy])) {
        return -1;
      }
      if (parseFloat(b[orderBy]) > parseFloat(a[orderBy])) {
        return 1;
      }
      return 0;
    }
  }
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: "name", numeric: false, label: "Visit" },
  { id: "visit_date", numeric: false, label: "Date" },
  {
    id: "location_name",
    label: "Location",
    numeric: false,
    disablePadding: false,
  },
  { id: "status", label: "Status", numeric: false },
  { id: "edit", label: "" },
];

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  buttonProgress: {
    color: "#2196f3",
    position: "absolute",
    left: "55%",
    marginTop: "4.5em",
  },
}));

function EnhancedTableHead(props) {
  const classes = useStyles();
  const { order, orderBy, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            // align={headCell.numeric ? "right" : "center"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

class VisitList extends Component {
  state = {
    subscriber_id: 0,
    subscriberList: [],
    allVisit: [],
    loading: false,
    activePage: undefined,
    itemsCountPerPage: 25,
    totalItemsCount: 0,
    toastItems: [],
    page: 0,
    rowsPerPage: 50,
    order: "asc",
    orderBy: "",
    infoMessage: "",
    color: "#1b5e20",
  };

  componentDidMount = () => {
    this.getSubscriberForCmb();
  };

  getSubscriberForCmb = async () => {
    const subscribers = await api.post("sc-subscriber/get-all-for-cmb");
    if (subscribers && subscribers.userdata) {
      let subscriberArray = subscribers.userdata.map((opt) => ({
        label: opt.company_name,
        value: opt,
      }));
      if (subscriberArray !== undefined) {
        this.setState({
          subscriber_id: subscriberArray[0],
          subscriberList: subscriberArray,
        });
        this.getVisitList(subscriberArray[0]);
      }

      // this.getVisitCount();
    } else {
      AuthHelperAccessCheck(subscribers);
    }
  };

  getVisitCount = async () => {
    this.setState({ loading: true });
    const _visitCountInput = {
      subscriber_id: this.state.subscriber_id.value.autoid,
    };
    const res = await api.post("sc-visit/get-visit-count", _visitCountInput);

    if (res && res.length) {
      let _visitCount = res[0].visit_count;
      if (_visitCount > 0) {
        this.setState({ totalItemsCount: _visitCount, activePage: 1 });
        this.getVisitList(1);
      } else {
        this.setState({
          allVisit: [],
          totalItemsCount: 0,
          activePage: 1,
          loading: false,
        });
      }
    } else {
      this.setState({ loading: false });
      AuthHelperAccessCheck(res);
    }
  };

  getVisitList = async (subscriberId) => {
    this.setState({ loading: true });
    const _visitListInputs = {
      subscriber_id: subscriberId.value.autoid,
    };

    const res = await api.post("sc-visit/get-visit-list", _visitListInputs);
    if (res && res.length) {
      this.setState({ allVisit: res, loading: false });
    } else {
      this.setState({ loading: false, allVisit: [] });
      AuthHelperAccessCheck(res);
    }
  };

  handleChange = (panel) => (event, isExpanded) => {
    this.setState({ expanded: isExpanded ? panel : false });
  };

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: parseInt(event.target.value, 10), page: 0 });
  };

  visitAdd = () => {
    const VisitAddButton = () => (
      <Route
        render={({ history }) => (
          <Fab
            color="primary"
            variant="extended"
            size="small"
            onClick={() => {
              history.push({
                pathname: "/visit",
                state: { subscriber_id: this.state.subscriber_id },
              });
            }}
          >
            <AddIcon />
            Add
          </Fab>
        )}
      />
    );
    return <VisitAddButton />;
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    this.getVisitList(e.target.value);
  };

  cancelButton = (visit) => {
    const CancelButton = () => (
      <Route
        render={({ history }) => (
          <button
            type="button"
            className="btn btn-link btn-sm"
            title="Cancel"
            onClick={() => {
              this.cancelVisit(visit);
            }}
          >
            <i className="fa fa-times" aria-hidden="true" />
          </button>
        )}
      />
    );
    return <CancelButton />;
  };

  cancelVisit = async (visit) => {
    const _cancelVisitInputs = {
      visit_id: visit.autoid,
    };

    const res = await api.post("sc-visit/cancel-visit", _cancelVisitInputs);
    if (res) {
      // this.getVisitCount();
      // this.onToastItemAdd(res.status, res.message);
      this.setState({ infoMessage: res.message });
      this.getVisitList(this.state.subscriber_id);
    } else {
      AuthHelperAccessCheck(res);
      this.setState({ color: "#d50000" });
    }
  };

  handleRequestSort = (event, property) => {
    const isAsc = this.state.orderBy === property && this.state.order === "asc";
    this.setState({ order: isAsc ? "desc" : "asc", orderBy: property });
  };

  /**
   * to get badge based on status fetch from DB.
   */
  getBadge = (status) => {
    let _classes = "badge badge-";
    _classes += status === "Cancelled" ? "danger" : "primary";
    return _classes;
  };

  onCloseSnackbar = () => {
    this.setState({ infoMessage: "" });
  };

  render() {
    let _showVisit = this.state.allVisit;
    return (
      <React.Fragment>
        <Grid container>
          <SnackBar
            status={this.state.infoMessage}
            onCloseError={this.onCloseSnackbar}
            color={this.state.color}
          />
          <Grid item xs={12}>
            <Typography variant="h3" style={{ fontWeight: "bold" }}>
              Visits
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <CmbSelect
              name="subscriber_id"
              value={this.state.subscriber_id}
              onChange={this.onChange}
              dataSource={this.state.subscriberList}
            />
          </Grid>
          <Grid item xs={8} style={{ textAlign: "right" }}>
            {this.visitAdd()}
          </Grid>
          {/* </Grid>
          </Grid> */}

          <Grid item xs={12} style={{ marginTop: "1em", minHeight: "15em" }}>
            <Paper elevation={3} style={{ backgroundColor: "#fff" }}>
              {this.state.loading ? (
                this.state.loading && (
                  <Paper
                    elevation={3}
                    style={{
                      backgroundColor: "#fff",
                      minHeight: "10em",
                      align: "center",
                    }}
                  >
                    <CircularProgress
                      size={50}
                      // className={classes.buttonProgress}
                      style={{ marginLeft: "40%" }}
                    />
                  </Paper>
                )
              ) : (
                <div>
                  {" "}
                  <Table>
                    <EnhancedTableHead
                      order={this.state.order}
                      orderBy={this.state.orderBy}
                      onRequestSort={this.handleRequestSort}
                      rowCount={_showVisit.length}
                    />
                    {_showVisit.length !== 0 ? (
                      <TableBody>
                        {stableSort(
                          _showVisit,
                          getComparator(this.state.order, this.state.orderBy)
                        )
                          .slice(
                            this.state.page * this.state.rowsPerPage,
                            this.state.page * this.state.rowsPerPage +
                              this.state.rowsPerPage
                          )
                          .map((visit, i) => (
                            <TableRow key={i}>
                              <TableCell component="th" scope="row">
                                {visit.name}
                              </TableCell>

                              <TableCell>{visit.visit_date}</TableCell>
                              <TableCell>{visit.location_name}</TableCell>

                              {/* <TableCell>{visit.status}</TableCell> */}

                              <TableCell>
                                <span className={this.getBadge(visit.status)}>
                                  {visit.status}
                                </span>
                              </TableCell>
                              <TableCell>
                                {visit.statusValue != 0
                                  ? this.cancelButton(visit)
                                  : null}
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    ) : (
                      <TableBody>
                        <TableRow align="center">
                          <TableCell />
                          <TableCell />
                          <TableCell>
                            <Typography
                              style={{
                                textAlign: "center",
                                marginTop: "1em",
                                marginLeft: "-11em",
                              }}
                            >
                              No Records...............
                            </Typography>
                          </TableCell>
                          <TableCell />
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                  <TablePagination
                    rowsPerPageOptions={[50, 200, 500]}
                    component="div"
                    count={_showVisit.length}
                    rowsPerPage={this.state.rowsPerPage}
                    page={this.state.page}
                    onChangePage={this.handleChangePage}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                  />
                </div>
              )}
            </Paper>
          </Grid>
        </Grid>

        {/* <div className="container mt-3">
          <div className="card">
            <ToastAlert
              toastItems={this.state.toastItems}
              onItemClose={this.handleToastItemClose}
            />
            <div className="card-header">
              <Row style={{ alignItems: "center" }}>
                <Col xl={6} lg={4} md={12} sm={12} xs={12}>
                  <h3>Visits</h3>
                </Col>
                <Col xl={3} lg={4} md={7} sm={6} xs={12}>
                  <CmbSelect
                    name="subscriber_id"
                    value={this.state.subscriber_id}
                    onChange={this.onChange}
                    dataSource={this.state.subscriberList}
                  />
                </Col>
                <Col
                  xl={3}
                  lg={4}
                  md={5}
                  sm={6}
                  xs={12}
                  style={{ display: "flex" }}
                >
                  <button
                    type="button"
                    className="btn btn-primary btn-sm"
                    onClick={this.getVisitCount}
                    style={{ height: "fit-content" }}
                  >
                    Search
                  </button>
                  {this.visitAdd()}
                  <div className="sweet-loading">
                    <RiseLoader
                      css={loaderCSS}
                      sizeUnit={"px"}
                      size={10}
                      color={"#123abc"}
                      loading={this.state.loading}
                    />
                  </div>{" "}
                </Col>
              </Row>
            </div>
          </div>
          <Row className="mt-2">
            <Col>
              <div className="card">
                <div className="table-responsive">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th>Title</th>
                        <th>Date </th>
                        <th>Location</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {_showVisit.map((visit, i) => (
                        <React.Fragment key={i}>
                          <tr className="grid-rows-text">
                            <td>{visit.name}</td>
                            <td>
                              {AppHelper_DateAndTimeToString(visit.visit_date)}
                            </td>
                            <td>{visit.location_name}</td>
                            <td>{visit.status}</td>
                            {visit.statusValue != 0 ? (
                              <td>{this.cancelButton(visit)}</td>
                            ) : (
                              <td />
                            )}
                          </tr>
                        </React.Fragment>
                      ))}
                    </tbody>
                  </table>
                  <center>
                    {_showVisit.length ? "" : "Currently No Visits."}
                  </center>
                </div>
              </div>
            </Col>
          </Row>
        </div> */}
      </React.Fragment>
    );
  }
}

export default VisitList;
