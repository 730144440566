import React, { useState, FunctionComponent, useEffect } from "react";
import {
  Paper,
  Button,
  Grid,
  Typography,
  TextField,
  Link,
  Fab,
  RadioGroup,
  FormControlLabel,
  Radio,
  MenuItem,
  CircularProgress,
  makeStyles,
  FormControl,
  InputLabel,
  Select as MUISelect,
  Divider,
} from "@material-ui/core";
import { Formik, Form, ErrorMessage } from "formik";
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from "react-router-dom";
import { api } from "../../businessjs/API";
import { Select } from "./Select";
import { SnackBar } from "../Login/SnackBar";
import { Redirect } from "react-router-dom";
import * as yup from "yup";

const createValidationSchema = yup.object().shape({
  phone: yup.string().matches(/(^[+]?[0-9]*$)/, {
    message: "Invalid phone number format",
  }),
});
const useStyles = makeStyles((theme) => ({
  buttonProgress: {
    position: "absolute",
    left: "62.5%",
    marginTop: "0.5em",
  },
}));
export const AddAccount = (props) => {
  const classes = useStyles();
  // const [countryOptions, setCountryOptions] = useState([]);
  const [error, setError] = useState(false);
  const [color, setColor] = useState("#d50000");
  const [infoMessgae, setInfoMessage] = useState(false);
  const [navigation, setNavigation] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [accountCode, setAccountCode] = useState("");
  const [countries, setCountries] = useState(undefined);
  const [initialValues, setInitialValues] = useState({
    name: "",
    address: "",
    email: "",
    phone: "",
    contactName: "",
    city: "",
    state: "",
    location: "",
    building: "",
    country: "",
    zipcode: "",
    note: "",
    accountType: "0",
    companyId: localStorage.apiCompanyId,
  });
  const NavLink = React.forwardRef((props, ref) => (
    <RouterLink innerRef={ref} {...props} />
  ));
  // const options = [
  //   { value: "india", label: "India" },
  //   { value: "QR", label: "Qatar" },
  // ];

  useEffect(() => {
    async function getNextAutoIncrement() {
      try {
        const nextAutoIncrement = await api.post(
          "sc-account/get-account-next-auto-increment"
        );

        if (nextAutoIncrement && nextAutoIncrement.length) {
          let code = "C-";
          code += (nextAutoIncrement[0].max_id + 1).toString().padStart(3, "0");
          setAccountCode(code);
        }
      } catch (e) {
        console.log("error", e);
      }
    }
    getNextAutoIncrement();
  }, []);

  useEffect(() => {
    async function getCountry() {
      if (!countries) {
        try {
          const countryResult = await api.post("country/getAllCountries");
          if (countryResult.length !== 0) {
            let dbArray = countryResult.map((opt) => ({
              label: opt.name,
              value: opt,
            }));
            setCountries(dbArray);
            // loadAccounts();
          }
        } catch (error) {
          console.log("something went wrong!", error);
        }
      }
    }
    getCountry();
  }, [countries]);

  useEffect(() => {
    const id = props.match.params.id;

    async function loadAccounts() {
      try {
        // getCountry();
        if (id !== "new") {
          setIsLoading(true);
          const accountList = await api.post("sc-account/get-account-list");

          if (accountList && Array.isArray(accountList)) {
            const editUserDetails = accountList.find(
              (account) => account.autoid === parseInt(id)
            );
            setInitialValues({
              name: editUserDetails.name,
              address: editUserDetails.address,
              email: editUserDetails.email,
              phone: editUserDetails.phone,
              contactName: editUserDetails.contact_name,
              city: editUserDetails.city,
              state: editUserDetails.state,
              location: editUserDetails.location,
              building: editUserDetails.building,
              // country: editUserDetails.country,
              country: editUserDetails.country, //eg:"Qatar"
              zipcode: editUserDetails.zipcode,
              note: editUserDetails.note,
              accountType: editUserDetails.account_type.toString(),
              // companyId: editUserDetails.accountId,
            });
          }
          setIsLoading(false);
        } else {
        }
      } catch (e) {
        console.log(e);
      }
    }
    loadAccounts();
  }, [props.match.params.id]);

  const addNewAccount = async (values) => {
    setIsLoading(true);
    const createInput = {
      name: values.name,
      address: values.address,
      email: values.email,
      phone: values.phone,
      contactName: values.contactName,
      city: values.city,
      state: values.state,
      location: values.location,
      building: values.building,
      country: values.country,
      zipcode: values.zipcode,
      note: values.note,
      accountType: values.accountType,
      companyId: localStorage.apiCompanyId,
      accountCode: accountCode,
    };
    // console.log("createInput", createInput);
    const createResult = await api.post("sc-account/account-add", createInput);
    if (createResult.status === "success") {
      setInfoMessage(createResult.message);
      setColor("#4caf50");
      setTimeout(() => {
        setNavigation(true);
        setIsLoading(false);
      }, 2000);
    } else {
      setInfoMessage(createResult.message);
      setColor("#d50000");
      setNavigation(false);
      setIsLoading(false);
    }
    setNavigation(false);
  };
  const updateAccountdetails = async (values) => {
    if (values === initialValues) {
      setColor("#0091ea");
      setInfoMessage("Please change fields as required...");
    } else {
      setIsLoading(true);
      const updateDetails = {
        name: values.name,
        address: values.address,
        email: values.email,
        phone: values.phone,
        contactName: values.contactName,
        city: values.city,
        state: values.state,
        location: values.location,
        building: values.building,
        country: values.country,
        zipcode: values.zipcode,
        note: values.note,
        accountType: values.accountType,
        autoId: props.match.params.id,
      };

      const updateResult = await api.post(
        "sc-account/update-account",
        updateDetails
      );
      if (updateResult.status === "success") {
        setInfoMessage(updateResult.message);
        setColor("#4caf50");
        setTimeout(() => {
          setNavigation(true);
          setIsLoading(false);
        }, 2000);
      } else {
        setInfoMessage(updateResult.message);
        setColor("#d50000");
        setNavigation(false);
        setIsLoading(false);
      }
    }

    setNavigation(false);
  };
  const onCloseSnackbar = () => {
    setInfoMessage("");
  };
  if (navigation) {
    return <Redirect to="/account"></Redirect>;
  } else {
    return (
      <div>
        <SnackBar
          status={infoMessgae}
          onCloseError={onCloseSnackbar}
          color={color}
        />
        <Paper elevation={4} style={{ minHeight: "25em", minWidth: "50%" }}>
          <Formik
            enableReinitialize
            validationSchema={createValidationSchema}
            initialValues={initialValues}
            onSubmit={async (values) => {
              if (props.match.params.id !== "new") {
                updateAccountdetails(values);
              } else {
                addNewAccount(values);
              }
            }}
          >
            {({
              values,
              handleChange,
              setFieldValue,
              resetForm,
              touched,
              errors,
            }) => (
              <Form>
                <Grid container spacing={3} style={{ padding: "2em" }}>
                  <Grid item xs={12}>
                    <Typography variant="h4" style={{ fontWeight: "bold" }}>
                      {props.match.params.id === "new"
                        ? "Add new account"
                        : "Edit Account"}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      required
                      fullWidth
                      variant="outlined"
                      name="name"
                      label="Name"
                      value={values.name}
                      onChange={handleChange}
                      inputProps={{ maxLength: 50 }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      fullWidth
                      required
                      variant="outlined"
                      name="address"
                      label="Addess"
                      multiline
                      value={values.address}
                      onChange={handleChange}
                      inputProps={{ maxLength: 50 }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel id="country-label">Country</InputLabel>
                      <MUISelect
                        labelId="country-label"
                        value={values.country.toLowerCase()}
                        onChange={handleChange("country")}
                        label="Country"
                      >
                        {(countries || []).map((country) => (
                          <MenuItem
                            value={country.value.name.toLowerCase()}
                            key={country.value.name.toLowerCase()}
                          >
                            {country.label}
                          </MenuItem>
                        ))}
                      </MUISelect>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <TextField
                      fullWidth
                      // required
                      variant="outlined"
                      name="state"
                      label="State"
                      value={values.state}
                      onChange={handleChange}
                      inputProps={{ maxLength: 50 }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      fullWidth
                      // required
                      variant="outlined"
                      name="city"
                      label="City"
                      value={values.city}
                      onChange={handleChange}
                      inputProps={{ maxLength: 50 }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      fullWidth
                      // required
                      variant="outlined"
                      name="location"
                      label="Location"
                      value={values.location}
                      onChange={handleChange}
                      inputProps={{ maxLength: 50 }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      fullWidth
                      // required
                      variant="outlined"
                      name="building"
                      label="Building"
                      value={values.building}
                      onChange={handleChange}
                      inputProps={{ maxLength: 50 }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <TextField
                      fullWidth
                      // required
                      variant="outlined"
                      name="zipcode"
                      label="Zip"
                      value={values.zipcode}
                      onChange={handleChange}
                      inputProps={{ maxLength: 50 }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      multiline={true}
                      name="note"
                      label="Note"
                      value={values.note}
                      onChange={handleChange}
                      inputProps={{ maxLength: 50 }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} style={{ marginTop: 20 }}>
                    <Typography variant="h4">Contact</Typography>
                    {/* <Divider
                      style={{ backgroundColor: "#bbb8b8", marginTop: 5 }}
                    /> */}
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      fullWidth
                      required
                      variant="outlined"
                      name="contactName"
                      label="Contact Name"
                      value={values.contactName}
                      onChange={handleChange}
                      inputProps={{ maxLength: 50 }}
                      disabled={props.match.params.id !== "new" ? true : false}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      fullWidth
                      required
                      variant="outlined"
                      name="email"
                      label="Email"
                      type="email"
                      value={values.email}
                      onChange={handleChange}
                      inputProps={{ maxLength: 50 }}
                      disabled={props.match.params.id !== "new" ? true : false}
                    />
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <TextField
                      fullWidth
                      // required
                      error={Boolean(touched.phone && errors.phone)}
                      helperText={touched.phone && errors.phone}
                      variant="outlined"
                      name="phone"
                      label="Phone Number"
                      value={values.phone}
                      onChange={handleChange}
                      inputProps={{ maxLength: 50 }}
                      disabled={props.match.params.id !== "new" ? true : false}
                    />
                  </Grid>

                  <Grid item xs={12} sm={8}>
                    <RadioGroup
                      aria-label="position"
                      row
                      name="accountType"
                      onChange={handleChange}
                      value={values.accountType}
                    >
                      <FormControlLabel
                        value="0"
                        control={<Radio color="primary" />}
                        label="Client"
                      />
                      <FormControlLabel
                        value="1"
                        control={<Radio color="primary" />}
                        label="Vendor"
                      />
                      <FormControlLabel
                        value="2"
                        control={<Radio color="primary" />}
                        label="Both"
                      />
                    </RadioGroup>
                  </Grid>
                  {/* <Grid item xs={6}></Grid> */}
                  <Grid item xs={6} style={{ textAlign: "right" }}>
                    <Fab
                      variant="extended"
                      size="medium"
                      color="primary"
                      type="reset"
                      component={NavLink}
                      to="/account"
                    >
                      Cancel
                    </Fab>
                  </Grid>
                  <Grid item xs={6} style={{ textAlign: "left" }}>
                    <Fab
                      variant="extended"
                      size="medium"
                      color="primary"
                      aria-label="add"
                      type="submit"
                      disabled={isLoading}
                    >
                      {props.match.params.id === "new" ? "Submit" : "Update"}
                    </Fab>
                    {isLoading && (
                      <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                      />
                    )}
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Paper>
      </div>
    );
  }
};
