import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  Card,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Paper,
  TableContainer,
  Table,
  TableBody,
  TablePagination,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  CircularProgress,
  DialogActions,
  DialogContentText,
  Dialog,
  DialogTitle,
  DialogContent,
  Fab,
  IconButton,
  Tooltip,
} from "@material-ui/core";
// import AddIcon from "@material-ui/icons/Add";
import { makeStyles } from "@material-ui/core/styles";
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
  Redirect,
} from "react-router-dom";
import moment from "moment";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 650,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  billTableRow: {
    paddingRight: "3em",
  },
}));

const headCells = [
  { id: "autoid", label: "Purchase No.", numeric: true },
  { id: "po_id", label: "PO No.", numeric: true },
  {
    id: "account_name",
    numeric: false,

    label: "Account",
  },
  { id: "purchase_date", label: "Date", numeric: false },
  { id: "ship_to", label: "Ship To", numeric: false },
  { id: "discount", label: "Discount", numeric: true },
  { id: "total_amount", label: "Order Total", numeric: true },
  { id: "status_type", label: "Status", numeric: false },
  { id: "view", label: "", numeric: false },
  { id: "edit", label: "", numeric: false },
  // { id: "delete", label: "", numeric: false },
];

const EnhancedTableHead = (props) => {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={
              headCell.id === "discount" || headCell.id === "total_amount"
                ? "right"
                : "center"
            }
            // padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

function descendingComparator(a, b, orderBy) {
  if (orderBy !== "") {
    // console.log("date", isNaN(a[orderBy]));
    if (isNaN(parseFloat(a[orderBy]))) {
      // console.log("date", a[orderBy]);
      if (b[orderBy].toLowerCase() < a[orderBy].toLowerCase()) {
        return -1;
      }
      if (b[orderBy].toLowerCase() > a[orderBy].toLowerCase()) {
        return 1;
      }
      return 0;
    } else {
      // console.log("in else");
      if (parseFloat(b[orderBy]) < parseFloat(a[orderBy])) {
        return -1;
      }
      if (parseFloat(b[orderBy]) > parseFloat(a[orderBy])) {
        return 1;
      }
      return 0;
    }
  }
}

const getComparator = (order, orderBy) => {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

export const PurchaseBillList = (props) => {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [openDeleteConfiramtion, setOpenDeleteConfirmation] = useState(false);
  const [id, setId] = useState("");
  const classes = useStyles();
  const { rows } = props;

  const NavLink = React.forwardRef((props, ref) => (
    <RouterLink innerRef={ref} {...props} />
  ));

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  /**
   * to get badge based on status fetch from DB.
   */
  const getBadge = (status) => {
    let _classes = "badge badge-";
    _classes += status === "Cancelled" ? "danger" : "success";
    return _classes;
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            {rows.length !== 0 ? (
              <TableBody>
                {stableSort(rows, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    return (
                      <TableRow hover tabIndex={-1} key={row.autoid}>
                        <TableCell
                          align="center"
                          className={classes.billTableRow}
                        >
                          {row.autoid}
                        </TableCell>
                        <TableCell
                          align="center"
                          className={classes.billTableRow}
                        >
                          {row.po_id ? row.po_id : "-"}
                        </TableCell>
                        <TableCell
                          align="center"
                          className={classes.billTableRow}
                        >
                          {row.account_name}
                        </TableCell>
                        <TableCell>
                          {moment(row.purchase_date).format("MMMM Do YYYY")}
                        </TableCell>
                        <TableCell
                          align="center"
                          className={classes.billTableRow}
                        >
                          {row.ship_to}
                        </TableCell>
                        <TableCell align="right">{row.discount}</TableCell>
                        <TableCell align="right">{row.total_amount}</TableCell>
                        <TableCell>
                          {/* {row.status_type} */}
                          <span className={getBadge(row.status_type)}>
                            {row.status_type}
                          </span>
                        </TableCell>
                        <TableCell style={{ width: "5%" }}>
                          <Typography
                            component={NavLink}
                            to={`/purchase-bill-view/${row.autoid}`}
                          >
                            View
                          </Typography>
                        </TableCell>
                        <TableCell style={{ width: "5%" }}>
                          <IconButton
                            style={{ display: row.status === 0 ? "none" : "" }}
                            component={NavLink}
                            to={`/purchase-bill/${row.autoid}`}
                          >
                            <Tooltip title="Edit">
                              <EditIcon color="primary" />
                            </Tooltip>
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            ) : (
              <TableRow align="center">
                <TableCell />
                <TableCell />
                <TableCell />
                <TableCell />
                <TableCell />
                <TableCell>
                  <Typography
                    style={{
                      textAlign: "center",
                      marginTop: "1em",
                      marginLeft: "-11em",
                    }}
                  >
                    No Records...............
                  </Typography>
                </TableCell>
                <TableCell />
                <TableCell />
                <TableCell />
                <TableCell />
              </TableRow>
            )}
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[50, 200, 500]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
};
