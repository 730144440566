import React, { useEffect } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { Box, Drawer, Hidden, List } from "@material-ui/core";
import {
  BarChart as BarChartIcon,
  ShoppingBag as ShoppingBagIcon,
  UserPlus as UserPlusIcon,
  Users as UsersIcon,
  Truck as TruckIcon,
  RefreshCw as ServiceIcon,
  Mail as MailIcon,
  Phone as PhoneIcon,
  ShoppingCart as PurchaseIcon,
  Award as TicketIcon,
  ChevronsRight,
  Trello as PurchaseBillIcon,
  Tag as QuotationIcon,
  Star as AdminIcon,
  Command as ProductsIcon,
  CreditCard as JobCardIcon,
  Layout as ProcessJobCardIcon,
  FileText as InvoiceIcon,
  MoreHorizontal as MoreIcon,
} from "react-feather";
import NavItem from "./NavItem";
import { useNavStyles } from "./useNavStyles";
const items = [
  {
    href: "/",
    icon: BarChartIcon,
    title: "Dashboard",
    subMenuItems: null,
  },
  {
    href: "/account",
    icon: MailIcon,
    title: "Accounts",
    subMenuItems: null,
  },

  {
    href: "/contact",
    icon: PhoneIcon,
    title: "Contacts",
    subMenuItems: null,
  },
  {
    href: "/quotation",
    icon: QuotationIcon,
    title: "Quotation",
    subMenuItems: null,
  },
  {
    href: "",
    icon: TruckIcon,
    title: "Vehicles",
    subMenuItems: [
      {
        href: "/subscriptionwithvehicle",
        icon: ChevronsRight,
        title: "SubscriptionWithVehicle",
        subMenuItems: null,
      },
      {
        href: "/changed-device",
        icon: ChevronsRight,
        title: "Changed Device",
        subMenuItems: null,
      },
      {
        href: "/vehicle-list",
        icon: ChevronsRight,
        title: "Vehicles",
        subMenuItems: null,
      },
    ],
  },
  {
    href: "",
    icon: PurchaseIcon,
    title: "Purchase",
    subMenuItems: [
      {
        href: "/purchase-order",
        icon: PurchaseIcon,
        title: "Purchase Orders",
        subMenuItems: null,
      },
      {
        href: "/purchase-bill",
        icon: PurchaseBillIcon,
        title: "Purchase Bill",
        subMenuItems: null,
      },
      {
        href: "/stocks",
        icon: UsersIcon,
        title: "Stock Details",
        subMenuItems: null,
      },
    ],
  },
  {
    href: "/invoice-list",
    icon: InvoiceIcon,
    title: "Invoice",
    subMenuItems: null,
  },
  {
    href: "/job-card",
    icon: JobCardIcon,
    title: "Job Card",
    subMenuItems: [
      {
        href: "/job-card",
        icon: JobCardIcon,
        title: "Job Card",
        subMenuItems: null,
      },
      {
        href: "/jobcard-process",
        icon: ProcessJobCardIcon,
        title: "Process Job Card",
        subMenuItems: null,
      },
    ],
  },

  {
    href: "/ticket-list",
    icon: TicketIcon,
    title: "Tickets",
    subMenuItems: null,
  },
  {
    href: "",
    icon: ShoppingBagIcon,
    title: "Products",
    subMenuItems: [
      // {
      //   href: "/products-home",
      //   icon: ShoppingBagIcon,
      //   title: "Products List",
      //   subMenuItems: null,
      // },
      {
        href: "/product-list",
        icon: ShoppingBagIcon,
        title: "Products List",
        subMenuItems: null,
      },
      {
        href: "/product-stocks",
        icon: ProductsIcon,
        title: "Client Product",
        subMenuItems: null,
      },
      {
        href: "/product-bundle-list",
        icon: ShoppingBagIcon,
        title: "Product Bundles",
        subMenuItems: null,
      },
      {
        href: "/payments-list",
        icon: ShoppingBagIcon,
        title: "Payments",
        subMenuItems: null,
      },
      {
        href: "/stocks",
        icon: UsersIcon,
        title: "Stock Details",
        subMenuItems: null,
      },
    ],
  },
  {
    href: "",
    icon: ServiceIcon,
    title: "Services",
    subMenuItems: [
      {
        href: "/subscriber-locations",
        icon: ServiceIcon,
        title: "Service Locations",
        subMenuItems: null,
      },
      {
        href: "/service-schedule-list",
        icon: ServiceIcon,
        title: "Service Schedules",
        subMenuItems: null,
      },
      {
        href: "/visit-list",
        icon: ServiceIcon,
        title: "Visits",
        subMenuItems: null,
      },
    ],
  },
  {
    href: "",
    icon: AdminIcon,
    title: "Admin",
    subMenuItems: [
      {
        href: "/supportcenter/subscriberlist",
        icon: UsersIcon,
        title: "Subscribers",
        subMenuItems: null,
      },
      {
        href: "/subscription-list",
        icon: UserPlusIcon,
        title: "Subscriptions",
        subMenuItems: null,
      },
      {
        href: "/supportcenter/member",
        icon: UsersIcon,
        title: "Members",
        subMenuItems: null,
      },

      {
        href: "/executive",
        icon: UsersIcon,
        title: "Executives",
        subMenuItems: null,
      },
    ],
  },
];

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useNavStyles();
  const location = useLocation();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      {/* <Box component="span" p={2}>
        <Typography className={classes.supportCenterHeading}>Support Center Admin Panel</Typography>
      </Box> */}
      {/* <Divider style={{marginTop:"0.7em"}}/> */}
      <Box p={2}>
        <List>
          {items.map((item, i) => (
            <NavItem
              href={item.href}
              key={i}
              title={item.title}
              icon={item.icon}
              subMenu={item.subMenuItems}
            />
          ))}
        </List>
      </Box>
      <Box flexGrow={1} />
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

NavBar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false,
};

export default NavBar;
