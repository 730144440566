import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import ControlledRoute from "./ControlledRoute";
import AppView from "./AppViews";
import SupportCenterLogin from "../compon/Login/SupportCenterLogin";

const AppRoute = () => {
  return (
    <BrowserRouter>
      <Switch>
        {/* <ControlledRoute
          path="/login"
          component={SupportCenterLogin}
          fallback="/supportcenter"
        /> */}
        <Route path="/login" component={SupportCenterLogin} />
        {/* <Route exact path="/" component={SupportCenterLogin} /> */}
        {/* <ControlledRoute
          path="/"
          component={SupportCenterLogin}
          // fallback="/supportcenter"
        /> */}
        <PrivateRoute
          path="/"
          component={AppView}
          fallback="/login"
        />
        {/* <AppView /> */}
      </Switch>
    </BrowserRouter>
  );
};

export default AppRoute;
