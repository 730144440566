import React, { Component } from "react";
import Select from "react-select";

class VehicleTypeSelect extends Component {
  constructor(props) {
    super(props);

    this.state = { selectedOption: null, optionsArray: [] };
  }

  handleChange = selectedOption => {
    this.setState({ selectedOption });

    let target = {};
    target.value = selectedOption;
    target.name = this.props.name;

    let event = {};
    event.target = target;
    this.props.onChange(event);
  };

  render() {
    return (
      <Select
        name={this.props.name}
        value={this.props.value}
        onChange={this.handleChange}
        options={this.props.dataSource}
      />
    );
  }
}

export default VehicleTypeSelect;
