import React, { useState, FunctionComponent, useEffect } from "react";
import {
  Paper,
  Button,
  Grid,
  Typography,
  TextField,
  makeStyles,
  Fab,
} from "@material-ui/core";
import { api } from "../../businessjs/API";
import { Formik, Form, ErrorMessage } from "formik";
import { SnackBar } from "../Login/SnackBar";
import {Redirect} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

export const ChangedDevice = () => {
  const classes = useStyles();
  const [infoMessage, setInfoMessage] = useState("");
  const [color, setColor] = useState("#d50000");
  const [navigate, setNavigate] = useState(false);
  const onCloseSnackbar = () => {
    setInfoMessage("");
  };
  if (navigate) {
    return <Redirect to="/"></Redirect>;
  } else {
    return (
      <div className={classes.root}>
        <SnackBar
          status={infoMessage}
          onCloseError={onCloseSnackbar}
          color={color}
        />
        <Paper elevation={4} style={{ minHeight: "25em", minWidth: "50%" }}>
          <Formik
            enableReinitialize
            initialValues={{ oldDeviceId: "", newDeviceId: "" }}
            onSubmit={async (values) => {
              const changeDeviceIdResult = await api.post(
                "sc-vehicle/change-vehicle-device-id",
                values
              );
              if (changeDeviceIdResult.message === "Record not available") {
                setColor("#d50000");
                setInfoMessage(changeDeviceIdResult.message);
                setNavigate(false);
              }
              if (changeDeviceIdResult.message === "Success: record changed") {
                setColor("#4caf50");
                setInfoMessage(
                  `Success:  ${changeDeviceIdResult.oldId} record changed`
                );
                setTimeout(() => {
                  setNavigate(true);
                }, 2000);
              }
            }}
          >
            {({ values, handleChange }) => (
              <Form>
                <Grid container spacing={3} style={{ padding: "2em" }}>
                  <Grid item xs={12}>
                    <Typography variant="h3" style={{ fontWeight: "bold" }}>
                      Change Device
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid
                      container
                      spacing={3}
                      direction="column"
                      alignItems="center"
                      justify="center"
                    >
                      <Grid item xs={12} sm={6} style={{ width: "43em" }}>
                        <TextField
                          required
                          fullWidth
                          variant="outlined"
                          name="oldDeviceId"
                          label="Please Enter Old Device ID"
                          value={values.oldDeviceId}
                          onChange={handleChange}
                          inputProps={{ maxLength: 50 }}
                        />
                      </Grid>

                      <Grid item xs={12} sm={6} style={{ width: "43em" }}>
                        <TextField
                          required
                          fullWidth
                          variant="outlined"
                          name="newDeviceId"
                          label="Please Enter New Device ID"
                          value={values.newDeviceId}
                          onChange={handleChange}
                          inputProps={{ maxLength: 50 }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  {/* <Grid item xs={6}></Grid> */}
                  <Grid item xs={6} style={{ textAlign: "right" }}>
                    <Fab
                      variant="extended"
                      size="medium"
                      color="primary"
                      type="reset"
                    >
                      Cancel
                    </Fab>
                  </Grid>
                  <Grid item xs={6} style={{ textAlign: "left" }}>
                    <Fab
                      variant="extended"
                      size="medium"
                      color="primary"
                      aria-label="add"
                      type="submit"
                    >
                      Update
                    </Fab>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Paper>
      </div>
    );
  }
};
