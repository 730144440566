import React, { useState, useEffect } from "react";
import {
  Paper,
  Button,
  Grid,
  Typography,
  TextField,
  Link,
  FormControlLabel,
  Divider,
} from "@material-ui/core";
import * as yup from "yup";
import { makeStyles } from "@material-ui/core/styles";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

import Select from "../../compon-controls/CmbSelect";
import { api } from "../../businessjs/API";

// import {Select} from "../../compon-controls/Select";

const createValidationSchema = yup.object().shape({
  phone: yup.string().matches(/(^[+]?[0-9]*$)/, {
    message: "Invalid phone number format",
  }),
});

const useStyles = makeStyles((theme) => ({
  resize: {
    "&::placeholder": {
      fontSize: "0.813rem",
    },
  },
}));
export const QuotationGeneral = (props) => {
  const classes = useStyles();
  const { formValues } = props;
  // console.log("accountList", formValues.accountId);
  // console.log("productItems", formValues.productItems);

  const getAccountDetails = async (account) => {
    props.changeFormValues((prev) => ({
      ...prev,
      loading: true,
    }));
    const input = {
      autoId: account.value.autoid,
    };
    try {
      const accountDetails = await api.post(
        "sc-account/get-account-by-id",
        input
      );
      // console.log("accountDetails", accountDetails[0]);
      if (accountDetails && accountDetails.length) {
        props.changeFormValues((prev) => ({
          ...prev,
          address: accountDetails[0].address,
          location: accountDetails[0].location,
          building: accountDetails[0].building,
          city: accountDetails[0].city,
          state: accountDetails[0].state,
          country: accountDetails[0].country,
          phone: accountDetails[0].phone,
          zipCode: accountDetails[0].zipcode,
          loading: false,
        }));
      } else {
        props.changeFormValues((prev) => ({
          ...prev,
          loading: false,
        }));
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getOwnContacts = (accountId) => {
    // console.log("ownContacts id", accountId.value.autoid);
    const ownContacts =
      formValues.allContact &&
      formValues.allContact.filter(
        (contact) => contact.value.account_id === accountId.value.autoid
      );
    // console.log("ownContacts",formValues.allContact, ownContacts);
    props.changeFormValues((prev) => ({
      ...prev,
      contactList: ownContacts,
      contactId: ownContacts && ownContacts[0],
    }));
  };

  return (
    <Grid>
      <Grid item xs={12}>
        <Typography style={{ padding: 15 }}>General</Typography>
        <Divider style={{ backgroundColor: "#cdc9c9" }} />
      </Grid>
      <Grid container spacing={3} style={{ padding: "2%" }}>
        <Grid item xs={12} sm={4}>
          <Typography style={{ fontSize: 14, fontWeight: "normal" }}>
            Account
          </Typography>
          <Select
            name="accountId"
            value={formValues.accountId}
            onChange={(e) => {
              props.changeFormValues((prev) => ({
                ...prev,
                accountId: e.target.value,
              }));
              getAccountDetails(e.target.value);
              getOwnContacts(e.target.value);
            }}
            dataSource={formValues.accountList}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography style={{ fontSize: 14, fontWeight: "normal" }}>
            Contact Person
          </Typography>
          <Select
            name="contactId"
            value={formValues.contactId}
            onChange={(e) => {
              props.changeFormValues((prev) => ({
                ...prev,
                contactId: e.target.value,
              }));
            }}
            dataSource={formValues.contactList}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography style={{ fontSize: 14, fontWeight: "normal" }}>
            Address
          </Typography>
          {/* <div className="text_container"> */}
          <TextField
            variant="outlined"
            name="address"
            placeholder="Address"
            fullWidth
            InputProps={{
              classes: {
                input: classes.resize,
              },
            }}
            required
            value={formValues.address}
            onChange={(e) => {
              e.persist();
              props.changeFormValues((prev) => ({
                ...prev,
                address: e.target.value,
              }));
            }}
          />
          {/* </div> */}
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography style={{ fontSize: 14, fontWeight: "normal" }}>
            Location
          </Typography>

          <TextField
            variant="outlined"
            name="location"
            placeholder="Location"
            fullWidth
            InputProps={{
              classes: {
                input: classes.resize,
              },
            }}
            required
            value={formValues.location}
            onChange={(e) => {
              e.persist();
              props.changeFormValues((prev) => ({
                ...prev,
                location: e.target.value,
              }));
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography style={{ fontSize: 14, fontWeight: "normal" }}>
            Building
          </Typography>

          <TextField
            variant="outlined"
            name="building"
            placeholder="Building"
            fullWidth
            InputProps={{
              classes: {
                input: classes.resize,
              },
            }}
            required
            value={formValues.building}
            onChange={(e) => {
              e.persist();
              props.changeFormValues((prev) => ({
                ...prev,
                building: e.target.value,
              }));
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography style={{ fontSize: 14, fontWeight: "normal" }}>
            City
          </Typography>

          <TextField
            variant="outlined"
            name="city"
            placeholder="City"
            fullWidth
            InputProps={{
              classes: {
                input: classes.resize,
              },
            }}
            required
            value={formValues.city}
            onChange={(e) => {
              e.persist();
              props.changeFormValues((prev) => ({
                ...prev,
                city: e.target.value,
              }));
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography style={{ fontSize: 14, fontWeight: "normal" }}>
            State
          </Typography>

          <TextField
            variant="outlined"
            name="state"
            placeholder="State"
            fullWidth
            InputProps={{
              classes: {
                input: classes.resize,
              },
            }}
            required
            value={formValues.state}
            onChange={(e) => {
              e.persist();
              props.changeFormValues((prev) => ({
                ...prev,
                state: e.target.value,
              }));
            }}
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <Typography style={{ fontSize: 14, fontWeight: "normal" }}>
            Country
          </Typography>

          <TextField
            variant="outlined"
            name="country"
            placeholder="Country"
            fullWidth
            InputProps={{
              classes: {
                input: classes.resize,
              },
            }}
            required
            value={formValues.country}
            onChange={(e) => {
              e.persist();
              props.changeFormValues((prev) => ({
                ...prev,
                country: e.target.value,
              }));
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography style={{ fontSize: 14, fontWeight: "normal" }}>
            Sales Rep. Name
          </Typography>
          <Select
            name="supportMemberId"
            value={formValues.supportMemberId}
            onChange={(e) => {
              props.changeFormValues((prev) => ({
                ...prev,
                supportMemberId: e.target.value,
              }));
            }}
            dataSource={formValues.supportMemberList}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography style={{ fontSize: 14, fontWeight: "normal" }}>
            Phone
          </Typography>

          <TextField
            variant="outlined"
            name="phone"
            placeholder="Phone"
            fullWidth
            InputProps={{
              classes: {
                input: classes.resize,
              },
            }}
            required
            value={formValues.phone}
            onChange={(e) => {
              e.persist();
              props.changeFormValues((prev) => ({
                ...prev,
                phone: e.target.value,
              }));
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography style={{ fontSize: 14, fontWeight: "normal" }}>
            Zip Code
          </Typography>
          <TextField
            variant="outlined"
            name="zipCode"
            placeholder="Zip Code"
            fullWidth
            InputProps={{
              classes: {
                input: classes.resize,
              },
            }}
            required
            value={formValues.zipCode}
            onChange={(e) => {
              e.persist();
              props.changeFormValues((prev) => ({
                ...prev,
                zipCode: e.target.value,
              }));
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography style={{ fontSize: 14, fontWeight: "normal" }}>
            Status
          </Typography>
          <Select
            name="status"
            value={formValues.status}
            // defaultValue={formValues.statusOptions[0]}
            onChange={(e) => {
              if (e.target.value.value === 0) {
                // console.log("inside if",e.target.value.value);
                props.changeFormValues((prev) => ({
                  ...prev,
                  status: e.target.value,
                  disableAdd: true,
                }));
              } else {
                // console.log("inside else",e.target.value.value);
                props.changeFormValues((prev) => ({
                  ...prev,
                  status: e.target.value,
                  disableAdd: false,
                }));
              }
            }}
            dataSource={formValues.statusOptions}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              disableToolbar
              variant="outlined"
              format="MM/dd/yyyy"
              id="date-picker-inline"
              label="Quote Date"
              value={formValues.quoteDate}
              //   onChange={props.setQuoteDate}
              onChange={(e) => {
                props.changeFormValues((prev) => ({
                  ...prev,
                  quoteDate: e,
                }));
              }}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              autoOk={true}
            />
          </MuiPickersUtilsProvider>
        </Grid>
      </Grid>
    </Grid>
  );
};
