import React, { Component } from "react";
import { Col, Row } from "reactstrap";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

import SubscriberEdit from "../compon-controls/SubscriberEdit";
import Vehicle from "../compon-controls/Vehicles";
import Products from "../compon-controls/Products";
import Ticket from "../compon-controls/Ticket";
import Invoices from "../compon-controls/Invoices";
import SubscriberMoreInfo from "../compon-controls/SubscriberMoreInfo";
import AssignAccount from "../compon-controls/AssignAccount";
import { AuthHelperAccessCheck } from "../businessjs/AuthHelper";
import { api } from "../businessjs/API";

class AccountDetails extends Component {
  state = {
    tabIndex: 0,
    accountid: this.props.location.state.companyId,
    account_name: this.props.location.state.companyName,
    status: this.props.location.state.statusCode,
    showDelete: false,
    isOnDeleteMode: false,
  };

  updateStatus = async (statusType, companyId) => {
    const _updateStatusInputs = {
      company_id: companyId,
      status_type: statusType,
    };
    const statusResult = await api.post(
      "sc-subscriber/update-subscriber-status",
      _updateStatusInputs
    );
    if (statusResult.status === "success") {
      if (statusType === 1) {
        this.setState({ status: 1 });
        this.props.history.push(`/supportcenter/subscriberlist`);
      } else if (statusType === 2) {
        this.setState({ status: 2 });
        this.props.history.push(`/supportcenter/subscriberlist`);
      } else if (statusType === -1) {
        this.setState({ showDelete: true, isOnDeleteMode: false });
        this.props.history.push(`/supportcenter/subscriberlist`);
      }
    } else {
      AuthHelperAccessCheck(statusResult);
    }
  };

  render() {
    return (
      <React.Fragment>
        <Row
          style={{
            minHeight: "100vh",
          }}
        >
          <Col xl={12} lg={12} md={12} sm={12} xs={12}>
            <div className="container mt-4">
              <div className="card">
                <div className="card-header">
                  <Row>
                    <Col xl={10}>
                      <h4>Account Details - {this.state.account_name} </h4>
                    </Col>
                    <Col>
                      {this.state.isOnDeleteMode ? (
                        <div className="btn-group float-right">
                          <button
                            className="btn btn-warning btn-sm"
                            onClick={() =>
                              this.setState({ isOnDeleteMode: false })
                            }
                          >
                            Cancel
                          </button>
                          <button
                            className="btn btn-danger btn-sm ml-1"
                            onClick={() =>
                              this.updateStatus(
                                -1,
                                this.props.location.state.companyId
                              )
                            }
                          >
                            Confirm
                          </button>
                        </div>
                      ) : (
                        <div className="btn-group float-right">
                          <button
                            onClick={() =>
                              this.state.status === 1
                                ? this.updateStatus(
                                    2,
                                    this.props.location.state.companyId
                                  )
                                : this.updateStatus(
                                    1,
                                    this.props.location.state.companyId
                                  )
                            }
                            className="btn btn-primary btn-sm"
                          >
                            {this.state.status === 1 ? "Suspend" : "Activate"}
                          </button>

                          <button
                            onClick={() =>
                              this.setState({ isOnDeleteMode: true })
                            }
                            className="btn btn-primary btn-sm ml-1"
                            disabled={this.state.showDelete}
                          >
                            Delete
                          </button>
                        </div>
                      )}
                    </Col>
                  </Row>
                </div>
                <Row>
                  <Col>
                    <Tabs
                      style={{ width: "100%" }}
                      selectedIndex={this.state.tabIndex}
                      onSelect={(tabIndex) => this.setState({ tabIndex })}
                    >
                      <TabList>
                        <Tab>Personal Info</Tab>
                        <Tab>More Info</Tab>
                        <Tab>Vehicles</Tab>
                        <Tab>Products</Tab>
                        <Tab>Tickets</Tab>
                        <Tab>Invoices</Tab>
                        <Tab>Assign Account</Tab>
                      </TabList>
                      <TabPanel>
                        <SubscriberEdit accountid={this.state.accountid} />
                      </TabPanel>
                      <TabPanel>
                        <SubscriberMoreInfo accountid={this.state.accountid} />
                      </TabPanel>
                      <TabPanel>
                        <Vehicle accountid={this.state.accountid}></Vehicle>
                      </TabPanel>

                      <TabPanel>
                        <Products accountid={this.state.accountid}></Products>
                      </TabPanel>
                      <TabPanel>
                        <Ticket accountid={this.state.accountid} />
                      </TabPanel>
                      <TabPanel>
                        <Invoices accountid={this.state.accountid} />
                      </TabPanel>
                      <TabPanel>
                        <AssignAccount accountid={this.state.accountid} />
                      </TabPanel>
                    </Tabs>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default AccountDetails;
