import React from "react";
import { Route } from "react-router-dom";

import ToastAlert from "./ToastAlert";
import Navbar from "../compon-styled/SupportCenterNavBar";
import SubscriberSelect from "../compon-controls/SubscriberSelect";
import VehicleTypeSelect from "../compon-controls/VehicleTypeSelect";
import { api } from "../businessjs/API";

class VehicleAdd extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      subscriber_id: "",
      title: "",
      plate_number: "",
      fleet_number: "",
      manufacture_name: "",
      model: "",
      year: "",
      fuel_type: "",
      engine: "",
      tyre: "",
      toastItems: [],
      allDataRows: [],
      filteredDataRows: [],
      sim_provider: "",
      sim: "",
      vehicleTypes: [],
      subscriberTypes: [],
      vehicle_type: "",
      autoid: 0
    };
  }

  componentDidMount = async () => {
    const accounts = await api.post("vehicle/getsubscribertypes");
    if (accounts) {
      if (Array.isArray(accounts)) {
        let dbArray = accounts.map(opt => ({
          label: opt.company_name,
          value: opt
        }));
        this.setState({
          subscriberTypes: dbArray
        });
      }
    }

    const vehicleType = await api.post("vehicle/getvehicletypes");
    if (vehicleType) {
      let dbArray = vehicleType.map(opt => ({ label: opt.title, value: opt }));
      this.setState({
        vehicleTypes: dbArray
      });
    }

    if (this.props.location.state.type === "edit") {
      const _vehicleEditInput = {
        vehicleId: this.props.location.state.vehicleId
      };
      this.getEditingVehicle(_vehicleEditInput);
    } else if (this.props.location.state.type === "add") {
      this.setState({
        subscriber_id: this.props.location.state.subscriber_id
          ? this.getSubscriberType(this.props.location.state.subscriber_id)
          : ""
      });
    }
  };

  getEditingVehicle = async _vehicleEditInput => {
    const vehicleData = await api.post(
      "sc-vehicle/get-vehicle-edit-data",
      _vehicleEditInput
    );

    if (vehicleData) {
      this.setState({
        subscriber_id: this.getSubscriberType(vehicleData[0].subscriber_id),
        vehicle_type: this.getVehicleType(vehicleData[0].vehicle_type),
        title: vehicleData[0].title,
        plate_number: vehicleData[0].plate_number,
        fleet_number: vehicleData[0].fleet_number,
        manufacture_name: vehicleData[0].manufacture_name,
        model: vehicleData[0].model,
        year: vehicleData[0].year,
        fuel_type: vehicleData[0].fuel_type,
        engine: vehicleData[0].engine,
        tyre: vehicleData[0].tyre,
        sim_provider: vehicleData[0].simprovider,
        sim: vehicleData[0].sim,
        autoid: vehicleData[0].autoid
      });
    }
  };

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onSubmit = async e => {
    e.preventDefault();
    const vehicleInputs = {
      subscriber_id: this.state.subscriber_id.value.autoid,
      vehicle_type: this.state.vehicle_type.value.autoid,
      title: this.state.title,
      plate_number: this.state.plate_number,
      fleet_number: this.state.fleet_number,
      manufacture_name: this.state.manufacture_name,
      model: this.state.model,
      year: this.state.year,
      fuel_type: this.state.fuel_type,
      engine: this.state.engine,
      tyre: this.state.tyre,
      sim_provider: this.state.sim_provider,
      sim: this.state.sim,
      autoid: this.state.autoid,
      updateby: localStorage.apiAuthAccessId
    };

    if (this.props.location.state.type === "add") {
      const addResult = await api.post("vehicle/new", vehicleInputs);
      if (addResult.status === "success") {
        this.onToastItemAdd(addResult.status, addResult.message);
        if (this.props.location.state.from === "account") {
          this.props.history.push({
            pathname: `/account-details`,
            state: {
              companyId: this.state.subscriber_id.value.autoid
            }
          });
        } else {
          this.props.history.push(`/vehicle-list`);
        }
      }
    } else if (this.props.location.state.type === "edit") {
      const updateResult = await api.post("vehicle/update", vehicleInputs);

      if (updateResult) {
        if (updateResult.status === "success") {
          this.onToastItemAdd(updateResult.status, updateResult.message);
          if (this.props.location.state.from === "account") {
            this.props.history.push({
              pathname: `/account-details`,
              state: {
                companyId: this.state.subscriber_id.value.autoid
              }
            });
          } else {
            this.props.history.push(`/vehicle-list`);
          }
        }
      }
    }
  };

  getVehicleType(value) {
    if (this.state.vehicleTypes) {
      let filteredDataRows = this.state.vehicleTypes.filter(el => {
        return el.value.autoid === value;
      })[0];

      return filteredDataRows;
    }
  }

  getSubscriberType(value) {
    if (this.state.subscriberTypes) {
      let filteredDataRows = this.state.subscriberTypes.filter(el => {
        return el.value.autoid === value;
      })[0];
      return filteredDataRows;
    }
  }

  handleToastItemClose = itemObject => {
    const toastItems = this.state.toastItems.filter(
      c => c.id !== itemObject.id
    );
    this.setState({ toastItems });
  };

  onToastItemAdd(type, message) {
    let newid = this.state.toastItems.length + 1;
    if (
      this.state.toastItems.filter(function(e) {
        return e.id === newid;
      }).length > 0
    ) {
      newid = this.state.toastItems.length + Math.floor(Math.random() * 100);
    }

    let newToastItems = this.state.toastItems;
    let itemToast = {
      id: newid,
      type: type === "success" ? "success" : "danger",
      message: message
    };

    newToastItems.push(itemToast);
    this.setState({ toastItems: newToastItems });

    setTimeout(() => {
      this.handleToastItemClose(itemToast);
    }, 5000);
  }

  cancelButton = () => {
    const ButtonCancel = () => (
      <Route
        render={({ history }) => (
          <button
            type="reset"
            className="btn btn-warning mr-1"
            onClick={() => {
              this.props.location.state.from === "account"
                ? this.props.history.push({
                    pathname: `/account-details`,
                    state: {
                      companyId: this.state.subscriber_id.value.autoid
                    }
                  })
                : this.props.history.push(`/vehicle-list`);
            }}
          >
            Cancel
          </button>
        )}
      />
    );
    return <ButtonCancel />;
  };

  render() {
    return (
      <React.Fragment>
        {/* <Navbar /> */}
        <main role="main">
          <section>
            <div className="container mt-4">
              <div className="row">
                <ToastAlert
                  toastItems={this.state.toastItems}
                  onItemClose={this.handleToastItemClose}
                />
                <div className=" col-md-8 offset-md-2">
                  <div className="card mb-1">
                    <div className="card-header">
                      <h4 className="card-title"> Form</h4>
                    </div>
                    <div className="card-content collpase show">
                      <div className="card-body">
                        <form className="form" onSubmit={this.onSubmit}>
                          <div className="form-body">
                            <div className="row">
                              <div className="col-12">
                                <div className="form-group">
                                  <label>Subscriber</label>
                                  <div className="position-relative has-icon-left">
                                    <SubscriberSelect
                                      name="subscriber_id"
                                      value={this.state.subscriber_id}
                                      onChange={this.onChange}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label>Title</label>
                                  <div className="position-relative has-icon-left">
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="title"
                                      value={this.state.title}
                                      onChange={this.onChange}
                                      required
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label>Type</label>
                                  <div className="position-relative has-icon-left">
                                    <VehicleTypeSelect
                                      name="vehicle_type"
                                      value={this.state.vehicle_type}
                                      onChange={this.onChange}
                                      dataSource={this.state.vehicleTypes}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-6 col-12">
                                <div className="form-group">
                                  <label>Plate Number</label>
                                  <div className="position-relative has-icon-left">
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="plate_number"
                                      value={this.state.plate_number}
                                      onChange={this.onChange}
                                      required
                                    />
                                  </div>
                                </div>
                              </div>{" "}
                              <div className="col-md-6 col-12">
                                <div className="form-group">
                                  <label>Fleet Number</label>
                                  <div className="position-relative has-icon-left">
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="fleet_number"
                                      value={this.state.fleet_number}
                                      onChange={this.onChange}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-4 col-12">
                                <div className="form-group">
                                  <label>Manufacture Name</label>
                                  <div className="position-relative has-icon-left">
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="manufacture_name"
                                      value={this.state.manufacture_name}
                                      onChange={this.onChange}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-4 col-12">
                                <div className="form-group">
                                  <label>Model</label>
                                  <div className="position-relative has-icon-left">
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="model"
                                      value={this.state.model}
                                      onChange={this.onChange}
                                    />
                                  </div>
                                </div>
                              </div>{" "}
                              <div className="col-md-4 col-12">
                                <div className="form-group">
                                  <label>Year</label>
                                  <div className="position-relative has-icon-left">
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="year"
                                      value={this.state.year}
                                      onChange={this.onChange}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-4 col-12">
                                <div className="form-group">
                                  <label>Fuel Type</label>
                                  <div className="position-relative has-icon-left">
                                    <select
                                      className="form-control"
                                      name="fuel_type"
                                      onChange={this.onChange}
                                      value={this.state.fuel_type}
                                    >
                                      <option value=""> Select </option>
                                      <option value="Petrol">Petrol</option>
                                      <option value="Diesel">Diesel</option>
                                      <option value="Gas">Gas</option>
                                      <option value="Other">Other</option>
                                    </select>
                                  </div>
                                </div>
                              </div>{" "}
                              <div className="col-md-4 col-12">
                                <div className="form-group">
                                  <label>Engine</label>
                                  <div className="position-relative has-icon-left">
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="engine"
                                      value={this.state.engine}
                                      onChange={this.onChange}
                                    />
                                  </div>
                                </div>
                              </div>{" "}
                              <div className="col-md-4 col-12">
                                <div className="form-group">
                                  <label>Tyre</label>
                                  <div className="position-relative has-icon-left">
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="tyre"
                                      value={this.state.tyre}
                                      onChange={this.onChange}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label>Sim</label>
                                  <div className="position-relative has-icon-left">
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="sim"
                                      value={this.state.sim}
                                      onChange={this.onChange}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label>Sim Provider</label>
                                  <div className="position-relative has-icon-left">
                                    <select
                                      className="form-control"
                                      name="sim_provider"
                                      onChange={this.onChange}
                                      value={this.state.sim_provider}
                                    >
                                      <option value=""> Select </option>
                                      <option value="Ooredoo">Ooredoo</option>
                                      <option value="Vodafone">Vodafone</option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="form-actions right">
                              {this.cancelButton()}
                              <button type="submit" className="btn btn-primary">
                                <i className="fa fa-check-square-o" /> Submit
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </React.Fragment>
    );
  }
}

export default VehicleAdd;
