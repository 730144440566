import React, { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { lighten, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import { IconButton, Typography } from "@material-ui/core";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Paper from "@material-ui/core/Paper";
import {
  Fab,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Tooltip,
} from "@material-ui/core";
import moment from "moment";
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
  Redirect,
} from "react-router-dom";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";

function descendingComparator(a, b, orderBy) {
  if (orderBy !== "") {
    // console.log("date", isNaN(a[orderBy]));
    if (isNaN(parseFloat(a[orderBy]))) {
      // console.log("date", a[orderBy]);
      if (b[orderBy].toLowerCase() < a[orderBy].toLowerCase()) {
        return -1;
      }
      if (b[orderBy].toLowerCase() > a[orderBy].toLowerCase()) {
        return 1;
      }
      return 0;
    } else {
      // console.log("in else");
      if (parseFloat(b[orderBy]) < parseFloat(a[orderBy])) {
        return -1;
      }
      if (parseFloat(b[orderBy]) > parseFloat(a[orderBy])) {
        return 1;
      }
      return 0;
    }
  }
}

const getComparator = (order, orderBy) => {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

const headCells = [
  { id: "autoid", label: "PO Number" },
  {
    id: "account_name",
    label: "Account",
  },
  { id: "po_date", label: "Date" },
  { id: "ship_to", label: "Ship To" },
  { id: "discount", label: "Discount" },
  { id: "total_amount", label: "Order Total" },
  { id: "status", label: "Status" },
  { id: "view", label: "" },
  { id: "edit", label: "" },
  // { id: "delete", label: "" },
];

const EnhancedTableHead = (props) => {
  const {
    classes,
    order,
    orderBy,
    // numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={
              headCell.id === "discount" || headCell.id === "total_amount"
                ? "right"
                : "center"
            }
            style={{ width: "15%" }}
            // padding="default"

            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              iconStyle={{ color: "#fff" }}
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 650,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  venderTableRow: {
    paddingLeft: "3em",
  },
}));

export const PurchaseOrderList = (props) => {
  const { rows } = props;

  const classes = useStyles();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const NavLink = React.forwardRef((props, ref) => (
    <RouterLink innerRef={ref} {...props} />
  ));

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  /**
   * to get badge based on status fetch from DB.
   */
  const getBadge = (status) => {
    let _classes = "badge badge-";
    _classes += status === "Cancelled" ? "danger" : "success";
    return _classes;
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {rows.length !== 0 ? (
                stableSort(rows, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    return (
                      <TableRow hover tabIndex={-1} key={row.id}>
                        <TableCell className={classes.venderTableRow}>
                          {row.autoid}
                        </TableCell>
                        <TableCell>{row.account_name}</TableCell>
                        <TableCell>
                          {moment(row.po_date).format("MMMM Do YYYY")}
                        </TableCell>
                        <TableCell className={classes.venderTableRow}>
                          {row.ship_to}
                        </TableCell>
                        <TableCell align="right">{row.discount}</TableCell>
                        <TableCell align="right">{row.total_amount}</TableCell>
                        <TableCell align="center">
                          {/* {row.status === 0 ? "Cancelled" : "Active"} */}
                          <span className={getBadge(row.status_type)}>
                            {row.status_type}
                          </span>
                        </TableCell>
                        <TableCell className={classes.contactTableRow}>
                          <Typography
                            component={NavLink}
                            to={`/purchase-order-view/${row.autoid}`}
                            style={{ width: "5%" }}
                          >
                            View
                          </Typography>
                        </TableCell>
                        <TableCell style={{ width: "5%" }}>
                          <IconButton
                            style={{ display: row.status === 0 ? "none" : "" }}
                            component={NavLink}
                            to={`/purchase-order/${row.autoid}`}
                          >
                            <Tooltip title="Edit">
                              <EditIcon color="primary"/>
                            </Tooltip>
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })
              ) : (
                <TableRow align="center">
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell>
                    <Typography
                      style={{
                        textAlign: "center",
                        marginTop: "1em",
                        marginLeft: "-6em",
                      }}
                    >
                      No Records...............
                    </Typography>
                  </TableCell>
                  <TableCell />
                  <TableCell />
                  <TableCell />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[50, 200, 500]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
};
