import React, { useState, useEffect } from "react";
import {
  Paper,
  Button,
  Grid,
  Typography,
  TextField,
  Link,
  FormControlLabel,
  Divider,
  Fab,
} from "@material-ui/core";
import * as yup from "yup";
import { makeStyles } from "@material-ui/core/styles";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

import Select from "../../compon-controls/CmbSelect";
import { QuotationProductTable } from "./QuotationProductTable";

const createValidationSchema = yup.object().shape({
  phone: yup.string().matches(/(^[+]?[0-9]*$)/, {
    message: "Invalid phone number format",
  }),
});

const useStyles = makeStyles((theme) => ({
  resize: {
    "&::placeholder": {
      fontSize: "0.813rem",
    },
  },
  button: {
    height: 20,
  },
}));

export const QuotationProduct = (props) => {
  const [mode, setMode] = useState("add");
  const classes = useStyles();
  const { formValues } = props;
  const getProductBundleItems = (product) => {
    try {
      let bundle_products = formValues.bundleProducts;
      const newFilteredItems = bundle_products.filter(
        (c) => c.bundle_id === product.bundle_id
      );

      return newFilteredItems;
    } catch (error) {
      console.log("something went wrong!", error);
      return null;
    }
  };

  const productSave = () => {
    if (formValues.selectedProduct) {
      let product = {
        autoid: formValues.selectedProduct.value.autoid,
        product_name: formValues.selectedProduct.value.product_name,
        bundle_name: formValues.selectedProduct.value.bundle_name,
        bundle_id: formValues.selectedProduct.value.bundle_id,
        bundle_items: [],
        price: formValues.price,
        quantity: formValues.quantity,
        discount: formValues.discount === undefined ? 0 : formValues.discount,
        total: formValues.total,
        mrp: formValues.price * formValues.quantity,
        status: 1,
      };

      if (product.bundle_id) {
        let bundle_items = getProductBundleItems(product);
        product.bundle_items = bundle_items;
      }

      if (mode === "add") {
        if (formValues.gridProduct.length === 0) {
          formValues.gridProduct.push(product);
        } else {
          formValues.gridProduct.push(product);
          const uniqueArray = formValues.gridProduct
            .slice()
            .reverse()
            .filter(
              (v, i, a) => a.findIndex((t) => t.autoid === v.autoid) === i
            )
            .reverse();
          formValues.gridProduct = uniqueArray;
        }

        const totalArray = formValues.gridProduct.map((item) => item.total);
        const reducer = (accumulator, currentValue) =>
          parseFloat(accumulator) + parseFloat(currentValue);
        const subtotal = totalArray.reduce(reducer);

        props.changeFormValues((prev) => ({
          ...prev,
          gridProduct: formValues.gridProduct,
          subTotal: subtotal,
          totalPrice: subtotal - formValues.totalDiscount,
        }));
      } else {
        let newFilteredItems = [];

        newFilteredItems = formValues.gridProduct.filter(
          (c) => c.autoid !== product.autoid
        );

        newFilteredItems.push(product);
        const totalArray = newFilteredItems.map((item) => item.total);
        const reducer = (accumulator, currentValue) =>
          parseFloat(accumulator) + parseFloat(currentValue);
        const subtotal = totalArray.reduce(reducer);
        props.changeFormValues((prev) => ({
          ...prev,
          gridProduct: newFilteredItems,
          subTotal: subtotal,
          totalPrice: subtotal - formValues.totalDiscount,
        }));
      }
    }
  };

  const deleteProduct = (row) => {
    const newFilteredItems = formValues.gridProduct.filter(
      (c) => c.autoid !== row.autoid
    );

    const totalArray = newFilteredItems.map((item) => item.total);
    const reducer = (accumulator, currentValue) =>
      parseFloat(accumulator) + parseFloat(currentValue);
    const subtotal = totalArray.reduce(reducer);

    props.changeFormValues((prev) => ({
      ...prev,
      gridProduct: newFilteredItems,
      subTotal: subtotal,
      totalPrice: subtotal,
    }));
  };

  const editProduct = (row) => {
    let _selectedProduct = {
      label: row.product_name,
      value: {
        autoid: row.autoid,
        product_name: row.product_name,
        price: row.price,
        bundle_id: row.bundle_id,
        bundle_name: row.bundle_name,
      },
    };

    props.changeFormValues((prev) => ({
      ...prev,
      selectedProduct: _selectedProduct,
      quantity: row.quantity,
      price: row.price,
      discount: row.discount,
      total: row.total,
    }));
    setMode("edit");
  };

  const clearFormValues = () => {
    props.changeFormValues((prev) => ({
      ...prev,
      selectedProduct: "",
      price: "",
      quantity: "",
      discount: "",
      total: "",
    }));
  };

  return (
    <Grid>
      <Grid item xs={12}>
        <Typography style={{ padding: 15 }}>Select Product</Typography>
        <Divider style={{ backgroundColor: "#cdc9c9" }} />
      </Grid>
      <Grid container spacing={5} style={{ padding: "2%" }}>
        <Grid item xs={12} sm={4}>
          <Typography style={{ fontSize: 14, fontWeight: "normal" }}>
            Product
          </Typography>
          <Select
            name="selectedProduct"
            value={formValues.selectedProduct}
            onChange={(e) => {
              props.changeFormValues((prev) => ({
                ...prev,
                selectedProduct: e.target.value,
                quantity: 1,
                price: e.target.value.value.price,
                total:
                  formValues.discount > 0
                    ? e.target.value.value.price * formValues.quantity -
                      (e.target.value.value.price *
                        formValues.quantity *
                        formValues.discount) /
                        100
                    : e.target.value.value.price,
                productItems: formValues.productItems.filter(
                  (product) =>
                    product.value.autoid !== e.target.value.value.autoid
                ),
              }));
            }}
            dataSource={formValues.productItems}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <Typography style={{ fontSize: 14, fontWeight: "normal" }}>
            Quantity
          </Typography>
          <TextField
            variant="outlined"
            name="quantity"
            placeholder="0"
            fullWidth
            type="number"
            InputProps={{
              classes: {
                input: classes.resize,
              },
            }}
            value={formValues.quantity}
            onChange={(e) => {
              e.persist();
              props.changeFormValues((prev) => ({
                ...prev,
                quantity: e.target.value,
                total:
                  formValues.price * e.target.value -
                  (formValues.price * e.target.value * formValues.discount) /
                    100,
              }));
            }}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <Typography style={{ fontSize: 14, fontWeight: "normal" }}>
            Price
          </Typography>
          <TextField
            variant="outlined"
            name="price"
            placeholder="0"
            fullWidth
            type="number"
            InputProps={{
              classes: {
                input: classes.resize,
              },
            }}
            value={formValues.price}
            onChange={(e) => {
              e.persist();
              props.changeFormValues((prev) => ({
                ...prev,
                price: e.target.value,
                total:
                  e.target.value * formValues.quantity -
                  (e.target.value * formValues.quantity * formValues.discount) /
                    100,
              }));
            }}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <Typography style={{ fontSize: 14, fontWeight: "normal" }}>
            Discount(%)
          </Typography>

          <TextField
            variant="outlined"
            name="discount"
            placeholder="0"
            fullWidth
            type="number"
            InputProps={{
              classes: {
                input: classes.resize,
              },
            }}
            value={formValues.discount}
            onChange={(e) => {
              e.persist();
              props.changeFormValues((prev) => ({
                ...prev,
                discount: e.target.value,
                total:
                  formValues.price * formValues.quantity -
                  (formValues.price * formValues.quantity * e.target.value) /
                    100,
              }));
            }}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <Typography style={{ fontSize: 14, fontWeight: "normal" }}>
            Total
          </Typography>
          <TextField
            variant="outlined"
            name="total"
            placeholder="0"
            fullWidth
            type="number"
            InputProps={{
              classes: {
                input: classes.resize,
              },
            }}
            value={formValues.total}
            onChange={(e) => {
              e.persist();
              props.changeFormValues((prev) => ({
                ...prev,
                total: e.target.value,
              }));
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Fab
            variant="extended"
            size="small"
            color="primary"
            className={classes.button}
            onClick={clearFormValues}
          >
            Clear
          </Fab>
          <Fab
            variant="extended"
            size="small"
            color="primary"
            style={{ marginLeft: 5 }}
            className={classes.button}
            onClick={productSave}
            disabled={formValues.disableAdd}
          >
            Add
          </Fab>
        </Grid>

        <Grid item xs={12}>
          <QuotationProductTable
            classes={classes}
            gridProduct={formValues.gridProduct}
            bundleItems={formValues.bundle_items}
            deleteProduct={deleteProduct}
            editProduct={editProduct}
            total={formValues.subTotal}
          />
        </Grid>
        <Grid item xs={12} style={{ marginTop: "1.5em" }}>
          <Typography variant="h5" style={{ fontWeight: "bold" }}>
            Quotation Price
          </Typography>
          <Divider />
        </Grid>

        <Grid item xs={12} sm={4}>
          <TextField
            fullWidth
            required
            variant="outlined"
            name="subTotal"
            label="Sub Total"
            value={formValues.subTotal}
            // onChange={(e) => {
            //   e.persist();
            //   props.changeFormValues((prev) => ({
            //     ...prev,
            //     subTotal: e.target.value,
            //     totalPrice:
            //       parseFloat(e.target.value) -
            //       parseFloat(formValues.totalDiscount),
            //   }));
            // }}
            // inputProps={{ maxLength: 50 }}
            InputProps={{
              classes: {
                input: classes.resize,
              },
            }}
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <TextField
            fullWidth
            required
            variant="outlined"
            name="totalDiscount"
            label="Discount(Qr)"
            value={formValues.totalDiscount}
            onChange={(e) => {
              e.persist();
              props.changeFormValues((prev) => ({
                ...prev,
                totalDiscount: e.target.value,
                totalPrice:
                  parseFloat(formValues.subTotal) - parseFloat(e.target.value),
              }));
            }}
            // onBlur={onDiscountBlur}
            inputProps={{ maxLength: 50 }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            fullWidth
            required
            variant="outlined"
            name="totalPrice"
            label="Total"
            value={formValues.totalPrice}
            // onChange={(e) => {
            //   e.persist();
            //   props.changeFormValues((prev) => ({
            //     ...prev,
            //     totalPrice: formValues.totalPrice,
            //   }));
            // }}
            inputProps={{ maxLength: 50 }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
