import React, { Component } from "react";
import CmbSelect from "../compon-controls/CmbSelect";
import { Col, Row } from "reactstrap";

class BundleProduct extends Component {
  constructor(props) {
    super(props);

    let _pname = "";
    let _price = 0.0;
    let _oprice = 0.0;
    let _qty = 0;
    let _total = 0.0;
    let _ototal = 0.0;

    let _product = this.props.selectedProduct;
    let _selPro = null;
    let _mode = "add";

    if (_product) {
      _pname = _product.product_name;
      _price = _product.price;
      _oprice = _product.original_price;
      _qty = _product.quantity;
      _total = _product.total;
      _ototal = _product.original_total;

      _selPro = {
        label: _product.product_name,
        value: {
          autoid: _product.autoid,
          product_name: _product.product_name,
          price: _product.price,
          out_side_city_price: _product.price
        }
      };
      _mode = "edit";
    }

    this.state = {
      products: this.props.products,
      selected_products: this.props.seletedProducts,
      selected_product: _selPro,

      product_name: _pname,
      price: _price,
      original_price: _oprice,
      quantity: _qty,

      total: _total,
      original_total: _ototal,
      errorMessage: "",
      mode: _mode
    };
  }

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onProductChange = e => {
    this.setState({ [e.target.name]: e.target.value });
    this.fillProductValues(e.target.value.value);
  };

  fillProductValues = product => {
    if (product) {
      this.setState({
        price: product.price,
        original_price: product.price,
        quantity: 1
      });
    }
  };

  validate = () => {
    let value = true;
    if (this.state.selected_product === null) {
      this.setState({
        errorMessage: "Please select product"
      });
      value = false;
    } else if (this.state.quantity <= 0) {
      this.setState({
        errorMessage: "Quantity should be greater than zero"
      });
      value = false;
    }

    return value;
  };

  removeProduct = product => {
    const newFiltedItems = this.state.selected_products.filter(
      c => c.autoid !== product.autoid
    );

    this.setState({
      selected_products: newFiltedItems
    });
  };

  productSave = () => {
    if (!this.validate()) return;

    const { selected_product, price, original_price, quantity } = this.state;

    let product = {
      autoid: selected_product.value.autoid,
      product_name: selected_product.value.product_name,
      price: price,
      original_price: original_price,
      quantity: quantity,
      total: (price * quantity).toFixed(2),
      original_total: (original_price * quantity).toFixed(2),
      status: 1
    };

    if (this.state.mode === "add") {
      this.state.selected_products.push(product);
      this.props.save(this.state.selected_products);
    } else {
      const newFiltedItems = this.state.selected_products.filter(
        c => c.autoid !== product.autoid
      );

      newFiltedItems.push(product);
      this.setState({
        selected_products: newFiltedItems
      });
      this.props.save(newFiltedItems);
    }

    this.props.cancel();
  };

  render() {
    return (
      <React.Fragment>
        <section className="manage-colom">
          <div className="container">
            <div className="card">
              <div className="card-header">
                <Row>
                  <Col>
                    <h4>Select Product</h4>
                  </Col>
                </Row>
              </div>
              <div className="card-content collpase show">
                <div className="card-body">
                  <div className="form-body">
                    <Row>
                      <Col xl={2}>
                        <label>Product</label>
                      </Col>
                      <Col xl={4}>
                        <CmbSelect
                          name="selected_product"
                          value={this.state.selected_product}
                          onChange={this.onProductChange}
                          dataSource={this.state.products}
                        />
                      </Col>
                      <Col xl={3}>
                        <label>Price</label>
                      </Col>
                      <Col xl={3}>
                        <input
                          type="number"
                          className="form-control"
                          name="price"
                          required
                          value={this.state.price}
                          onChange={this.onChange}
                          placeholder="0.00"
                        />
                      </Col>
                    </Row>

                    <Row className="mt-2">
                      <Col xl={2}>
                        <label>Quantity</label>
                      </Col>
                      <Col xl={4}>
                        <input
                          type="number"
                          className="form-control"
                          name="quantity"
                          value={this.state.quantity}
                          onChange={this.onChange}
                          placeholder="0.00"
                        />
                      </Col>
                      <Col xl={3}>
                        <label> Original Price</label>
                      </Col>
                      <Col xl={3}>
                        <input
                          type="number"
                          className="form-control"
                          name="original_price"
                          value={this.state.original_price}
                          onChange={this.onChange}
                          placeholder="0.00"
                        />
                      </Col>
                    </Row>

                    <Row className="mt-4">
                      <Col style={{ display: "flex" }}>
                        <button
                          className="btn btn-primary btn-sm"
                          onClick={this.productSave}
                        >
                          Save
                        </button>
                        <button
                          className="btn btn-primary btn-sm ml-2 "
                          onClick={() => {
                            this.props.cancel();
                          }}
                        >
                          Cancel
                        </button>
                      </Col>
                      <Col>
                        <span className="text-danger">
                          {this.state.errorMessage}
                        </span>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default BundleProduct;
