import React, { Component } from "react";
import jwt_decode from "jwt-decode";
import { Col, Row } from "reactstrap";

import ReactHtmlParser from "react-html-parser";
import { AppHelper_DateAndTimeToString } from "../businessjs/AppHelperFn";

class ViewTicketItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      comment: "",
      ticketId: 0,
      showTicketItems: [],
    };
  }
  componentDidMount = () => {
    const token = localStorage.apiAuthAccessKey;
    const decoded = jwt_decode(token);
    const decodedUser = decoded.userAccessKey[0];
    this.setState({
      subscriber_id: decodedUser.subscriber_id,
      executive_id: decodedUser.autoid,
      ticketId: this.props.ticketId,
    });
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  render() {
    return (
      <React.Fragment>
        <Row>
          <Col>
            <div className="card">
              <div
                className="card-header"
                style={{
                  backgroundColor: "aliceblue",
                  lineHeight: "5px",
                }}
              >
                <Row>
                  <Col
                    xl={7}
                    lg={8}
                    md={7}
                    sm={5}
                    xs={12}
                    style={{ textAlign: "left" }}
                  >
                    <i className="fa fa-user fa-lg" aria-hidden="true" />
                    <label style={{ marginLeft: "9px", fontWeight: "400" }}>
                      {this.props.itemObject.UserName}
                    </label>
                  </Col>
                  <Col xl={5} lg={4} md={5} sm={7} xs={12}>
                    <i className="fa fa-clock-o fa-lg" aria-hidden="true" />
                    <label style={{ marginLeft: "9px", fontWeight: "400" }}>
                      {AppHelper_DateAndTimeToString(
                        this.props.itemObject.updatetime
                      )}
                    </label>
                  </Col>
                </Row>
              </div>
            </div>
            <Row className="mt-2 ml-1" >
              <Col style={{ textAlign: "left" }}>{ReactHtmlParser(this.props.itemObject.update_text)}</Col>
            </Row>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default ViewTicketItem;
