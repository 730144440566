import React, { useState, useEffect } from "react";
import {
  Paper,
  Button,
  Grid,
  Typography,
  TextField,
  Link,
  Fab,
  FormControlLabel,
  Radio,
  Snackbar,
  CircularProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Select as MUISelect,
  FormControl,
  InputLabel,
  MenuItem,
} from "@material-ui/core";
import { Formik, Form, ErrorMessage } from "formik";
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from "react-router-dom";
import { api } from "../../businessjs/API";
import { Select } from "../../compon-controls/Select";
import { SnackBar } from "../../compon-controls/SnackBar";
import { Redirect } from "react-router-dom";
import * as yup from "yup";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const createValidationSchema = yup.object().shape({
  contactNumber: yup.string().matches(/(^[+]?[0-9]*$)/, {
    message: "Invalid phone number",
  }),
  confirmPassword: yup.string().when("password", {
    is: (val) => (val && val.length > 0 ? true : false),
    then: yup
      .string()
      .oneOf([yup.ref("password")], "Both password need to be the same"),
  }),
});

const honorificOptions = [
  { value: "mr", label: "Mr." },
  { value: "mrs", label: "Mrs." },
  { value: "ms", label: "Ms." },
  { value: "miss", label: "Miss." },
];

const roleOptions = [
  { value: 1, label: "Owner" },
  { value: 2, label: "Admin" },
  { value: 3, label: "User" },
];

export const AddExecutive = (props) => {
  const [error, setError] = useState(false);
  const [color, setColor] = useState("#d50000");
  const [infoMessgae, setInfoMessage] = useState(false);
  const [navigation, setNavigation] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formlValues, setFormlValues] = useState({
    subscriberId: "",
    firstName: "",
    email: "",
    lastName: "",
    contactNumber: "",
    status: 0,
    subscriberList: [],
    honorific: undefined,
    nickName: "",
    role: 0,
    password: "",
    confirmPassword: "",
  });
  const NavLink = React.forwardRef((props, ref) => (
    <RouterLink innerRef={ref} {...props} />
  ));

  useEffect(() => {
    getSubscriberForCmb();
  }, [props.match.params.id]);

  const getSubscriberForCmb = async () => {
    setLoading(true);
    const subscribers = await api.post("sc-subscriber/get-all-for-cmb");
    if (subscribers && subscribers.userdata) {
      let subscriberArray = subscribers.userdata.map((opt) => ({
        label: opt.company_name,
        value: opt,
      }));

      setFormlValues({
        subscriberList: subscriberArray,
        honorific: honorificOptions[0],
      });
      loadExecutive(subscriberArray);
    }
  };

  const loadExecutive = async (subscriberArray) => {
    setLoading(true);
    const id = props.match.params.id;
    try {
      if (id !== "new") {
        const input = {
          autoId: id,
        };
        const currentExecutive = await api.post(
          "sc-executive/get-executive-by-subid",
          input
        );
        // console.log("deta", currentExecutive[0]);
        if (currentExecutive && currentExecutive.length) {
          setFormlValues({
            firstName: currentExecutive[0].first_name,
            lastName: currentExecutive[0].last_name,
            email: currentExecutive[0].email,
            contactNumber: currentExecutive[0].contact_number,
            // subscriberId: subscriberArray.find(
            //   (option) =>
            //     option.value.autoid === currentExecutive[0].subscriber_id
            // ),
            subscriberId: currentExecutive[0].subscriber_id,
            role: roleOptions.find(
              (option) => option.value === currentExecutive[0].role
            ),
            nickName: currentExecutive[0].nick_name,
            honorific: honorificOptions.find(
              (option) => option.value === currentExecutive[0].honorifics
            ),
            subscriberList: subscriberArray,
          });
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const onCloseSnackbar = () => {
    setInfoMessage("");
  };

  const addNewExecutive = async (values) => {
    if (values.subscriberId === undefined) {
      setColor("#0091ea");
      setInfoMessage("Please select subscriber...");
    } else if (values.honorific === undefined) {
      console.log(values.honorific);
      setColor("#0091ea");
      setInfoMessage("Please select honorific...");
    } else if (values.role === undefined) {
      setColor("#0091ea");
      setInfoMessage("Please select role...");
    } else {
      const addInput = {
        subscriberId: values.subscriberId,
        honorific: values.honorific.value,
        firstName: values.firstName,
        lastName: values.lastName === undefined ? "" : values.lastName,
        nickName: values.nickName === undefined ? "" : values.nickName,
        email: values.email,
        contactNumber:
          values.contactNumber === undefined ? "" : values.contactNumber,
        role: values.role.value,
        password: values.password,
      };
      console.log("addInput", addInput);
      const addResult = await api.post("sc-executive/executive-add", addInput);
      if (addResult.status === "success") {
        setInfoMessage(addResult.message);
        setColor("#4caf50");
        setTimeout(() => {
          setNavigation(true);
        }, 2000);
      } else {
        setInfoMessage(addResult.message);
        setColor("#d50000");
        setNavigation(false);
      }
      setNavigation(false);
    }
  };

  const updateExecutive = async (values) => {
    if (values === formlValues) {
      setColor("#0091ea");
      setInfoMessage("Please change fields as required...");
    } else {
      const editInput = {
        subscriberId: values.subscriberId,
        honorific: values.honorific.value,
        firstName: values.firstName,
        lastName: values.lastName,
        nickName: values.nickName === undefined ? "" : values.nickName,
        email: values.email,
        contactNumber: values.contactNumber,
        role: values.role.value,
        autoId: props.match.params.id,
      };

      const editResult = await api.post(
        "sc-executive/update-executive",
        editInput
      );
      if (editResult.status === "success") {
        setInfoMessage(editResult.message);
        setColor("#4caf50");
        setTimeout(() => {
          setNavigation(true);
        }, 2000);
      } else {
        setInfoMessage(editResult.message);
        setColor("#d50000");
        setNavigation(false);
      }
      setNavigation(false);
    }
  };

  if (navigation) {
    return <Redirect to="/executive"></Redirect>;
  } else {
    return (
      <div>
        <SnackBar
          status={infoMessgae}
          onCloseError={onCloseSnackbar}
          color={color}
        />
        <Paper elevation={4} style={{ minWidth: "50%" }}>
          <Formik
            enableReinitialize
            validationSchema={createValidationSchema}
            initialValues={formlValues}
            onSubmit={async (values) => {
              if (props.match.params.id !== "new") {
                updateExecutive(values);
              } else {
                addNewExecutive(values);
              }
            }}
          >
            {({ values, handleChange, setFieldValue, touched, errors }) => (
              <Form>
                <Grid
                  container
                  spacing={3}
                  style={{ padding: "2em", marginTop: "2em" }}
                >
                  <Grid item xs={12}>
                    <Typography variant="h4" style={{ fontWeight: "bold" }}>
                      {props.match.params.id === "new"
                        ? "Add New Executive"
                        : "Edit Executive"}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <FormControl variant="outlined" fullWidth required>
                      <InputLabel>Subscriber Name</InputLabel>
                      <MUISelect
                        value={values.subscriberId}
                        onChange={(event) => {
                          setFieldValue("subscriberId", event.target.value);
                        }}
                        label="Subscriber Name"
                        disabled={
                          props.match.params.id !== "new" ? true : false
                        }
                      >
                        {values.subscriberList &&
                          values.subscriberList.map((option) => (
                            <MenuItem
                              value={option.value.autoid}
                              key={option.value.autoid}
                            >
                              {option.label}
                            </MenuItem>
                          ))}
                      </MUISelect>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Select
                      required
                      style={{ minHeight: "2em", backgroundColor: "blue" }}
                      onChange={(value) => {
                        setFieldValue("honorific", value);
                      }}
                      options={honorificOptions}
                      // placeholder={<div>Select honorific....</div>}
                      placeholder="Select honorific..."
                      value={values.honorific}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      required
                      fullWidth
                      variant="outlined"
                      name="firstName"
                      label="First Name"
                      value={values.firstName}
                      onChange={handleChange}
                      inputProps={{ maxLength: 50 }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      fullWidth
                      // required
                      variant="outlined"
                      name="lastName"
                      label="Last Name"
                      multiline
                      value={values.lastName}
                      onChange={handleChange}
                      inputProps={{ maxLength: 50 }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      name="nickName"
                      label="Nick Name"
                      multiline
                      value={values.nickName}
                      onChange={handleChange}
                      inputProps={{ maxLength: 50 }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      fullWidth
                      // required
                      error={Boolean(errors.contactNumber)}
                      helperText={errors.contactNumber}
                      variant="outlined"
                      name="contactNumber"
                      label="Contact Number"
                      value={values.contactNumber}
                      onChange={handleChange}
                      inputProps={{ maxLength: 50 }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <TextField
                      fullWidth
                      required
                      type="email"
                      variant="outlined"
                      name="email"
                      label="E-mail"
                      value={values.email}
                      onChange={handleChange}
                      inputProps={{ maxLength: 50 }}
                    />
                  </Grid>
                  {props.match.params.id === "new" ? (
                    <>
                      <Grid item xs={12} sm={4}>
                        <TextField
                          required
                          fullWidth
                          variant="outlined"
                          name="password"
                          label="Password"
                          value={values.password}
                          onChange={handleChange}
                          inputProps={{ maxLength: 50 }}
                          type="password"
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextField
                          required
                          fullWidth
                          error={Boolean(errors.confirmPassword)}
                          helperText={errors.confirmPassword}
                          variant="outlined"
                          name="confirmPassword"
                          label="Confirm Password"
                          value={values.confirmPassword}
                          onChange={handleChange}
                          inputProps={{ maxLength: 50 }}
                          type="password"
                        />
                      </Grid>
                    </>
                  ) : null}

                  <Grid item xs={12} sm={4}>
                    <Select
                      required
                      style={{ minHeight: "2em", backgroundColor: "blue" }}
                      onChange={(value) => {
                        setFieldValue("role", value);
                      }}
                      options={roleOptions}
                      placeholder="Select Role..."
                      value={values.role}
                    />
                  </Grid>
                  {props.match.params.id !== "new" ? (
                    <Grid item xs={12} sm={6}></Grid>
                  ) : null}

                  <Grid item xs={6} style={{ textAlign: "right" }}>
                    <Fab
                      variant="extended"
                      size="medium"
                      color="primary"
                      component={NavLink}
                      to="/executive"
                    >
                      Cancel
                    </Fab>
                  </Grid>
                  <Grid item xs={6} style={{ textAlign: "left" }}>
                    <Fab
                      variant="extended"
                      size="medium"
                      color="primary"
                      type="submit"
                      disabled={props.match.params.id !== "new" ? loading : ""}
                    >
                      {props.match.params.id === "new" ? "Submit" : "Update"}
                      {loading === true && props.match.params.id !== "new" ? (
                        <CircularProgress size={35} />
                      ) : null}
                    </Fab>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Paper>
      </div>
    );
  }
};
