import React, { Component } from "react";
import Datetime from "react-datetime";
import { Route, Redirect } from "react-router-dom";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import { EditorState, convertToRaw } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { withStyles } from "@material-ui/core/styles";
import { AuthHelperAccessCheck } from "../businessjs/AuthHelper";
import SubscriberSelect from "../compon-controls/SubscriberSelect";
import { api } from "../businessjs/API";
import {
  Paper,
  Grid,
  Typography,
  Fab,
  MenuItem,
  TextField,
} from "@material-ui/core";
import { SnackBar } from "./Login/SnackBar";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing.unit * 2,
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
});
class TicketCreation extends Component {
  constructor(props) {
    super(props);
    this.redirect = 0;
    this.state = {
      userName: "",
      priority: "1",
      editorState: EditorState.createEmpty(),
      ticketDate: new Date(),
      subject: "",
      role: 0,
      updateText: "",
      toastItems: [],
      errorMessage: "",
      redirect: 0,
      subscriber_id: "",
      subscriberTypes: [],
      color: "#d50000",
    };
  }

  componentDidMount = async () => {
    const accounts = await api.post("vehicle/getsubscribertypes");
    if (accounts) {
      const subscriberId =
        this.props.location.state !== undefined
          ? this.props.location.state.subscriber_id
          : this.state.subscriber_id;
      let dbArray = accounts.map((opt) => ({
        label: opt.company_name,
        value: opt,
      }));
      this.setState({
        subscriberTypes: dbArray,
        subscriber_id: this.getSubscriberType(subscriberId, dbArray),
      });
    }
  };

  getSubscriberType(value, accountList) {
    if (accountList) {
      let filteredDataRows = accountList.filter((el) => {
        return el.value.autoid === value;
      })[0];

      return filteredDataRows;
    }
  }

  onSubmit = async (e) => {
    e.preventDefault();
    if (
      this.state.subscriber_id === null ||
      this.state.subscriber_id === undefined
    ) {
      this.setState({ errorMessage: "Please select subscriber" });
      return;
    } else {
      this.setState({ errorMessage: "" });
      let _ticketCreateData = {
        subscriber_id: this.state.subscriber_id.value.autoid,
        subject: this.state.subject,
        ticketDate: this.state.ticketDate,
        priority: this.state.priority,
        updateText: this.state.updateText,
        userType: 2, // user_type 1:system, 2:supportmembers, 3:executives
        accountId: this.state.subscriber_id.value.account_id,
      };

      const addResult = await api.post(
        "ticket/ticket-creation",
        _ticketCreateData
      );
      if (addResult) {
        AuthHelperAccessCheck(addResult);
        if (addResult.status === "success") {
          this.setState({ errorMessage: addResult.message, color: "#4caf50" });
          if (this.props.location.state === undefined) {
            this.redirect = 1;
          } else if (this.props.location.state.from === "ticket") {
            this.props.history.push({
              pathname: `/account-details`,
              state: {
                companyId: this.state.subscriber_id.value.autoid,
              },
            });
          }
        }
      }
    }
  };

  onEditorStateChange = (editorState) => {
    this.setState({ editorState });
    let value = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    this.setState({
      updateText: value,
    });
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onDateChange = (e) => {
    this.setState({ ticketDate: e._d });
  };

  cancelButton = () => {
    const ButtonCancel = () => (
      <Route
        render={({ history }) => (
          <Fab
            color="primary"
            size="small"
            variant="extended"
            onClick={() => {
              if (this.props.location.state === undefined) {
                history.push({
                  pathname: "/ticket-list",
                });
              } else if (this.props.location.state.from === "ticket") {
                this.props.history.push({
                  pathname: `/account-details`,
                  state: {
                    companyId: this.props.location.state.subscriber_id,
                  },
                });
              }
            }}
          >
            Cancel
          </Fab>
        )}
      />
    );
    return <ButtonCancel />;
  };
  onCloseSnackbar = () => {
    this.setState({ errorMessage: "" });
  };
  render() {
    const { classes } = this.props;
    const { errorMessage } = this.state;
    if (this.redirect === 1) return <Redirect to="/ticket-list" />;
    return (
      <div className={classes.root}>
        <SnackBar
          status={errorMessage}
          onCloseError={this.onCloseSnackbar}
          color={this.state.color}
        />
        <Paper className={classes.paper} elevation={3}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography
                variant="h3"
                color="textSecondary"
                style={{
                  fontWeight: "bold",
                  textAlign: "start",
                }}
              >
                Open New Ticket
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography
                style={{
                  fontWeight: "bold",
                  textAlign: "start",
                  fontSize: "0.875rem",
                }}
              >
                Subscriber
              </Typography>
              <SubscriberSelect
                name="subscriber_id"
                value={this.state.subscriber_id}
                onChange={this.onChange}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <Typography
                style={{
                  fontWeight: "bold",
                  textAlign: "start",
                  fontSize: "0.875rem",
                }}
              >
                Priority
              </Typography>
              <TextField
                id="outlined-select-currency"
                select
                size="small"
                name="priority"
                onChange={this.onChange}
                value={this.state.priority}
                required
                variant="outlined"
                fullWidth
              >
                <MenuItem value="0">Low</MenuItem>
                <MenuItem value="1">Medium</MenuItem>
                <MenuItem value="2">High</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography
                style={{
                  fontWeight: "bold",
                  textAlign: "start",
                  fontSize: "0.875rem",
                }}
              >
                Date
              </Typography>
              <Datetime
                defaultValue={this.state.ticketDate}
                input={true}
                closeOnSelect={true}
                onChange={this.onDateChange}
                inputProps={{
                  style: { height: 39 },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography
                style={{
                  fontWeight: "bold",
                  textAlign: "start",
                  fontSize: "0.875rem",
                }}
              >
                Subject
              </Typography>
              <TextField
                id="outlined-select-currency"
                name="subject"
                fullWidth
                size="small"
                variant="outlined"
                value={this.state.subject}
                onChange={this.onChange}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography
                style={{
                  fontWeight: "bold",
                  textAlign: "start",
                  fontSize: "0.875rem",
                }}
              >
                Message:
              </Typography>
              <div className="border border-secondary">
                <Editor
                  editorState={this.state.editorState}
                  wrapperClassName="wrapperClassName"
                  editorClassName="editorClassName"
                  onEditorStateChange={this.onEditorStateChange}
                />
              </div>
            </Grid>
            <Grid item xs={6} style={{ textAlign: "end" }}>
              <Fab
                type="submit"
                color="primary"
                size="small"
                variant="extended"
                style={{ marginRight: "1em", marginLeft: "1em" }}
                onClick={this.onSubmit}
              >
                Save
              </Fab>
            </Grid>
            <Grid item xs={6} style={{ textAlign: "start" }}>
              {this.cancelButton()}
            </Grid>
          </Grid>
        </Paper>
      </div>
    );
  }
}

export default withStyles(styles)(TicketCreation);
