/**
 * TicketList :to list all tickets on the screen.
 */
import React, { Component } from "react";
import { Route } from "react-router-dom";
import { Link } from "react-router-dom";
import SubscriberSelect from "../compon-controls/SubscriberSelectForTicket";
import { AuthHelperAccessCheck } from "../businessjs/AuthHelper";
import { AppHelper_DateAndTimeToString } from "../businessjs/AppHelperFn";
import { api } from "../businessjs/API";
import Grid from "@material-ui/core/Grid";
import {
  Tooltip,
  Typography,
  CircularProgress,
  TextField,
  MenuItem,
  TableSortLabel,
  Fab,
} from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChatIcon from "@material-ui/icons/Chat";
import ServiceIcon from "@material-ui/icons/Build";

const styles = (theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
    overflowX: "auto",
  },
  table: {
    minWidth: 700,
  },
  SearchInput: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  searchPaper: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: 400,
  },
  margin: {
    margin: theme.spacing(1),
  },
  buttonProgress: {
    color: "#2196f3",
    position: "absolute",
    left: "55%",
    marginTop: "4.5em",
  },
});

function descendingComparator(a, b, orderBy) {
  if (orderBy !== "") {
    if (isNaN(parseFloat(a[orderBy]))) {
      if (b[orderBy].toLowerCase() < a[orderBy].toLowerCase()) {
        return -1;
      }
      if (b[orderBy].toLowerCase() > a[orderBy].toLowerCase()) {
        return 1;
      }
      return 0;
    } else {
      if (parseFloat(b[orderBy]) < parseFloat(a[orderBy])) {
        return -1;
      }
      if (parseFloat(b[orderBy]) > parseFloat(a[orderBy])) {
        return 1;
      }
      return 0;
    }
  }
}

const getComparator = (order, orderBy) => {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

const headCells = [
  { id: "autoId", label: "Subject" },
  { id: "ticket_date", label: "Ticket Date" },
  { id: "status", label: "Status" },
  { id: "priority", label: "Priority" },
  { id: "update_time", label: "Last Update" },
  { id: "company_name", label: "Account" },
  { id: "first_name", label: "Created By" },
  { id: "comment", label: "" },
];
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));
function EnhancedTableHead(props) {
  const classes = useStyles();
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "center"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
class TicketList extends Component {
  state = {
    loading: false,
    allTickets: [],
    searchSubject: "",
    subscriber_id: { value: 0, label: "All" },
    subscribers: [],
    status: 1,
    page: 0,
    rowsPerPage: 50,
    order: "asc",
    orderBy: "",
    expanded: false,
  };

  componentDidMount() {
    const _ticketCountInputs = {
      subscriber_id: 0,
      searchSubject: this.state.searchSubject,
      status: this.state.status,
    };
    this.getTicketList(_ticketCountInputs);
  }

  
  getTicketList = async ( _ticketListInputs) => {
    const ticketList = await api.post(
      "sc-ticket/get-ticket-list",
      _ticketListInputs
    );

    if (ticketList) {
      if (Array.isArray(ticketList)) {
        this.setState({ allTickets: ticketList,loading:false });
      } else {
        this.setState({ loading: false });
        AuthHelperAccessCheck(ticketList);
      }
    }
  };

  onSubscriberChange = (value) => {
    this.setState({ subscriber_id: value });
    const searchInput = {
      subscriber_id: value.value.autoid
      ? value.value.autoid
      : 0,
      searchSubject: this.state.searchSubject,
      status: this.state.status,
    };
    this.getTicketList(searchInput);
  };
  onStatusChange = (value) => {
    this.setState({ status: value });
    const searchInput = {
      subscriber_id: this.state.subscriber_id.value,
      searchSubject: this.state.searchSubject,
      status: value,
    };
    this.getTicketList(searchInput);
  };
  onSubjectChange = (value) => {
    this.setState({ searchSubject: value });
    const searchInput = {
      subscriber_id: this.state.subscriber_id.value,
      searchSubject: value,
      status: this.state.status,
    };
    this.getTicketList(searchInput);
  };
  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: parseInt(event.target.value, 10), page: 0 });
  };
  handleRequestSort = (event, property) => {
    const isAsc = this.state.orderBy === property && this.state.order === "asc";
    this.setState({ order: isAsc ? "desc" : "asc", orderBy: property });
  };
  handleChange = (panel) => (event, isExpanded) => {
    this.setState({ expanded: isExpanded ? panel : false });
  };
  ticketAdd = () => {
    const TicketAddButton = () => (
      <Route
        render={({ history }) => (
          <Fab
            size="small"
            color="primary"
            variant="extended"
            onClick={() => {
              history.push({
                pathname: "/ticket-creation",
              });
            }}
          >
            Add
          </Fab>
        )}
      />
    );
    return <TicketAddButton />;
  };

  serviceScheduleButton = (item) => {
    const ServiceScheduleButton = () => (
      <Route
        render={({ history }) => (
          <button
            type="button"
            className="btn btn-link btn-sm"
            title="Service Schedule"
            onClick={() => {
              history.push({
                pathname: "/service-schedules",
                state: {
                  ticketId: item.autoId,
                  subscriber_id: item.subscriber_id,
                  from: "ticket",
                },
              });
            }}
          >
            <Tooltip title="Service Schedule">
              <ServiceIcon color="primary" />
            </Tooltip>
          </button>
        )}
      />
    );
    return <ServiceScheduleButton />;
  };

  commentButton = (item) => {
    const CommentButton = () => (
      <Route
        render={({ history }) => (
          <button
            type="button"
            className="btn btn-link btn-sm"
            title="Comment"
            onClick={() => {
              history.push({
                pathname: "/view-ticket",
                state: {
                  ticketId: item.autoId,
                  subject: item.subject,
                  subscriber_id: item.subscriber_id,
                  status: item.statusType,
                  from: "ticket",
                },
              });
            }}
          >
            {" "}
            <Tooltip title="Add Comment">
              <ChatIcon color="primary" />
            </Tooltip>
          </button>
        )}
      />
    );
    return <CommentButton />;
  };

  /**
   * to get badge based on priority fetch from DB.
   */
  getBadge = (priority) => {
    let _classes = "badge badge-";
    _classes +=
      priority === "High"
        ? "danger"
        : priority === "Medium"
        ? "success"
        : "primary";
    return _classes;
  };

  render() {
    let _showTickets = this.state.allTickets;
    const { expanded } = this.state;
    const { classes } = this.props;
    return (
      <React.Fragment>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h3" style={{ fontWeight: "bold" }}>
              Tickets
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ textAlign: "right" }}>
            {this.ticketAdd()}
          </Grid>
          {/* <Paper> */}
          <Grid item xs={12} sm={12} style={{ textAlign: "left" }}>
            <Accordion
              expanded={expanded === "panel1"}
              onChange={this.handleChange("panel1")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography className={classes.heading}>Search</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {/* <Paper elevation={3}> */}
                <Grid container spacing={3}>
                  <Grid item xs={6} sm={4}>
                    <Typography variant="h6" style={{ fontWeight: "bold" }}>
                      Subscriber{" "}
                    </Typography>
                    <SubscriberSelect
                      name="subscriber_id"
                      value={this.state.subscriber_id}
                      onChange={(e) =>
                        this.onSubscriberChange(e.target.value)
                      }
                      showAll={true}
                    />
                  </Grid>

                  <Grid item xs={6} sm={4}>
                    <Typography variant="h6" style={{ fontWeight: "bold" }}>
                      Status
                    </Typography>
                    <TextField
                      fullWidth
                      id="status"
                      select
                      name="status"
                      variant="outlined"
                      onChange={(e) => this.onStatusChange(e.target.value)}
                      value={this.state.status}
                    >
                      {/* {console.log("subscriber_id",this.state.subscriber_id.autoid)} */}
                      <MenuItem value="0">All</MenuItem>
                      <MenuItem value="1">Open</MenuItem>
                      <MenuItem value="2">Assigned</MenuItem>
                      <MenuItem value="3">Answered</MenuItem>
                      <MenuItem value="4">Pending</MenuItem>
                      <MenuItem value="50">Closed</MenuItem>
                      {/* <MenuItem key={option.value} value={option}></MenuItem> */}
                    </TextField>
                  </Grid>

                  <Grid item xs={6} sm={4}>
                    <Typography variant="h6" style={{ fontWeight: "bold" }}>
                      Subject
                    </Typography>
                    <TextField
                      fullWidth
                      type="text"
                      name="searchSubject"
                      // label=" Subject"
                      variant="outlined"
                      onChange={(e) =>
                        this.onSubjectChange(e.target.value)
                      }
                      value={this.state.searchSubject}
                    />
                  </Grid>
                </Grid>
                {/* </Paper> */}
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item xs={12}>
            <Paper elevation={3} style={{ backgroundColor: "#fff" }}>
              {this.state.loading ? (
                this.state.loading && (
                  <Paper
                    elevation={3}
                    style={{ backgroundColor: "#fff", minHeight: "15em" }}
                  >
                    <CircularProgress
                      size={50}
                      className={classes.buttonProgress}
                    />
                  </Paper>
                )
              ) : (
                <div>
                  {" "}
                  <Table className={classes.table}>
                    <EnhancedTableHead
                      classes={classes}
                      // numSelected={selected.length}
                      order={this.state.order}
                      orderBy={this.state.orderBy}
                      // onSelectAllClick={handleSelectAllClick}
                      onRequestSort={this.handleRequestSort}
                      rowCount={_showTickets.length}
                    />
                    {_showTickets.length !== 0 ? (
                      <TableBody>
                        {stableSort(
                          _showTickets,
                          getComparator(this.state.order, this.state.orderBy)
                        )
                          .slice(
                            this.state.page * this.state.rowsPerPage,
                            this.state.page * this.state.rowsPerPage +
                              this.state.rowsPerPage
                          )
                          .map((item, i) => (
                            <TableRow key={i}>
                              <TableCell
                                component="th"
                                scope="row"
                                className={classes.subscriberTableRow}
                                align="center"
                              >
                                <Link
                                  to={{
                                    pathname: "/view-ticket",
                                    state: {
                                      subscriber_id: item.subscriber_id,
                                      ticketId: item.autoId,
                                      subject: item.subject,
                                      status: item.statusType,
                                      from: "ticket",
                                    },
                                  }}
                                >
                                  #{item.autoId + " " + item.subject}
                                </Link>
                              </TableCell>
                              <TableCell
                                className={classes.subscriberTableRow}
                                align="right"
                              >
                                {AppHelper_DateAndTimeToString(
                                  item.ticket_date
                                )}
                              </TableCell>
                              <TableCell
                                className={classes.subscriberTableRow}
                                align="center"
                              >
                                {item.status}
                              </TableCell>
                              <TableCell
                                className={classes.subscriberTableRow}
                                align="center"
                              >
                                <span className={this.getBadge(item.priority)}>
                                  {item.priority}
                                </span>
                              </TableCell>

                              <TableCell
                                className={classes.subscriberTableRow}
                                align="center"
                              >
                                {AppHelper_DateAndTimeToString(
                                  item.update_time
                                )}
                              </TableCell>
                              <TableCell
                                className={classes.subscriberTableRow}
                                align="center"
                              >
                                {item.company_name}
                              </TableCell>
                              <TableCell
                                className={classes.subscriberTableRow}
                                align="center"
                              >
                                {item.first_name
                                  ? item.first_name
                                  : "" + ", " + item.last_name
                                  ? item.last_name
                                  : ""}
                              </TableCell>
                              <TableCell
                                className={classes.subscriberTableRow}
                                align="center"
                              >
                                {this.commentButton(item)}
                                {item.status !== "Closed"
                                  ? this.serviceScheduleButton(item)
                                  : null}
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    ) : (
                      <TableRow align="center">
                        <TableCell />
                        <TableCell />
                        <TableCell />

                        <TableCell>
                          <Typography
                            style={{
                              textAlign: "center",
                              marginTop: "1em",
                              // marginLeft: "-6em",
                            }}
                          >
                            No Records...............
                          </Typography>
                        </TableCell>

                        <TableCell />
                        <TableCell />
                        <TableCell />
                      </TableRow>
                    )}
                  </Table>
                  <TablePagination
                    rowsPerPageOptions={[50, 200, 500]}
                    component="div"
                    count={_showTickets.length}
                    rowsPerPage={this.state.rowsPerPage}
                    page={this.state.page}
                    onChangePage={this.handleChangePage}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                  />
                </div>
              )}
            </Paper>
          </Grid>
          {/* </Paper> */}
        </Grid>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(TicketList);
