import { api } from "./API";

export const AuthHelperLogout = (param1) => {
  try {
    localStorage.removeItem("apiAuthAccessKey");
    localStorage.removeItem("apiAuthAccessId");
    localStorage.removeItem("pronto_auth");
  } catch (e) {}
};

export const AuthHelperAccessCheck = (res) => {
  if (res !== undefined) {
    if (res.error_code && res.error_code === 5001) {
      AuthHelperLogout(res);
    }
  }
};

export const AuthHelperValidateOverAPI = (param1) => {
  AuthHelperLogout(param1);
};

export const AuthHelperValidateOverToken = (param1) => {
  try {
    const token = localStorage.apiAuthAccessKey;
    // const token = localStorage.pronto_auth;
    // console.log("credential", token);

    if (token) {
      return true;
    } else {
      AuthHelperLogout(param1);
      return false;
    }
  } catch (e) {
    AuthHelperLogout(param1);
    return false;
  }
};

export const AuthHelperGerSubscriberRoleText = (param1) => {
  switch (param1) {
    case 1:
      return "Owner";
    case 2:
      return "Admin";
    default:
      return "User";
  }
};

export const AuthHelperGerSubscriberTypeText = (param1) => {
  switch (param1) {
    case 1:
      return "Basic";
    case 2:
      return "Premium";
    default:
      return "Basic";
  }
};

export const AuthHelperGerSubscriberLocationTypeText = (param1) => {
  switch (param1) {
    case 1:
      return "Shipping Address";
    case 2:
      return "Collection Point";
    default:
      return "Unknown";
  }
};
