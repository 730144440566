import React, { Component } from "react";
import Datetime from "react-datetime";
import Popup from "reactjs-popup";
import { Route, Link } from "react-router-dom";
import { Col, Row } from "reactstrap";

import NavBar from "../compon-styled/SupportCenterNavBar";
import InvoiceProduct from "./InvoiceProduct";
import { AuthHelperAccessCheck } from "../businessjs/AuthHelper";
import ToastAlert from "./ToastAlert";
import CmbSelect from "../compon-controls/CmbSelect";
import { AppHelper_DateAndTimeToString } from "../businessjs/AppHelperFn";
import { api } from "../businessjs/API";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import {
  Grid,
  Paper,
  TextField,
  Typography,
  MenuItem,
  Fab,
  TableCell,
  Table,
  TableContainer,
  TableRow,
  TableBody,
  TableHead,
  Tooltip,
  CircularProgress,
  TextareaAutosize,
} from "@material-ui/core";
import moment from "moment";
import { SnackBar } from "./Login/SnackBar";

const styles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));
const statusOptions = [
  { value: 0, label: "Draft" },
  { value: 1, label: "Payment Pending" },
  { value: 2, label: "Paid" },
  { value: 3, label: "Partially Paid" },
  { value: 4, label: "Cancelled" },
];
class Invoice extends Component {
  constructor(props) {
    super(props);
    let endDay = new Date();
    this.redirect = 0;
    this.state = {
      auto_id:
        this.props.location.state && this.props.location.state.invoice_id
          ? this.props.location.state.invoice_id
          : null,
      invoiceAddress: "",
      invoiceDate:
        this.props.location.state && this.props.location.state.invoice_date
          ? AppHelper_DateAndTimeToString(
              this.props.location.state.invoice_date
            )
          : new Date(),
      invoiceDueDate:
        this.props.location.state && this.props.location.state.invoice_due_date
          ? AppHelper_DateAndTimeToString(
              this.props.location.state.invoice_due_date
            )
          : endDay.setDate(endDay.getDate() + 7),
      subscriber_id: "",
      toastItems: [],
      product_items: [],
      status: statusOptions[0],
      selected_products: [],
      bundle_products: [],
      totalPrice: 0.0,
      discount: 0.0,
      price: 0.0,
      mrp: 0.0,
      isShowSaveFab: false,
      isShowAddFab: false,
      subscriberList: [],
      updatedInvoiceId: 0,
      showPDF:
        this.props.location.state && this.props.location.state.invoice_id
          ? "col"
          : "col hidden",
      caption:
        this.props.location.state && this.props.location.state.invoice_id
          ? "Edit"
          : "New",
      invoiceType: "",
      redirect: 0,
      errorMessage: "",
      color: "#d50000",
      isLoading: false,
      disableAdd: false,
      note:
        'Warranty Terms :\nFor term and conditions follow "Annexure A" document.',
      disableSelect: true,
    };
  }

  componentDidMount = () => {
    this.getSubscriberForCmb();

    this.getProduct(localStorage.apiCompanyId, localStorage.apiDivisionId);
    this.getBundleProducts(
      localStorage.apiCompanyId,
      localStorage.apiDivisionId
    );

    if (this.state.auto_id) {
      this.getInvoiceById(this.state.auto_id);
      this.setState({ isShowAddFab: true, isShowSaveFab: true });
    } else {
      this.setState({
        invoiceDate: new Date(),
        invoiceDueDate: new Date(),
      });
    }
  };

  getSubscriberForCmb = async () => {
    const subscribers = await api.post("sc-subscriber/get-all-for-cmb");
    if (subscribers && subscribers.userdata) {
      const subscriberId =
        this.props.location.state !== undefined
          ? this.props.location.state.subscriber_id
          : "";
      let subscriberArray = subscribers.userdata.map((opt) => ({
        label: opt.company_name,
        value: opt,
      }));

      if (this.state.auto_id) {
        this.setState({
          subscriberList: subscriberArray,
        });
      } else {
        this.getSubscriberProfile(subscriberArray[0].value.autoid);
        this.setState({
          subscriber_id: subscriberId
            ? this.getSubscriberType(subscriberId, subscriberArray)
            : subscriberArray[0],
          subscriberList: subscriberArray,
        });
      }
    } else {
      AuthHelperAccessCheck(subscribers);
    }
  };

  getSubscriberProfile = async (companyId) => {
    try {
      const getSubscriberInputs = {
        companyId: companyId,
      };
      const subscriberDetails = await api.post(
        "sc-subscriber/get-subscriber-profile-by-id",
        getSubscriberInputs
      );

      if (subscriberDetails.length !== 0) {
        this.setState({ invoiceAddress: subscriberDetails[0].address });
      } else {
        this.setState({ invoiceAddress: "" });
      }
    } catch (error) {
      console.log("something went wrong!", error);
    }
  };

  getSubscriberType(value, accountList) {
    if (accountList) {
      let filteredDataRows = accountList.filter((el) => {
        return el.value.autoid === value;
      })[0];

      return filteredDataRows;
    }
  }

  getProduct = async (_companyId, _divisionId) => {
    const _inputProduct = {
      company_id: _companyId,
      division_id: _divisionId,
    };

    const res = await api.post("sc-invoice/get-products-for-invoice");
    if (res && res.length > 0) {
      let _productArray = res.map((opt) => ({
        label: opt.product_name,
        value: opt,
      }));
      this.setState({
        product_items: _productArray,
      });
    } else {
      this.setState({
        product_items: [],
      });
      AuthHelperAccessCheck(res);
    }
  };

  getBundleProducts = async (_companyId, _divisionId) => {
    const _inputProduct = {
      company_id: _companyId,
      division_id: _divisionId,
    };

    const res = await api.post(
      "sc-bundle/get-all-bundle-products",
      _inputProduct
    );

    if (res && res.length > 0) {
      this.setState({
        bundle_products: res,
      });
    } else {
      this.setState({
        bundle_products: [],
      });
      AuthHelperAccessCheck(res);
    }
  };

  getInvoiceById = async (invoiceId) => {
    const _input = {
      invoice_id: invoiceId,
    };
    const invoiceById = await api.post("sc-invoice/get-invoice-by-id", _input);
    if (invoiceById) {
      if (invoiceById.invoice) {
        // console.log("invoice status",invoiceById.invoice.status)
        if (invoiceById.invoice.status === 0) {
          this.setState({ disableSelect: false });
        }
        this.setState({
          autoid: invoiceById.invoice.autoid,
          invoiceAddress: invoiceById.invoice.invoice_address,
          note: invoiceById.invoice.note,
          discount: invoiceById.invoice.discount,
          status: statusOptions[invoiceById.invoice.status],
          invoiceDate: invoiceById.invoice.invoice_date,
          invoiceDueDate: invoiceById.invoice.invoice_due_date,
          company_id: invoiceById.invoice.company_id,
          subscriber_id: {
            label: invoiceById.invoice.company_name,
            value: {
              autoid: invoiceById.invoice.subscriber_id,
              company_name: invoiceById.invoice.company_name,
              account_id: invoiceById.invoice.account_id,
            },
          },
          mrp: invoiceById.invoice.total_amount,
          invoiceType: invoiceById.invoice.invoice_type,
          disableAdd: invoiceById.invoice.status === 0 ? false : true,
        });
      }
      if (invoiceById.invoiceSet && invoiceById.invoiceSet.length) {
        let bundleSet = [];
        if (invoiceById.bundleSet && invoiceById.bundleSet.length) {
          bundleSet = invoiceById.bundleSet;
        }
        let serialNumArray = [];
        if (invoiceById.serialNoSet && invoiceById.serialNoSet.length) {
          serialNumArray = invoiceById.serialNoSet;
        }
        let invoice_items = invoiceById.invoiceSet.map((p) => ({
          autoid: p.product_id,
          price: p.price,
          quantity: p.quantity,
          mrp: (p.price * p.quantity).toFixed(2),
          total:
            p.price * p.quantity - (p.price * p.quantity * p.discount) / 100,
          status: p.status,
          product_name: p.product_name,
          bundle_name: p.bundle_name,
          discount: p.discount,
          bundle_id: p.bundle_id,
          bundle_items:
            bundleSet && bundleSet.length
              ? bundleSet.filter((pro) => pro.bundle_id === p.bundle_id)
              : null,
          serialNumberFinalArray: serialNumArray,
          serialization: p.serialization,
        }));

        let total = Object.keys(invoice_items).reduce((previous, key) => {
          return (previous += parseFloat(invoice_items[key].total));
        }, 0);

        this.getFilteredInvoice(invoice_items);
        this.setState({
          selected_products: invoice_items,
          totalPrice: total,
          isShowSaveFab: false,
          isShowAddFab: false,
        });
      }
    } else {
      AuthHelperAccessCheck(invoiceById);
    }
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    // this.getSubscriberProfile(e.target.value.value.autoid);
    if (e.target.name === "status") {
      if (e.target.value.value !== 0) {
        this.setState({ disableAdd: true });
      } else {
        // console.log("value status")
        this.setState({ disableAdd: false });
      }
    }
  };

  onSubscriberChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    this.getSubscriberProfile(e.target.value.value.autoid);
  };
  onNoteChange = (value) => {
    this.setState({ note: value });
  };
  onDateChange = (e) => {
    // console.log("date", e);
    this.setState({ invoiceDate: e });
  };

  onDueDateChange = (e) => {
    // console.log("dueDate", e);
    this.setState({ invoiceDueDate: e });
  };

  productAdd = (products) => {
    // console.log("products", products);
    if (products) {
      let total = Object.keys(products).reduce((previous, key) => {
        return (previous += parseFloat(products[key].total));
      }, 0);

      let mrpTotal = Object.keys(products).reduce((previous, key) => {
        return (previous += parseFloat(products[key].mrp));
      }, 0);
      this.setState({
        selected_products: products,
        mrp: mrpTotal.toFixed(2), //  original price without discount.
        totalPrice: total.toFixed(2),
      });
      this.getFilteredInvoice(products);
    }
  };

  getFilteredInvoice = (invoice) => {
    let filtered = this.state.product_items.filter((item) =>
      invoice.every((invoice) => {
        return invoice.autoid !== item.value.autoid;
      })
    );
    this.setState({
      product_items: filtered,
    });
  };

  invoiceXML = () => {
    const { selected_products } = this.state;
    // console.log("selectedProducts", selected_products);
    let xmlStr = "";
    if (selected_products && selected_products.length) {
      xmlStr += "<Invoices>";
      selected_products.forEach(function (value) {
        xmlStr += '<Invoice product_id="' + value.autoid + '"  ';
        xmlStr += 'price="' + value.price + '" ';
        xmlStr += 'quantity="' + value.quantity + '" ';
        xmlStr += 'status="' + value.status + '" ';
        xmlStr += 'discount="' + value.discount + '" ';
        xmlStr += 'serialization="' + value.serialization + '" />';
      });
      xmlStr += "</Invoices>";
    }

    return xmlStr;
  };

  serialXML = () => {
    const { selected_products } = this.state;
    let serialArray = [];
    selected_products.map((product) =>
      product.serialNumberFinalArray.length > 0
        ? product.serialNumberFinalArray.map((serial) =>
            serialArray.push(serial)
          )
        : ""
    );
    serialArray = [...new Set(serialArray)];
    // console.log("serialArray", serialArray);
    let xmlStr = "";
    if (serialArray && serialArray.length > 0) {
      xmlStr += "<Serializations>";
      serialArray.forEach((value) => {
        xmlStr += '<Serialization product_id="' + value.product_id + '"  ';
        xmlStr += 'stock_id="' + value.product_stock_id + '" ';
        xmlStr += 'serial_no="' + value.serial_no + '"/> ';
      });
      xmlStr += "</Serializations>";
    }

    return xmlStr;
  };

  deleteButton = (_product) => {
    const DeleteButton = () => (
      <Route
        render={({ history }) => (
          <Tooltip title="Delete">
            <button
              type="button"
              className="btn btn-link btn-sm"
              // title="Remove"
              onClick={() => {
                this.removeProduct(_product);
              }}
            >
              <DeleteIcon color="primary" />
              {/* <i className="fa fa-remove" aria-hidden="true" /> */}
            </button>
          </Tooltip>
        )}
      />
    );
    return <DeleteButton />;
  };

  editButton = (_product) => {
    const EditButton = () => (
      <Route
        render={({ history }) => (
          <Popup
            trigger={
              <Tooltip title="Edit">
                <button type="button" className="btn btn-link btn-sm">
                  <EditIcon color="primary" />
                  {/* <i className="fa fa-pencil" aria-hidden="true" /> */}
                </button>
              </Tooltip>
            }
            modal
            closeOnDocumentClick
          >
            {(close) => (
              <InvoiceProduct
                products={this.state.product_items}
                bundleProducts={this.state.bundle_products} // taking products from a bundle product
                selectedProducts={this.state.selected_products}
                currentProduct={_product}
                save={this.productAdd}
                cancel={close}
              />
            )}
          </Popup>
        )}
      />
    );
    return <EditButton />;
  };

  removeProduct = (product) => {
    const newFiltedItems = this.state.selected_products.filter(
      (c) => c.autoid !== product.autoid
    );

    this.setState({
      selected_products: newFiltedItems,
    });
    this.productAdd(newFiltedItems);
  };

  handleToastItemClose = (itemObject) => {
    const toastItems = this.state.toastItems.filter(
      (c) => c.id !== itemObject.id
    );

    if (this.redirect == 1) {
      this.setState({
        redirect: 1,
        toastItems: toastItems,
      });
    } else {
      this.setState({ toastItems });
    }
  };

  onToastItemAdd(type, message) {
    let newid = this.state.toastItems.length + 1;
    if (
      this.state.toastItems.filter(function (e) {
        return e.id === newid;
      }).length > 0
    ) {
      newid = this.state.toastItems.length + Math.floor(Math.random() * 100);
    }

    let newToastItems = this.state.toastItems;
    let itemToast = {
      id: newid,
      type: type === "success" ? "success" : "danger",
      message: message,
    };

    newToastItems.push(itemToast);
    this.setState({ toastItems: newToastItems });

    setTimeout(() => {
      this.handleToastItemClose(itemToast);
    }, 2000);
  }

  onSubmit = async (e) => {
    e.preventDefault();
    this.setState({ isShowSaveFab: true, isLoading: true });
    if (!this.validate()) return;
    // console.log("this.state.subscriber_id", this.state.subscriber_id);
    const _invoiceProduct = {
      subscriber_id: this.state.subscriber_id.value.autoid,
      company_id: localStorage.apiCompanyId,
      accountId: this.state.subscriber_id.value.account_id,
      address: this.state.invoiceAddress,
      invoice_date: moment(this.state.invoiceDate).format("YYYY-MM-DD"),
      invoice_due_date: moment(this.state.invoiceDueDate)
        .add(7, "days")
        .format("YYYY-MM-DD"),
      mrp: this.state.totalPrice,
      discount: this.state.discount,
      price: this.state.totalPrice,
      status: this.state.status.value,
      note: this.state.note,
      invoice: this.invoiceXML(),
      serializationItems: this.serialXML(),
    };
    // console.log("invoice input", _invoiceProduct);
    if (this.state.autoid) {
      //edit
      _invoiceProduct.auto_id = this.state.autoid;
      const invoice = await api.post(
        "sc-invoice/edit-invoice",
        _invoiceProduct
      );
      if (invoice) {
        AuthHelperAccessCheck(invoice);
        if (invoice.status === "success") {
          this.setState({
            auto_id: invoice.invoice_id,
            isLoading: false,
            errorMessage: "Successfully Updated..",
            color: "#1b5e20",
          });
          this.onToastItemAdd(invoice.status, invoice.message);
          setTimeout(() => {
            this.setState({ showPDF: "col" });
            this.props.history.push({
              pathname: `/invoice-list`,
            });
          }, 2000);
        } else {
          this.onToastItemAdd(invoice.status, invoice.message);
          this.setState({ isLoading: false });
        }
      }
    } else {
      // console.log("_invoiceProduct", _invoiceProduct);
      const invoice = await api.post("sc-invoice/add-invoice", _invoiceProduct);
      if (invoice) {
        this.setState({
          autoid: invoice.invoice_id,
        });
        AuthHelperAccessCheck(invoice);
        if (invoice.status === "success") {
          this.setState({
            auto_id: invoice.invoice_id,
            errorMessage: "Successfully Updated..",
            color: "#1b5e20",
          });
          this.onToastItemAdd(invoice.status, invoice.message);
          setTimeout(() => {
            this.setState({ showPDF: "col", isLoading: false });
            this.props.history.push({
              pathname: `/invoice-bill`,
              search: `?id=${invoice.invoice_id}`,
              state: {
                products: this.state.selected_products,
                // serializationExist:this.state.isSerialNumbersExist
              },
            });
          }, 2000);
        } else {
          this.onToastItemAdd(invoice.status, invoice.message);
          this.setState({ isLoading: false });
        }
      }
    }
  };

  validate = () => {
    let value = true;
    this.setState({ errorMessage: "" });
    if (this.state.selected_products.length === 0) {
      this.setState({
        errorMessage: "Please select product.",
        isLoading: false,
        isShowSaveFab: false,
      });
      value = false;
    }

    return value;
  };

  cancelButton = () => {
    const CancelButton = () => (
      <Route
        render={({ history }) => (
          <Fab
            variant="extended"
            color="primary"
            size="small"
            onClick={() => {
              this.cancelInvoice();
            }}
          >
            Cancel Invoice
          </Fab>
        )}
      />
    );
    return <CancelButton />;
  };

  cancelInvoice = async () => {
    const _cancelInvoiceInputs = {
      subscriber_id: this.state.subscriber_id.value.autoid,
      company_id: this.state.company_id,
      invoice_id: this.state.auto_id,
      invoice_type: this.state.invoiceType,
    };
    const invoice = await api.post(
      "sc-invoice/cancel-invoice",
      _cancelInvoiceInputs
    );
    if (invoice.status === "success") {
      this.redirect = 1;
      this.onToastItemAdd(invoice.status, invoice.message);
    } else {
      AuthHelperAccessCheck(invoice);
    }
  };
  onCloseSnackbar = () => {
    this.setState({ errorMessage: "" });
  };
  render() {
    if (
      this.state.redirect === 1 &&
      this.props.location.state.from === "invoice"
    ) {
      this.props.history.push({
        pathname: `/invoice-list`,
      });
    } else if (
      this.state.redirect === 1 &&
      this.props.location.state.from === "account"
    ) {
      this.props.history.push({
        pathname: `/account-details`,
        state: {
          companyId: this.state.subscriber_id.value.autoid,
        },
      });
    }
    let _showProduct = this.state.selected_products;
    const {
      errorMessage,
      color,
      isShowAddFab,
      isShowSaveFab,
      isLoading,
      disableAdd,
      disableSelect,
    } = this.state;
    // console.log("disableSelect", this.state.status.value);
    let disabled = disableSelect;
    if (this.state.status.value !== 0) {
      disabled = false;
    } else {
      disabled = true;
    }
    const { classes } = this.props;
    const texttValue = this.state.note;
    return (
      <React.Fragment>
        <SnackBar
          status={errorMessage}
          onCloseError={this.onCloseSnackbar}
          color={color}
        />
        <Paper className={classes.paper}>
          <Grid
            container
            spacing={3}
            justify="center"
            style={{ padding: "2em" }}
          >
            <Grid item xs={12} style={{ paddingBottom: "2em" }}>
              <Typography variant="h4" style={{ fontWeight: "bold" }}>
                {this.state.caption} Invoice
              </Typography>
            </Grid>
            <Grid item xs={6} sm={4}>
              <Typography
                variant="h6"
                style={{ textAlign: "left", fontWeight: "bold" }}
              >
                Subscriber
              </Typography>
              <CmbSelect
                name="subscriber_id"
                value={this.state.subscriber_id}
                // onChange={this.onChange}
                onChange={this.onSubscriberChange}
                dataSource={this.state.subscriberList}
              />
            </Grid>
            <Grid item xs={6} sm={4}>
              <Typography
                variant="h6"
                style={{ textAlign: "left", fontWeight: "bold" }}
              >
                Address
              </Typography>
              <TextField
                fullWidth
                variant="outlined"
                type="text"
                name="invoiceAddress"
                required
                disabled
                value={this.state.invoiceAddress}
                onChange={this.onChange}
              />
            </Grid>
            <Grid item xs={6} sm={4}>
              <Typography
                variant="h6"
                style={{ textAlign: "left", fontWeight: "bold" }}
              >
                Status
              </Typography>
              <CmbSelect
                isDisabled={disabled}
                name="status"
                value={this.state.status}
                onChange={this.onChange}
                dataSource={statusOptions}
                isOptionDisabled={(option) => option.value === 0}
              />
              {/* <TextField
                required
                fullWidth
                variant="outlined"
                select
                label="Status"
                name="status"
                onChange={this.onChange}
                value={this.state.status}
              >
                {statusOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField> */}
            </Grid>
            <Grid item xs={6} sm={4}>
              <Typography
                variant="h6"
                style={{ textAlign: "left", fontWeight: "bold" }}
              >
                Invoice Date
              </Typography>
              <TextField
                type="date"
                fullWidth
                variant="outlined"
                name="invoiceDate"
                required
                // defaultValue={this.state.invoiceDate}
                value={moment(this.state.invoiceDate).format("YYYY-MM-DD")}
                onChange={(event) => this.onDateChange(event.target.value)}
              />
            </Grid>
            <Grid item xs={6} sm={4}>
              <Typography
                variant="h6"
                style={{ textAlign: "left", fontWeight: "bold" }}
              >
                Invoice Due Date
              </Typography>
              <TextField
                type="date"
                fullWidth
                variant="outlined"
                name="invoiceDueDate"
                required
                // defaultValue={this.state.invoiceDueDate}
                value={moment(this.state.invoiceDueDate)
                  .add(7, "days")
                  .format("YYYY-MM-DD")}
                onChange={(event) => this.onDueDateChange(event.target.value)}
              />
            </Grid>
            <Grid item xs={6} sm={4}>
              {/* <Paper className={classes.paper}>xs=6 sm=3</Paper> */}
            </Grid>
            <Grid item xs={12} style={{ textAlign: "left" }}>
              <Popup
                trigger={
                  <Fab
                    variant="extended"
                    size="medium"
                    color="primary"
                    aria-label="add"
                    disabled={isShowAddFab || disableAdd}
                    // type="submit"
                    // onClick={this.onAddProduct}
                  >
                    Add Product
                  </Fab>
                }
                modal
                closeOnDocumentClick
              >
                {(close) => (
                  <InvoiceProduct
                    products={this.state.product_items}
                    bundleProducts={this.state.bundle_products} // taking products from a bundle product
                    selectedProducts={this.state.selected_products}
                    save={this.productAdd}
                    cancel={close}
                    divisionSet={this.state.divisionList}
                  />
                )}
              </Popup>
            </Grid>
            <Grid item xs={12}>
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Quantity</TableCell>
                      <TableCell align="center">Description</TableCell>
                      <TableCell align="right">Unit Price (QR)</TableCell>
                      {/* <TableCell align="right">Discount(%)</TableCell> */}
                      <TableCell align="right">Total Price (QR)</TableCell>
                      <TableCell
                        style={{ display: disableAdd ? "none" : "" }}
                      ></TableCell>
                      <TableCell
                        style={{ display: disableAdd ? "none" : "" }}
                      ></TableCell>
                    </TableRow>
                  </TableHead>
                  {_showProduct.length ? (
                    <TableBody>
                      {_showProduct
                        ? _showProduct.map((_product) => (
                            <React.Fragment>
                              <TableRow key={_product.product_name}>
                                <TableCell align="center">
                                  {_product.quantity}
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  align="center"
                                >
                                  {_product.product_name}
                                </TableCell>
                                <TableCell align="right">
                                  {_product.price}
                                </TableCell>
                                {/* <TableCell align="right">{row.discount}</TableCell> */}
                                <TableCell align="right">
                                  {parseFloat(_product.total).toFixed(2)}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  style={{ display: disableAdd ? "none" : "" }}
                                >
                                  {this.editButton(_product)}

                                  {this.deleteButton(_product)}
                                </TableCell>
                              </TableRow>

                              {_product.bundle_items &&
                              _product.bundle_items.length
                                ? _product.bundle_items.map((_item, j) => (
                                    <React.Fragment key={j}>
                                      <TableRow
                                        style={{
                                          width: "100%",
                                          fontSize: "13px",
                                          fontStyle: "italic",
                                        }}
                                      >
                                        <TableCell></TableCell>
                                        <TableCell align="center">
                                          {_item.product_name}
                                        </TableCell>
                                        <TableCell align="right">
                                          {_item.price}
                                        </TableCell>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                        {/* <TableCell></TableCell> */}
                                      </TableRow>
                                    </React.Fragment>
                                  ))
                                : ""}
                            </React.Fragment>
                          ))
                        : null}
                    </TableBody>
                  ) : (
                    <TableBody>
                      {" "}
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell align="center"></TableCell>
                        <TableCell align="center">
                          <Typography variant="h5">
                            No Products Selected....
                          </Typography>
                        </TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        {/* <TableCell></TableCell> */}
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Grid>
            <Grid item xs={12}>
              <Row>
                <Col xl={8}></Col>
                <Col xl={4}>
                  <div>
                    {_showProduct.length ? (
                      <div style={{ textAlign: "end" }}>
                        <Row>
                          <Col xl={6}>
                            <label>Discount(Qr) : </label>
                          </Col>
                          <Col xl={6}>
                            <input
                              type="text"
                              style={{
                                width: "100%",
                                textAlign: "end",
                              }}
                              name="discount"
                              value={this.state.discount}
                              onChange={this.onChange}
                              disabled={disableAdd}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col xl={6}>
                            <label>Total(Qr) : </label>
                          </Col>
                          <Col xl={6}>
                            {" "}
                            <label>
                              {(
                                this.state.totalPrice - this.state.discount
                              ).toFixed(2)}{" "}
                            </label>
                          </Col>
                        </Row>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </Col>
              </Row>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="h6"
                style={{ textAlign: "left", fontWeight: "bold" }}
              >
                Note
              </Typography>
              <TextField
                fullWidth
                multiline
                rows={3}
                rowsMax={10}
                variant="outlined"
                type="text"
                name="note"
                value={this.state.note}
                onChange={(event) => this.onNoteChange(event.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <Row className="mt-4">
                <Col>
                  <Row>
                    <Col xl={1}>
                      <Fab
                        variant="extended"
                        color="primary"
                        size="small"
                        disabled={isShowSaveFab}
                        // type="button"
                        // className="btn btn-primary btn-sm"
                        onClick={this.onSubmit}
                      >
                        Save
                      </Fab>
                      {isLoading && (
                        <CircularProgress
                          size={24}
                          style={{
                            position: "absolute",
                            left: "52%",
                            bottom: "20%",
                          }}
                        />
                      )}
                    </Col>
                    <Col xl={2} className="mt-2">
                      <Link
                        className={this.state.showPDF}
                        to={{
                          pathname: "/invoice-bill?id=" + this.state.auto_id,
                        }}
                        target="_blank"
                      >
                        View PDF
                      </Link>
                    </Col>
                    <Col>
                      {/* <span className="text-danger">
                        {this.state.errorMessage}
                      </span> */}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Grid>
          </Grid>
        </Paper>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(Invoice);
