import React, { Component } from "react";
import { Route } from "react-router-dom";
import { RiseLoader } from "react-spinners";
import { css } from "@emotion/core";
import Datetime from "react-datetime";
import { Col, Row } from "reactstrap";

import SubscriberSelect from "../compon-controls/SubscriberSelect";
import NavBar from "../compon-styled/SupportCenterNavBar";
import { AuthHelperAccessCheck } from "../businessjs/AuthHelper";

import { AppHelper_DateAndTimeToString } from "../businessjs/AppHelperFn";
import ToastAlert from "./ToastAlert";
import { api } from "../businessjs/API";

const loaderCSS = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

class JobCardInvoice extends Component {
  constructor(props) {
    super(props);

    this.show_process = 1;
    this.show_invoice = 0;
  }

  state = {
    jobcards: [],
    products: [],
    visits: [],
    subscriber_id: this.props.location.state.subscriber_id,
    jc_total: 0.0,
    invoiceAddress: "",
    invoiceDate: new Date(),
    invoiceDueDate: new Date(),
    status: 1,
    discount: 0.0,
    total: 0.0,
    toastItems: [],
    invoice_id: 0,
    loading: false,
    errorMessage: ""
  };

  componentDidMount() {
    this.getJobCardInvoice();
  }

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onDiscountChange = e => {
    this.setState({
      [e.target.name]: e.target.value,
      total: (this.state.jc_total - e.target.value).toFixed(2)
    });
  };

  processJobCards = () => {
    const { jobcards, products, visits } = this.state;
    let processed_jc = [];
    let _total = 0.0;

    if (products && visits) {
      this.setState({
        loading: true
      });

      products.forEach(product => {
        visits.forEach(visit => {
          if (jobcards) {
            const result = jobcards.filter(
              row =>
                row.service_product_id === product.service_product_id &&
                row.visit_id === visit.visit_id
            );

            if (result && result.length > 0) {
              let count = result[0].max_services_per_visit;

              if (result.length > count) {
                let i = 0;
                result.forEach(jc => {
                  if (i >= count) {
                    jc.price = jc.additional_per_unit_price;
                    _total =
                      Number(_total) + Number(jc.additional_per_unit_price);
                  }
                  i++;
                });
              }
              processed_jc.push.apply(processed_jc, result);
            }
          }
        });
      });

      this.setState({
        jobcards: processed_jc,
        jc_total: _total.toFixed(2),
        total: (_total - this.state.discount).toFixed(2),
        loading: false
      });

      this.show_invoice = 1;
    }
  };

  invoiceXML = () => {
    const { jobcards } = this.state;

    let xmlStr = "";
    if (jobcards && jobcards.length) {
      xmlStr += "<Invoices>";
      jobcards.forEach(function(value) {
        xmlStr += '<Invoice job_card_id="' + value.autoid + '"  ';
        xmlStr += 'price="' + value.price + '" ';
        xmlStr += 'status="' + 1 + '" />';
      });
      xmlStr += "</Invoices>";
    }

    return xmlStr;
  };

  saveInvoice = async () => {
    if (!this.validate()) return;
    this.setState({
      loading: true
    });

    const _invoiceProduct = {
      subscriber_id: this.state.subscriber_id.value.autoid,
      company_id: localStorage.apiCompanyId,
      address: this.state.invoiceAddress,
      invoice_date: this.state.invoiceDate,
      invoice_due_date: this.state.invoiceDueDate,
      sub_total: this.state.jc_total,
      discount: this.state.discount,
      total: this.state.jc_total - this.state.discount,
      status: this.state.status,
      jobcards: this.invoiceXML(),
      invoice_type: 2 // 1 = product invoice .... 2 = jobcard invoice
    };

    const jobCardInvoice = await api.post(
      "sc-invoice/add-job-card-invoice",
      _invoiceProduct
    );
    if (jobCardInvoice) {
      AuthHelperAccessCheck(jobCardInvoice);
      if (jobCardInvoice.status === "success") {
        this.setState({
          invoice_id: jobCardInvoice.invoice_id,
          loading: false
        });

        this.show_invoice = 0;
        this.show_process = 0;
        window.open(
          "/job-card-invoice-bill?id=" +
            jobCardInvoice.invoice_id +
            "&sub_id=" +
            this.state.subscriber_id.value.autoid,
          "_blank"
        );

        this.onToastItemAdd(jobCardInvoice.status, jobCardInvoice.message);
      } else {
        this.setState({ loading: false });
        this.onToastItemAdd(jobCardInvoice.status, jobCardInvoice.message);
      }
    }
  };

  validate = () => {
    let value = true;
    if (this.state.invoiceAddress === "") {
      this.setState({
        errorMessage: "Please Enter Address."
      });
      value = false;
    }
    return value;
  };

  getJobCardInvoice = async () => {
    this.setState({ loading: true });
    const _input = {
      subscriber_id: this.state.subscriber_id.value.autoid
    };

    const res = await api.post("job-card/get-unbilled-jobcards", _input);

    if (res) {
      this.setState({
        jobcards: res.jobcards,
        products: res.products,
        visits: res.visits,
        loading: false
      });
    } else {
      this.setState({ loading: false });
    }
  };

  process = () => {
    const ProcessButton = () => (
      <Route
        render={({ history }) => (
          <button
            type="button"
            className="btn btn-primary ml-2"
            onClick={() => {
              this.processJobCards();
            }}
          >
            Process
          </button>
        )}
      />
    );
    return <ProcessButton />;
  };

  invoice = () => {
    const InvoiceButton = () => (
      <Route
        render={({ history }) => (
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => {
              this.saveInvoice();
            }}
          >
            Invoice
          </button>
        )}
      />
    );
    return <InvoiceButton />;
  };

  invoicePDF = () => {
    const PDFButton = () => (
      <Route
        render={({ history }) => (
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => {
              alert("PDF");
            }}
          >
            Invoice PDF
          </button>
        )}
      />
    );
    return <PDFButton />;
  };

  handleToastItemClose = itemObject => {
    const toastItems = this.state.toastItems.filter(
      c => c.id !== itemObject.id
    );

    this.setState({ toastItems });
  };

  onToastItemAdd(type, message) {
    let newid = this.state.toastItems.length + 1;
    if (
      this.state.toastItems.filter(function(e) {
        return e.id === newid;
      }).length > 0
    ) {
      newid = this.state.toastItems.length + Math.floor(Math.random() * 100);
    }

    let newToastItems = this.state.toastItems;
    let itemToast = {
      id: newid,
      type: type === "success" ? "success" : "danger",
      message: message
    };

    newToastItems.push(itemToast);
    this.setState({ toastItems: newToastItems });

    setTimeout(() => {
      this.handleToastItemClose(itemToast);
    }, 2000);
  }

  render() {
    const { jobcards } = this.state;
    return (
      <React.Fragment>
        {/* <NavBar /> */}
        <div className="container mt-4 mb-2">
          <div className="card">
            <ToastAlert
              toastItems={this.state.toastItems}
              onItemClose={this.handleToastItemClose}
            />
            <div className="card-header">
              <Row>
                <Col xl={8} lg={7} md={12} sm={12} xs={12}>
                  <h3>Job Card Invoice</h3>
                </Col>
                {this.show_process === 1 ? (
                  <Col xl={1} lg={2} md={2} sm={3} xs={3}>
                    {this.process()}
                  </Col>
                ) : (
                  ""
                )}
                {this.show_invoice === 1 ? (
                  <Col xl={1} lg={1} md={2} sm={3} xs={3}>
                    {this.invoice()}
                  </Col>
                ) : (
                  ""
                )}

                <Col xl={1} lg={2} md={6} sm={3} xs={3}>
                  <div className="sweet-loading">
                    <RiseLoader
                      css={loaderCSS}
                      sizeUnit={"px"}
                      size={10}
                      color={"#123abc"}
                      loading={this.state.loading}
                    />
                  </div>
                </Col>
              </Row>
            </div>

            <div className="card-content collpase show">
              <div className="card-body">
                <div className="form-body">
                  <Row>
                    <Col>
                      <Row>
                        <Col xl={2}>
                          <label>Client</label>
                        </Col>
                        <Col xl={4}>
                          <SubscriberSelect
                            name="subscriber_id"
                            value={this.state.subscriber_id}
                            onChange={this.onChange}
                          />
                        </Col>
                        <Col xl={1} />
                        <Col xl={2}>
                          <label>Invoice Date</label>
                        </Col>
                        <Col x={3}>
                          <Datetime
                            defaultValue={this.state.invoiceDate}
                            input={true}
                            closeOnSelect={true}
                            onChange={this.onDateChange}
                          />
                        </Col>
                      </Row>

                      <Row className="mt-2">
                        <Col xl={2}>
                          <label>Address</label>
                        </Col>
                        <Col xl={4}>
                          <input
                            type="text"
                            className="form-control"
                            name="invoiceAddress"
                            required
                            value={this.state.invoiceAddress}
                            onChange={this.onChange}
                          />
                        </Col>
                        <Col xl={1} />
                        <Col xl={2}>
                          <label>Invoice Due Date</label>
                        </Col>
                        <Col x={3}>
                          <Datetime
                            defaultValue={this.state.invoiceDueDate}
                            input={true}
                            closeOnSelect={true}
                            onChange={this.onDateChange}
                          />
                        </Col>
                      </Row>

                      <Row className="mt-2">
                        <Col xl={7} />
                        <Col xl={2}>
                          <label>Status</label>
                        </Col>
                        <Col x={3}>
                          <select
                            className="form-control"
                            name="status"
                            onChange={this.onChange}
                            value={this.state.status}
                            required
                          >
                            <option value="1">Completed</option>
                            <option value="2">Pending</option>
                            <option value="3">Cancelled</option>
                          </select>
                        </Col>
                      </Row>

                      <Row>
                        <Col>
                          <span className="text-danger">
                            {this.state.errorMessage}
                          </span>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </div>

          <Row className="mt-2">
            <Col>
              <div className="card">
                <div className="table-responsive">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th>Title</th>
                        <th>Date</th>
                        <th>Type</th>
                        <th>Location</th>
                        <th>Ticket</th>
                        <th>Visit</th>
                        <th>Visit Date</th>
                        <th>Used Service</th>
                        <th style={{ textAlign: "right" }}>price</th>
                      </tr>
                    </thead>
                    <tbody>
                      {jobcards.map((_jobcard, i) => (
                        <React.Fragment key={i}>
                          <tr>
                            <td>{_jobcard.title}</td>
                            <td>
                              {AppHelper_DateAndTimeToString(_jobcard.job_date)}
                            </td>
                            <td>{_jobcard.type}</td>
                            <td>{_jobcard.location_name}</td>
                            <td>{_jobcard.subject}</td>
                            <td>{_jobcard.name}</td>
                            <td>
                              {AppHelper_DateAndTimeToString(
                                _jobcard.visit_date
                              )}
                            </td>
                            <td>{_jobcard.product_name}</td>
                            <td style={{ textAlign: "right" }}>
                              {_jobcard.price}
                            </td>
                          </tr>
                        </React.Fragment>
                      ))}
                    </tbody>
                  </table>
                  <center>
                    {jobcards.length ? "" : "Currently No Job cards"}
                  </center>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="mt-2">
            <Col>
              <Row>
                <Col xl={9} />
                <Col xl={1}>
                  <label>SubTotal</label>
                </Col>
                <Col xl={2}>
                  <input
                    type="text"
                    className="form-control"
                    name="title"
                    required
                    value={this.state.jc_total}
                    onChange={this.onChange}
                    style={{ color: "black" }}
                    readOnly={true}
                  />
                </Col>
              </Row>

              <Row>
                <Col xl={9} />
                <Col xl={1}>
                  <label>Discount</label>
                </Col>
                <Col xl={2}>
                  <input
                    type="number"
                    className="form-control"
                    name="discount"
                    required
                    value={this.state.discount}
                    onChange={this.onDiscountChange}
                  />
                </Col>
              </Row>

              <Row>
                <Col xl={9} />
                <Col xl={1}>
                  <label>Total</label>
                </Col>
                <Col xl={2}>
                  <input
                    type="text"
                    className="form-control"
                    name="total"
                    required
                    value={this.state.total}
                    onChange={this.onChange}
                    style={{ color: "black" }}
                    readOnly={true}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </React.Fragment>
    );
  }
}

export default JobCardInvoice;
