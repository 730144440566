import React, { Component } from "react";
import { Col, Row } from "reactstrap";

import NavBar from "../compon-styled/SupportCenterNavBar";
import { AuthHelperAccessCheck } from "../businessjs/AuthHelper";
import ToastAlert from "./ToastAlert";
import BundleProduct from "./BundleProduct";
import Popup from "reactjs-popup";
import { Route, Redirect } from "react-router-dom";
import { api } from "../businessjs/API";

class Bundle extends Component {
  constructor(props) {
    super(props);
    this.redirect = 0;

    this.state = {
      autoid:
        this.props.location.state && this.props.location.state.bundle_id
          ? this.props.location.state.bundle_id
          : null,
      bundle_name: "",
      price: 0.0,
      original_price: 0.0,
      status: 1,
      division: "",
      toastItems: [],
      product_items: [],
      selected_products: [],
      redirect: 0,
      errorMessage: ""
    };
  }
  componentDidMount = () => {
    this.getProduct(localStorage.apiCompanyId, localStorage.apiDivisionId);
    if (this.state.autoid) {
      this.BundleById(this.state.autoid);
    }
  };

  BundleById = async budleId => {
    const _input = {
      bundle_id: budleId
    };
    // this method return two recordset
    //1. product bundle details
    //2. product bundle items details (products)

    const res = await api.post("sc-bundle/get-bundle-by-id", _input);
    if (res) {
      if (res.bundle) {
        //set the values of product bundle
        this.setState({
          autoid: res.bundle.autoid,
          bundle_name: res.bundle.bundle_name,
          price: res.bundle.price,
          original_price: res.bundle.original_price,
          status: res.bundle.status
        });
      }
      if (res.productSet && res.productSet.length) {
        //set the values of products
        let product_items = res.productSet.map(p => ({
          autoid: p.product_id,
          product_name: p.product_name,
          price: p.price,
          original_price: p.original_price,
          quantity: p.product_quantity,
          total: (p.price * p.product_quantity).toFixed(2),
          original_total: (p.original_price * p.product_quantity).toFixed(2),
          status: p.status
        }));

        this.getFilteredProduct(product_items);
        this.setState({
          selected_products: product_items
        });
      }
    } else {
      AuthHelperAccessCheck(res);
    }
  };

  getProduct = async (_companyId, _divisionId) => {
    const _inputProduct = {
      company_id: _companyId,
      division_id: _divisionId
    };

    const res = await api.post(
      "sc-product/get-cmb-product-list",
      _inputProduct
    );
    if (res && res.length) {
      let ProductArray = res.map(opt => ({
        label: opt.product_name,
        value: opt
      }));

      this.setState({
        product_items: ProductArray
      });
    } else {
      this.setState({
        product_items: []
      });
      AuthHelperAccessCheck(res);
    }
  };

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  productAdd = products => {
    if (products) {
      this.getFilteredProduct(products);
      this.setState({
        selected_products: products
      });

      this.calculateTotal(products);
    }
  };

  getFilteredProduct = products => {
    let filtered = this.state.product_items.filter(item =>
      products.every(product => {
        return product.autoid !== item.value.autoid;
      })
    );
    this.setState({
      product_items: filtered
    });
  };

  calculateTotal = products => {
    let price_total = 0;
    let original_total = 0;

    products.forEach(function(element) {
      price_total += element.price * element.quantity;
      original_total += element.original_price * element.quantity;
    });

    this.setState({
      price: price_total.toFixed(2),
      original_price: original_total.toFixed(2)
    });
  };

  editButton = _product => {
    const EditButton = () => (
      <Route
        render={({ history }) => (
          <Popup
            trigger={
              <button type="button" className="btn btn-link btn-sm">
                <i className="fa fa-pencil" aria-hidden="true" />
              </button>
            }
            modal
            closeOnDocumentClick
          >
            {close => (
              <BundleProduct
                products={this.state.product_items}
                seletedProducts={this.state.selected_products}
                selectedProduct={_product}
                save={this.productAdd}
                cancel={close}
              />
            )}
          </Popup>
        )}
      />
    );
    return <EditButton />;
  };

  deleteButton = _product => {
    const DeleteButton = () => (
      <Route
        render={({ history }) => (
          <button
            type="button"
            className="btn btn-link btn-sm"
            title="Remove"
            onClick={() => {
              this.removeProduct(_product);
            }}
          >
            <i className="fa fa-remove" aria-hidden="true" />
          </button>
        )}
      />
    );
    return <DeleteButton />;
  };

  removeProduct = product => {
    const newFiltedItems = this.state.selected_products.filter(
      c => c.autoid !== product.autoid
    );

    this.setState({
      selected_products: newFiltedItems
    });

    this.calculateTotal(newFiltedItems);
  };

  productXML = () => {
    const { selected_products } = this.state;
    let xmlStr = "";
    if (selected_products && selected_products.length) {
      xmlStr += "<products>";
      selected_products.forEach(function(value) {
        xmlStr += '<product product_id="' + value.autoid + '"  ';
        xmlStr += 'product_name="' + value.product_name + '" ';
        xmlStr += 'product_quantity="' + value.quantity + '" ';
        xmlStr += 'price="' + value.price + '" ';
        xmlStr += 'original_price="' + value.original_price + '" ';
        xmlStr += 'status="' + value.status + '" />';
      });
      xmlStr += "</products>";
    }

    return xmlStr;
  };

  validate = () => {
    let result = true;

    if (this.state.bundle_name === "") {
      result = false;
      this.setState({ errorMessage: "Please enter bundle name" });
    } else if (this.state.price < 0) {
      result = false;
      this.setState({
        errorMessage: "Price should be greater than or equal to zero"
      });
    } else if (this.state.original_price < 0) {
      result = false;
      this.setState({
        errorMessage: "Original Price should be greater than or equal to zero"
      });
    } else if (
      this.state.selected_products &&
      this.state.selected_products.length <= 0
    ) {
      result = false;
      this.setState({ errorMessage: "Please add products" });
    }

    return result;
  };

  onSubmit = async e => {
    e.preventDefault();

    if (!this.validate()) return;
    const _productBundle = {
      division_id: localStorage.apiDivisionId,
      bundle_name: this.state.bundle_name,
      price: this.state.price,
      original_price: this.state.original_price,
      status: this.state.status,
      prod_items: this.productXML()
    };

    if (this.state.autoid) {
      //edit
      _productBundle.auto_id = this.state.autoid;
      const res = await api.post(
        "sc-bundle/edit-product-bundle",
        _productBundle
      );
      if (res) {
        AuthHelperAccessCheck(res);
        if (res.status === "success") {
          this.redirect = 1;
          this.onToastItemAdd(res.status, res.message);
        }
      }
    } else {
      const res = await api.post(
        "sc-bundle/add-product-bundle",
        _productBundle
      );
      if (res) {
        AuthHelperAccessCheck(res);
        if (res.status === "success") {
          this.redirect = 1;
          this.onToastItemAdd(res.status, res.message);
        }
      }
    }
  };

  handleToastItemClose = itemObject => {
    const toastItems = this.state.toastItems.filter(
      c => c.id !== itemObject.id
    );

    if (this.redirect == 1) {
      this.setState({
        redirect: 1,
        toastItems: toastItems
      });
    } else {
      this.setState({ toastItems });
    }
  };

  onToastItemAdd(type, message) {
    let newid = this.state.toastItems.length + 1;
    if (
      this.state.toastItems.filter(function(e) {
        return e.id === newid;
      }).length > 0
    ) {
      newid = this.state.toastItems.length + Math.floor(Math.random() * 100);
    }

    let newToastItems = this.state.toastItems;
    let itemToast = {
      id: newid,
      type: type === "success" ? "success" : "danger",
      message: message
    };

    newToastItems.push(itemToast);
    this.setState({ toastItems: newToastItems });

    setTimeout(() => {
      this.handleToastItemClose(itemToast);
    }, 2000);
  }

  render() {
    if (this.state.redirect === 1)
      return <Redirect to="/product-bundle-list" />;

    let _showProduct = this.state.selected_products;
    return (
      <React.Fragment>
        {/* <NavBar /> */}

        <div className="container mt-4">
          <div className="card">
            <ToastAlert
              toastItems={this.state.toastItems}
              onItemClose={this.handleToastItemClose}
            />
            <div className="card-header">
              <Row>
                <Col>
                  <h4>Add Bundle</h4>
                </Col>
              </Row>
            </div>
            <div className="card-content collpase show">
              <div className="card-body">
                <form className="form">
                  <div className="form-body">
                    <Row className="mt-3">
                      <Col xl={2}>
                        <label>Bundle Name</label>
                      </Col>
                      <Col xl={4}>
                        <input
                          type="text"
                          className="form-control"
                          name="bundle_name"
                          required
                          value={this.state.bundle_name}
                          onChange={this.onChange}
                        />
                      </Col>
                      <Col xl={2}>
                        <label>Price</label>
                      </Col>
                      <Col xl={4}>
                        <input
                          type="number"
                          className="form-control"
                          name="price"
                          required
                          value={this.state.price}
                          onChange={this.onChange}
                          placeholder="0.00"
                        />
                      </Col>
                    </Row>

                    <Row className="mt-3">
                      <Col xl={2}>
                        <label>Status</label>
                      </Col>
                      <Col xl={4}>
                        <select
                          className="form-control"
                          name="status"
                          onChange={this.onChange}
                          value={this.state.status}
                          required
                        >
                          <option value="1">Active</option>
                          <option value="2">Inactivate</option>
                        </select>
                      </Col>
                      <Col xl={2}>
                        <label> Original Price</label>
                      </Col>
                      <Col
                        xl={4}
                        style={{
                          paddingLeft: "2em"
                        }}
                      >
                        {this.state.original_price}
                      </Col>
                    </Row>
                    <Row className="mt-4">
                      <Col>
                        <Popup
                          trigger={
                            <button
                              type="button"
                              className="btn btn-primary btn-sm"
                            >
                              Add Product
                            </button>
                          }
                          modal
                          closeOnDocumentClick
                        >
                          {close => (
                            <BundleProduct
                              products={this.state.product_items}
                              seletedProducts={this.state.selected_products}
                              selectedProduct={null}
                              save={this.productAdd}
                              cancel={close}
                            />
                          )}
                        </Popup>
                      </Col>
                    </Row>
                    <Row className="mt-4">
                      <Col>
                        <div className="table-responsive">
                          <table className="table table-striped">
                            <thead>
                              <tr>
                                <th>Product</th>
                                <th style={{ textAlign: "end" }}>Price(Qr)</th>
                                <th style={{ textAlign: "end" }}>
                                  Original Price(Qr)
                                </th>
                                <th style={{ textAlign: "end" }}>Quantity</th>
                                <th style={{ textAlign: "end" }}>Total</th>
                                <th style={{ textAlign: "end" }}>
                                  Origninal Total
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {_showProduct
                                ? _showProduct.map((_product, i) => (
                                    <React.Fragment key={i}>
                                      <tr>
                                        <td>{_product.product_name}</td>
                                        <td style={{ textAlign: "right" }}>
                                          {_product.price}
                                        </td>
                                        <td style={{ textAlign: "end" }}>
                                          {_product.original_price}
                                        </td>
                                        <td style={{ textAlign: "end" }}>
                                          {_product.quantity}
                                        </td>
                                        <td style={{ textAlign: "end" }}>
                                          {_product.total}
                                        </td>
                                        <td style={{ textAlign: "end" }}>
                                          {_product.original_total}
                                        </td>
                                        <td>{this.editButton(_product)}</td>
                                        <td>{this.deleteButton(_product)}</td>
                                      </tr>
                                    </React.Fragment>
                                  ))
                                : null}
                            </tbody>
                          </table>
                          <center>
                            {_showProduct.length ? "" : "No Product selected"}
                          </center>
                        </div>
                      </Col>
                    </Row>
                    <Row className="mt-4">
                      <Col style={{ display: "flex" }}>
                        <button
                          type="submit"
                          className="btn btn-primary btn-sm"
                          onClick={this.onSubmit}
                        >
                          Save{" "}
                        </button>

                        <span className="text-danger">
                          {this.state.errorMessage}
                        </span>
                      </Col>
                    </Row>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Bundle;
