import React, { useContext, useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import { checkAuth } from "./CheckAuth";
import { AuthenticationContext } from "../AuthenticationContext";
import { api } from "../businessjs/API";
import jwt_decode from "jwt-decode";
import { contextType } from "react-datetime";

const PrivateRoute = ({ component: Component, fallback, ...rest }) => {
  const { auth, setAuth } = useContext(AuthenticationContext);
  api.onAuthChanged = () => {
    setAuth(false);
  };
  useEffect((props) => {
    const token = localStorage.apiAuthAccessKey;
    // console.log("Inside private", token);
    // const{props}=this.props;
    if (typeof token !== "undefined") {
      const decoded = jwt_decode(token);
      if (decoded.accessType === "supportcenter") {
        // console.log("Inside useEffct auth true");
        setAuth(true);

        // props.history.push("/");
      } else if (decoded.accessType === "subscriber") {
        // console.log("Inside useEffct else if");
        // props.history.push("/subscriberhome");
      }
    } else {
      // console.log("Inside useEffct auth false");
      // api.onAuthChanged = () => {
      setAuth(false);
      // };
      // props.history.push("/login");
    }
  }, []);
  // console.log("auth", auth);

  return (
    <Route
      {...rest}
      render={() => {
        // console.log('sssd',auth)
        if (auth === undefined) {
          return <h1>Checking...</h1>;
        }
        if (auth === true) {
          return <Component />;
        } else {
          return <Redirect to="/login" />;
        }
      }}
    />
  );
  // return <Route {...rest} render={(props) => <Component />} />;
};

export default PrivateRoute;
