import jwt_decode from "jwt-decode";
import React, { Component } from "react";
import {
  Box,
  Button,
  Container,
  Grid,
  Link,
  Fab,
  CircularProgress,
  FormControlLabel,
  TextField,
  Typography,
  withStyles,
  Paper,
} from "@material-ui/core";
import MailIcon from "@material-ui/icons/Mail";
import LockIcon from "@material-ui/icons/Lock";
import { api } from "../../businessjs/API";
import LoginAppBar from "./LoginAppBar";
import { SnackBar } from "../../compon-controls/SnackBar";
import { AuthenticationContext } from "../../AuthenticationContext";
const styles = (theme) => ({
  mainContainer: {
    height: "100vh",
  },

  credentialContainer: {
    padding: theme.spacing(4, 4),
  },
  labelStyle: {
    fontSize: "0.813rem",
    fontWeight: "lighter",
  },
  supportCenterTitle: {
    marginTop: "1em",
    fontWeight: "bold",
    fontSize: "1.5rem",
    color: "#007bff",
  },
  titlePaper: {
    fontWeight: "bold",
  },
  fab: {
    color: "white",
    width: "12em",
    textTransform: "none",
    fontSize: "1.2em",
    backgroundColor: "#007bff",
  },
  textFieldwidth: {
    width: "30em",
  },

  margin: {
    marginTop: theme.spacing(3),
  },
  paper: {
    borderRadius: "0.7em",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "6em",
  },
  credentials: {
    fontSize: "0.9em",
    fontWeight: "bold",
  },
  formControlLabelMarginTop: {
    marginTop: "2em",
  },
  checkboxMarginLeft: {
    marginLeft: "1.6em",
  },
  rememberTypography: {
    fontSize: "0.7rem",
    fontWeight: "bold",
  },
  forgotTypography: {
    fontSize: "0.875rem",
    marginTop: "2em",
    // marginLeft: "2em",
    color: "#e91e63",
    fontWeight: "bold",
    textAlign: "center",
  },
  termsTypography: {
    fontSize: "0.875rem",
    marginTop: "1em",
    // marginLeft: '2em',
    color: "#e91e63",
    fontWeight: "lighter",
    textAlign: "center",
  },
  iconColor: { color: "#9e9e9e" },
  circle: {
    backgroundColor: "#f7941d",
  },
  message: {
    color: "#f44336",
    fontSize: "0.9em",
  },
  errorStyle: {
    color: "#b71c1c",
    fontSize: "0.875rem",
    paddingTop: "0.5em",
  },
  buttonProgress: {
    color: "#1b5e20",
    position: "absolute",
    left: "49%",
    marginTop: "0.9em",
  },
});
class SupportCenterLogin extends Component {
  static contextType = AuthenticationContext;
  constructor(props) {
    super(props);
    this.state = {
      loginemail: "",
      loginpassword: "",
      errorText: "",
      btnSignInText: "Sign In",
      companyCode: "ct01",
      divisionCode: "d01",
      isLoading: false,
      snackbarColor: "#d50000",
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
   
  }
  
  componentDidMount() {
    return
    const token = localStorage.apiAuthAccessKey;
    try {
      if(this.context.auth===true)
      {
        this.props.history.push("/");
      }
      else
      {
        this.props.history.push("/login");
      }
    } catch (e) {
      console.log(" token not valid", token);
    }
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }
  onCloseSnackbar = () => {
    this.setState({ errorText: "" });
  };
  onSubmit = async (e) => {
    e.preventDefault();
    const regFormInputes = {
      loginemail: this.state.loginemail,
      loginpassword: this.state.loginpassword,
      companyCode: this.state.companyCode,
      divisionCode: this.state.divisionCode,
    };

    this.setState({
      errorText: "",
      btnSignInText: "Progressing...",
      isLoading: true,
    });
    const loginResult = await api.post("auth/supportauth", regFormInputes);
    this.setState({ btnSignInText: "Sign In" });
    if (loginResult) {
      api.setCredentials(loginResult.accessToken, loginResult.userdata_id);
      localStorage.setItem("apiAuthAccessKey", loginResult.accessToken);
      localStorage.setItem("apiAuthAccessId", loginResult.userdata_id);
      localStorage.setItem("apiCompanyId", loginResult.company_id);
      localStorage.setItem("apiDivisionId", loginResult.division_id);
      // const setAuth=this.context;
      if (loginResult.status === "success") {
        this.context.setAuth(true)
        this.props.history.push("/");
      } else {
        if (loginResult.status) {
          this.setState({
            errorText:
              loginResult.status.toUpperCase() + ": " + loginResult.message,
            isLoading: false,
          });
        }
      }
    }
  };

  render() {
    const { classes } = this.props;
    const { isLoading, errorText, snackbarColor } = this.state;
    return (
      <Grid container spacing={24}>
        <SnackBar
          status={errorText}
          onCloseError={this.onCloseSnackbar}
          color={snackbarColor}
        />
        <Grid item xs={12}>
          <LoginAppBar />
        </Grid>
        <Grid item xs={12}>
          <Box
            display="flex"
            flexDirection="column"
            height="100%"
            justifyContent="center"
            alignItems="center"
          >
            <Container maxWidth="sm">
              <Paper elevarion={3} style={{ height: "30em", marginTop: "7em" }}>
                <form onSubmit={this.onSubmit}>
                  <Grid
                    container
                    direction="column"
                    alignItems="center"
                    spacing={3}
                  >
                    <Grid item xs={12}>
                      <Typography
                        // color="primary"
                        className={classes.supportCenterTitle}
                      >
                        Support Center
                      </Typography>
                    </Grid>

                    <Grid item className={classes.textFieldwidth}>
                      <Grid container spacing={1} alignItems="flex-end">
                        <Grid item>
                          <MailIcon
                            fontSize="small"
                            className={classes.iconColor}
                          />
                        </Grid>
                        <Grid item xs={10}>
                          <TextField
                            label="Email"
                            // error={Boolean(touched.email && errors.email)}
                            // helperText={touched.email && errors.email}
                            fullWidth={true}
                            type="email"
                            name="loginemail"
                            onChange={this.onChange}
                            value={this.state.loginemail}
                            required
                            InputLabelProps={{
                              classes: { root: classes.labelStyle },
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item className={classes.textFieldwidth}>
                      <Grid container spacing={1} alignItems="flex-end">
                        <Grid item>
                          <LockIcon
                            fontSize="small"
                            className={classes.iconColor}
                          />
                        </Grid>
                        <Grid item xs={10}>
                          <TextField
                            // error={Boolean(touched.password && errors.password)}
                            // helperText={touched.password && errors.password}
                            label="Password"
                            fullWidth={true}
                            type="password"
                            name="loginpassword"
                            value={this.state.loginpassword}
                            onChange={this.onChange}
                            required
                            InputLabelProps={{
                              classes: { root: classes.labelStyle },
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item style={{ marginTop: "1em" }}>
                      <Fab
                        variant="extended"
                        // color="primary"
                        className={classes.fab}
                        type="submit"
                        disabled={isLoading}
                      >
                        Sign In
                      </Fab>
                      {isLoading && (
                        <CircularProgress
                          size={24}
                          className={classes.buttonProgress}
                        />
                      )}
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      style={{
                        textAlign: "center",
                        color: "#e91e63",
                        marginTop: "1em",
                      }}
                    >
                      OR
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container justify="space-evenly">
                      <Grid item xs={12}>
                        <Typography
                          // color="primary"
                          className={classes.forgotTypography}
                        >
                          Don’t have account?{" "}
                          <a href="https://www.cloudtel.qa/"> Contact Us </a>
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography
                          // color="primary"
                          className={classes.termsTypography}
                        >
                          By Signing In, you agree to our Terms & Policy{" "}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </form>
              </Paper>
            </Container>
          </Box>
        </Grid>
      </Grid>
    );
  }
}
export default withStyles(styles)(SupportCenterLogin);
