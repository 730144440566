import React, { Component } from "react";
import { Route } from "react-router-dom";
import { RiseLoader } from "react-spinners";
import { css } from "@emotion/core";
import { Col, Row } from "reactstrap";

import SubscriberSelect from "../compon-controls/SubscriberSelect";
import NavBar from "../compon-styled/SupportCenterNavBar";
import { AppHelper_DateAndTimeToString } from "../businessjs/AppHelperFn";
import { api } from "../businessjs/API";
const loaderCSS = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

class ProcessJobCard extends Component {
  state = {
    jobcards: [],
    products: [],
    visits: [],
    subscriber_id: null,
    loading: false,
    errorMessage: ""
  };

  getJobCardInvoice = async subscriberId => {
    this.setState({
      loading: true
    });
    const _input = {
      subscriber_id: subscriberId.value.autoid
    };

    const res = await api.post(
      "job-card/get-unbilled-jobcards-details",
      _input
    );
    if (res) {
      this.setState({
        jobcards: res.jobcards,
        products: res.products,
        visits: res.visits,
        productVisits: res.productvisits,
        loading: false
      });
    } else {
      this.setState({ loading: false });
    }
  };

  invoice = () => {
    const InvoiceButton = () => (
      <Route
        render={({ history }) => (
          <button
            type="button"
            className="btn btn-primary ml-2"
            onClick={() => {
              if (this.state.jobcards.length === 0) {
                this.setState({
                  errorMessage: "Currently no jobcards for this subscriber"
                });
                return;
              }

              history.push({
                pathname: "/jobcard-invoice",
                state: {
                  subscriber_id: this.state.subscriber_id
                }
              });
            }}
          >
            Invoice
          </button>
        )}
      />
    );
    return <InvoiceButton />;
  };

  onSubscriberChange = e => {
    this.setState({ [e.target.name]: e.target.value });
    this.getJobCardInvoice(e.target.value);
  };

  render() {
    const { jobcards, products, visits } = this.state;

    return (
      <React.Fragment>
        {/* <NavBar /> */}
        <div className="container mt-4 mb-2" style={{minHeight:600}}>
          <div className="card">
            <div className="card-header">
              <Row>
                <Col>
                  <Row>
                    <Col xl={6}>
                      <h3>Process Job Card</h3>
                    </Col>
                    <Col xl={3}>
                      <SubscriberSelect
                        name="subscriber_id"
                        value={this.state.subscriber_id}
                        onChange={this.onSubscriberChange}
                      />
                    </Col>
                    {this.state.products.length != 0 ? (
                      <Col xl={1}>{this.invoice()}</Col>
                    ) : (
                      ""
                    )}
                    <Col xl={2}>
                      <div className="sweet-loading">
                        <RiseLoader
                          css={loaderCSS}
                          sizeUnit={"px"}
                          size={10}
                          color={"#123abc"}
                          loading={this.state.loading}
                        />
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </div>
          {this.state.subscriber_id !== null ? (
            <>
              <Row className="mt-2" style={{display:this.state.subscriber_id!==null?"none":"none"}}>
                <Col>
                  <strong className="text-primary">Products</strong>

                  <div className="card">
                    <div className="table-responsive">
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Price(Qr)</th>
                            <th>No. Services</th>
                            <th>Max Services</th>
                            <th>Quantity</th>
                            <th>Add. Price(Qr)</th>
                          </tr>
                        </thead>
                        <tbody>
                          {products.map((_product, i) => (
                            <React.Fragment key={i}>
                              <tr className="grid-rows-text">
                                <td>{_product.product_name}</td>
                                <td>{_product.price}</td>
                                <td>{_product.number_of_service_visits}</td>
                                <td>{_product.max_services_per_visit}</td>
                                <td>{_product.quantity}</td>
                                <td>{_product.additional_per_unit_price}</td>
                              </tr>
                            </React.Fragment>
                          ))}
                        </tbody>
                      </table>
                      <center>
                        {products.length ? "" : "Currently No Products "}
                      </center>
                    </div>
                  </div>
                </Col>
                <Col>
                  <strong className="text-primary">Visits</strong>
                  <div className="card">
                    <div className="table-responsive">
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th>Visit Title</th>
                            <th>Date</th>
                            <th>Location</th>
                            <th>Count</th>
                          </tr>
                        </thead>
                        <tbody>
                          {visits.map((_visit, i) => (
                            <React.Fragment key={i}>
                              <tr>
                                <td>{_visit.name}</td>
                                <td>
                                  {AppHelper_DateAndTimeToString(
                                    _visit.visit_date
                                  )}
                                </td>
                                <td>{_visit.location_name}</td>
                                <td>{_visit.visit_count}</td>
                              </tr>
                            </React.Fragment>
                          ))}
                        </tbody>
                      </table>
                      <center>
                        {visits.length ? "" : "Currently No Visits"}
                      </center>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col>
                  <strong className="text-primary">Job Cards</strong>
                  <div className="card">
                    <div className="table-responsive">
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th>Title</th>
                            <th>Date</th>
                            <th>Type</th>
                            <th>Status</th>
                            <th>Location</th>
                            <th>Ticket</th>
                            <th>Visit</th>
                            <th>Visit Date</th>
                            <th>Used Service</th>
                          </tr>
                        </thead>
                        <tbody>
                          {jobcards.map((_jobcard, i) => (
                            <React.Fragment key={i}>
                              <tr>
                                <td>{_jobcard.title}</td>
                                <td>
                                  {AppHelper_DateAndTimeToString(
                                    _jobcard.job_date
                                  )}
                                </td>
                                <td>{_jobcard.type}</td>
                                <td>{_jobcard.status}</td>
                                <td>{_jobcard.location_name}</td>
                                <td>{_jobcard.subject}</td>
                                <td>{_jobcard.name}</td>
                                <td>
                                  {AppHelper_DateAndTimeToString(
                                    _jobcard.visit_date
                                  )}
                                </td>
                                <td>{_jobcard.product_name}</td>
                              </tr>
                            </React.Fragment>
                          ))}
                        </tbody>
                      </table>
                      <center>
                        {jobcards.length ? "" : "Currently No Job cards"}
                      </center>
                    </div>
                  </div>
                </Col>
              </Row>
            </>
          ) : (
            <center className="mt-3">
              Please select a Subscriber from the select box.
            </center>
          )}
          {this.state.subscriber_id != null &&
          this.state.jobcards.length === 0 ? (
            <center className="mt-3">
              <span className="text-danger">{this.state.errorMessage}</span>
            </center>
          ) : (
            ""
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default ProcessJobCard;
