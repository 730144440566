import {
  AppBar,
  Fab,
  Grid,
  Paper,
  Tab,
  Tabs,
  Typography,
  Button,
  Tooltip,
} from "@material-ui/core";
import React, { Component, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { ProductListTabPanel } from "./ProductListTabPanel";
import AddIcon from "@material-ui/icons/Add";
import { Route, Link } from "react-router-dom";
import { api } from "../../businessjs/API";
import { ServiceListTabPanel } from "./ServiceListTabPanel";
import { SubscriptionListTabPanel } from "./SubscriptionListTabPanel";
import { BundleListTabPanel } from "./BundleListTabPanel";

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
});

export const ProductHome = () => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [allProducts, setAllProducts] = useState(undefined);
  const [status, setStatus] = useState(0);
  const [productTypes, setProductTypes] = useState(undefined);
  const [serviceTypes, setServiceTypes] = useState(undefined);
  const [subscriptionTypes, setSubscriptionTypes] = useState(undefined);
  const [bundleTypes, setBundleTypes] = useState(undefined);

  useEffect(() => {
    const loadAllProducts = async () => {
      try {
        const _productListInputs = {
          divisionId: localStorage.apiCompanyId,
          company_id: localStorage.apiDivisionId,
          status: status,
        };
        const products = await api.post(
          "sc-product/get-product-list",
          _productListInputs
        );
        console.log("home all", products);

        // for type= product
        const productType = products.filter(
          (product) => product.type === "Product"
        );

        // for type= service
        const serviceType = products.filter(
          (product) => product.type === "Service"
        );

        // for type= subscription
        const subscriptionType = products.filter(
          (product) => product.type === "Subscription"
        );

        // for type= bundle
        const bundleType = products.filter(
          (product) => product.type === "Bundle"
        );
        // console.log("home productType", productType);
        setAllProducts(products);
        setProductTypes(productType);
        setServiceTypes(serviceType);
        setSubscriptionTypes(subscriptionType);
        setBundleTypes(bundleType);
      } catch (e) {
        console.log(e);
      }
    };

    if (!allProducts) {
      loadAllProducts();
    }
  }, [allProducts]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const productAdd = () => {
    const ProductAddButton = () => (
      <Route
        render={({ history }) => (
          <Fab
            size="small"
            color="primary"
            variant="extended"
            onClick={() => {
              history.push({
                pathname: "/product",
                state: {
                  type: "add",
                },
              });
            }}
          >
            <AddIcon className={classes.extendedIcon} />
            Add New
          </Fab>
        )}
      />
    );
    return <ProductAddButton />;
  };

  return (
    <Grid
      container
      spacing={3}
      // style={{ backgroundColor: "white" }}
    >
      <Grid item xs={12}>
        <Typography variant="h3" style={{ fontWeight: "bold" }}>
          Products
        </Typography>
      </Grid>
      {/* <Grid item xs={6}>
        <Tooltip title="Click to view Bundles">
          <Typography component={Link} to="/product-bundle-list">
            Product Bundles
          </Typography>
        </Tooltip>
      </Grid> */}
      <Grid item xs={12} style={{ textAlign: "right" }}>
        <Tooltip title="Click to view Bundles">
          <Fab
            component={Link}
            to="/product-bundle-list"
            size="small"
            color="primary"
            variant="extended"
            style={{ marginRight: "1em" }}
          >
            Product Bundles
          </Fab>
        </Tooltip>
        {productAdd()}
      </Grid>
      <Grid item xs={12}>
        <Paper className={classes.root}>
          <Tabs
            value={value}
            onChange={handleChange}
            variant="fullWidth"
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab label="Products " />
            <Tab label="Services " />
            <Tab label="Subscriptions " />
            {/* <Tab label="Bundles" /> */}
          </Tabs>
          <ProductListTabPanel value={value} index={0}>
            {productTypes}
          </ProductListTabPanel>
          <ServiceListTabPanel value={value} index={1}>
            {serviceTypes}
          </ServiceListTabPanel>
          <SubscriptionListTabPanel value={value} index={2}>
            {subscriptionTypes}
          </SubscriptionListTabPanel>
          {/* <BundleListTabPanel value={value} index={3}>
            {bundleTypes}
          </BundleListTabPanel> */}
        </Paper>
      </Grid>
    </Grid>
  );
};
