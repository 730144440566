import React, { useState } from "react";
import { NavLink as RouterLink } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import {
  Button,
  ListItem,
  makeStyles,
  Box,
  Typography,
  Collapse,
} from "@material-ui/core";
import { ChevronDown, ChevronUp, ChevronsRight } from "react-feather";

const useStyles = makeStyles((theme) => ({
  item: {
    display: "flex",
    paddingTop: 0,
    paddingBottom: 0,
  },
  subItem: {
    display: "flex",
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: "2em",
  },
  button: {
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightMedium,
    justifyContent: "flex-start",
    letterSpacing: 0,
    padding: "10px 8px",
    textTransform: "none",
    width: "100%",
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  title: {
    marginRight: "auto",
    fontWeight: "Lighter",
    fontSize: "0.813rem",
  },
  active: {
    color: theme.palette.primary.main,
    "& $title": {
      fontWeight: theme.typography.fontWeightMedium,
    },
    "& $icon": {
      color: theme.palette.primary.main,
    },
  },
}));

const NavItem = ({ className, href, icon: Icon, title, subMenu, ...rest }) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState();
  const [active, setActive] = useState(false);
  const handleClick = () => {
    setExpanded(!expanded);
  };
  const subMenuHandleClick = () => {
    setActive(!active);
  };
  // if(subMenu!==null)
  // {
  //   console.log(">>>>>>>",expanded["Vehicle"]);
  // }
  return (
    <div>
      {subMenu !== null ? (
        <div>
          <ListItem
            className={clsx(classes.item, className)}
            disableGutters
            {...rest}
          >
            <Button
              activeClassName={expanded ? classes.active : ""}
              className={classes.button}
              component={RouterLink}
              to={href}
              onClick={handleClick}
            >
              {Icon && <Icon className={classes.icon} size="20" />}
              <span className={classes.title}>{title}</span>

              {expanded ? <ChevronUp /> : <ChevronDown />}
            </Button>
          </ListItem>
          <Collapse in={expanded} timeout="auto" unmountOnExit={true}>
            {subMenu &&
              subMenu.map((item, i) => (
                <ListItem
                  key={i}
                  className={clsx(classes.subItem, className)}
                  disableGutters
                  {...rest}
                >
                  <Button
                    activeClassName={active ? classes.active : ""}
                    className={classes.button}
                    component={RouterLink}
                    to={item.href}
                    onClick={subMenuHandleClick}
                  >
                    {/* {item.icon && <ChevronsRight className={classes.icon} size="20" />} */}
                    <span className={classes.title}>{item.title}</span>
                  </Button>
                </ListItem>
              ))}
          </Collapse>
        </div>
      ) : (
        <ListItem
          className={clsx(classes.item, className)}
          disableGutters
          {...rest}
        >
          <Button
            activeClassName={classes.active}
            className={classes.button}
            component={RouterLink}
            to={href}
          >
            {Icon && <Icon className={classes.icon} size="20" />}
            <span className={classes.title}>{title}</span>
          </Button>
        </ListItem>
      )}
    </div>
  );
};

NavItem.propTypes = {
  className: PropTypes.string,
  href: PropTypes.string,
  icon: PropTypes.elementType,
  title: PropTypes.string,
};

export default NavItem;
