import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { Bar } from "react-chartjs-2";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  useTheme,
  makeStyles,
  colors,
} from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import { useState, useEffect } from "react";

const useStyles = makeStyles(() => ({
  root: {},
}));

const Sales = ({ salesData, className, ...rest }) => {
  const [data, setData] = useState();

  const classes = useStyles();
  const theme = useTheme();
  useEffect(() => {
    if (salesData !== undefined) {
      // console.log("sales", salesData);
      const monthData = salesData.map((item) => item.month);
      const countData = salesData.map((item) => item.count);
      // console.log("monthData", monthData);
      const dataInitial = {
        datasets: [
          // {
          //   backgroundColor: colors.grey[200],
          //   data: [11, 20, 12, 29, 30, 25, 13],
          //   label: "Last year",
          // },
          {
            backgroundColor: "#311b92",
            data: countData.reverse(),
            label: "This year",
          },
        ],
        labels: monthData.reverse(),
      };
      setData(dataInitial);
    }
  }, [salesData]);

  const options = {
    animation: true,
    cornerRadius: 20,
    layout: { padding: 0 },
    legend: { display: true },
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      xAxes: [
        {
          barThickness: 12,
          maxBarThickness: 10,
          barPercentage: 0.5,
          categoryPercentage: 0.5,
          ticks: {
            fontColor: theme.palette.text.secondary,
          },
          gridLines: {
            display: false,
            drawBorder: false,
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            fontColor: theme.palette.text.secondary,
            beginAtZero: true,
            min: 0,
          },
          gridLines: {
            borderDash: [2],
            borderDashOffset: [2],
            color: theme.palette.divider,
            drawBorder: false,
            zeroLineBorderDash: [2],
            zeroLineBorderDashOffset: [2],
            zeroLineColor: theme.palette.divider,
          },
        },
      ],
    },
    tooltips: {
      backgroundColor: theme.palette.background.default,
      bodyFontColor: theme.palette.text.secondary,
      borderColor: theme.palette.divider,
      borderWidth: 1,
      enabled: true,
      footerFontColor: theme.palette.text.secondary,
      intersect: false,
      mode: "index",
      titleFontColor: theme.palette.text.primary,
    },
  };

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
      style={{ borderRadius: "0.75em" }}
    >
      {/* {console.log("data", data)} */}
      <CardHeader
        // action={(
        //   <Button
        //     endIcon={<ArrowDropDownIcon />}
        //     size="small"
        //     variant="text"
        //   >
        //     Last 7 days
        //   </Button>
        // )}
        title="Latest Sales"
      />
      <Divider />
      <CardContent>
        <Box height={400} position="relative">
          <Bar data={data} options={options} />
        </Box>
      </CardContent>
      {/* <Divider />
      <Box
        display="flex"
        justifyContent="flex-end"
        p={2}
      >
        <Button
          color="primary"
          endIcon={<ArrowRightIcon />}
          size="small"
          variant="text"
        >
          Overview
        </Button>
      </Box> */}
    </Card>
  );
};

Sales.propTypes = {
  className: PropTypes.string,
};

export default Sales;
