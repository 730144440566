import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Toolbar from "@material-ui/core/Toolbar";
import { Badge, Box, Avatar, Button } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import NotificationsIcon from "@material-ui/icons/NotificationsOutlined";
import jwt_decode from "jwt-decode";
import {
  AuthHelperValidateOverToken,
  AuthHelperGerSubscriberRoleText,
  AuthHelperLogout,
} from "../../businessjs/AuthHelper";
import { AuthenticationContext } from "../../AuthenticationContext";
const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up("sm")]: {
      // width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      backgroundColor: "#fff",
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    // [theme.breakpoints.down("md")]: {
    //   display: "flex",
    // },
    [theme.breakpoints.up("lg")]: {
      display: "none",
    },
  },
  supportTitle: {
    fontWeight:"bold",
    [theme.breakpoints.up("lg")]: {
      paddingLeft: "10em",
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

const TopBar = ({ className, onMobileNavOpen, ...rest }) => {
  // const { window } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [notifications] = useState([]);
  const [token, setToken] = useState();
  const [subscriberId, setSubscriberId] = useState();
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [email, setEmail] = useState();
  const [accountType, setAccountType] = useState();
  const [role, setRole] = useState();
  const [homeUrl, setHomeUrl] = useState();
  const [roleText, setRoleText] = useState();
  const [companyCode, setCompanyCode] = useState();
  const [loginType, setLoginType] = useState("general");
  const [userType, setUserType] = useState("guest");
  const [divisionCode, setDivisionCode] = useState();
  const [signOutFlag, setSignOutFlag] = useState(false);
  const {auth,setAuth}=useContext(AuthenticationContext);
  useEffect(() => {
    if (AuthHelperValidateOverToken("layoutsubscriber")) {
      const token = localStorage.apiAuthAccessKey;
      updateState(token);
    } else {
      return <Redirect to="/login"></Redirect>;
    }
  }, []);
  const updateState = (token) => {
    const decoded = jwt_decode(token);
    const decodedUser = decoded.userAccessKey[0];
    setToken(token);
    setSubscriberId(decodedUser.subscriber_id);
    setFirstName(decodedUser.first_name);
    setLastName(decodedUser.last_name);
    setEmail(decodedUser.email);
    setAccountType(decodedUser.account_type);
    setLoginType(decoded.accessType);
    setUserType(decoded.accessType);
    setRole(decodedUser.role);
    setHomeUrl(
      decoded.accessType === "subscriber" ? "/subscriberhome" : "/supportcenter"
    );
    setRoleText(AuthHelperGerSubscriberRoleText(decodedUser.role));
    setCompanyCode(decodedUser.company_code);
    setDivisionCode(decodedUser.division_code);
    // this.setState({
    //   token: token,
    //   subscriber_id: decodedUser.subscriber_id,
    //   first_name: decodedUser.first_name,
    //   last_name: decodedUser.last_name,
    //   email: decodedUser.email,
    //   account_type: decodedUser.account_type,
    //   loginType: decoded.accessType,
    //   userType: decoded.accessType,
    //   role: decodedUser.role,
    //   home_url:
    //     decoded.accessType === "subscriber"
    //       ? "/subscriberhome"
    //       : "/supportcenter",
    //   roleText: AuthHelperGerSubscriberRoleText(decodedUser.role),
    //   company_code: decodedUser.company_code,
    //   division_code: decodedUser.division_code
    // });
  };

  const onClickMenuItem_LogOut = (e) => {
    e.preventDefault();
    AuthHelperLogout("layoutsubscriber");
    setLoginType("general");
    setUserType("guest");
   setAuth(false);
    // this.setState({
    //   loginType: "general",
    //   userType: "guest",
    // });
    localStorage.clear();
    setSignOutFlag(true);
    return <Redirect to="/login"></Redirect>;
  };
  if (signOutFlag) {
    return <Redirect to="/login"></Redirect>;
  } else {
    return (
      <div className={classes.root}>
        <CssBaseline />
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar style={{ backgroundColor: "#fff" }}>
            <IconButton
              color="primary"
              aria-label="open drawer"
              edge="start"
              onClick={onMobileNavOpen}
              className={classes.menuButton}
            >
              <MenuIcon color="primary" />
            </IconButton>
            <Typography
              color="primary"
              variant="h3"
              className={classes.supportTitle}
            >
              Support Center Admin Panel
            </Typography>
            <Box flexGrow={1} />
            <Hidden mdDown>
              <IconButton
                color="primary"
                style={{ backgroundColor: "primary" }}
              >
                <Badge
                  badgeContent={notifications.length}
                  color="secondary"
                  variant="dot"
                >
                  <NotificationsIcon color="primary" />
                </Badge>
              </IconButton>
            </Hidden>
            <Button
              color="primary"
              onClick={onClickMenuItem_LogOut}
              style={{ textTransform: "none" }}
            >
              Hi, {firstName}| Exit
            </Button>
          </Toolbar>
        </AppBar>
      </div>
    );
  }
};

// ResponsiveDrawer.propTypes = {
//   /**
//    * Injected by the documentation to work in an iframe.
//    * You won't need it on your project.
//    */
//   window: PropTypes.func,
// };

export default TopBar;
