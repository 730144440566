import React, { Component } from "react";
import { Route } from "react-router-dom";
import { Col, Row } from "reactstrap";

import { AuthHelperAccessCheck } from "../businessjs/AuthHelper";
import { AppHelper_DateAndTimeToString } from "../businessjs/AppHelperFn";
import { api } from "../businessjs/API";

class Products extends Component {
  state = {
    allProduct: [],
    loading: false,
    activePage: undefined,
    itemsCountPerPage: 25,
    totalItemsCount: 0,
    subscriber_id: this.props.accountid
  };

  componentDidMount = () => {
    this.getProductList();
  };

  getProductList = async () => {
    const _productListInputs = {
      subscriber_id: this.state.subscriber_id,
      divisionId: localStorage.apiCompanyId,
      company_id: localStorage.apiDivisionId
    };

    const productList = await api.post(
      "sc-product/get-products-by-account",
      _productListInputs
    );

    if (productList) {
      this.setState({ allProduct: productList, loading: false });
      if (!this.state.activePage) {
        this.setState({ totalItemsCount: productList.length, activePage: 1 });
      }
    } else {
      this.setState({ loading: false });
      AuthHelperAccessCheck(productList);
    }
  };

  handlePageChange = pageNumber => {
    this.setState({ activePage: pageNumber });
    this.getProductList(pageNumber);
  };

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onDivisionChange = e => {
    this.setState({
      [e.target.name]: e.target.value,
      company_id: e.target.value.value.company_id
    });
  };

  viewBundle = _product => {
    const ViewBundleButton = () => (
      <Route
        render={({ history }) => (
          <button
            type="button"
            className="btn btn-link btn-sm"
            title="View"
            onClick={() => {
              history.push({
                pathname: "/bundle-view",
                state: {
                  productId: _product.autoid,
                  bundle_id: _product.bundle_id
                }
              });
            }}
          >
            Bundle
          </button>
        )}
      />
    );
    return <ViewBundleButton />;
  };

  render() {
    let _showProduct = this.state.allProduct;
    return (
      <React.Fragment>
        <div className="container mt-4">
          <div className="card">
            <Row>
              <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                <div className="card">
                  <div className="table-responsive">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th>Invoice No</th>
                          <th>Invoice Date</th>
                          <th>Prodcut Type</th>
                          <th>Product Name</th>
                          <th>Quantity</th>
                          <th>Warranty Days</th>
                        </tr>
                      </thead>
                      <tbody>
                        {_showProduct
                          ? _showProduct.map((_product, i) => (
                              <React.Fragment key={i}>
                                <tr
                                  style={{
                                    backgroundColor: _product.is_expired
                                  }}
                                >
                                  <td>{_product.invoice_no}</td>
                                  <td>
                                    {AppHelper_DateAndTimeToString(
                                      _product.invoice_date
                                    )}
                                  </td>
                                  <td>{_product.prod_type}</td>
                                  <td>{_product.product_name}</td>
                                  <td>{_product.quantity}</td>
                                  <td>{_product.active_days}</td>
                                </tr>
                              </React.Fragment>
                            ))
                          : ""}
                      </tbody>
                    </table>
                    <center>
                      {_showProduct.length ? "" : "Currently No Tickets"}
                    </center>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Products;
