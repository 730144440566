import ReactSelect from "react-select";
import React from "react";
const customStyles = {
  control: (base, state) => ({
    ...base,
    height: "55px",
    minHeight: "24px",
    overflow:"auto", 
  }),
  menu: base => ({
    ...base,
    zIndex: 100,
    backgroundColor:"#fff"
  })
};

const Select = (props) => {
  return (
    <ReactSelect
      options={props.options}
      isDisabled={props.disable}
      name={props.name}
      placeholder={props.placeholder}
      isMulti={props.isMulti}
      onChange={props.onChange}
      defaultValue={props.defaultValue}
      styles={customStyles}
      value={props.value}
      theme={(theme) => ({
        ...theme,

        colors: {
          ...theme.colors,
          // primary25: "#2978ca",
          primary: "#b3e5fc",
          // primary50: "#2978ca"
        }
      })}
    />
  );
};

export { Select };
