/**
 * TicketList :to list all tickets on the screen.
 */
import React, { Component } from "react";
import { Route } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { RiseLoader } from "react-spinners";
import { css } from "@emotion/core";
import { Col, Row } from "reactstrap";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import { AuthHelperAccessCheck } from "../businessjs/AuthHelper";
import { AppHelper_DateAndTimeToString } from "../businessjs/AppHelperFn";
import SubscriberSelect from "../compon-controls/SubscriberSelect";
import { api } from "../businessjs/API";
import { makeStyles } from "@material-ui/core/styles";
import TablePagination from "@material-ui/core/TablePagination";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import {
  Typography,
  Grid,
  Fab,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  CircularProgress,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import EditIcon from "@material-ui/icons/Edit";

function descendingComparator(a, b, orderBy) {
  if (orderBy !== "") {
    if (isNaN(parseFloat(a[orderBy]))) {
      if (b[orderBy].toLowerCase() < a[orderBy].toLowerCase()) {
        return -1;
      }
      if (b[orderBy].toLowerCase() > a[orderBy].toLowerCase()) {
        return 1;
      }
      return 0;
    } else {
      if (parseFloat(b[orderBy]) < parseFloat(a[orderBy])) {
        return -1;
      }
      if (parseFloat(b[orderBy]) > parseFloat(a[orderBy])) {
        return 1;
      }
      return 0;
    }
  }
}

const getComparator = (order, orderBy) => {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

const headCells = [
  { id: "location_name", label: "Location" },
  { id: "LocationType", label: "Type" },
  { id: "updatetime", label: "Date" },
  { id: "company_name", label: "Subscriber" },
  { id: "edit", label: "" },
];

const EnhancedTableHead = (props) => {
  const {
    order,
    orderBy,

    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="left"
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

class SubscriberLocationsList extends Component {
  state = {
    allLocations: [],
    searchSubject: "",
    loading: false,
    subscriber_id: { label: "All", value: { autoid: 0 } },
    order: "asc",
    orderBy: "",
    page: 0,
    rowsPerPage: 50,
    expanded: false,
  };

  componentDidMount() {
    this.getLocationList();
  }
  handleRequestSort = (event, property) => {
    const { order, orderBy } = this.state;
    const isAsc = orderBy === property && order === "asc";
    this.setState({ order: isAsc ? "desc" : "asc", orderBy: property });
  };
  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: parseInt(event.target.value, 10), page: 0 });
  };
  getLocationList = async () => {
    this.setState({ loading: true });
    const token = localStorage.apiAuthAccessKey;
    const decoded = jwt_decode(token);
    const decodedUser = decoded.userAccessKey[0];
    const _ticketListInputs = {
      subscriber_id:
        this.state.subscriber_id.value === 0
          ? 0
          : this.state.subscriber_id.value.autoid,

      searchSubject: this.state.searchSubject,
      executive_id: decodedUser.autoid,
    };
    const locations = await api.post(
      "subscriberlocations/get-sc-locations",
      _ticketListInputs
    );

    if (locations) {
      if (Array.isArray(locations)) {
        this.setState({
          allLocations: locations,
          loading: false,
        });
      } else {
        this.setState({ loading: false });
        AuthHelperAccessCheck(locations);
      }
    }
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  locationAdd = () => {
    const LocationAddButton = () => (
      <Route
        render={({ history }) => (
          <Fab
            variant="extended"
            color="primary"
            size="small"
            onClick={() => {
              history.push({
                pathname: "/subscriber-location-creation",
                state: {
                  type: "add",
                },
              });
            }}
          >
            Add
          </Fab>
        )}
      />
    );
    return <LocationAddButton />;
  };

  editButton = (item) => {
    const EditButton = () => (
      <Route
        render={({ history }) => (
          <button
            type="button"
            className="btn btn-link"
            title="Edit"
            onClick={() => {
              history.push({
                pathname: "/subscriber-location-creation",
                state: {
                  type: "edit",
                  locationObject: item,
                },
              });
            }}
          >
            <EditIcon />
          </button>
        )}
      />
    );
    return <EditButton />;
  };
  handleAccordionChange = (panel) => (event, isExpanded) => {
    this.setState({
      expanded: isExpanded ? panel : false,
      subscriber_id: { label: "All", value: { autoid: 0 } },
      searchSubject: "",
    });
  };
  render() {
    let _locations = this.state.allLocations;
    const { order, orderBy, rowsPerPage, page, expanded } = this.state;
    return (
      <React.Fragment>
        <Grid container>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={8}>
                <Typography variant="h3" style={{ fontWeight: "bold" }}>
                  Subscriber Locations
                </Typography>
              </Grid>
              <Grid item xs={4} style={{ textAlign: "right" }}>
                {this.locationAdd()}
              </Grid>
              <Grid item xs={8}>
                <Accordion
                  expanded={expanded === "panel1"}
                  onChange={this.handleAccordionChange("panel1")}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <Typography variant="h5" style={{ fontWeight: "bold" }}>
                      Search
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={1}>
                      <Grid item xs={4}>
                        <SubscriberSelect
                          name="subscriber_id"
                          value={this.state.subscriber_id}
                          onChange={this.onChange}
                          showAll={true}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          type="text"
                          size="small"
                          variant="outlined"
                          label="Location"
                          name="searchSubject"
                          placeholder=" Location..."
                          onChange={this.onChange}
                          value={this.state.searchSubject}
                          InputProps={{ style: { height: "2.3em" } }}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <Fab
                          size="small"
                          variant="extended"
                          color="primary"
                          onClick={this.getLocationList}
                        >
                          Search
                        </Fab>
                      </Grid>
                    </Grid>

                    {/* </Paper> */}
                  </AccordionDetails>
                </Accordion>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} style={{ marginTop: "1em" }}>
            {this.state.loading ? (
              this.state.loading && (
                <Paper
                  elevation={3}
                  style={{ backgroundColor: "#fff", minHeight: "15em" }}
                >
                  <CircularProgress
                    size={50}
                    style={{
                      color: "#2196f3",
                      position: "absolute",
                      left: "55%",
                      marginTop: "4.5em",
                    }}
                  />
                </Paper>
              )
            ) : (
              <Paper>
                <TableContainer>
                  <Table
                    style={{ minWidth: 650 }}
                    size="small"
                    aria-label="a dense table"
                  >
                    <EnhancedTableHead
                      order={order}
                      orderBy={orderBy}
                      onRequestSort={this.handleRequestSort}
                      rowCount={_locations.length}
                    />
                    {_locations.length !== 0 ? (
                      <TableBody>
                        {stableSort(_locations, getComparator(order, orderBy))
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((item, i) => (
                            <TableRow key={i}>
                              <TableCell component="th" scope="row">
                                {item.location_name}
                              </TableCell>
                              <TableCell>{item.LocationType}</TableCell>
                              <TableCell>
                                {" "}
                                {AppHelper_DateAndTimeToString(item.updatetime)}
                              </TableCell>
                              <TableCell>{item.company_name}</TableCell>
                              <TableCell>{this.editButton(item)}</TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    ) : (
                      <TableBody>
                        <TableRow>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                          <TableCell>
                            <Typography
                              variant="h5"
                              style={{
                                textAlign: "center",
                                marginTop: "1em",
                                marginBottom: "1em",
                              }}
                            >
                              Currently No Locations
                            </Typography>
                          </TableCell>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>

                <TablePagination
                  rowsPerPageOptions={[50, 200, 500]}
                  component="div"
                  count={_locations.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onChangePage={this.handleChangePage}
                  onChangeRowsPerPage={this.handleChangeRowsPerPage}
                />
              </Paper>
            )}
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

export default SubscriberLocationsList;
