import React, { Component } from "react";
import { Col, Row } from "reactstrap";
import NavBar from "../compon-styled/SupportCenterNavBar";
import { Route } from "react-router-dom";
import SubscriberSelect from "../compon-controls/SubscriberSelect";
import { AuthHelperAccessCheck } from "../businessjs/AuthHelper";
import Pagination from "react-js-pagination";
import { RiseLoader } from "react-spinners";
import { css } from "@emotion/core";
import { api } from "../businessjs/API";

import {
  Typography,
  InputBase,
  IconButton,
  Fab,
  CircularProgress,
  FormControl,
  TextField,
  MenuItem,
  TableSortLabel,
  Grid,
  InputLabel,
  Select,
} from "@material-ui/core";
// import { Select } from "../compon-controls/Select";
import { SnackBar } from "../compon-controls/SnackBar";
import AddIcon from "@material-ui/icons/Add";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";

const loaderCSS = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

function descendingComparator(a, b, orderBy) {
  if (orderBy !== "") {
    // console.log("date", isNaN(a[orderBy]));
    if (isNaN(parseFloat(a[orderBy]))) {
      // console.log("date", a[orderBy]);
      if (b[orderBy].toLowerCase() < a[orderBy].toLowerCase()) {
        return -1;
      }
      if (b[orderBy].toLowerCase() > a[orderBy].toLowerCase()) {
        return 1;
      }
      return 0;
    } else {
      // console.log("in else");
      if (parseFloat(b[orderBy]) < parseFloat(a[orderBy])) {
        return -1;
      }
      if (parseFloat(b[orderBy]) > parseFloat(a[orderBy])) {
        return 1;
      }
      return 0;
    }
  }
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  // const classes = useStyles();
  const { order, orderBy, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const headCells = [
  { id: "title", label: "Title" },
  {
    id: "plate_number",
    label: "Plate Number",
    numeric: true,
    // disablePadding: false,
  },
  {
    id: "fleet_number",
    label: "Fleet Number",
    numeric: true,
    // disablePadding: false,
  },
  {
    id: "manufacture_name",
    label: "Manufacture",
    numeric: true,
    // disablePadding: false,
  },
  { id: "model", label: "Model" },
  { id: "fuel_type", label: "Fuel" },
  { id: "company_name", label: "Subscriber" },
  { id: "edit", label: "" },
];

class SupportCenterVehicleList extends Component {
  state = {
    filteredDataRows: [],
    allDataRows: [],
    subscriber_id: 0,
    totalItemsCount: 0,
    itemsCountPerPage: 25,
    activePage: 1,
    searchPlateNumber: "",
    loading: true,
    companyList: [],
    page: 0,
    rowsPerPage: 50,
    order: "asc",
    orderBy: "",
  };

  componentDidMount() {
    const countInput = {
      subscriber_id: this.state.subscriber_id,
      searchPlateNumber: this.state.searchPlateNumber,
    };
    this.getAllVehicles(countInput);
    this.getSubscriberForCmb();
  }

  getAllVehicles = async (countInput) => {
    this.setState({ loading: true });
    const vehicleList = await api.post(
      "sc-vehicle/get-all-vehicles",
      countInput
    );
    if (vehicleList) {
      if (Array.isArray(vehicleList)) {
        this.setState({ allDataRows: vehicleList });
        this.setState({ filteredDataRows: vehicleList, loading: false });
      } else {
        this.setState({ loading: false });
        AuthHelperAccessCheck(vehicleList);
      }
    }
  };
  getSubscriberForCmb = async () => {
    const subscribers = await api.post("sc-subscriber/get-all-for-cmb");
    if (subscribers && subscribers.userdata) {
      let subscriberArray = subscribers.userdata.map((opt) => ({
        label: opt.company_name,
        value: opt,
      }));
      this.setState({
        companyList: subscriberArray,
      });
    } else {
      AuthHelperAccessCheck(subscribers);
    }
  };

  getVehicles = async (pageNumber) => {
    const { itemsCountPerPage } = this.state;

    let start = (pageNumber - 1) * this.state.itemsCountPerPage;
    const listInputs = {
      subscriber_id: this.state.subscriber_id,
      searchPlateNumber: this.state.searchPlateNumber,
      startLimit: start,
      endLimit: itemsCountPerPage,
    };
    const vehicleList = await api.post(
      "sc-vehicle/get-vehicle-list",
      listInputs
    );
    if (vehicleList) {
      if (Array.isArray(vehicleList)) {
        this.setState({ allDataRows: vehicleList });
        this.setState({ filteredDataRows: vehicleList, loading: false });

        if (!this.state.activePage) {
          this.setState({ totalItemsCount: vehicleList.length, activePage: 1 });
        } else {
        }
      } else {
        this.setState({ loading: false });
        AuthHelperAccessCheck(vehicleList);
      }
    }
  };

  checkStaus = (item) => {
    if (item.status === 0) {
      const selectedItem = {
        subscriber_id: item.subscriber_id,
        autoid: item.autoid,
        status: 1,
      };
      return (
        <button
          type="button"
          className="btn btn-primary btn-sm"
          onClick={() => this.updateVehicleStatus(selectedItem)}
        >
          Activate
        </button>
      );
    } else if (item.status === 1 || item.status === 2) {
      const selectedItem = {
        subscriber_id: item.subscriber_id,
        autoid: item.autoid,
        status: 0,
      };
      return (
        <button
          type="button"
          className="btn btn-primary btn-sm"
          onClick={() => this.updateVehicleStatus(selectedItem)}
        >
          De-activate
        </button>
      );
    }
  };

  updateVehicleStatus = async (selectedItem) => {
    const updateInputes = {
      subscriber_id: this.state.subscriber_id
        ? this.state.subscriber_id.value.autoid
        : 0,
      autoid: selectedItem.autoid,
      status: selectedItem.status,
    };
    const updateVehicle = await api.post("vehicle/statusUpdate", updateInputes);
    if (updateVehicle) {
      this.onToastItemAdd(updateVehicle.status, updateVehicle.message);
    }
  };

  vehicleAdd = () => {
    const ButtonAdd = () => (
      <Route
        render={({ history }) => (
          <Fab
            color="primary"
            variant="extended"
            size="medium"
            onClick={() => {
              history.push({
                pathname: "/vehicleadd",
                state: {
                  type: "add",
                },
              });
            }}
          >
            <AddIcon
            // className={classes.extendedIcon}
            />
            Add
          </Fab>
        )}
      />
    );
    return <ButtonAdd />;
  };

  vehicleEdit = (Item) => {
    const ButtonEdit = () => (
      <Route
        render={({ history }) => (
          <button
            type="button"
            className="btn btn-link"
            title="Edit"
            onClick={() => {
              history.push({
                pathname: "/vehicleadd",
                state: {
                  vehicleId: Item.autoid,
                  type: "edit",
                },
              });
            }}
          >
            <i className="fa fa-pencil" aria-hidden="true" />
          </button>
        )}
      />
    );
    return <ButtonEdit />;
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: parseInt(event.target.value, 10), page: 0 });
  };

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleRequestSort = (event, property) => {
    const isAsc = this.state.orderBy === property && this.state.order === "asc";
    this.setState({ order: isAsc ? "desc" : "asc", orderBy: property });
  };

  render() {
    let vehicleLists = this.state.filteredDataRows;

    const { classes } = this.props;
    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h3" style={{ fontWeight: "bold" }}>
            Vehicles List
          </Typography>
        </Grid>

        <Grid item xs={6} style={{ textAlign: "left" }}>
          <Typography variant="h5" style={{ fontWeight: "bold" }}>
            Total : {vehicleLists.length}
          </Typography>
        </Grid>

        <Grid item xs={6} style={{ textAlign: "right" }}>
          {this.vehicleAdd()}
        </Grid>

        <Grid item xs={12} sm={12} style={{ textAlign: "left" }}>
          <Paper elevation={3}>
            <Grid
              container
              spacing={1}
              // justify="center"
              style={{ padding: "1em" }}
            >
              <Grid item xs={6} sm={5}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-helper-label">
                    Subscriber Name
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    name="subscriber_id"
                    value={this.state.subscriber_id}
                    onChange={(event) => {
                      this.setState({ subscriber_id: event.target.value });
                      console.log(event.target.value);
                      const countInput = {
                        subscriber_id: event.target.value,
                        searchPlateNumber: this.state.searchPlateNumber,
                      };
                      this.getAllVehicles(countInput);
                    }}
                  >
                    <MenuItem value={0}>All</MenuItem>
                    {this.state.companyList.map((subscriber, index) => (
                      <MenuItem value={subscriber.value.autoid} key={index}>
                        {subscriber.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={6} sm={4}>
                <TextField
                  fullWidth
                  name="searchPlateNumber"
                  label="Title"
                  value={this.state.searchPlateNumber}
                  onChange={(event) => {
                    this.setState({ searchPlateNumber: event.target.value });

                    const countInput = {
                      subscriber_id: this.state.subscriber_id,
                      searchPlateNumber: event.target.value,
                    };

                    this.getAllVehicles(countInput);
                  }}
                  inputProps={{ maxLength: 50 }}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        <Grid item xs={12} style={{ minHeight: "25em" }}>
          <Paper elevation={3} style={{ backgroundColor: "#fff" }}>
            {this.state.loading ? (
              this.state.loading && (
                <Paper
                  elevation={3}
                  style={{ backgroundColor: "#fff", minHeight: "15em" }}
                >
                  <CircularProgress
                    size={50}
                    // className={classes.buttonProgress}
                  />
                </Paper>
              )
            ) : (
              <div>
                {" "}
                <Table
                // className={classes.table}
                >
                  <EnhancedTableHead
                    classes={classes}
                    // numSelected={selected.length}
                    order={this.state.order}
                    orderBy={this.state.orderBy}
                    // onSelectAllClick={handleSelectAllClick}
                    onRequestSort={this.handleRequestSort}
                    rowCount={vehicleLists.length}
                  />
                  <TableBody>
                    {stableSort(
                      vehicleLists,
                      getComparator(this.state.order, this.state.orderBy)
                    )
                      .slice(
                        this.state.page * this.state.rowsPerPage,
                        this.state.page * this.state.rowsPerPage +
                          this.state.rowsPerPage
                      )
                      .map((_vehicle, i) => (
                        <TableRow key={i}>
                          <TableCell component="th" scope="row">
                            {_vehicle.title}
                          </TableCell>
                          <TableCell>{_vehicle.plate_number}</TableCell>
                          <TableCell>{_vehicle.fleet_number}</TableCell>
                          <TableCell>{_vehicle.manufacture_name}</TableCell>
                          <TableCell>{_vehicle.model}</TableCell>

                          <TableCell>{_vehicle.fuel_type}</TableCell>
                          <TableCell>{_vehicle.company_name}</TableCell>
                          <TableCell>
                            {this.vehicleEdit(_vehicle)}
                            {this.checkStaus(_vehicle)}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
                <TablePagination
                  rowsPerPageOptions={[50, 200, 500]}
                  component="div"
                  count={vehicleLists.length}
                  rowsPerPage={this.state.rowsPerPage}
                  page={this.state.page}
                  onChangePage={this.handleChangePage}
                  onChangeRowsPerPage={this.handleChangeRowsPerPage}
                />
              </div>
            )}
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

export default SupportCenterVehicleList;
