import React from "react";
import { Col, Row } from "reactstrap";
import Datetime from "react-datetime";
import { Route } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
import moment from "moment";

import CmbSelect from "./CmbSelect";
import ToastAlert from "../compon/ToastAlert";
import { AppHelper_DateAndTimeToString } from "../businessjs/AppHelperFn";
import { api } from "../businessjs/API";

class SubscriberEdit extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      toastItems: [],
      companyName: "",
      companyCode: "",
      licenseNumber: "Unknown",
      countries: [],
      country: "",
      accountType: "",
      currency: "",
      shipmentPreference: 0,
      registrationType: "Unknown",
      createdDate: "",
      expiryDate: "",
      loading: false,
    };
  }

  componentDidMount = () => {
    this.getCountry();
  };

  getCountry = async () => {
    try {
      const countryResult = await api.post("country/getAllCountries");

      if (countryResult.length !== 0) {
        let dbArray = countryResult.map((opt) => ({
          label: opt.name,
          value: opt,
        }));
        dbArray.splice(0, 0, { label: "All", value: { autoid: 0 } });
        this.setState({ countries: dbArray });
        const getSubscriberInputs = {
          companyId: this.props.accountid,
        };
        this.getSubscriberDetailsById(getSubscriberInputs);
      }
    } catch (error) {
      console.log("something went wrong!", error);
    }
  };

  getSubscriberDetailsById = async (getSubscriberInputs) => {
    this.setState({ loading: true });
    try {
      const subscriberDetails = await api.post(
        "sc-subscriber/get-subscriber-details-by-id",
        getSubscriberInputs
      );

      if (subscriberDetails.length !== 0) {
        this.setState({
          companyCode: subscriberDetails[0].company_code,
          accountType: subscriberDetails[0].account_type,
          licenseNumber: subscriberDetails[0].license_number,
          companyName: subscriberDetails[0].company_name,
          country: this.getCurrentCountry(subscriberDetails[0].country),
          registrationType: subscriberDetails[0].registration_type,
          shipmentPreference: subscriberDetails[0].shipment_preference,
          currency: subscriberDetails[0].default_currency,
          createdDate: new Date(subscriberDetails[0].createdtime),
          expiryDate: new Date(subscriberDetails[0].expirytime),
          loading: false,
        });
      }
    } catch (error) {
      this.setState({ loading: false });
      console.log("something went wrong!", error);
    }
  };

  getCurrentCountry = (value) => {
    if (this.state.countries) {
      let filteredDataRows = this.state.countries.filter((el) => {
        return el.value.autoid === value;
      })[0];
      return filteredDataRows;
    }
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleToastItemClose = (itemObject) => {
    const toastItems = this.state.toastItems.filter(
      (c) => c.id !== itemObject.id
    );
    this.setState({ toastItems });
  };

  onToastItemAdd(type, message) {
    let newid = this.state.toastItems.length + 1;
    if (
      this.state.toastItems.filter(function (e) {
        return e.id === newid;
      }).length > 0
    ) {
      newid = this.state.toastItems.length + Math.floor(Math.random() * 100);
    }
    let newToastItems = this.state.toastItems;
    let itemToast = {
      id: newid,
      type: type === "success" ? "success" : "danger",
      message: message,
    };
    newToastItems.push(itemToast);
    this.setState({ toastItems: newToastItems });

    setTimeout(() => {
      this.handleToastItemClose(itemToast);
    }, 3000);
  }

  cancelButton = () => {
    const ButtonCancel = () => (
      <Route
        render={({ history }) => (
          <button
            type="reset"
            className="btn btn-sm btn-warning mr-1"
            onClick={() => {
              history.goBack({
                pathname: "/supportcenter/subscriberlist",
              });
            }}
          >
            Cancel
          </button>
        )}
      />
    );
    return <ButtonCancel />;
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onExpiryDateChange = (e) => {
    this.setState({ expiryDate: e._d });
  };

  onSubmit = async (e) => {
    e.preventDefault();
    const updateSubscriberInputs = {
      companyCode: this.state.companyCode,
      accountType: this.state.accountType,
      licenseNumber: this.state.licenseNumber,
      companyName: this.state.companyName,
      country: this.state.country
        ? this.state.country.value.autoid
        : this.state.country,
      registrationType: this.state.registrationType,
      shipmentPreference: this.state.shipmentPreference,
      currency: this.state.currency,
      createdDate: new Date(this.state.createdDate),
      expiryDate: new Date(this.state.expiryDate),
      companyId: this.props.accountid,
    };

    const updateResult = await api.post(
      "sc-subscriber/update-subscriber",
      updateSubscriberInputs
    );

    if (updateResult.status === "success") {
      this.onToastItemAdd(updateResult.status, updateResult.message);
    }
  };

  render() {
    return (
      <React.Fragment>
        <div className="container mt-4 mb-2">
          <Row
            style={{
              margin: "0",
            }}
          >
            <Col
              style={{
                flexDirection: "column",
                display: "flex",
              }}
            >
              <Row>
                <ToastAlert
                  toastItems={this.state.toastItems}
                  onItemClose={this.handleToastItemClose}
                />
                <Col>
                  <div className="card">
                    <div className="card-content collpase show">
                      <div className="card-body">
                        <form className="form" onSubmit={this.onSubmit}>
                          <div className="form-body">
                            <Row>
                              <Col>
                                <label>Subscriber Name</label>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="companyName"
                                  required
                                  value={this.state.companyName}
                                  onChange={this.onChange}
                                />
                              </Col>
                            </Row>
                            <Row className="mt-4">
                              <Col xl={6} lg={6} md={6} sm={6} xs={12}>
                                <Row>
                                  <Col>
                                    <label>Account Code</label>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="companyCode"
                                      required
                                      value={this.state.companyCode}
                                      onChange={this.onChange}
                                    />
                                  </Col>
                                </Row>
                              </Col>
                              <Col xl={6} lg={6} md={6} sm={6} xs={12}>
                                <Row>
                                  <Col>
                                    <label>License Number</label>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="licenseNumber"
                                      required
                                      value={this.state.licenseNumber}
                                      onChange={this.onChange}
                                    />
                                  </Col>
                                </Row>
                              </Col>
                            </Row>

                            <Row className="mt-4">
                              <Col xl={4} lg={3} md={6} sm={6}>
                                <Row>
                                  <Col>
                                    <label>Country</label>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col>
                                    <CmbSelect
                                      name="country"
                                      value={this.state.country}
                                      onChange={this.onChange}
                                      dataSource={this.state.countries}
                                    />
                                  </Col>
                                </Row>
                              </Col>
                              <Col xl={4} lg={3} md={6} sm={6}>
                                <Row>
                                  <Col>
                                    <label>Account Type</label>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col>
                                    <select
                                      className="form-control"
                                      name="accountType"
                                      onChange={this.onChange}
                                      value={this.state.accountType}
                                      required
                                    >
                                      <option value="0">Select</option>
                                      <option value="1">Basic</option>
                                      <option value="2">Premium</option>
                                    </select>
                                  </Col>
                                </Row>
                              </Col>

                              <Col xl={4} lg={3} md={6} sm={6}>
                                <Row>
                                  <Col>
                                    <label>Currency</label>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col>
                                    <select
                                      className="form-control"
                                      name="currency"
                                      onChange={this.onChange}
                                      value={this.state.currency}
                                      required
                                    >
                                      <option value="0">Select</option>
                                      <option value="1">Riyal</option>
                                      <option value="2">USD</option>
                                    </select>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>

                            <Row className="mt-4">
                              <Col xl={4} lg={4} md={4} sm={6}>
                                <Row>
                                  <Col>
                                    <label>Created Date</label>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col>
                                    <Datetime
                                      value={this.state.createdDate}
                                      input={true}
                                      inputProps={{
                                        placeholder: "N/A",
                                        disabled: true,
                                      }}
                                    />
                                  </Col>
                                </Row>
                              </Col>

                              <Col xl={4} lg={4} md={4} sm={6}>
                                <Row>
                                  <Col>
                                    <label>Expiry Date</label>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col>
                                    <Datetime
                                      value={this.state.expiryDate}
                                      input={true}
                                      closeOnSelect={true}
                                      onChange={this.onExpiryDateChange}
                                    />
                                  </Col>
                                </Row>
                              </Col>
                            </Row>

                            <Row
                              className="mt-4"
                              style={{ alignItems: "flex-start" }}
                            >
                              <Col>
                                {/* <div className="form-actions right"> */}
                                {this.cancelButton()}
                                <>
                                  <button
                                    type="submit"
                                    className="btn btn-primary btn-sm"
                                    disabled={this.state.loading}
                                  >
                                    <i className="fa fa-check-square-o" />
                                    Update
                                  </button>

                                  {this.state.loading && (
                                    <CircularProgress
                                      size={24}
                                      // className={classes.buttonProgress}
                                    />
                                  )}
                                </>
                                {/* </div> */}
                              </Col>
                            </Row>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </React.Fragment>
    );
  }
}

export default SubscriberEdit;
