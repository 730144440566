import React, { useState, FunctionComponent, useEffect } from "react";
import {
  Paper,
  makeStyles,
  Grid,
  Typography,
  TextField,
  Fab,
  Divider,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  CircularProgress,
} from "@material-ui/core";
import { Formik, Form } from "formik";
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from "react-router-dom";
import { api } from "../../businessjs/API";
import { SnackBar } from "../Login/SnackBar";
import { Redirect } from "react-router-dom";
import moment from "moment";
import { ProductTable } from "./ProductTable";
import { ProductAddForm } from "./ProductAddForm";

const useStyles = makeStyles((theme) => ({
  formControl: {
    // margin: theme.spacing(1),
    minWidth: 120,
  },
  table: {
    minWidth: 650,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  buttonProgress: {
    position: "absolute",
    left: "62.5%",
    marginTop: "0.5em",
  },
}));
const statusOptions = [
  {
    value: 1,
    label: "Active",
  },
  { value: 0, label: "Cancelled" },
];
export const PurchaseOrderForm = (props) => {
  const classes = useStyles();
  // const [countryOptions, setCountryOptions] = useState([]);
  const [error, setError] = useState(false);
  const [color, setColor] = useState("#d50000");
  const [infoMessgae, setInfoMessage] = useState(false);
  const [navigation, setNavigation] = useState(false);
  const [accountDetails, setAccountDetails] = useState([]);
  const [accountOptions, setAccountOptions] = useState([]);
  const [contactOptions, setContactOptions] = useState([]);
  const [allContacts, setAllContacts] = useState([]);
  const [accountName, setAccountName] = useState("");
  const [addProductVisible, setAddProductVisible] = useState(false);
  const [productList, setProductList] = useState([]);
  const [productRow, setProductRow] = useState([]);
  const [refNumber, setRefNumber] = useState("");
  const [poDate, setPoDate] = useState(moment().format("YYYY-MM-DD"));
  const [accountId, setAccountId] = useState("");
  const [accountStreetAddress, setAccountStreetAddress] = useState("");
  const [accountCity, setAccountCity] = useState("");
  const [accountState, setAccountState] = useState("");
  const [accountZip, setAccountZip] = useState("");
  const [accountContactName, setAccountContactName] = useState("");
  const [approvedBy, setApprovedBy] = useState("");
  const [comments, setComments] = useState("");
  const [subTotal, setSubTotal] = useState("");
  const [discount, setDiscount] = useState(0);
  const [totalPrice, setTotalPrice] = useState("");
  const [productObject, setProductObject] = useState();
  const [otherCharges, setOtherCharges] = useState(0);
  const [shipTo, setShipTo] = useState("Qatar");
  const [addProductFlag, setAddProductFlag] = useState(false);
  const [tableTotal, setTableTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(1);
  const [disableAdd, setDisableAdd] = useState(false);
  const [discountValueError, setDiscountValueError] = useState(false);
  const NavLink = React.forwardRef((props, ref) => (
    <RouterLink innerRef={ref} {...props} />
  ));
  useEffect(() => {
    const id = props.match.params.id;
    async function loadPurchaseOrders() {
      try {
        if (id !== "new") {
          setLoading(true);
          const purchaseOrderId = id;
          const purchaseOrderDetails = await api.post(
            "sc-purchase-order/get-purchase-order-by-id",
            { purchaseOrderId }
          );
          // console.log("purchaseorderList", purchaseOrderDetails);
          if (
            purchaseOrderDetails !== undefined &&
            purchaseOrderDetails.status !== "failed"
          ) {
            const purchaseOrder = purchaseOrderDetails.purchaseOrder;
            const productArray = purchaseOrderDetails.poProductSet;
            setPoDate(moment(purchaseOrder.po_date).format("YYYY-MM-DD"));
            setAccountName(purchaseOrder.account_id);
            setAddProductVisible(false);
            setProductRow(productArray);
            setRefNumber(purchaseOrder.reference_id);
            setAccountId(purchaseOrder.account_id);
            setAccountStreetAddress(purchaseOrder.address);
            setAccountCity(purchaseOrder.city);
            setAccountState(purchaseOrder.state);
            setAccountZip(purchaseOrder.zipcode);
            setAccountContactName(purchaseOrder.contact_id);
            setApprovedBy(purchaseOrder.approved_by);
            setComments(purchaseOrder.comments);
            setSubTotal(
              parseFloat(purchaseOrder.discount) +
                parseFloat(purchaseOrder.total_amount) -
                parseFloat(purchaseOrder.other_charge)
            );
            setDiscount(purchaseOrder.discount);
            setTotalPrice(purchaseOrder.total_amount);
            setOtherCharges(purchaseOrder.other_charge);
            setShipTo(purchaseOrder.ship_to);
            setStatus(purchaseOrder.status);
            setLoading(false);
          } else {
            setInfoMessage("Failed to load data because of slow network");
            setColor("#d50000");
            setLoading(false);
          }
        } else {
        }
      } catch (e) {
        console.log(e);
      }
    }
    loadPurchaseOrders();
  }, [props.match.params.id]);
  useEffect(() => {
    async function loadAccounts_Products_Contacts() {
      try {
        const accountOptionsList = await api.post(
          "sc-account/get-all-vendor-accounts"
        );
        if (accountOptionsList && Array.isArray(accountOptionsList)) {
          setAccountOptions(accountOptionsList);
        }
        const inputProduct = {
          company_id: localStorage.apiCompanyId,
          division_id: localStorage.apiDivisionId,
        };
        const allContacts = await api.post("sc-contact/get-all-for-cmb");
        if (allContacts && Array.isArray(allContacts)) {
          setContactOptions(allContacts);
          setAllContacts(allContacts);
        }
        const allProducts = await api.post(
          "sc-product/get-all-product",
          inputProduct
        );
        // console.log('allProducts',allProducts);
        setProductList(allProducts);
      } catch (e) {
        console.log(e);
      }
    }
    loadAccounts_Products_Contacts();
  }, [accountOptions.length]);
  const onEditTableRow = (name) => {
    const productDetails =
      productRow && productRow.find((item) => item.productName === name);
    // console.log("productDetails", productDetails);
    if (productDetails !== undefined) {
      setProductObject(productDetails);
      setAddProductVisible(true);
    }
  };
  const onDeleteRow = (name) => {
    const productDetails =
      productRow && productRow.filter((item) => item.productName !== name);
    setProductRow(productDetails);
  };

  const handleSelectChange = async (value) => {
    try {
      const accountList = await api.post("sc-account/get-account-list");
      // console.log("accountList form", accountList);
      if (accountList.status !== "failed") {
        setAccountDetails(accountList);
      }
      setAccountName(value);
      const initialValuesAccount =
        accountList &&
        accountList.find((account) => account.autoid === parseInt(value));
      // console.log("accountDetails",accountDetails)
      // console.log("initialValues>>>>>>>>>", initialValuesAccount);
      if (initialValuesAccount !== undefined) {
        if (initialValuesAccount.autoid !== undefined) {
          setAccountId(initialValuesAccount.autoid);
        }
        if (initialValuesAccount.address !== undefined) {
          setAccountStreetAddress(initialValuesAccount.address);
        }
        if (initialValuesAccount.state !== undefined) {
          setAccountState(initialValuesAccount.state);
        }
        if (initialValuesAccount.city !== undefined) {
          setAccountCity(initialValuesAccount.city);
        }
        if (initialValuesAccount.zipcode !== undefined) {
          setAccountZip(initialValuesAccount.zipcode);
        }
        if (initialValuesAccount.contact_name !== undefined) {
          const name = initialValuesAccount.contact_name;
          const contactId =
            contactOptions &&
            contactOptions.find((contact) => contact.first_name === name);
          if (contactId !== undefined) {
            const id = contactId.autoid;
            setAccountContactName(id);
          }
        }
      }

      // Selecting corresponding contacts.
      const ownContacts =
        allContacts &&
        allContacts.filter((contact) => contact.account_id === value);

      setContactOptions(ownContacts);
    } catch (e) {
      console.log(e);
    }
  };
  const handleSelectContactChange = (value) => {
    setAccountContactName(value);
  };
  const handleStatusChange = (value) => {
    setStatus(value);
    if (value === 0) {
      setDisableAdd(true);
    } else {
      setDisableAdd(false);
    }
  };
  const onCloseSnackbar = () => {
    setInfoMessage("");
  };
  const onAddProduct = () => {
    setAddProductVisible(true);
    setProductObject();
    setAddProductFlag(true);
  };
  const addProductDialogClose = () => {
    setAddProductVisible(false);
  };
  const onProductAddSave = (values) => {
    // console.log("productValues", values);
    if (productRow.length === 0) {
      setProductRow([values]);
      setSubTotal(values.total);
      setTotalPrice(values.total);
    } else {
      setAddProductFlag(false);
      const duplicateProductRow = productRow.map((item) => ({ ...item }));

      const overlappedRow =
        duplicateProductRow &&
        duplicateProductRow.find(
          (item) => item.productName === values.productName
        );
      if (overlappedRow) {
        const nonOverlappedRow =
          duplicateProductRow &&
          duplicateProductRow.filter(
            (item) => item.productName !== values.productName
          );
        overlappedRow.quantity = values.quantity;
        overlappedRow.total = values.total;
        const products = [overlappedRow, ...nonOverlappedRow];
        setProductRow(products);
        const priceArray =
          products && products.map((item) => parseFloat(item.total));
        const total = priceArray.reduce((a, b) => a + b, 0);
        setTableTotal(total);
        setSubTotal(total);
        setTotalPrice(total);
      } else {
        const products = [...productRow, values];
        setProductRow(products);
        const priceArray =
          products && products.map((item) => parseFloat(item.total));
        const total = priceArray.reduce((a, b) => a + b, 0);
        setTableTotal(total);
        setSubTotal(total);
        setTotalPrice(total);
      }
    }
    setAddProductVisible(false);
  };
  const onDiscountChange = (value) => {
    // console.log("value", value);
    if (value < 0) {
      setDiscount(value);
      setDiscountValueError(true);
    } else {
      setDiscount(value);
      setDiscountValueError(false);
      if (value === "") {
        setTotalPrice(parseFloat(subTotal) + parseFloat(otherCharges) - 0);
      } else {
        setTotalPrice(parseFloat(subTotal) + parseFloat(otherCharges) - value);
      }
    }
  };
  const onOtherChargesChange = (value) => {
    setOtherCharges(value);
    setTotalPrice(parseFloat(subTotal) + parseFloat(value) - discount);
  };
  const onOtherChargesBlur = (value) => {
    if (value === "") {
      setOtherCharges(0);
    }
  };
  const onDiscountBlur = () => {
    if (discount === "") {
      setDiscount(0);
    }
  };
  const onCancel = () => {
    setNavigation(true);
  };
  const productXML = () => {
    let xmlStr = "";
    if (productRow && productRow.length) {
      xmlStr += "<Items>";
      productRow.forEach(function (value) {
        xmlStr += '<Item product_id="' + value.autoid + '"  ';
        xmlStr += 'price="' + value.price + '" ';
        xmlStr += 'quantity="' + value.quantity + '" ';
        xmlStr += 'status="' + 1 + '" ';
        xmlStr += 'discount="' + value.discount + '" />';
      });
      xmlStr += "</Items>";
    }

    return xmlStr;
  };
  const purchaseOrderSubmit = async () => {
    if (productRow.length === 0) {
      setInfoMessage("Please Select atleast one product");
      setColor("#d50000");
    } else {
      if (props.match.params.id === "new") {
        if (accountName === "") {
          setInfoMessage("Please Select AccountName");
          setColor("#d50000");
        } else if (accountContactName === "") {
          setInfoMessage("Please Select ContactName");
          setColor("#d50000");
        } else {
          setLoading(true);
          const purchaseInput = {
            companyId: localStorage.apiCompanyId,
            poDate: poDate,
            accountId: accountId,
            approvedBy: approvedBy,
            comments: comments,
            totalAmount: totalPrice,
            otherCharge: otherCharges,
            discount: discount,
            shipTo: shipTo,
            referenceId: refNumber,
            address: accountStreetAddress,
            state: accountState,
            city: accountCity,
            zipcode: accountZip,
            contactId: accountContactName,
            status: status,
            purchaseOrderItems: productXML(),
          };
          // console.log("purchaseInput", purchaseInput);
          const addResult = await api.post(
            "sc-purchase-order/add-purchase-order",
            purchaseInput
          );
          // console.log("addResult", addResult);
          setLoading(false);
          if (addResult.status === "success") {
            setInfoMessage(addResult.message);
            setColor("#4caf50");
            setTimeout(() => {
              setNavigation(true);
            }, 2000);
          } else {
            setInfoMessage(addResult.message);
            setColor("#d50000");
            setNavigation(false);
          }
        }
      } else {
        setLoading(true);
        const purchaseInput = {
          poDate: poDate,
          accountId: accountId,
          approvedBy: approvedBy,
          comments: comments,
          totalAmount: totalPrice,
          otherCharge: otherCharges,
          discount: discount,
          shipTo: shipTo,
          referenceId: refNumber,
          address: accountStreetAddress,
          state: accountState,
          city: accountCity,
          zipcode: accountZip,
          contactId: accountContactName,
          status: status,
          purchaseOrderItems: productXML(),
          purchaseOrderId: props.match.params.id,
        };
        // console.log("purchaseInput", purchaseInput);
        const addResult = await api.post(
          "sc-purchase-order/update-purchase-order",
          purchaseInput
        );
        // console.log("addResult", addResult);
        setLoading(false);
        if (addResult.status === "success") {
          setInfoMessage(addResult.message);
          setColor("#4caf50");
          setTimeout(() => {
            setNavigation(true);
          }, 2000);
        } else {
          setInfoMessage(addResult.message);
          setColor("#d50000");
          setNavigation(false);
        }
      }
    }
  };
  if (navigation) {
    return <Redirect to="/purchase-order"></Redirect>;
  } else {
    return (
      <div>
        <SnackBar
          status={infoMessgae}
          onCloseError={onCloseSnackbar}
          color={color}
        />
        <Paper elevation={4} style={{ minHeight: "25em", minWidth: "50%" }}>
          <Formik>
            <Form>
              <Grid container spacing={3} style={{ padding: "2em" }}>
                <Grid item xs={12}>
                  <Typography variant="h4" style={{ fontWeight: "bold" }}>
                    {props.match.params.id === "new"
                      ? "Add new Purchase Order"
                      : "Edit purchase Order"}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    required
                    fullWidth
                    variant="outlined"
                    name="refNumber"
                    label="Reference Numer"
                    value={refNumber}
                    onChange={(event) => setRefNumber(event.target.value)}
                    inputProps={{ maxLength: 50 }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
                    id="status"
                    select
                    label="Status"
                    value={status}
                    onChange={(event) => handleStatusChange(event.target.value)}
                    // helperText="Please select your currency"
                    variant="outlined"
                  >
                    {statusOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    required
                    fullWidth
                    variant="outlined"
                    type="date"
                    name="poDate"
                    label="PO Date"
                    defaultValue={poDate}
                    value={poDate}
                    onChange={(event) => setPoDate(event.target.value)}
                    inputProps={{ maxLength: 50 }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12} style={{ marginTop: "1.5em" }}>
                  <Typography variant="h5" style={{ fontWeight: "bold" }}>
                    Account Details
                  </Typography>
                  <Divider />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                    fullWidth
                  >
                    <InputLabel id="demo-simple-select-outlined-label">
                      Account Name
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={accountName}
                      onChange={(event) =>
                        handleSelectChange(event.target.value)
                      }
                      label="Account Name"
                    >
                      {accountOptions &&
                        accountOptions.map((option) => (
                          <MenuItem value={option.autoid} key={option.autoid}>
                            {option.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    required
                    fullWidth
                    name="accountId"
                    label="Id"
                    variant="outlined"
                    disabled
                    value={accountId}
                    onChange={(event) => setAccountId(event.target.value)}
                    inputProps={{ maxLength: 50 }}
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
                    required
                    variant="outlined"
                    name="accountStreetAddress"
                    label="Street Address"
                    value={accountStreetAddress}
                    onChange={(event) =>
                      setAccountStreetAddress(event.target.value)
                    }
                    inputProps={{ maxLength: 50 }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    required
                    fullWidth
                    variant="outlined"
                    name="accountCity"
                    label="City"
                    value={accountCity}
                    onChange={(event) => setAccountCity(event.target.value)}
                    inputProps={{ maxLength: 50 }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
                    required
                    variant="outlined"
                    name="accountState"
                    label="State"
                    value={accountState}
                    onChange={(event) => setAccountState(event.target.value)}
                    inputProps={{ maxLength: 50 }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
                    required
                    variant="outlined"
                    name="accountZip"
                    label="Zip Code"
                    value={accountZip}
                    onChange={(event) => setAccountZip(event.target.value)}
                    inputProps={{ maxLength: 50 }}
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                    fullWidth
                  >
                    <InputLabel id="demo-simple-select-outlined-label">
                      Contact Name
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={accountContactName}
                      onChange={(event) =>
                        handleSelectContactChange(event.target.value)
                      }
                      label="Account Name"
                    >
                      {contactOptions &&
                        contactOptions.map((option) => (
                          <MenuItem value={option.autoid} key={option.autoid}>
                            {option.first_name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
                    required
                    variant="outlined"
                    name="approvedBy"
                    label="Approved By"
                    value={approvedBy}
                    onChange={(event) => setApprovedBy(event.target.value)}
                    inputProps={{ maxLength: 50 }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="comments"
                    label="Comments"
                    value={comments}
                    onChange={(event) => setComments(event.target.value)}
                    inputProps={{ maxLength: 50 }}
                  />
                </Grid>
                <Grid item xs={12} style={{ textAlign: "left" }}>
                  <Fab
                    variant="extended"
                    size="medium"
                    color="primary"
                    aria-label="add"
                    // type="submit"
                    disabled={loading || disableAdd}
                    onClick={onAddProduct}
                  >
                    Add Product
                  </Fab>
                </Grid>
                <Grid item xs={12}>
                  {addProductVisible ? (
                    <ProductAddForm
                      addProductVisible={addProductVisible}
                      addProductDialogClose={addProductDialogClose}
                      onProductAddSave={onProductAddSave}
                      productList={productList}
                      productObjectDetails={productObject}
                    />
                  ) : (
                    ""
                  )}
                </Grid>
                <Grid item xs={12}>
                  <ProductTable
                    productRow={productRow}
                    onEditTableRow={onEditTableRow}
                    onDeleteRow={onDeleteRow}
                    total={tableTotal}
                  />
                </Grid>
                <Grid item xs={12} style={{ marginTop: "1.5em" }}>
                  <Typography variant="h5" style={{ fontWeight: "bold" }}>
                    Order Details
                  </Typography>
                  <Divider />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
                    required
                    variant="outlined"
                    name="shipTo"
                    label="Ship To"
                    value={shipTo}
                    onChange={(event) => setShipTo(event.target.value)}
                    inputProps={{ maxLength: 50 }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
                    required
                    variant="outlined"
                    name="subTotal"
                    label="Sub Total"
                    disabled
                    value={subTotal}
                    onChange={(event) => setSubTotal(event.target.value)}
                    inputProps={{ maxLength: 50 }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
                    required
                    variant="outlined"
                    name="otherCharges"
                    label="Other Charges"
                    value={otherCharges}
                    onChange={(event) =>
                      onOtherChargesChange(event.target.value)
                    }
                    onBlur={onOtherChargesBlur}
                    inputProps={{ maxLength: 50 }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
                    required
                    variant="outlined"
                    name="discount"
                    label="Discount(Qr)"
                    value={discount}
                    onChange={(event) => onDiscountChange(event.target.value)}
                    onBlur={onDiscountBlur}
                    inputProps={{ maxLength: 50 }}
                    error={discountValueError}
                    helperText={
                      discountValueError
                        ? "Discount Value must be less than 100"
                        : ""
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
                    required
                    variant="outlined"
                    name="totalPrice"
                    label="Order Total"
                    disabled
                    value={totalPrice}
                    onChange={(event) => setTotalPrice(event.target.value)}
                    inputProps={{ maxLength: 50 }}
                  />
                </Grid>
                {/* <Grid item xs={6}></Grid> */}
                <Grid item xs={6} style={{ textAlign: "right" }}>
                  <Fab
                    variant="extended"
                    size="medium"
                    color="primary"
                    type="reset"
                    onClick={onCancel}
                  >
                    Cancel
                  </Fab>
                </Grid>
                <Grid item xs={6} style={{ textAlign: "left" }}>
                  <Fab
                    variant="extended"
                    size="medium"
                    color="primary"
                    aria-label="add"
                    disabled={loading}
                    // type="submit"
                    onClick={purchaseOrderSubmit}
                  >
                    {props.match.params.id === "new" ? "Submit" : "Update"}
                  </Fab>
                  {loading && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </Grid>
              </Grid>
              {/* </form> */}
            </Form>
            {/* )} */}
          </Formik>
        </Paper>
      </div>
    );
  }
};
