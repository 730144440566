import React, { Component } from "react";
import { Col, Row } from "reactstrap";
import Datetime from "react-datetime";
import { Route } from "react-router-dom";
import Divider from "@material-ui/core/Divider";
import jwt_decode from "jwt-decode";
import moment from "moment";

import ToastAlert from "./ToastAlert";
import CmbSelect from "../compon-controls/CmbSelect";
import { Select } from "../compon-controls/Select";

import { api } from "../businessjs/API";

class SubscriberAdd extends Component {
  state = {
    toastItems: [],
    companyName: "",
    companyCode: "",
    licenseNumber: "",
    countries: [],
    country: "",
    accountType: 1,
    currency: 1,
    shipmentPreference: 0,
    registrationType: "Unknown",
    createdDate: moment(new Date()).format("MM/DD/YYYY"),
    // expiryDate: new Date(),
    expiryDate: moment(new Date()).add(1, "years").calendar(),
    errorMessage: "",
    state: "",
    city: "",
    address: "",
    location: "",
    building: "",
    zipcode: "",
    honorific: "Mr",
    firstName: "",
    lastName: "",
    nickName: "",
    email: "",
    password: "",
    confirmPassword: "",
    phone: "",
    accountId: null,
  };

  componentDidMount = async () => {
    if (this.props.match.params.id !== "new") {
      this.getSubscriberDetails(this.props.match.params.id);
    }
    const token = localStorage.apiAuthAccessKey;
    const decoded = jwt_decode(token);
    const decodedUser = decoded.userAccessKey[0];
    const nextAutoIncrement = await api.post(
      "sc-subscriber/get-subscriber-next-auto-increment"
    );

    if (nextAutoIncrement && nextAutoIncrement.length) {
      let code = "C-";
      code += (nextAutoIncrement[0].max_id + 1).toString().padStart(3, "0");
      this.setState({
        role: decodedUser.role,
        companyCode: code,
      });
    }

    this.getCountry();
  };

  getSubscriberDetails = (data) => {
    const subscriberData = JSON.parse(data);
    this.setState({
      companyName: subscriberData.name,
      state: subscriberData.state,
      city: subscriberData.city,
      address: subscriberData.address,
      building: subscriberData.building,
      location: subscriberData.location,
      zipcode: subscriberData.zipcode,
      firstName: subscriberData.first_name,
      lastName: subscriberData.last_name,
      email: subscriberData.contact_email,
      phone: subscriberData.contact_phone,
      accountId: subscriberData.autoid,
      country: subscriberData.country,
    });
  };

  getCountry = async () => {
    try {
      const countryResult = await api.post("country/getAllCountries");
      if (countryResult.length !== 0) {
        let dbArray = countryResult.map((opt) => ({
          label: opt.name,
          value: opt,
        }));

        if (this.props.match.params.id !== "new") {
          const subscriberData = JSON.parse(this.props.match.params.id);
          const currentCountry = dbArray.filter(
            (country) =>
              country.label.toLowerCase() ===
              subscriberData.country.toLowerCase()
          )[0];
          this.setState({ countries: dbArray, country: currentCountry });
        } else {
          this.setState({ countries: dbArray });
        }
      }
    } catch (error) {
      console.log("something went wrong!", error);
    }
  };

  validate = () => {
    let value = true;

    if (this.state.country === "") {
      this.setState({
        errorMessage: "Please select Country",
      });
      value = false;
    } else if (this.state.accountType === 0) {
      this.setState({
        errorMessage: "Please select Account Type",
      });
      value = false;
    } else if (this.state.currency === 0) {
      this.setState({
        errorMessage: "Please select Currency",
      });
      value = false;
    }

    return value;
  };

  onSubmit = async (e) => {
    e.preventDefault();
    if (!this.validate()) return;

    const addSubscriberInputs = {
      companyCode: this.state.companyCode,
      accountType: this.state.accountType,
      licenseNumber: this.state.licenseNumber,
      companyName: this.state.companyName,
      country: this.state.country
        ? this.state.country.value.autoid
        : this.state.country,
      registrationType: this.state.registrationType,
      shipmentPreference: this.state.shipmentPreference,
      currency: this.state.currency,
      createdDate: moment(this.state.createdDate).format("YYYY-MM-DD"),
      expiryDate: moment(this.state.expiryDate).format("YYYY-MM-DD"),
      state: this.state.state,
      city: this.state.city,
      address: this.state.address,
      location: this.state.location,
      building: this.state.building,
      zipcode: this.state.zipcode,
      honorific: this.state.honorific,
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      nickName: this.state.nickName,
      email: this.state.email,
      password: this.state.password,
      phone: this.state.phone,
      role: 1, // default owner
      accountId: this.state.accountId,
    };
    // console.log("addSubscriberInputs", addSubscriberInputs);
    const addResult = await api.post(
      "sc-subscriber/subscriber-add",
      addSubscriberInputs
    );
    // console.log("addResult", addResult);
    if (addResult.status === "success") {
      this.onToastItemAdd(addResult.status, addResult.message);
      setTimeout(() => {
        this.props.history.push({
          pathname: `/supportcenter/subscriberlist`,
        });
      }, 2000);
    } else {
      this.onToastItemAdd(addResult.status, addResult.message);
    }
  };

  handleToastItemClose = (itemObject) => {
    const toastItems = this.state.toastItems.filter(
      (c) => c.id !== itemObject.id
    );
    this.setState({ toastItems });
  };

  onToastItemAdd(type, message) {
    let newid = this.state.toastItems.length + 1;
    if (
      this.state.toastItems.filter(function (e) {
        return e.id === newid;
      }).length > 0
    ) {
      newid = this.state.toastItems.length + Math.floor(Math.random() * 100);
    }

    let newToastItems = this.state.toastItems;
    let itemToast = {
      id: newid,
      type: type === "success" ? "success" : "danger",
      message: message,
    };

    newToastItems.push(itemToast);
    this.setState({ toastItems: newToastItems });

    setTimeout(() => {
      this.handleToastItemClose(itemToast);
    }, 3000);
  }

  cancelButton = () => {
    const ButtonCancel = () => (
      <Route
        render={({ history }) => (
          <button
            type="reset"
            className="btn btn-sm btn-warning mr-1"
            onClick={() => {
              history.goBack({
                pathname: "/supportcenter/subscriberlist",
              });
            }}
          >
            Cancel
          </button>
        )}
      />
    );
    return <ButtonCancel />;
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onCreatedDateChange = (e) => {
    this.setState({ createdDate: e._d });
  };

  onExpiryDateChange = (e) => {
    this.setState({ expiryDate: e._d });
  };

  render() {
    return (
      <React.Fragment>
        <div className="container  mb-2">
          <Row
            style={{
              margin: "0",
            }}
          >
            <Col
              style={{
                flexDirection: "column",
                display: "flex",
              }}
            >
              <Row>
                <ToastAlert
                  toastItems={this.state.toastItems}
                  onItemClose={this.handleToastItemClose}
                />
                <Col>
                  <div className="card">
                    <div className="card-header">
                      <Row>
                        <Col>
                          <h3>Register </h3>
                        </Col>
                      </Row>
                    </div>
                    <div className="card-content collpase show">
                      <div className="card-body">
                        <form className="form" onSubmit={this.onSubmit}>
                          <div className="form-body">
                            <Row className="mt-3">
                              <Col xl={6} lg={6} md={6} sm={6} xs={12}>
                                <Row>
                                  <Col>
                                    <label>Subscriber Name</label>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="companyName"
                                      required
                                      value={this.state.companyName}
                                      onChange={this.onChange}
                                    />
                                  </Col>
                                </Row>
                              </Col>
                              <Col xl={6} lg={6} md={6} sm={6} xs={12}>
                                <Row>
                                  <Col>
                                    <label>License/CR</label>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="licenseNumber"
                                      // required
                                      value={this.state.licenseNumber}
                                      onChange={this.onChange}
                                    />
                                  </Col>
                                </Row>
                              </Col>
                            </Row>

                            <Row className="mt-3">
                              <Col xl={4} lg={3} md={6} sm={6}>
                                <Row>
                                  <Col>
                                    <label>Country</label>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col>
                                    {/* <Select
                                      name="country"
                                      value={this.state.country}
                                      onChange={this.onChange}
                                      dataSource={this.state.countries}
                                    /> */}
                                    <CmbSelect
                                      name="country"
                                      value={this.state.country}
                                      onChange={this.onChange}
                                      dataSource={this.state.countries}
                                    />
                                  </Col>
                                </Row>
                              </Col>
                              <Col xl={4} lg={3} md={6} sm={6}>
                                <Row>
                                  <Col>
                                    <label>Account Type</label>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col>
                                    <select
                                      className="form-control"
                                      name="accountType"
                                      onChange={this.onChange}
                                      value={this.state.accountType}
                                      required
                                    >
                                      <option value="0">Select</option>
                                      <option value="1">Basic</option>
                                      <option value="2">Premium</option>
                                    </select>
                                  </Col>
                                </Row>
                              </Col>

                              <Col xl={4} lg={3} md={6} sm={6}>
                                <Row>
                                  <Col>
                                    <label>Currency</label>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col>
                                    <select
                                      className="form-control"
                                      name="currency"
                                      onChange={this.onChange}
                                      value={this.state.currency}
                                      required
                                    >
                                      <option value="0">Select</option>
                                      <option value="1">QR</option>
                                      <option value="2">USD</option>
                                    </select>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>

                            <Row className="mt-3">
                              <Col xl={4} lg={3} md={6} sm={6}>
                                <Row>
                                  <Col>
                                    <label>State</label>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="state"
                                      // required
                                      value={this.state.state}
                                      onChange={this.onChange}
                                    />
                                  </Col>
                                </Row>
                              </Col>
                              <Col xl={4} lg={3} md={6} sm={6}>
                                <Row>
                                  <Col>
                                    <label>City</label>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="city"
                                      // required
                                      value={this.state.city}
                                      onChange={this.onChange}
                                    />
                                  </Col>
                                </Row>
                              </Col>
                              <Col xl={4} lg={3} md={6} sm={6}>
                                <Row>
                                  <Col>
                                    <label>Address</label>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="address"
                                      required
                                      value={this.state.address}
                                      onChange={this.onChange}
                                    />
                                  </Col>
                                </Row>
                              </Col>
                            </Row>

                            <Row className="mt-3">
                              <Col xl={4} lg={3} md={6} sm={6}>
                                <Row>
                                  <Col>
                                    <label>Location</label>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="location"
                                      value={this.state.location}
                                      onChange={this.onChange}
                                    />
                                  </Col>
                                </Row>
                              </Col>
                              <Col xl={4} lg={3} md={6} sm={6}>
                                <Row>
                                  <Col>
                                    <label>Building</label>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="building"
                                      // required
                                      value={this.state.building}
                                      onChange={this.onChange}
                                    />
                                  </Col>
                                </Row>
                              </Col>
                              <Col xl={4} lg={3} md={6} sm={6}>
                                <Row>
                                  <Col>
                                    <label>Zip code</label>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="zipcode"
                                      // required
                                      value={this.state.zipcode}
                                      onChange={this.onChange}
                                    />
                                  </Col>
                                </Row>
                              </Col>
                            </Row>

                            <Row className="mt-3">
                              <Col xl={4} lg={4} md={4} sm={4}>
                                <Row>
                                  <Col>
                                    <label>Created Date</label>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col>
                                    <Datetime
                                      defaultValue={this.state.createdDate}
                                      input={true}
                                      closeOnSelect={true}
                                      onChange={this.onCreatedDateChange}
                                      // style={{ width: 40 }}
                                    />
                                  </Col>
                                </Row>
                              </Col>

                              <Col xl={4} lg={4} md={4} sm={6}>
                                <Row>
                                  <Col>
                                    <label>Expiry Date</label>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col>
                                    <Datetime
                                      defaultValue={this.state.expiryDate}
                                      input={true}
                                      closeOnSelect={true}
                                      onChange={this.onExpiryDateChange}
                                    />
                                  </Col>
                                </Row>
                              </Col>
                            </Row>

                            <Row style={{ marginTop: 25 }}>
                              <Col>
                                <h5>Executive</h5>
                              </Col>
                            </Row>
                            <Divider style={{ backgroundColor: "#bbb8b8" }} />
                            <Row className="mt-3">
                              <Col xl={3} lg={3} md={6} sm={6}>
                                <Row>
                                  <Col>
                                    <label>Honorific</label>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col>
                                    <select
                                      className="form-control"
                                      name="honorific"
                                      onChange={this.onChange}
                                      value={this.state.honorific}
                                      required
                                    >
                                      <option value="0">Select</option>
                                      <option value="Mr">Mr.</option>
                                      <option value="Mrs">Mrs.</option>
                                      <option value="Ms">Ms.</option>
                                      <option value="Miss">Miss.</option>
                                    </select>
                                  </Col>
                                </Row>
                              </Col>
                              <Col xl={3} lg={3} md={6} sm={6}>
                                <Row>
                                  <Col>
                                    <label>First Name</label>
                                  </Col>
                                </Row>

                                <Row style={{ marginTop: 5 }}>
                                  <Col>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="firstName"
                                      required
                                      value={this.state.firstName}
                                      onChange={this.onChange}
                                    />
                                  </Col>
                                </Row>
                              </Col>
                              <Col xl={3} lg={3} md={6} sm={6}>
                                <Row>
                                  <Col>
                                    <label>Last Name</label>
                                  </Col>
                                </Row>

                                <Row style={{ marginTop: 5 }}>
                                  <Col>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="lastName"
                                      // required
                                      value={this.state.lastName}
                                      onChange={this.onChange}
                                    />
                                  </Col>
                                </Row>
                              </Col>
                              <Col xl={3} lg={3} md={6} sm={6}>
                                <Row>
                                  <Col>
                                    <label>Nick Name</label>
                                  </Col>
                                </Row>

                                <Row style={{ marginTop: 5 }}>
                                  <Col>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="nickName"
                                      value={this.state.nickName}
                                      onChange={this.onChange}
                                    />
                                  </Col>
                                </Row>
                              </Col>
                            </Row>

                            <Row className="mt-3">
                              <Col xl={3} lg={3} md={6} sm={6}>
                                <Row>
                                  <Col>
                                    <label>E-mail</label>
                                  </Col>
                                </Row>

                                <Row style={{ ali: "center" }}>
                                  <Col>
                                    <input
                                      type="email"
                                      className="form-control"
                                      name="email"
                                      required
                                      value={this.state.email}
                                      onChange={this.onChange}
                                    />
                                  </Col>
                                </Row>
                              </Col>
                              <Col xl={3} lg={3} md={6} sm={6}>
                                <Row>
                                  <Col>
                                    <label>Password</label>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col>
                                    <input
                                      type="password"
                                      className="form-control"
                                      name="password"
                                      required
                                      value={this.state.password}
                                      onChange={this.onChange}
                                    />
                                  </Col>
                                </Row>
                              </Col>

                              <Col xl={3} lg={3} md={6} sm={6}>
                                <Row>
                                  <Col>
                                    <label>Phone</label>
                                  </Col>
                                </Row>

                                <Row style={{ ali: "center" }}>
                                  <Col>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="phone"
                                      // required
                                      value={this.state.phone}
                                      onChange={this.onChange}
                                    />
                                  </Col>
                                </Row>
                              </Col>
                            </Row>

                            <Row className="mt-4">
                              <Col>
                                <div className="form-actions">
                                  {this.cancelButton()}
                                  <button
                                    type="submit"
                                    className="btn btn-primary btn-sm"
                                  >
                                    <i className="fa fa-check-square-o" />
                                    Submit
                                  </button>
                                </div>
                              </Col>
                              <Col>
                                <span className="text-danger">
                                  {this.state.errorMessage}
                                </span>
                              </Col>
                            </Row>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </React.Fragment>
    );
  }
}

export default SubscriberAdd;
