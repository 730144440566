import {
  Fab,
  Grid,
  Paper,
  Tab,
  Tabs,
  Typography,
  Button,
  Tooltip,
  CircularProgress,
} from "@material-ui/core";

// import { TabContext, TabList, TabPanel } from "@material-ui/lab";

import React, { Component, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import { Route, Link } from "react-router-dom";
import { api } from "../../businessjs/API";
import { ProductDetailPanel } from "./ProductDetailPanel";
import { SerialNumbersPanel } from "./SerialNumbersPanel";
const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
});

export const StockView = (props) => {
  const classes = useStyles();
  const productId = props.match.params.id;

  // const [value, setValue] = useState(0);
  const [value, setValue] = React.useState(0);
  const [serialNumbers, setSerialNumbers] = useState(undefined);
  const [productDetails, setProductDetails] = useState(undefined); // default is undeined.
  const [batchArray, setBatchArray] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const loadProductStock = async () => {
      setLoading(true);
      try {
        const input = {
          product_id: props.match.params.id,
        };
        const productStockData = await api.post(
          "sc-product/get-product-stock-details",
          input
        );
        if (productStockData.product) {
          const serialArray = productStockData.productSerial.filter(
            (item) => item.serial_no !== ""
          );
          const batchTempArray = productStockData.productSerial.filter(
            (item) => item.serial_no === ""
          );
          setProductDetails(productStockData.product);
          setSerialNumbers(serialArray);
          setBatchArray(batchTempArray);
          setLoading(false);
        } else {
          setLoading(false);
        }
      } catch (e) {
        setLoading(false);
        console.log(e);
      }
    };
    if (!productDetails) {
      loadProductStock();
    }
  }, [productDetails]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  if (!productDetails) {
    return null;
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        {loading ? (
          loading && (
            <Paper
              elevation={3}
              style={{
                backgroundColor: "#fff",
                minHeight: "30em",
                textAlign: "center",
              }}
            >
              <CircularProgress size={50} className={classes.buttonProgress} />
            </Paper>
          )
        ) : (
          <Paper className={classes.root}>
            <Tabs
              value={value}
              onChange={handleChange}
              variant="fullWidth"
              indicatorColor="primary"
              textColor="primary"
            >
              <Tab label="Product Details " />
              {serialNumbers && serialNumbers.length ? (
                <Tab label="Serial Numbers " />
              ) : null}
            </Tabs>
            <ProductDetailPanel
              value={value}
              index={0}
              items={productDetails}
              batch={batchArray}
            >
              {productDetails}
            </ProductDetailPanel>

            <SerialNumbersPanel value={value} index={1}>
              {serialNumbers}
            </SerialNumbersPanel>

            {/* <TabContext value={value}>
              <TabList
                onChange={handleChange}
                variant="fullWidth"
                indicatorColor="primary"
                textColor="primary"
              >
                <Tab label="Product Details" value="1" />
                <Tab label="Serial Numbers" value="2" />
              </TabList>

              <TabPanel value="1">
                <ProductDetailPanel children={productDetails} />
              </TabPanel>
              <TabPanel value="2">Item Two</TabPanel>
            </TabContext> */}
          </Paper>
        )}
      </Grid>
    </Grid>
  );
};
