import React, { useState, useEffect } from "react";
import AppRoute from "./routes/AppRoute";
import { ThemeProvider } from "@material-ui/core";
import GlobalStyles from "./compon-styled/GlobalStyles";
import "./compon/mixins/chartjs";
import theme from "./theme";
import { AuthenticationContext } from "./AuthenticationContext";



export const App = () => {
  const [auth,setAuth]=useState()
  const token=localStorage.apiAuthAccessKey;
  // useEffect(()=>{
  //   if(token)
  //   {
  //     setAuth(true)
  //   }
  //   else{
  //     setAuth(false)
  //   }
  // },[])
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <AuthenticationContext.Provider value={{auth,setAuth}}>
        <AppRoute />
      </AuthenticationContext.Provider>
    </ThemeProvider>
  );
};


