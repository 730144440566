import React, { Component } from "react";
import jwt_decode from "jwt-decode";
import {
  AuthHelperValidateOverToken,
  AuthHelperGerSubscriberRoleText,
} from "../../businessjs/AuthHelper";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Paper } from "@material-ui/core";
import Sales from "./Sales";
import ActiveTickets from "./ActiveTickets";
import TotalCustomers from "./TotalCustomers";
import TotalVehicle from "./TotalVehicle";
import DeviceWarranty from "./DeviceWarranty";
import CompletedTickets from "./CompletedTickets";
import { api } from "../../businessjs/API";

const styles = (theme) => ({
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
  },
  toolbar: theme.mixins.toolbar,
});
class SubscriberHome extends Component {
  constructor(props) {
    super(props);
  this.state={dashBoardData:{}};
  }
  componentDidMount = () => {
    this.getDashBoardData();
    if (AuthHelperValidateOverToken("layoutsupportcenter")) {
      const token = localStorage.apiAuthAccessKey;
      const decoded = jwt_decode(token);
      const decodedUser = decoded.userAccessKey[0];

      this.setState({
        subscriber_id: decodedUser.subscriber_id,
        first_name: decodedUser.first_name,
        last_name: decodedUser.last_name,
        email: decodedUser.email,
        account_type: decodedUser.account_type,
        loginType: "supportcenter",
        userType: "supportcenter",
        role: decodedUser.role,
        roleText: AuthHelperGerSubscriberRoleText(decodedUser.role),
      });
      
    } else {
      console.log("SubscriberHome login not valid !!");
    }
    
  };
  getDashBoardData = async () => {
    // console.log("inside componentDidMount");
    const dashBoardDetails = await api.post("sc-dashboard/get-dashboard-data");
    // console.log("dashBordData",dashBoardDetails);
    if(dashBoardDetails!==undefined&&dashBoardDetails.status!=="failed")
    {
      this.setState({dashBoardData:dashBoardDetails})
    }
  };
  render() {
    const { classes } = this.props;
    const {dashBoardData}=this.state;
    // console.log("dasbordInvoice",dashBoardData.invoiceSet)
    return (
      <React.Fragment>
        <Grid container spacing={3}>
          <Grid
            item
            lg={3}
            sm={6}
            xl={3}
            xs={12}
            style={{ borderRadius: "0.75em" }}
          >
            <Paper elevation={5} style={{ borderRadius: "0.75em" }}>
              <TotalCustomers 
              customersCount={dashBoardData.clientCount}
              />
            </Paper>
          </Grid>
          <Grid
            item
            lg={3}
            sm={6}
            xl={3}
            xs={12}
            style={{ borderRadius: "0.75em" }}
          >
            <Paper elevation={5} style={{ borderRadius: "0.75em" }}>
              <ActiveTickets activeTicketsCount={dashBoardData.activeTicketSet}/>
            </Paper>
          </Grid>
          <Grid
            item
            lg={3}
            sm={6}
            xl={3}
            xs={12}
            style={{ borderRadius: "0.75em" }}
          >
            <Paper elevation={5} style={{ borderRadius: "0.75em" }}>
              <CompletedTickets completedTicketsCount={dashBoardData.allTicketstSet}/>
            </Paper>
          </Grid>
          <Grid
            item
            lg={3}
            sm={6}
            xl={3}
            xs={12}
            style={{ borderRadius: "0.75em" }}
          >
            <Paper elevation={5} style={{ borderRadius: "0.75em" }}>
              <TotalVehicle vehiclesCount={dashBoardData.vehicleCount}/>
            </Paper>
          </Grid>
          <Grid
            item
            lg={12}
            md={12}
            xl={9}
            xs={12}
            style={{ borderRadius: "0.75em" }}
          >
            <Paper elevation={5} style={{ borderRadius: "0.75em" }}>
              <Sales salesData={dashBoardData.invoiceSet}/>
            </Paper>
          </Grid>
          {/* <Grid
            item
            lg={4}
            md={6}
            xl={3}
            xs={12}
            style={{ borderRadius: "0.75em" }}
          >
            <Paper elevation={5} style={{ borderRadius: "0.75em" }}>
              <DeviceWarranty />
            </Paper>
          </Grid> */}
        </Grid>
        {/* </Container> */}
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(SubscriberHome);
